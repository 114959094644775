import { ChipStatusType } from "../types/status-chip";

export function statusToColor(status: string): string {
  switch (status) {
    case ChipStatusType.Active:
    case ChipStatusType.Approved:
    case ChipStatusType.Online:
    case ChipStatusType.Processed:
      return "green";
    case ChipStatusType.Deactivated:
      return "black";
    case ChipStatusType.Deleted:
    case ChipStatusType.Removed:
    case ChipStatusType.Rejected:
    case ChipStatusType.Offline:
    case ChipStatusType.Expired:
      return "red";
    case ChipStatusType.Revise:
    case ChipStatusType.Check:
    case ChipStatusType.QualityCheckFailed:
    case ChipStatusType.Pending:
      return "orange";
    case ChipStatusType.Temp:
    case ChipStatusType.New:
    default:
      return "blue";
  }
}
