import { AttributeReviewQueueRoles, Privilege, PrivilegeService } from "@root/commons/privileges";
import { AppNavigation, NavigationItem } from "@gygadmin/client3";
import { Privilege as GraphPrivilege } from "@client/commons/privileges";
import { Role } from "@gygadmin/auth/roles";

const privilegeService = new PrivilegeService();

class Navigation implements AppNavigation {
  getItems(): NavigationItem[] {
    return [
      {
        title: "Activities",
        icon: "mdi-ticket",
        to: "/activities",
        authorize: [],
        items: [
          {
            title: "Search",
            icon: "mdi-magnify",
            to: "/activities",
            authorize: [],
          },
        ],
      },
      {
        title: "Locations",
        icon: "mdi-map-marker",
        to: "/locations",
        authorize: [],
        items: [
          {
            title: "Search",
            icon: "mdi-magnify",
            to: "/locations",
            authorize: [],
          },
          {
            title: "Create",
            icon: "mdi-plus-circle-outline",
            to: "/locations/create",
            authorize: [],
          },
          {
            title: "Guidelines",
            icon: "mdi-book-open-variant",
            to: "/locations/guidelines",
            authorize: [],
          },
        ],
      },
      {
        title: "Countries",
        icon: "mdi-flag",
        to: "/countries",
        authorize: [],
      },
      {
        title: "Tags",
        icon: "mdi-tag-multiple-outline",
        to: "/tags",
        authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)],
        items: [
          {
            title: "Search",
            icon: "mdi-magnify",
            to: "/tags",
            authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)],
          },
          {
            title: "Create",
            icon: "mdi-plus-circle-outline",
            to: "/tags/create",
            authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)],
          },
        ],
      },
      {
        title: "Translations",
        icon: "mdi-translate",
        to: "/translations",
        authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)],
      },
      {
        title: "Rules Engine",
        icon: "mdi-cog-refresh-outline",
        to: "/rules-engine",
        privileges: [
          GraphPrivilege.DerivedAttributeConfigurationRead,
          GraphPrivilege.QualityIssueConfigurationRead,
          GraphPrivilege.RestrictionConfigurationRead,
        ],
        items: [
          {
            title: "Derived Attributes",
            to: "/rules-engine/derived-attributes",
            privileges: [GraphPrivilege.DerivedAttributeConfigurationRead],
          },
          {
            title: "Quality Issues",
            to: "/rules-engine/quality-issues",
            privileges: [GraphPrivilege.QualityIssueConfigurationRead],
          },
          {
            title: "Restrictions",
            to: "/rules-engine/restrictions",
            privileges: [GraphPrivilege.RestrictionConfigurationRead],
          },
        ],
      },
      {
        title: "Tools",
        icon: "mdi-hammer-screwdriver",
        to: "/tools",
        authorize: [
          ...privilegeService.getRolesOfPrivilege(Privilege.BulkEdit),
          ...privilegeService.getRolesOfPrivilege(Privilege.UpdateReview),
          ...privilegeService.getRolesOfPrivilege(Privilege.UpdateReviewBatch),
        ],
        items: [
          {
            title: "Bulk Edit",
            to: "/tools/bulk-edit",
            authorize: [...privilegeService.getRolesOfPrivilege(Privilege.BulkEdit)],
          },
          {
            title: "Review Move and Copy Tools",
            to: "/tools/reviews-move-and-copy",
            authorize: [...privilegeService.getRolesOfPrivilege(Privilege.UpdateReviewBatch)],
          },
          {
            title: "Cache Management",
            to: "/tools/cache-management",
            authorize: [Role.engineeringCatalog],
          },
        ],
      },
      {
        title: "Attribute Review Queue",
        icon: "mdi-layers-search",
        to: "/attribute-review-queue",
        authorize: [...AttributeReviewQueueRoles],
        items: [
          {
            title: "Prioritized Review Queue",
            to: "/attribute-review-queue/prioritized",
            authorize: [...privilegeService.getRolesOfPrivilege(Privilege.PrioritizedReviewQueue)],
          },
        ],
      },
      // hygen_placeholder__new_navigation_items
    ];
  }
}

const appNavigation = new Navigation();

export default appNavigation;
