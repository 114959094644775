import { IPageLoadError } from "@client/commons/types/helpers";
import {
  DerivedAttributePageFieldsFragment,
  UpdateDerivedAttributeConfigurationInput,
} from "@client/autogenerated/graphql/operations";
import { RulesEngineMapper } from "@client/mappers/rules-engine";
import { MutationTree } from "vuex";
import { RulesEngineDerivedAttributesState, RulesEnginePageAlert } from "./types";

export const mutations: MutationTree<RulesEngineDerivedAttributesState> = {
  SET_PAGE_DATA(
    state: RulesEngineDerivedAttributesState,
    fields: DerivedAttributePageFieldsFragment,
  ): void {
    const attribute: DerivedAttributePageFieldsFragment = JSON.parse(JSON.stringify(fields ?? {}));
    // makes sure the options are sorted by the ordering field
    attribute?.options?.sort((a, b) => {
      if (a?.order < b?.order) return -1;
      if (a?.order > b?.order) return 1;
      return 0;
    });

    const model: UpdateDerivedAttributeConfigurationInput = {
      id: attribute.id,
      enabled: attribute.enabled,
      entityType: attribute.entityType,
      internalName: attribute.internalName,
      multiple: attribute.multiple,
      name: attribute.name,
      order: attribute.order,
      type: attribute.type,
      options:
        attribute.options?.map((option) => ({
          id: option.id,
          order: option.order,
          rules: RulesEngineMapper.unflatRuleItemsTree(option.rules ?? []),
          value: option.value,
          enabled: option.enabled,
        })) ?? [],
      rules: RulesEngineMapper.unflatRuleItemsTree(attribute.rules ?? []),
    };

    state.page = {
      ...state.page,
      id: Number(attribute.id),
      loading: false,
      previousState: JSON.stringify(model ?? {}),
      data: attribute,
      edit: model,
    };
  },
  SET_PAGE_LOAD_ERROR(state: RulesEngineDerivedAttributesState, loadError?: IPageLoadError): void {
    state.page["loadError"] = loadError;
  },
  SET_PAGE_LOADING(state: RulesEngineDerivedAttributesState, loading: boolean): void {
    state.page = {
      ...state.page,
      loading,
    };
  },
  SET_PAGE_IS_SAVING(state: RulesEngineDerivedAttributesState, saving: boolean): void {
    state.page = {
      ...state.page,
      isSaving: saving,
    };
  },
  SET_PAGE_ALERT(state: RulesEngineDerivedAttributesState, alert?: RulesEnginePageAlert): void {
    state.page = {
      ...state.page,
      alert,
    };
  },
};

export default mutations;
