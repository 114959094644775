import { RouteLocationNormalized, RouteRecordRaw, Router, createRouter, createWebHistory } from "vue-router";
import { Store } from "vuex";
import { AppState } from "../store/types";
import { UserAccessCheck, userCanAccessRouteCheck } from "../commons/auth";
import { AuthService } from "@gygadmin/auth/client";
import Error403Page from "../theme/pages/error-403/403.vue";
import Error404Page from "../theme/pages/error-404/404.vue";
import { environment, serviceName } from "../configs/app";
import { datadogRum } from "@datadog/browser-rum";

const startView = (view: string): void => {
    if (environment !== "production") {
        return;
    }
    const trackedView = view.endsWith("/") ? view.slice(0, -1) : view;
    datadogRum.startView(trackedView);
}


const sanitiseMultipleForwardSlashes = (to: RouteLocationNormalized) => {
    return to.path.replace(/([^:]\/)\/+/g, "$1");
};
const setupRouterMiddleware = (router: Router, store: Store<AppState>): void => {
    router.beforeEach((to, _from, next) => {
        const sanitizedPath = sanitiseMultipleForwardSlashes(to);
        if (sanitizedPath !== to.path) {
            return next({ path: sanitizedPath });
        }
        if (to?.name !== "ForbiddenPage") {
            const userCanAccessRoute = userCanAccessRouteCheck(store, {
                roles: to.meta ? (to.meta["authorize"] as string[]) : undefined,
                privileges: to.meta ? (to.meta["privileges"] as string[]) : undefined,
            });

            if (userCanAccessRoute === UserAccessCheck.accessDenied) {
                router.replace({
                    name: "ForbiddenPage",
                    params: { 0: `/${to.path}` },
                    replace: true,
                });
                return;
            }

            if (userCanAccessRoute === UserAccessCheck.redirectToLogin) {
                AuthService.options.loadingScreen(true);
                AuthService.redirectToLogin();
                return;
            }
        }

        return next();
    });

    router.afterEach((to, _from) => {
        let componentPath = to.name ? `#${to.name.toString()}` : to.path;
        if (!to.name) {
            Object.entries(to.params).forEach(([_, paramValue]) => {
                componentPath = componentPath.replace(paramValue.toString(), "?");
            });
        }
        const viewID = `[${serviceName}]${componentPath}`;
        startView(viewID);
    });
}

export const initializeRouter = (store: Store<AppState>, routes?: RouteRecordRaw[]) => {
    const router = createRouter({
        scrollBehavior: async (to, _from, savedPosition) => {
            if (to.hash) {
                let timeout = 10000;
                const delay = 100;
                while (timeout > 0) {
                    const el = document.querySelector(to.hash);
                    if (el) {
                        await new Promise((resolve) => setTimeout(resolve, delay));
                        return { selector: to.hash, behavior: "smooth" };
                    }
                    await new Promise((resolve) => setTimeout(resolve, delay));
                    timeout = timeout - delay;
                }
            } else {
                return savedPosition ?? {};
            }
        },
        history: createWebHistory(),
        routes: [
            ...(routes ?? []),
            {
                path: "/:pathMatch(.*)*",
                name: "PageNotFound",
                component: Error404Page,
            },
            {
                path: "/:pathMatch(.*)*",
                name: "ForbiddenPage",
                component: Error403Page,
            },
        ],
    });
    setupRouterMiddleware(router, store);
    return router;
}