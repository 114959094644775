import { AppState } from "@gygadmin/client3";
import { CountryPage, CountryPicturesTab, CountryState } from "./types";
import { GetterTree } from "vuex";
import { AggregatedCountry } from "@root/commons/types";

export const getters: GetterTree<CountryState, AppState> = {
  getState(state: CountryState): CountryState {
    return state;
  },
  getCountry(state: CountryState): AggregatedCountry | null {
    if (state.page.country !== undefined) {
      return state.page.country;
    }
    return null;
  },
  getCountryPage(state: CountryState): CountryPage {
    return state.page;
  },
  getCountryPicturesTab(state: CountryState): CountryPicturesTab {
    return state.page.tabs.countryPictures;
  },
};

export default getters;
