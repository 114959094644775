import { EnhancedContentReviewComment, ContentReviewComment, User } from "@root/commons/types";
import {
  ContentReviewComment as GraphQLContentReviewComment,
  ContentReviewCommentCreateInput,
  ContentReviewCommentEditInput,
} from "@client/autogenerated/graphql/operations";

export class ContentReviewCommentMapper {
  static toManagementComment(comment: GraphQLContentReviewComment): EnhancedContentReviewComment {
    return {
      id: Number(comment.id),
      content_review_id: comment.contentReviewId ?? undefined,
      comment: comment.comment ?? undefined,
      creation_user: comment.creationUser
        ? ({
            id: Number(comment.creationUser.id),
            type: comment.creationUser.type,
          } as User)
        : undefined,
      update_user: comment.updateUser
        ? ({
            id: Number(comment.updateUser.id),
            type: comment.updateUser.type,
          } as User)
        : undefined,
      creation_user_name: comment.creationStaff ? comment.creationStaff.name : undefined,
      update_user_name: comment.updateStaff ? comment.updateStaff.name : undefined,
      creation_timestamp: comment.creationTimestamp ?? undefined,
      update_timestamp: comment.updateTimestamp ?? undefined,
    };
  }

  static toCreateCommentInput(comment: ContentReviewComment): ContentReviewCommentCreateInput {
    return {
      contentReviewId: comment.content_review_id!,
      comment: comment.comment!,
    };
  }

  static toEditCommentInput(comment: ContentReviewComment): ContentReviewCommentEditInput {
    return {
      id: `${comment.id}`,
      contentReviewId: comment.content_review_id!,
      comment: comment.comment!,
    };
  }
}
