import { gql } from "@gygadmin/client3";

export const CreateQualityIssueMutation = gql`
  mutation CreateQualityIssueConfiguration($input: CreateQualityIssueConfigurationInput!) {
    createQualityIssueConfiguration(input: $input) {
      success
      qualityIssueConfiguration {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const DeleteQualityIssueMutation = gql`
  mutation DeleteQualityIssueConfiguration($id: ID!) {
    deleteQualityIssueConfiguration(id: $id) {
      success
    }
  }
`;

const selectedRuleItemsFragment = gql`
  fragment SelectedRuleItemsFields on RulesEngineRuleItem {
    uid
    parent {
      uid
    }
    clause
    operation
    ruleId
    params {
      key
      value {
        dropdown {
          values
        }
        freeText {
          values
        }
        integer {
          value
        }
        interval {
          lowerBound
          upperBound
        }
      }
    }
  }
`;

const QualityIssuesPageFragment = gql`
  ${selectedRuleItemsFragment}

  fragment QualityIssuePageFields on QualityIssueConfiguration {
    id
    entityType
    internalName
    name
    order
    enabled
    creationTime
    lastModificationUser {
      id
      type
    }
    lastModificationTime
    availableRules {
      id
      name
      internalName
      description
      inputs {
        internalName
        label
        order
        validation {
          __typename
          ... on RulesEngineRuleInputValidationInterval {
            lowerBound
            upperBound
          }
          ... on RulesEngineRuleInputValidationDropdown {
            items {
              label
              value
            }
            multiSelect
          }
          ... on RulesEngineRuleInputValidationFreeText {
            minCharLength
            maxCharLength
          }
          ... on RulesEngineRuleInputValidationInteger {
            multiInput
            min
            max
          }
        }
      }
    }
    rules {
      ...SelectedRuleItemsFields
    }
  }
`;

export const QualityIssuePageQuery = gql`
  ${QualityIssuesPageFragment}

  query QualityIssueConfigurationPage($id: ID!) {
    qualityIssueConfiguration(id: $id) {
      ...QualityIssuePageFields
    }
  }
`;

export const QualityIssueEditMutation = gql`
  ${QualityIssuesPageFragment}

  mutation EditQualityIssueConfiguration($input: UpdateQualityIssueConfigurationInput!) {
    updateQualityIssueConfiguration(input: $input) {
      success
      qualityIssueConfiguration {
        ...QualityIssuePageFields
      }
      errors {
        message
      }
    }
  }
`;

export const QualityIssuePreviewQuery = gql`
  query QualityIssueConfigurationPreview($input: QualityIssueConfigurationPreviewInput!) {
    qualityIssueConfigurationPreview(input: $input) {
      items {
        check
        entityId
      }
    }
  }
`;

export const QualityIssueSearchQuery = gql`
  query QualityIssueConfigurationSearch($input: SearchInput) {
    qualityIssueConfigurationSearch(input: $input) {
      pagination {
        totalItems
        offset
        limit
        hasPreviousPage
        hasNextPage
      }
      items {
        id
        entityType
        internalName
        name
        enabled
      }
    }
  }
`;
