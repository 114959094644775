import app from "@gygadmin/client3";
import routes from "./pages/routes/paths";
import modules from "./store/modules";
import gettexts from "../dist/gettexts.json";
import navigation from "./pages/navigation";

app
  .initialize({
    routes,
    localizations: gettexts,
    storeModules: modules,
    appNavigation: navigation,
  })
  .then((app) => app.mount())
  .catch((err) => console.error("Failed to mount app: ", err));
