import { LocaleMessageObject } from "vue-i18n";
import { AxiosInstance } from "axios";
import RestClient from "@root/commons/utils/rest-client";

class GettextService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/client",
    });
  }

  async getTranslations(locale: string): Promise<LocaleMessageObject> {
    const response = await this.restClient.get("/gettext");
    return response.data[locale];
  }
}

export default new GettextService();
