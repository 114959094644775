// hygen_placeholder__imports
import tag from "./tag";
import location from "./location";
import country from "./country";
import activity from "./activity";
import fieldCheck from "./fields/check";
import translations from "./translations";
import prioritizedReviewQueue from "./attribute-review-queue/prioritized";
import sandbox from "./sandbox";
import derivedAttribute from "./derived-attribute";
import qualityIssue from "./quality-issues";
import tools from "./tools";

export default {
  // hygen_placeholder__modules
  tag,
  location,
  country,
  activity,
  fieldCheck,
  translations,
  prioritizedReviewQueue,
  sandbox,
  derivedAttribute,
  qualityIssue,
  tools,
};
