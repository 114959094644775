import { SearchInput } from "../../../__generated__/graphql/operations";
import { GraphSearch } from "../../../components/gyg-datatable/models/types";
import { QUICK_SEARCH_FIELD, QUICK_SEARCH_LIMIT } from "../config/quick-search";
import graphClient from "@getyourguide/graphql-client-js";
import { QuickSearchQueryConfig } from "../types/search";

class SearchService {
  async quickSearch(
    query: GraphSearch,
    term: string,
    offset = 0,
    queryConfig?: QuickSearchQueryConfig,
  ): Promise<any> {
    const q = JSON.parse(JSON.stringify(query.query));
    return await graphClient
      .gql()
      .query({
        query: q,
        variables: {
          input: this.toSearchInput(term, offset, queryConfig),
        },
      })
      .then((res) => res.data[query.key])
      .catch(() => []);
  }

  toSearchInput(term: string, offset = 0, queryConfig?: QuickSearchQueryConfig): SearchInput {
    return {
      filters: [{ field: queryConfig?.searchField ?? QUICK_SEARCH_FIELD, values: [term] }],
      limit: queryConfig?.limit ?? QUICK_SEARCH_LIMIT,
      offset: offset,
    };
  }
}

const searchService = new SearchService();

export { searchService };
