import { gql } from "@gygadmin/client3";
import { PriceOverridesFragment } from "./price-overrides";
import { PricingFragment } from "./pricing";

export const AvailabilityBlockFields = gql`
  fragment AvailabilityBlockFields on AvailabilityBlock {
    id
    name
    type
    activityId
    activityOptionId
    activityOptionPricingId
    validityDateRange {
      start
      end
    }
    specialDates {
      date
      timeSlots {
        from
        to
      }
    }
    weekdays {
      day
      timeSlots {
        from
        to
      }
    }
  }
`;

const AvailabilityAndPricingFragment = gql`
    ${PricingFragment}
    ${PriceOverridesFragment}
    ${AvailabilityBlockFields}
    fragment AvailabilityAndPricing on AvailabilityAndPricing {
        availabilityBlock {
            ...AvailabilityBlockFields
        }
        pricing {
            ...PricingFragment
        }
        priceOverrides {
            ...PriceOverridesFragment
        }
    }
`;

export const AvailabilityAndPricingErrorDetailsFragment = gql`
    fragment AvailabilityAndPricingErrorDetails on AvailabilityAndPricingOverlapException {
        overlappingSchedules
        overlappingSlots {
            day
            timeSlots {
                from
                to
            }
        }
        overlappingSpecialDates {
            date
            timeSlots {
                from
                to
            }
        }
    }
`;

export const AvailabilityAndPricingOptionByOptionId = gql`
    ${AvailabilityAndPricingFragment}
    query AvailabilityAndPricingOptionByOptionId($activityOptionId: ID!) {
        activityOption(id: $activityOptionId) {
            activity {
                id
            }
            connectivitySettings {
                target
            }
            inventoryConfiguration {
                newTicketSource
                usesGroupPricing
                usesPricingOverApi
                minParticipantsOnlyForFirstBooking
            }
            availabilityAndPricing {
                ...AvailabilityAndPricing
            }
        }
    }
`;

export const UpdateAvailabilityAndPricing = gql`
    ${AvailabilityAndPricingFragment}
    ${AvailabilityAndPricingErrorDetailsFragment}
    mutation UpdateAvailabilityAndPricing(
        $input: AvailabilityAndPricingInput!
        $availabilityBlockId: ID!
        $pricingId: ID!
    ) {
        updateAvailabilityAndPricing(
            input: $input
            availabilityBlockId: $availabilityBlockId
            pricingId: $pricingId
        ) {
            success
            message
            errorDetails {
                ...AvailabilityAndPricingErrorDetails
            }
            availabilityAndPricing {
                ...AvailabilityAndPricing
            }
        }
    }
`;