import {gql} from "@gygadmin/client3";

export const CreateLocationPicture = gql`
    mutation CreateLocationPictureInput($input: CreateLocationPictureInput!) {
        createLocationPicture(input: $input) {
            errors {
                message
            }
            picture {
                id
                filename
                location {
                    id
                }
                order
                url
                type
            }
            success
        }
    }
`;

/* @team:catalog */
export const DeleteLocationPicture = gql`
    mutation DeleteLocationPicture($pictureId: ID!) {
        deleteLocationPicture(pictureId: $pictureId) {
            success
            errors {
                message
            }
        }
    }
`;