import { AxiosInstance } from "axios";
import RestClient from "@root/commons/utils/rest-client";
import { FieldCheck } from "@root/commons/types/fields";

class FieldsCheckService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  async fetch(field: FieldCheck): Promise<FieldCheck> {
    return this.restClient.get(`/fields/checks/${field.type}/${field.type_id}/${field.key}`);
  }

  async fetchAll(type: string, typeId: string): Promise<FieldCheck> {
    return this.restClient.get(`/fields/checks/${type}/${typeId}`);
  }

  async save(field: FieldCheck): Promise<FieldCheck> {
    return this.restClient.post("/fields/checks", field);
  }

  async remove(field: FieldCheck): Promise<void> {
    return this.restClient.delete(`/fields/checks/${field.type}/${field.type_id}/${field.key}`);
  }
}

export default new FieldsCheckService();
