import { RoleToPrivilegeMapping } from "@root/commons/privileges/mappings";
import { Privilege } from "@root/commons/privileges/privileges";

export class PrivilegeService {
  getRolesOfPrivilege(privilege: Privilege): Array<string> {
    const roles: Array<string> = [];
    Object.keys(RoleToPrivilegeMapping).forEach((role) => {
      if (RoleToPrivilegeMapping[role].includes(privilege)) {
        roles.push(role);
      }
    });
    return roles;
  }

  rolesCanAccessPrivilege(privilege: Privilege, userRoles: string[] = []): boolean {
    const privilegeRoles = this.getRolesOfPrivilege(privilege);
    return userRoles.some((userRole) => privilegeRoles.includes(userRole));
  }
}
