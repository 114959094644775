import { gql } from "@gygadmin/client3";

export const CreateRestrictionMutation = gql`
  mutation CreateRestrictionConfiguration($input: CreateRestrictionConfigurationInput!) {
    createRestrictionConfiguration(input: $input) {
      success
      restrictionConfiguration {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const DeleteRestrictionMutation = gql`
  mutation DeleteRestrictionConfiguration($id: ID!) {
    deleteRestrictionConfiguration(id: $id) {
      success
    }
  }
`;

const selectedRuleItemsFragment = gql`
  fragment SelectedRuleItemsFields on RulesEngineRuleItem {
    uid
    parent {
      uid
    }
    clause
    operation
    ruleId
    params {
      key
      value {
        dropdown {
          values
        }
        freeText {
          values
        }
        integer {
          value
        }
        interval {
          lowerBound
          upperBound
        }
      }
    }
  }
`;

const RestrictionsPageFragment = gql`
  ${selectedRuleItemsFragment}

  fragment RestrictionPageFields on RestrictionConfiguration {
    id
    entityType
    internalName
    name
    order
    enabled
    creationTime
    lastModificationUser {
      id
      name
    }
    lastModificationTime
    availableRules {
      id
      name
      internalName
      description
      inputs {
        internalName
        label
        order
        validation {
          __typename
          ... on RulesEngineRuleInputValidationInterval {
            lowerBound
            upperBound
          }
          ... on RulesEngineRuleInputValidationDropdown {
            items {
              label
              value
            }
            multiSelect
          }
          ... on RulesEngineRuleInputValidationFreeText {
            minCharLength
            maxCharLength
          }
          ... on RulesEngineRuleInputValidationInteger {
            multiInput
            min
            max
          }
        }
      }
    }
    rules {
      ...SelectedRuleItemsFields
    }
  }
`;

export const RestrictionPageQuery = gql`
  ${RestrictionsPageFragment}

  query RestrictionConfigurationPage($id: ID!) {
    restrictionConfiguration(id: $id) {
      ...RestrictionPageFields
    }
  }
`;

export const RestrictionEditMutation = gql`
  ${RestrictionsPageFragment}

  mutation EditRestrictionConfiguration($input: UpdateRestrictionConfigurationInput!) {
    updateRestrictionConfiguration(input: $input) {
      success
      restrictionConfiguration {
        ...RestrictionPageFields
      }
      errors {
        message
      }
    }
  }
`;

export const RestrictionPreviewQuery = gql`
  query RestrictionConfigurationPreview($input: RestrictionConfigurationPreviewInput!) {
    restrictionConfigurationPreview(input: $input) {
      items {
        check
        entityId
      }
    }
  }
`;

export const RestrictionSearchQuery = gql`
  query RestrictionConfigurationSearch($input: SearchInput) {
    restrictionConfigurationSearch(input: $input) {
      pagination {
        totalItems
        offset
        limit
        hasPreviousPage
        hasNextPage
      }
      items {
        id
        entityType
        internalName
        name
        enabled
      }
    }
  }
`;
