import { gql } from "@gygadmin/client3";

export const RestrictionStatSearchQuery = gql`
  query Stats($id: ID!, $input: RestrictionStatsInput) {
    restrictionConfiguration(id: $id) {
      restrictionStats(input: $input) {
        stats {
          entityId
          createdAt
          updatedAt
          entryPoint
          entityType
        }
        totalResults
      }
    }
  }
`;
