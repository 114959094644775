
import { environment, firebaseConfig } from "../configs/app";
import { AuthCheckOptions } from "../types/config";
import { AppState } from "../main";
import { Store } from "vuex";
import { Role } from "@gygadmin/auth/roles";
import { AuthService } from "@gygadmin/auth/client";

export enum UserAccessCheck {
  accessGranted = "access_granted",
  accessDenied = "access_denied",
  redirectToLogin = "redirect_login",
}

export const userCanAccessRouteCheck = (store: Store<AppState>, { roles, privileges }: AuthCheckOptions): UserAccessCheck => {
  if (firebaseConfig.mockUser || environment === "development") {
    return UserAccessCheck.accessGranted;
  }
  roles = roles ?? [];
  privileges = privileges ?? [];

  const allowGuest = roles.includes(Role.guest);
  const currentUser = store.getters["user/getState"];
  const isStaff = store.getters["user/isStaff"];

  if (!isStaff && !allowGuest) {
    return UserAccessCheck.redirectToLogin;
  }

  AuthService.options.loadingScreen(false);

  if (roles.length || privileges.length) {
    const hasRole = roles.some((role) => currentUser.roles.includes(role));
    const hasPrivilege = privileges.some((privilege) =>
      currentUser.privileges?.includes(privilege),
    );
    if (!hasRole && !hasPrivilege) {
      return UserAccessCheck.accessDenied;
    }
  }

  return UserAccessCheck.accessGranted;
}
