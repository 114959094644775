import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { AuthOptions, AuthService } from '..';

export const initializeAuthentication = (
  options: AuthOptions
): Promise<void> => {
  AuthService.setOptions(options);
  if (getApps().length === 0) {
    initializeApp(AuthService.options.configs.options);
  }

  AuthService.startRefreshUserCheck();

  return new Promise((resolve) => {
    if (AuthService.hasMockedUser()) {
      AuthService.refreshUser().finally(() => resolve());
      return;
    }
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        AuthService.decodeFirebaseUser(user).then((decodedUser) => {
          AuthService.updateUserFromFirebase(decodedUser).finally(() =>
            resolve()
          );
        });
        return;
      }

      // used to update the user and the session cookie shared with the server
      AuthService.refreshUser().finally(() => resolve());
    });
  });
};
