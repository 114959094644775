import { gql } from "../../graphql";

export const DealsFragment = gql`
  fragment DealsFragment on Deal {
    id
    dateRange {
      start
      end
    }
    percentage
    reservedVacancies
    activityOptions {
      id
    }
    maxVacancies
    status
    name
    type
    noticePeriodInSeconds
    timeSlots {
      day
      timeSlots
    }
  }
`;

export const DealsSearch = gql`
  ${DealsFragment}
  query DealsSearch($input: SearchInput!) {
    dealsSearch(input: $input) {
      items {
        ...DealsFragment
      }
    }
  }
`;

export const UpdateDeal = gql`
  ${DealsFragment}
  mutation UpdateDeal($input: DealInput!) {
    updateDeal(input: $input) {
      deal {
        ...DealsFragment
      }
    }
  }
`;
