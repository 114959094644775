import gql from "graphql-tag";

export const IndividualPricingCategoryFragment = gql`
  fragment IndividualPricingCategoryFields on IndividualPricingCategory {
    id
    ageRange {
      maxAge
      minAge
    }
    bookIndependently
    bookingType
    commissionRate
    resolvedCommissionRate
    ticketCategory
  }
`;

export const GroupPricingCategoryFragment = gql`
  fragment GroupPricingCategoryFields on GroupPricingCategory {
    id
    commissionRate
    resolvedCommissionRate
    isAdditionalPaxForGroup
  }
`;

export const PricingScalesFragment = gql`
  fragment PricingScaleFields on PricingScale {
    id
    minParticipants
    maxParticipants
    prices {
      id
      breakagePrice
      netRatePrice
      retailPrice
      returnPrice
    }
  }
`;

export const PricingFragment = gql`
  ${IndividualPricingCategoryFragment}
  ${GroupPricingCategoryFragment}
  ${PricingScalesFragment}
  fragment PricingFragment on ActivityOptionPricing {
    id
    name
    usesFixedNetRates
    groupVacanciesPerTimeSlot
    maxIndividualParticipantsPerTimeSlot
    participantsRange {
      min
      max
    }
    pricesPerCategory {
      id
      pricingId
      ... on IndividualPricingCategory {
        ...IndividualPricingCategoryFields
      }
      ... on GroupPricingCategory {
        ...GroupPricingCategoryFields
      }
      pricingScales {
        ...PricingScaleFields
      }
    }
    addons {
      id
      text {
        addonId
        description
        id
        languageId
      }
      pricingId
      commissionRate
      resolvedCommissionRate
      pricingScales {
        ...PricingScaleFields
      }
    }
  }
`;
