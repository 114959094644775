import {gql} from "@gygadmin/client3";

/* @team:catalog */
export const PushForTranslation = gql`
    mutation PushForTranslation($input: PushForTranslationInput!) {
        pushForTranslation(input: $input) {
            success
        }
    }
`;

export const ReprocessDelayedXTMTourPush = gql`
    mutation ReprocessDelayedXTMTourPush($xtmDelayedTourPushId: Int!) {
        reprocessXTMDelayedTourPush(xtmDelayedTourPushId: $xtmDelayedTourPushId)
    }
`;