import { Module } from "vuex";
import { AppState } from "../../types";
import { initialState } from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import { User } from "./types";

const userModule: Module<User, AppState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default userModule;
