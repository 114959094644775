import RestClient from "@root/commons/utils/rest-client";
import { AggregatedCountry, Country } from "@root/commons/types";

class CountriesService {
  restClient: ReturnType<typeof RestClient.create>;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Get the country based on the id.
   *
   * @param countryId
   */
  async get(countryId: number): Promise<AggregatedCountry> {
    return this.restClient
      .get(`/countries/${countryId}/detail`)
      .then((response) => response.data as AggregatedCountry)
      .catch((_) => ({}));
  }

  /**
   * Get all countries.
   */
  async fetchAll(): Promise<Country[]> {
    return this.restClient.get(`/countries`).then((response) => response.data as Country[]);
  }

  async update(countryId: number, country: AggregatedCountry): Promise<AggregatedCountry> {
    return this.restClient
      .put(`/countries/${countryId}`, country)
      .then((response) => response.data as AggregatedCountry);
  }
}

export default new CountriesService();
