import { AppState } from "@gygadmin/client3";
import { LocationPage, LocationPicturesTab, LocationState } from "./types";
import { GetterTree } from "vuex";
import { AggregatedLocation, Tag } from "@root/commons/types";

export const getters: GetterTree<LocationState, AppState> = {
  getState(state: LocationState): LocationState {
    return state;
  },
  getLocation(state: LocationState): AggregatedLocation | null {
    if (state.page.location !== undefined) {
      return state.page.location;
    }
    return null;
  },
  getLocationPage(state: LocationState): LocationPage {
    return state.page;
  },
  getLocationPicturesTab(state: LocationState): LocationPicturesTab {
    return state.page.tabs.locationPictures;
  },
  getAreaTypeOptions(state: LocationState): Tag[] {
    return state.page.areaTypeOptions;
  },
};

export default getters;
