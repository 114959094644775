import { ModuleTree, createStore } from "vuex";
import modules from "./modules";
import { initialState } from "./state";
import { ActionContext } from "vuex";
import { AppState, Notification } from "./types";

export const initializeStore = (appModules?: ModuleTree<AppState>) => createStore({
  state: JSON.parse(JSON.stringify(initialState)),
  actions: {
    showLoading(context: ActionContext<AppState, AppState>, show: boolean): void {
      context.commit("SHOW_LOADING", show);
    },
    showSuccessNotification(context: ActionContext<AppState, AppState>, text: string): void {
      context.commit("SHOW_SUCCESS_NOTIFICATION", text);
    },
    showInfoNotification(context: ActionContext<AppState, AppState>, text: string): void {
      context.commit("SHOW_INFO_NOTIFICATION", text);
    },
    showErrorNotification(context: ActionContext<AppState, AppState>, text: string): void {
      context.commit("SHOW_ERROR_NOTIFICATION", text);
    },
    closeNotification(context: ActionContext<AppState, AppState>): void {
      context.commit("CLOSE_NOTIFICATION");
    },
  },
  getters: {
    getNotification(state: AppState): Notification {
      return state.notification;
    },
  },
  mutations: {
    SHOW_LOADING(state: AppState, show: boolean): void {
      state.loading = show;
    },
    SHOW_SUCCESS_NOTIFICATION(state: AppState, text: string): void {
      state.notification.text = text;
      state.notification.level = "success";
      state.notification.open = true;
    },
    SHOW_INFO_NOTIFICATION(state: AppState, text: string): void {
      state.notification.text = text;
      state.notification.level = "info";
      state.notification.open = true;
    },
    SHOW_ERROR_NOTIFICATION(state: AppState, text: string): void {
      state.notification.text = text;
      state.notification.level = "error";
      state.notification.open = true;
    },
    CLOSE_NOTIFICATION(state: AppState): void {
      state.notification.open = false;
    },
  },
  modules: {
    ...modules,
    ...appModules,
  },
});
