import { AppState, Language } from "@gygadmin/client3";
import { ActionContext, ActionTree } from "vuex";
import { PrioritizedReviewQueueState } from "@client/store/modules/attribute-review-queue/prioritized/types";
import { ContentReviewService, ActivitiesTextService, ActivityOptionTextService } from "@client/services";
import { ContentReview, ContentReviewComment} from "@root/commons/types";
import { ContentReviewEntity } from "@client/helpers/attribute-review-queue/types";

export const actions: ActionTree<PrioritizedReviewQueueState, AppState> = {
  async setDetailsPageSaving(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    isSaving: boolean,
  ) {
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_SAVING", isSaving);
  },
  async loadPrioritizedReviewQueueItem(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    id: number,
  ): Promise<void> {
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_LOADING", true);
    try {
      const contentReview = await ContentReviewService.get(id);
      context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", contentReview);

      if(contentReview.entity === ContentReviewEntity.TOUR) {
        const englishText = await ActivitiesTextService.fetchByTourIdAndLanguageId(contentReview.entity_id, Language.english.id);
        context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_ENGLISH_TEXT", englishText);
      } else {
        const allTexts = await ActivityOptionTextService.fetchAllAutotranslatedByOption(contentReview.entity_id);
        //const englishText = allTexts.filter(text => text.language_id === Language.english.id);
        const englishText = allTexts.filter(text => text.language_id === Language.english.id)[0]; // revert after test
        context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_ENGLISH_TEXT", englishText);
      }
    } finally {
      context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_LOADING", false);
    }
  },
  async pollPrioritizedReviewQueueItem(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    id: number,
  ): Promise<void> {
    const contentReview = await ContentReviewService.get(id);
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", contentReview);
  },
  async savePrioritizedReviewQueueItem(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    contentReview: ContentReview,
  ): Promise<void> {
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_SAVING", true);
    try {
      const savedContentReview = await ContentReviewService.update(contentReview);
      context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", savedContentReview);
    } finally {
      context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_SAVING", false);
    }
  },
  async assignAllOpenQueueItemsToCurrentUser(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    contentReviewId: number,
  ) {
    const savedContentReview = await ContentReviewService.assignAllOpenQueueItemsToCurrentUser(
      contentReviewId,
    );
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", savedContentReview);
  },
  async saveContentReviewChecks(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    { contentReviewId, contentReviewChecks },
  ) {
    const savedContentReview = await ContentReviewService.saveContentReviewChecks(
      contentReviewId,
      contentReviewChecks,
    );
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", savedContentReview);
  },
  async sendGroupedSupplierEmail(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    { contentReviewId, selectedQualityIssues, override = false },
  ) {
    const savedContentReview = await ContentReviewService.sendGroupedSupplierEmail(
      contentReviewId,
      selectedQualityIssues,
      override,
    );
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", savedContentReview);
  },
  async revert(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    contentReviewId: number,
  ) {
    const savedContentReview = await ContentReviewService.revert(contentReviewId);
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", savedContentReview);
  },
  async revertNewActivity(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    { contentReviewId, newActivityRevert },
  ) {
    const savedContentReview = await ContentReviewService.revertNewActivity(
      contentReviewId,
      newActivityRevert,
    );
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", savedContentReview);
  },
  setAssignedUser(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    assignedUserId: number,
  ) {
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_ASSIGNED_USER", assignedUserId);
  },
  async loadDetailsPageAuthorizedUsers(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
  ): Promise<void> {
    context.commit("SET_PAGE_PRIORITIZED_DETAIL_USERS_LOADING", true);
    try {
      const users = await ContentReviewService.getAuthorizedUsers();
      context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_AUTHORIZED_USERS", users);
    } finally {
      context.commit("SET_PAGE_PRIORITIZED_DETAIL_USERS_LOADING", false);
    }
  },
  async loadListPageAuthorizedUsers(context: ActionContext<PrioritizedReviewQueueState, AppState>) {
    const users = await ContentReviewService.getAuthorizedUsers();
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_LIST_AUTHORIZED_USERS", users);
  },
  async createComment(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    comment: ContentReviewComment,
  ) {
    const contentReview = await ContentReviewService.createComment(comment);
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", contentReview);
  },
  async editComment(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    comment: ContentReviewComment,
  ) {
    const contentReview = await ContentReviewService.editComment(comment);
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", contentReview);
  },
  async deleteComment(
    context: ActionContext<PrioritizedReviewQueueState, AppState>,
    comment: ContentReviewComment,
  ) {
    const contentReview = await ContentReviewService.deleteComment(comment);
    context.commit("SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW", contentReview);
  },
};

export default actions;
