import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import { graphClient } from "@gygadmin/client3";
import {
  ActivityPicture,
  CreatePictureInput,
  DeletePictureInput,
  MutationCreateTourPictureArgs,
  MutationDeleteTourPictureArgs,
  MutationReorderTourPicturesArgs,
  MutationUpdateTourPictureArgs,
  PictureMutationPayload,
  Query,
  ReorderMutationPayload,
  UpdatePictureInput,
  ReorderPictureInput,
} from "@client/autogenerated/graphql/operations";
import {
  CreateTourPicture,
  DeleteTourPicture,
  GetAllPicturesOfActivity,
  ReorderTourPicture,
  UpdateTourPicture,
} from "@client/graphql/activities/activity-pictures";

export class ActivityPicturesService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Upload pictures to be used in activity.
   *
   * @param input
   */
  async upload(input: CreatePictureInput): Promise<PictureMutationPayload> {
    return await graphClient
      .gql()
      .mutate<PictureMutationPayload, MutationCreateTourPictureArgs>({
        mutation: CreateTourPicture,
        variables: {
          input,
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }
        return res.data as PictureMutationPayload;
      });
  }

  /**
   * Update picture
   *
   * @param input
   */
  async update(input: UpdatePictureInput): Promise<PictureMutationPayload> {
    return await graphClient
      .gql()
      .mutate<PictureMutationPayload, MutationUpdateTourPictureArgs>({
        mutation: UpdateTourPicture,
        variables: {
          input,
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }
        return res.data as PictureMutationPayload;
      });
  }

  /**
   * Reorder pictures
   *
   * @param input
   */
  async reorder(activityId: string, input: ReorderPictureInput): Promise<boolean> {
    return await graphClient
      .gql()
      .mutate<ReorderMutationPayload, MutationReorderTourPicturesArgs>({
        mutation: ReorderTourPicture,
        variables: {
          activityId,
          input,
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }
        return res.data?.success ?? false;
      });
  }

  /**
   * Fetch all pictures based on the provided activity id.
   *
   * @param activityId
   */
  async fetchAllByActivity(activityId: number): Promise<ActivityPicture[]> {
    return (await graphClient
      .gql()
      .query<Query>({
        query: GetAllPicturesOfActivity,
        variables: {
          activityId: `${activityId}`,
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }
        return res.data.activity?.pictures || [];
      })
      .catch((_) => [])) as ActivityPicture[];
  }

  /**
   * Removes a picture by the given id.
   *
   * @param pictureId
   */
  async delete(pictureId: number): Promise<boolean> {
    return await graphClient
      .gql()
      .mutate<PictureMutationPayload, MutationDeleteTourPictureArgs>({
        mutation: DeleteTourPicture,
        variables: {
          pictureId: pictureId.toString(),
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }
        return res.data?.success ?? false;
      });
  }

  /**
   * Deletes the given picture from CDN.
   *
   * @param pictureId
   */
  async deleteFromCDN(pictureId: number): Promise<boolean> {
    const input: DeletePictureInput = {
      deleteFromCDN: true,
    };

    return await graphClient
      .gql()
      .mutate<PictureMutationPayload, MutationDeleteTourPictureArgs>({
        mutation: DeleteTourPicture,
        variables: {
          pictureId: pictureId.toString(),
          input: input,
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }
        return res.data?.success ?? false;
      });
  }
}

export default new ActivityPicturesService();
