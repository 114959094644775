// eslint-disable-next-line diversity-inclusion/all
import { MutationTree } from "vuex";
import { User } from "./types";

export const mutations: MutationTree<User> = {
  SET_USER(state: User, data: User): void {
    let picture = data.picture;
    if (picture?.includes("profile_dummy")) {
      picture = "";
    }
    state.staffId = data.staffId;
    state.uid = data.uid;
    state.name = data.name;
    state.picture = picture;
    state.email = data.email;
    state.roles = data.roles;
  },
  SET_USER_PRIVILEGES(state: User, privileges: string[]): void {
    state.privileges = privileges;
  },
  SET_USER_LEVEL(state: User, root: boolean): void {
    state.root = root;
  },
};

export default mutations;
