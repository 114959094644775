import AttributeReviewQueueRouterPage from "../../views/attribute-review-queue/index.vue";
import { Privilege, PrivilegeService } from "@root/commons/privileges";

const privilegeService = new PrivilegeService();

export default [
  {
    path: "/attribute-review-queue",
    component: AttributeReviewQueueRouterPage,
    redirect: "/attribute-review-queue/prioritized",
    children: [
      {
        path: "prioritized",
        meta: {
          authorize: [...privilegeService.getRolesOfPrivilege(Privilege.PrioritizedReviewQueue)],
        },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "attribute-review-queue" */
            "../../views/attribute-review-queue/prioritized-review-queue/list/prioritized-review-queue-page.vue"
          ),
      },
      {
        path: "prioritized/:contentReviewId",
        props: (route: any) => ({
          contentReviewId: route.params.contentReviewId,
          tourId: route.query.tourId,
        }),
        meta: {
          authorize: [...privilegeService.getRolesOfPrivilege(Privilege.PrioritizedReviewQueue)],
        },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "attribute-review-queue" */
            "../../views/attribute-review-queue/prioritized-review-queue/detail/detail.vue"
          ),
      },
    ],
  },
];
