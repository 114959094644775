import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import { Module } from "vuex";
import { AppState } from "@gygadmin/client3";
import { initialState } from "./state";
import { TagState } from "./types";

const tagModule: Module<TagState, AppState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default tagModule;
