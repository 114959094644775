import { gygadminUrl, publicDomains } from "../../configs/app";
import { Language } from "../../commons/languages";

const travelerUrl = publicDomains[Language.english.isoCode];

export const customerInvoicePage = (hashCode: string) =>
  `${travelerUrl}/customer-invoice/${hashCode}`;

export const externalCouponPage = (couponCode: string) =>
  `${travelerUrl}/coupon/view/${couponCode}`;

export const confirmationPage = (hashCode: string) => `${travelerUrl}/confirmation/${hashCode}`;

export const voucherPage = (hashCode: string) => `${travelerUrl}/voucher/${hashCode}`;

export const historyPage = (bookingId: string | undefined) =>
  bookingId ? `${gygadminUrl}/portal/#page=history&object=booking&id=${bookingId}` : "#";

export const externalActivityPage = (activityId: string) => `${travelerUrl}/-t${activityId}`;

export const externalBookingPage = (hashCode: string) => `${travelerUrl}/booking/${hashCode}`;
