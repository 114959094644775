<template>
  <VNavigationDrawer
    v-model="drawer"
    v-model:rail="mini"
    :expand-on-hover="expandOnHover"
    class="layout-base-navigation"
  >
    <VListItem>
      <template #prepend>
        <VListItemTitle>
          <HomeLogo
            :mini="mini"
            :applet-name="appletName"
          />
        </VListItemTitle>
      </template>

      <template #append>
        <AppsMenu v-bind="{ ...$props, ...$attrs }" />
        <VBtn
          v-if="!expandOnHover"
          size="x-small"
          icon
          class="mt-1"
          variant="flat"
          @click="onClickButton"
        >
          <VIcon size="small">
            mdi-chevron-left
          </VIcon>
        </VBtn>
      </template>
    </VListItem>

    <VDivider class="no-flex" />

    <VListItem
      :title="decodedUserName"
      class="mt-2 mb-2"
    >
      <template #prepend>
        <VAvatar v-if="hasPicture">
          <VImg :src="userPicture" />
        </VAvatar>
        <VIcon
          v-else
          size="x-large"
          color="primary"
          icon="mdi-account-circle"
        />
      </template>

      <template #append>
        <VMenu
          transition="slide-y-transition"
          location="left"
        >
          <template #activator="{ props }">
            <VBtn
              size="small"
              icon
              class="mt-1"
              v-bind="props"
            >
              <VIcon size="small">
                mdi-dots-vertical
              </VIcon>
            </VBtn>
          </template>
          <VList density="compact">
            <VListItem
              density="compact"
              title="Logout"
              @click="signout"
            >
              <template #prepend>
                <VIcon>mdi-logout</VIcon>
              </template>
            </VListItem>
          </VList>
        </VMenu>
      </template>
    </VListItem>

    <VDivider class="no-flex" />

    <slot class="no-flex" />

    <NavigationItems
      class="flex-grow"
      :items="items"
    />

    <footer class="gygapp-footer">
      <VRow
        justify="center"
        no-gutters
      >
        <VCol
          class="py-2 text-center text-body-2"
          cols="12"
        >
          &copy; {{ mini ? "GYG" : "GetYourGuide " }}
          <slot name="footer" />
        </VCol>
      </VRow>
    </footer>
  </VNavigationDrawer>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import NavigationItems from "./NavigationItems.vue";
import AppsMenu from "./AppsMenu.vue";
import { NavigationItem } from "./types";
import { AuthService } from "@gygadmin/auth/client";
import HomeLogo from "./HomeLogo.vue";

export default defineComponent({
  name: "SidebarNavigation",
  components: {
    HomeLogo,
    NavigationItems,
    AppsMenu,
  },
  props: {
    items: {
      type: Array as PropType<NavigationItem[]>,
      default: () => [],
    },
    showNavigation: {
      type: Boolean,
      default: true,
    },
    appletName: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
    userName: {
      type: String,
      default: "",
    },
    userPicture: {
      type: String,
      default: "",
    },
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mini: this.$props.expandOnHover,
    };
  },
  computed: {
    decodedUserName(): string {
      return decodeURI(encodeURI(this.userName)) ?? this.userName;
    },
    hasPicture(): boolean {
      return this.userPicture !== undefined && this.userPicture.length > 0;
    },
    drawer: {
      get(): boolean {
        return this.showNavigation;
      },
      set(showNavigation: boolean) {
        this.$emit("update:showNavigation", showNavigation);
      },
    },
  },
  methods: {
    onClickButton(): void {
      this.$emit("update:showNavigation", false);
    },
    async signout(): Promise<void> {
      await AuthService.signOut();
    },
  },
});
</script>

<style lang="scss">
.layout-base-navigation {
  overflow: visible !important;
  z-index: 200;

  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
  }

  &__applet_title {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
  }

  &__user_photo {
    width: 30px !important;
    min-width: 30px !important;
    height: 30px !important;
    margin-left: 5px !important;
  }

  &__no_picture {
    background-image: url("../../assets/images/no-user-pic.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  &__user_name {
    text-indent: 5px;
  }

  .gygapp-footer {
    width: 100%;
  }

  .no-flex {
    flex-basis: auto;
    flex-grow: 0;
  }

  .flex-grow {
    flex-grow: 1;
  }
}
</style>
