import { PrioritizedReviewQueueState } from "@client/store/modules/attribute-review-queue/prioritized/types";

export const initialState: PrioritizedReviewQueueState = {
  prioritizedReviewQueueListPage: {},
  prioritizedReviewQueuePage: {
    loading: false,
    detail: {
      loading: false,
      usersLoading: false,
      saving: false,
      englishText: "",
    },
  },
};
