import {
  ActivityOptionPricing,
  AvailabilityAndPricing,
  AvailabilityAndPricingInput,
  AvailabilityBlock,
  AvailabilityBlockInput,
  GeneratedContentUserInput,
  GroupPricingCategory,
  IndividualPricingCategory,
  InventoryDateRangeInput,
  PriceOverride,
  PriceOverrideInput,
  PriceOverridePrices,
  PriceOverridePricesInput,
  PricingCategory,
  PricingCategoryInput,
  PricingInput,
  PricingScale,
  PricingScaleInput,
  PricingType,
} from "@client/autogenerated/graphql/operations";
import {
  ActivitiesChangeRequestsService,
  ActivitiesService,
  ActivitiesTextService,
  ActivityCommentsService,
  ActivityGeneratedContentService,
  ActivityOptionsService,
  ActivityOptionTextService,
  ActivityPicturesService,
} from "@client/services";
import { ConnectivityOptionsService } from "@client/services/activities";
import { PricingConfigurationService } from "@client/services/pricing-availability";
import { SandboxService } from "@client/services/sandbox";
import gygadmin, { AppState } from "@gygadmin/client3";
import { SaveInfo } from "@gygadmin/client3/src/components/content-mode";
import {
  Activity,
  ActivityChangeRequest,
  ActivityComment,
  ActivityOption,
  ActivityOptionText,
  ActivityPicture,
  ActivityText,
} from "@root/commons/types";
import { ActionContext, ActionTree } from "vuex";
import {
  ActivityCommentsTabAlert,
  ActivityCommentsTabChangeDialog,
  ActivityCommentsTabRemoveDialog,
  ActivityPageAlert,
  ActivityState,
} from "./types";

export const actions: ActionTree<ActivityState, AppState> = {
  loadPage(context: ActionContext<ActivityState, AppState>, activityId: number): void {
    context.commit("SET_PAGE_LOADING", true);
    ActivitiesService.get(activityId)
      .then((activity) => {
        activity.gygadmin_status_color = ActivitiesService.convertGygadminStatusToColor(
          activity.gygadmin_status,
        );
        context.commit("SET_PAGE_ACTIVITY", activity);
        context.commit("SET_PAGE_LOADING", false);
        context.dispatch("loadActivityCommentsCount", activityId).then();
        context.dispatch("loadActivityGeneratedContentInput", activityId).then();
      })
      .catch((_) => {
        context.commit("SET_PAGE_LOADING", false);
        context.commit("SET_ACTIVITY_UNDERLYING_DATA_LOADING", false);
      });
  },
  showActivityPageAlert(context: ActionContext<ActivityState, AppState>, show: boolean): void {
    context.commit("SHOW_ACTIVITY_PAGE_ALERT", show);
  },
  setActivityPageAlert(
    context: ActionContext<ActivityState, AppState>,
    alert: ActivityPageAlert,
  ): void {
    context.commit("SET_ACTIVITY_PAGE_ALERT", alert);
  },
  setActivityData(context: ActionContext<ActivityState, AppState>, activity: Activity): void {
    activity.gygadmin_status_color = ActivitiesService.convertGygadminStatusToColor(
      activity.gygadmin_status,
    );
    context.commit("SET_PAGE_ACTIVITY", activity);
  },
  loadSupplierActivitiesTab(
    context: ActionContext<ActivityState, AppState>,
    supplierId: number,
  ): void {
    context.commit("SET_TAB_SUPPLIER_ACTIVITIES_LOADING", true);
    ActivitiesService.fetchAllBySupplier(supplierId)
      .then((activities) => {
        context.commit("SET_TAB_SUPPLIER_ACTIVITIES_ITEMS", activities);
        context.commit("SET_TAB_SUPPLIER_ACTIVITIES_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_SUPPLIER_ACTIVITIES_LOADING", false);
      });
  },
  loadActivityCommentsTab(
    context: ActionContext<ActivityState, AppState>,
    activityId: number,
  ): void {
    context.commit("SET_TAB_ACTIVITY_COMMENTS_LOADING", true);
    ActivityCommentsService.fetchAllByActivity(activityId)
      .then((comments) => {
        context.commit("SET_TAB_ACTIVITY_COMMENTS_ITEMS", comments);
        context.commit("SET_TAB_ACTIVITY_COMMENTS_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_ACTIVITY_COMMENTS_LOADING", false);
      });
  },
  loadActivityGeneratedContentInput(
    context: ActionContext<ActivityState, AppState>,
    activityId: number,
  ): void {
    context.commit("SET_TAB_ACTIVITY_AI_INPUT_LOADING", true);
    ActivityGeneratedContentService.fetchUserInputByActivity(activityId)
      .then((userInput: GeneratedContentUserInput) => {
        context.commit("SET_TAB_ACTIVITY_AI_INPUT", userInput);
        context.commit("SET_TAB_ACTIVITY_AI_INPUT_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_ACTIVITY_AI_INPUT_LOADING", false);
      });
  },
  loadActivityCommentsCount(
    context: ActionContext<ActivityState, AppState>,
    activityId: number,
  ): void {
    ActivityCommentsService.fetchAllByActivity(activityId)
      .then((comments) => {
        context.commit("SET_ACTIVITY_COMMENTS_COUNT", comments.length);
      })
      .catch(() => {
        context.commit("SET_ACTIVITY_COMMENTS_COUNT", 0);
      });
  },
  loadActivitySandboxFieldsTab(
    context: ActionContext<ActivityState, AppState>,
    activityId: number,
  ): void {
    context.commit("SET_TAB_ACTIVITY_SANDBOX_FIELDS_LOADING", true);
    SandboxService.fetchActivityFields(activityId)
      .then((items) => {
        context.commit("SET_TAB_ACTIVITY_SANDBOX_FIELDS_ITEMS", items);
        context.commit("SET_TAB_ACTIVITY_SANDBOX_FIELDS_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_ACTIVITY_SANDBOX_FIELDS_LOADING", false);
      });
  },
  loadActivityOptionSandboxFieldsTab(
    context: ActionContext<ActivityState, AppState>,
    optionId: number,
  ): void {
    context.commit("SET_TAB_ACTIVITY_OPTION_SANDBOX_FIELDS_LOADING", true);
    SandboxService.fetchOptionFields(optionId)
      .then((items) => {
        context.commit("SET_TAB_ACTIVITY_OPTION_SANDBOX_FIELDS_ITEMS", items);
        context.commit("SET_TAB_ACTIVITY_OPTION_SANDBOX_FIELDS_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_ACTIVITY_OPTION_SANDBOX_FIELDS_LOADING", false);
      });
  },
  loadActivityPicturesTab(
    context: ActionContext<ActivityState, AppState>,
    activityId: number,
  ): void {
    context.commit("SET_TAB_ACTIVITY_PICTURES_LOADING", true);
    ActivityPicturesService.fetchAllByActivity(activityId)
      .then((pictures) => {
        context.commit("SET_TAB_ACTIVITY_PICTURES_ITEMS", pictures);
        context.commit("SET_TAB_ACTIVITY_PICTURES_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_ACTIVITY_PICTURES_LOADING", false);
      });
  },

  loadActivityTextTab(context: ActionContext<ActivityState, AppState>, activityId: number): void {
    context.commit("SET_TAB_ACTIVITY_TEXT_LOADING", true);
    ActivitiesTextService.fetchAllByActivity(activityId)
      .then((textEntries) => {
        context.commit("SET_TAB_ACTIVITY_TEXT_ITEMS", textEntries);
        ActivitiesTextService.fetchAllAutotranslatedByActivity(activityId).then((entries) => {
          context.commit("SET_TAB_ACTIVITY_TEXT_AUTO_ITEMS", entries);
          context.commit("SET_TAB_ACTIVITY_TEXT_LOADING", false);
        });
      })
      .catch((_) => {
        context.commit("SET_TAB_ACTIVITY_TEXT_LOADING", false);
      });
  },
  loadActivityOptionTextTab(
    context: ActionContext<ActivityState, AppState>,
    optionId: number,
  ): void {
    context.commit("SET_TAB_ACTIVITY_OPTION_TEXT_LOADING", true);
    ActivityOptionTextService.fetchAllByOption(optionId)
      .then((items) => {
        context.commit("SET_TAB_ACTIVITY_OPTION_TEXT_ITEMS", items);
        ActivityOptionTextService.fetchAllAutotranslatedByOption(optionId).then((entries) => {
          context.commit("SET_TAB_ACTIVITY_OPTION_TEXT_AUTO_ITEMS", entries);
          context.commit("SET_TAB_ACTIVITY_OPTION_TEXT_LOADING", false);
        });
      })
      .catch((_) => {
        context.commit("SET_TAB_ACTIVITY_OPTION_TEXT_LOADING", false);
      });
  },
  loadActivityChangeRequestsTab(
    context: ActionContext<ActivityState, AppState>,
    activityId: number,
  ): void {
    context.commit("SET_TAB_ACTIVITY_CHANGE_REQUESTS_LOADING", true);
    ActivitiesChangeRequestsService.fetchAllByActivity(activityId)
      .then((items) => {
        context.commit("SET_TAB_ACTIVITY_CHANGE_REQUESTS_ITEMS", items);
        context.commit("SET_TAB_ACTIVITY_CHANGE_REQUESTS_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_ACTIVITY_CHANGE_REQUESTS_LOADING", false);
      });
  },
  setActivityChangeRequest(
    context: ActionContext<ActivityState, AppState>,
    item: ActivityChangeRequest,
  ): void {
    context.commit("SET_TAB_ACTIVITY_CHANGE_REQUESTS_ITEM", item);
  },
  setActivityText(context: ActionContext<ActivityState, AppState>, textEntry: ActivityText): void {
    context.commit("SET_TAB_ACTIVITY_TEXT_ITEM", textEntry);
  },
  setActivityOptionText(
    context: ActionContext<ActivityState, AppState>,
    item: ActivityOptionText,
  ): void {
    context.commit("SET_TAB_ACTIVITY_OPTION_TEXT_ITEM", item);
  },
  setActivityPictures(
    context: ActionContext<ActivityState, AppState>,
    pictures: ActivityPicture[],
  ): void {
    context.commit("SET_TAB_ACTIVITY_PICTURES_ITEMS", pictures);
  },

  loadActivityOptionsTab(
    context: ActionContext<ActivityState, AppState>,
    load: { activityId: number; specialOffers: boolean },
  ): void {
    context.commit("SET_TAB_ACTIVITY_OPTIONS_LOADING", true);
    const fetchOptions = !load.specialOffers
      ? ActivityOptionsService.fetchAllByActivity(load.activityId)
      : ActivityOptionsService.fetchAllSpecialOffersByActivity(load.activityId);
    fetchOptions
      .then((options) => {
        context.commit("SET_TAB_ACTIVITY_OPTIONS_ITEMS", options);
        context.commit("SET_TAB_ACTIVITY_OPTIONS_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_ACTIVITY_OPTIONS_LOADING", false);
      });
  },

  async loadActivityOptionsDetail(
    context: ActionContext<ActivityState, AppState>,
    optionId: number,
  ): Promise<void> {
    context.commit("SET_TAB_ACTIVITY_OPTIONS_DETAIL_LOADING", true);
    const options = context.state.page.tabs.activityOptions.options;
    const optionSelected = options.find((option) => option.tour_option_id === optionId);
    if (optionSelected !== undefined) {
      context.commit("SET_TAB_ACTIVITY_OPTIONS_DETAIL_ITEM", optionSelected);
    } else {
      context.commit("SET_TAB_ACTIVITY_OPTIONS_DETAIL_LOADING", false);
    }
    if (context.state.page.tabs.activityOptions.detail.loading) {
      try {
        const response = await ConnectivityOptionsService.getConnectivitySettingsByOptionId(
          optionId,
        );
        context.commit(
          "SET_CONNECTIVITY_SETTINGS_ITEM",
          response.activityOption?.connectivitySettings,
        );
      } catch (_) {
        context.commit("SET_CONNECTIVITY_SETTINGS_ITEM", null);
        context.commit("SET_TAB_ACTIVITY_OPTIONS_DETAIL_LOADING", false);
      }
    }
    if (context.state.page.tabs.activityOptions.detail.loading) {
      try {
        const response = await ConnectivityOptionsService.getConnectivitySourceConfigurations();
        context.commit(
          "SET_CONNECTIVITY_SOURCE_CONFIGURATIONS_ITEMS",
          response.connectivitySourceConfigurations,
        );
      } catch (_) {
        context.commit("SET_CONNECTIVITY_SOURCE_CONFIGURATIONS_ITEMS", null);
      }
      context.commit("SET_TAB_ACTIVITY_OPTIONS_DETAIL_LOADING", false);
    }
  },
  setActivityOption(context: ActionContext<ActivityState, AppState>, option: ActivityOption): void {
    context.commit("SET_TAB_ACTIVITY_OPTIONS_DETAIL_ITEM", option);
  },
  setActivityCommentsTabAlert(
    context: ActionContext<ActivityState, AppState>,
    alert: ActivityCommentsTabAlert,
  ): void {
    context.commit("SET_TAB_ACTIVITY_COMMENTS_ALERT", alert);
  },
  setActivityCommentsTabRemoveDialog(
    context: ActionContext<ActivityState, AppState>,
    dialog: ActivityCommentsTabRemoveDialog,
  ): void {
    context.commit("SET_TAB_ACTIVITY_COMMENTS_REMOVE_DIALOG", dialog);
  },
  removeActivityComment(context: ActionContext<ActivityState, AppState>, commentId: number): void {
    context.commit("SET_TAB_ACTIVITY_COMMENTS_REMOVE_DIALOG", {
      loading: true,
    });
    context.dispatch("setActivityCommentsTabAlert", { show: false }).then();
    const operation = ActivityCommentsService.delete(commentId)
      .then((deleted) => deleted)
      .catch((_) => false);
    operation.then((deleted) => {
      context.commit("SET_TAB_ACTIVITY_COMMENTS_REMOVE_DIALOG", {
        open: false,
        loading: false,
        commentId: null,
      });
      if (deleted) {
        context
          .dispatch("setActivityCommentsTabAlert", {
            show: true,
            type: "success",
            message: gygadmin.i18n.t("gygAdmin catalog comment remove success"),
          })
          .then();
        context.dispatch("loadActivityCommentsTab", context.getters.getActivity.tour_id).then();
      } else {
        context
          .dispatch("setActivityCommentsTabAlert", {
            show: true,
            type: "error",
            message: gygadmin.i18n.t("gygAdmin catalog comment remove error"),
          })
          .then();
      }
    });
  },
  setActivityCommentsTabChangeDialog(
    context: ActionContext<ActivityState, AppState>,
    dialog: ActivityCommentsTabChangeDialog,
  ): void {
    context.commit("SET_TAB_ACTIVITY_COMMENTS_CHANGE_DIALOG", dialog);
  },
  saveActivityComment(
    context: ActionContext<ActivityState, AppState>,
    comment: ActivityComment,
  ): void {
    context.commit("SET_TAB_ACTIVITY_COMMENTS_CHANGE_DIALOG", {
      loading: true,
    });
    context.dispatch("setActivityCommentsTabAlert", { show: false }).then();
    const operation = ActivityCommentsService.save(comment)
      .then((saved) => saved)
      .catch((_) => false);
    operation.then((saved) => {
      context.commit("SET_TAB_ACTIVITY_COMMENTS_CHANGE_DIALOG", {
        open: false,
        loading: false,
        comment: null,
      });
      if (saved) {
        context
          .dispatch("setActivityCommentsTabAlert", {
            show: true,
            type: "success",
            message: gygadmin.i18n.t("gygAdmin catalog comment save success"),
          })
          .then();
        context.dispatch("loadActivityCommentsTab", context.getters.getActivity.tour_id).then();
      } else {
        context
          .dispatch("setActivityCommentsTabAlert", {
            show: true,
            type: "error",
            message: gygadmin.i18n.t("gygAdmin catalog comment save error"),
          })
          .then();
      }
    });
  },
  loadActivityOptionPricingConfiguration(
    context: ActionContext<ActivityState, AppState>,
    activityOptionId: number,
  ): void {
    context.commit("SET_ACTIVITY_OPTION_PRICING_LOADING", true);
    PricingConfigurationService.getActivityOptionByActivityOptionId(activityOptionId.toString())
      .then((res) => {
        if (res) {
          context.commit("SET_ACTIVITY_OPTION_PRICING_SECTION", res);
        }
      })
      .catch((err) =>
        context.dispatch("setActivityPageAlert", {
          show: true,
          type: "error",
          message: `Error loading the Pricing Configuration for the Option: ${err.message}`,
        }),
      )
      .finally(() => {
        context.commit("SET_ACTIVITY_OPTION_PRICING_LOADING", false);
      });
  },

  updateAvailabilityAndPricing(
    context: ActionContext<ActivityState, AppState>,
    updatedPricing: AvailabilityAndPricing,
  ): Promise<SaveInfo> {
    function mapPriceOverridePricesToPriceOverridePricesInput(
      pricingPrice: PriceOverridePrices,
    ): PriceOverridePricesInput {
      const { id, prices, pricingId, pricingCategoryId, pricingScaleId } = pricingPrice;
      return {
        id,
        prices: removeGraphTypename(prices),
        pricingId,
        retailPrice: prices.retailPrice,
        pricingScaleId,
        pricingCategoryId,
      };
    }

    function mapPriceOverrideToPriceOverrideInput(
      priceOverride: PriceOverride,
    ): PriceOverrideInput {
      return {
        dateRanges:
          priceOverride.dateRanges?.map((dateRange) => ({
            end: dateRange.end!,
            start: dateRange.start!,
          })) ?? [],
        id: priceOverride.id.toString(),
        name: priceOverride.name,
        pricingPrices: priceOverride.pricingPrices?.map<PriceOverridePricesInput>(
          mapPriceOverridePricesToPriceOverridePricesInput,
        ),
      };
    }

    function mapPricingScaleToPricingScalesInput(scale: PricingScale): PricingScaleInput {
      return {
        ...removeGraphTypename(scale),
        prices: {
          ...removeGraphTypename(scale.prices),
        },
      };
    }

    function mapPricingCategoryToPricingCategoryInput(
      cat: PricingCategory,
      isGroupPricing: boolean,
    ): PricingCategoryInput {
      return {
        id: cat.id ? cat.id.toString() : undefined,
        commissionRate: Number.isFinite(cat.commissionRate) ? `${cat.commissionRate}` : undefined,
        bookIndependently: !isGroupPricing && (cat as IndividualPricingCategory).bookIndependently,
        bookingType: !isGroupPricing ? (cat as IndividualPricingCategory).bookingType : "",
        pricingId: cat?.pricingId,
        ticketCategory: !isGroupPricing ? (cat as IndividualPricingCategory).ticketCategory : "",
        ageRange: !isGroupPricing
          ? {
              maxAge: (cat as IndividualPricingCategory).ageRange?.maxAge,
              minAge: (cat as IndividualPricingCategory).ageRange?.minAge,
            }
          : undefined,
        isAdditionalPaxForGroup:
          isGroupPricing && (cat as GroupPricingCategory).isAdditionalPaxForGroup,
        pricingScales: cat.pricingScales?.map(mapPricingScaleToPricingScalesInput),
      };
    }

    function mapActivityOptionPricingToPricingInput(
      pricing: ActivityOptionPricing,
      isGroupPricing: boolean,
    ): PricingInput {
      const scales = pricing.pricesPerCategory?.[0]?.pricingScales;
      const highestMaxParticipantsInPs = scales
        ? Math.max(...scales.map((scale: PricingScale) => scale.maxParticipants ?? 0))
        : pricing.participantsRange?.max;

      return {
        groupVacanciesPerTimeSlot: pricing.groupVacanciesPerTimeSlot,
        maxIndividualParticipantsPerTimeSlot: isGroupPricing
          ? undefined
          : highestMaxParticipantsInPs,
        name: pricing.name ?? "",
        participantsRange: {
          max: pricing.participantsRange?.max,
          min: pricing.participantsRange?.min,
        },
        pricingType: isGroupPricing ? PricingType.Group : PricingType.Individual,
        pricingCategories: pricing.pricesPerCategory?.map((cat) =>
          mapPricingCategoryToPricingCategoryInput(cat, isGroupPricing),
        ),
        addons: pricing.addons?.map((addon) => ({
          id: addon.id,
          commissionRate: addon.commissionRate,
          pricingId: addon.pricingId || pricing.id,
          text: {
            description: addon.text?.description,
            id: addon.text?.id,
            languageId: addon.text?.languageId,
          },
          pricingScales: addon.pricingScales?.map(mapPricingScaleToPricingScalesInput),
        })),
        activityId: context.state.page.tabs.activityOptions.detail.option!.tour_id!.toString(),
        activityOptionId:
          context.state.page.tabs.activityOptions.detail.option!.tour_option_id!.toString(),
      };
    }

    function mapAvailabilityBlockToAvailabilityBlockInput(
      availabilityBlock: AvailabilityBlock,
    ): AvailabilityBlockInput {
      const {
        activityId,
        validityDateRange: { start, end },
        name,
        specialDates,
        specialDatesMaxVacancies,
        type,
      } = availabilityBlock;

      const closedDateRanges = availabilityBlock.closedDateRanges?.map<InventoryDateRangeInput>(
        ({ end, start }) => ({
          end: end!,
          start: start!,
        }),
      );

      const weekdays = availabilityBlock.weekdays?.map((weekday) => ({
        ...removeGraphTypename(weekday),
        timeSlots: weekday.timeSlots?.map(removeGraphTypename),
      }));

      const specialDatesNoTypename =
        specialDates?.forEach((specialDate) => {
          removeGraphTypename(specialDate);
        }) ?? [];

      return {
        activityId,
        closedDateRanges,
        dataRange: { end, start: start! },
        name,
        specialDates: specialDatesNoTypename,
        specialDatesMaxVacancies,
        type,
        weekdays,
      };
    }

    const usesGroupPricing = Boolean(
      context.state.page.tabs.activityOptions.pricing.inventoryConfiguration?.usesGroupPricing,
    );

    const priceOverridesInput: PriceOverrideInput[] | undefined =
      updatedPricing.priceOverrides?.map(mapPriceOverrideToPriceOverrideInput);
    const pricingInput = mapActivityOptionPricingToPricingInput(
      updatedPricing.pricing,
      usesGroupPricing,
    );
    const availabilityBlockInput: AvailabilityBlockInput =
      mapAvailabilityBlockToAvailabilityBlockInput(updatedPricing.availabilityBlock);

    const payload: AvailabilityAndPricingInput = {
      priceOverridesInput,
      pricingInput,
      availabilityBlockInput,
    };

    return PricingConfigurationService.updateAvailabilityAndPricing(
      payload,
      updatedPricing.pricing.id,
      updatedPricing.availabilityBlock.id,
    )
      .then((res) => {
        if (res?.success && res.availabilityAndPricing) {
          res?.success &&
            context.commit("UPDATE_ACTIVITY_OPTION_PRICING", res.availabilityAndPricing);
        } else if (!res?.success) {
          context.dispatch("setActivityPageAlert", {
            message: res?.message,
            level: "error",
            timeout: 10000,
          });
        }

        return {
          success: !!res?.success,
          message: res?.message || "",
        };
      })
      .catch((error) => ({
        success: false,
        message: error,
      }));
  },

  loadConnectivitySettings(
    context: ActionContext<ActivityState, AppState>,
    optionId: number,
  ): void {
    context.commit("SET_TAB_ACTIVITY_OPTIONS_DETAIL_LOADING", true);
    ConnectivityOptionsService.getConnectivitySettingsByOptionId(optionId)
      .then((res) => {
        context.commit("SET_CONNECTIVITY_SETTINGS_ITEM", res.activityOption?.connectivitySettings);
      })
      .catch((_) => {
        context.commit("SET_CONNECTIVITY_SETTINGS_ITEM", null);
      })
      .finally(() => {
        context.commit("SET_TAB_ACTIVITY_OPTIONS_DETAIL_LOADING", false);
      });
  },
};

function removeGraphTypename<T extends { __typename?: string }>({
  __typename,
  ...rest
}: T): Omit<T, "__typename"> {
  return rest;
}

export default actions;
