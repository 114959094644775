import { AppState } from "@gygadmin/client3";
import { CountryState } from "./types";
import { ActionContext, ActionTree } from "vuex";
import { CountriesService } from "@client/services";
import { AggregatedCountry } from "@root/commons/types/countries";

export const actions: ActionTree<CountryState, AppState> = {
  loadPage(context: ActionContext<CountryState, AppState>, countryId: number): void {
    context.commit("SET_PAGE_LOADING", true);
    CountriesService.get(countryId)
      .then((country) => {
        context.commit("SET_PAGE_COUNTRY", country);
        context.commit("SET_PAGE_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_PAGE_LOADING", false);
      });
  },
  setCountryData(context: ActionContext<CountryState, AppState>, country: AggregatedCountry): void {
    context.commit("SET_PAGE_COUNTRY", country);
  },
};

export default actions;
