import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import { ActivityChangeRequest } from "@root/commons/types";

export class ActivitiesChangeRequestsService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Fetch all change requests based on the provided activity id.
   *
   * @param activityId
   */
  async fetchAllByActivity(activityId: number): Promise<ActivityChangeRequest[]> {
    return this.restClient
      .get(`/activities/${activityId}/change-requests`)
      .then((response) => response.data)
      .catch((_) => []);
  }

  /**
   * Update the given change request.
   *
   * @param id
   * @param changeRequest
   * @param notify Trigger status change notification
   */
  async update(
    id: number,
    changeRequest: ActivityChangeRequest,
    notify = false,
  ): Promise<ActivityChangeRequest> {
    return this.restClient
      .put(`/activities/change-requests/${id}?notify=${notify}`, changeRequest)
      .then((response) => response.data as ActivityChangeRequest);
  }
}

export default new ActivitiesChangeRequestsService();
