import {
  RulesEngineRuleItemInput,
  SelectedRuleItemsFieldsFragment,
} from "../autogenerated/graphql/operations";
import { FieldCheckKey } from "@gygadmin/client3/src/types/fields";
import { ContentReviewSection } from "@client/helpers/attribute-review-queue/types";

export class RulesEngineMapper {
  static unflatRuleItemsTree(
    items: SelectedRuleItemsFieldsFragment[],
    parent?: SelectedRuleItemsFieldsFragment["parent"],
  ): RulesEngineRuleItemInput[] {
    const rules: RulesEngineRuleItemInput[] = [];
    const currentItems = items.filter((item) => item.parent?.uid === parent?.uid);
    const nextItems = items.filter((item) => item.parent?.uid !== parent?.uid);

    currentItems.forEach((currentItem) => {
      rules.push({
        clause: currentItem.clause,
        operation: currentItem.operation,
        ruleId: currentItem.ruleId ?? undefined,
        rules: RulesEngineMapper.unflatRuleItemsTree(nextItems, currentItem),
        params: currentItem.params?.map((param) => ({
          key: param.key,
          value: {
            dropdown: {
              values: param.value?.dropdown?.values ?? undefined,
            },
            freeText: {
              values: param.value?.freeText?.values ?? undefined,
            },
            integer: {
              value: param.value?.integer?.value ?? undefined,
            },
            interval: {
              lowerBound: param.value?.interval?.lowerBound ?? undefined,
              upperBound: param.value?.interval?.upperBound ?? undefined,
            },
          },
        })),
      });
    });

    return rules;
  }

  static contentReviewFieldToTourField(
    contentReview: ContentReviewSection
  ): string {
    if (contentReview === ContentReviewSection.TITLE) {
      return FieldCheckKey.TOUR_TITLE.toString();
    } else if (contentReview === ContentReviewSection.DESCRIPTION) {
      return FieldCheckKey.TOUR_DESCRIPTION.toString();
    } else if (contentReview === ContentReviewSection.HIGHLIGHTS) {
      return FieldCheckKey.TOUR_HIGHLIGHTS.toString();
    } else if (contentReview === ContentReviewSection.WHATS_INCLUDED) {
      return FieldCheckKey.TOUR_INCLUSIONS.toString();
    } else if (contentReview === ContentReviewSection.NOT_INCLUDED) {
      return FieldCheckKey.TOUR_EXCLUSIONS.toString();
    } else if (contentReview === ContentReviewSection.KNOW_BEFORE_YOU_GO) {
      return FieldCheckKey.TOUR_ADDITIONAL_INFORMATION.toString();
    } else if (contentReview === ContentReviewSection.DESCRIPTION_SUMMARY) {
      return FieldCheckKey.TOUR_ABSTRACT.toString();
    } else if (contentReview === ContentReviewSection.VOUCHER_INFORMATION) {
      return FieldCheckKey.TOUR_VOUCHER_INFORMATION.toString();
    } else {
      return contentReview.toString();
    }
  }
}
