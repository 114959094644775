import {
  CreateDerivedAttributeConfigurationInput,
  CreateDerivedAttributeConfigurationMutation,
  CreateDerivedAttributeConfigurationMutationVariables,
  DeleteDerivedAttributeConfigurationMutation,
  DeleteDerivedAttributeConfigurationMutationVariables,
  DerivedAttributeConfigurationPageQuery,
  DerivedAttributeConfigurationPageQueryVariables,
  DerivedAttributeConfigurationPreviewInput,
  DerivedAttributeConfigurationPreviewQuery,
  DerivedAttributeConfigurationPreviewQueryVariables,
  EditDerivedAttributeConfigurationMutation,
  EditDerivedAttributeConfigurationMutationVariables,
  UpdateDerivedAttributeConfigurationInput,
} from "@client/autogenerated/graphql/operations";
import {
  CreateDerivedAttributeMutation,
  DeleteDerivedAttributeMutation,
  DerivedAttributeEditMutation,
  DerivedAttributePageQuery,
  DerivedAttributePreviewQuery,
} from "@client/graphql/rules-engine/derived-attributes";
import { assertPresent, graphClient } from "@gygadmin/client3";

export class RulesEngineDerivedAttributesService {
  async getPage(
    id: number,
  ): Promise<DerivedAttributeConfigurationPageQuery["derivedAttributeConfiguration"]> {
    return graphClient
      .gql()
      .query<
        DerivedAttributeConfigurationPageQuery,
        DerivedAttributeConfigurationPageQueryVariables
      >({
        query: DerivedAttributePageQuery,
        variables: {
          id: String(id),
        },
      })
      .then((res) => res.data?.derivedAttributeConfiguration);
  }

  async edit(
    input: UpdateDerivedAttributeConfigurationInput,
  ): Promise<EditDerivedAttributeConfigurationMutation["updateDerivedAttributeConfiguration"]> {
    return graphClient
      .gql()
      .mutate<
        EditDerivedAttributeConfigurationMutation,
        EditDerivedAttributeConfigurationMutationVariables
      >({
        mutation: DerivedAttributeEditMutation,
        variables: {
          input,
        },
      })
      .then((res) => res.data?.updateDerivedAttributeConfiguration);
  }

  async delete(id: number): Promise<boolean> {
    return graphClient
      .gql()
      .mutate<
        DeleteDerivedAttributeConfigurationMutation,
        DeleteDerivedAttributeConfigurationMutationVariables
      >({
        mutation: DeleteDerivedAttributeMutation,
        variables: {
          id: String(id),
        },
      })
      .then((res) => res.data?.deleteDerivedAttributeConfiguration?.success ?? false);
  }

  async create(input: CreateDerivedAttributeConfigurationInput): Promise<number> {
    const response = await graphClient
      .gql()
      .mutate<
        CreateDerivedAttributeConfigurationMutation,
        CreateDerivedAttributeConfigurationMutationVariables
      >({
        mutation: CreateDerivedAttributeMutation,
        variables: {
          input,
        },
      })
      .then((res) => res.data?.createDerivedAttributeConfiguration);

    if (!response?.success) {
      throw new Error("Failed to create derived attribute");
    }

    assertPresent(response.derivedAttributeConfiguration);

    return Number(response.derivedAttributeConfiguration.id);
  }

  async preview(
    input: DerivedAttributeConfigurationPreviewInput,
  ): Promise<DerivedAttributeConfigurationPreviewQuery["derivedAttributeConfigurationPreview"]> {
    return graphClient
      .gql()
      .query<
        DerivedAttributeConfigurationPreviewQuery,
        DerivedAttributeConfigurationPreviewQueryVariables
      >({
        query: DerivedAttributePreviewQuery,
        fetchPolicy: "network-only",
        variables: {
          input,
        },
      })
      .then((res) => res.data.derivedAttributeConfigurationPreview);
  }
}

export default new RulesEngineDerivedAttributesService();
