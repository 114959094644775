export interface FieldInfo {
  /**
   * The id of the entity this field belongs to (e.g. tour_id, country_id, etc.)
   */
  entityId: number;

  /**
   * The name of the field as identified in the HTML form.
   */
  formRef: string;

  /**
   * The label that should be displayed to the user in the UI to identify the field.
   */
  label: string;

  /**
   * True if the field has translatable content. This will for instance show an extra button in the editor
   * that is labeled 'Save and Translate'. The save callback of the editor will hold a special options object
   * which tells if the save operation should also translate the content.
   */
  translatable?: boolean;

  /**
   * Information about the field check if applicable.
   * If this is undefined, the UI will not show any checkbox or similar
   */
  fieldCheck?: FieldCheckInfo;

  /**
   * Information to retrieve history data for this field from historization service
   */
  historyInfo?: HistoryInfo;
}

export enum FieldCheckType {
  TOUR = "tour",
  TAG = "tag",
  CATEGORY = "category",
  LOCATION = "location",
  USER = "user",
  OTHER = "other",
}

export enum FieldCheckKey {
  TOUR_PICTURE = "pictures",
  TOUR_EMERGENCY_PHONE_NUMBER = "emergency_phone_nr",
  TOUR_TITLE = "title",
  TOUR_ABSTRACT = "abstract",
  TOUR_DESCRIPTION = "description",
  TOUR_HIGHLIGHTS = "highlights",
  TOUR_ADDITIONAL_INFORMATION = "additional_information",
  TOUR_INCLUSIONS = "inclusions",
  TOUR_EXCLUSIONS = "exclusions",
  TOUR_VOUCHER_INFORMATION = "voucher_information",
  TOUR_LOCATIONS = "locations",
}

export interface FieldCheck {
  id?: number;
  type: string;
  type_id: string;
  key: string;
  checked: boolean;
}

/**
 * This interface is used in combination with the FieldInfo.
 */
export interface FieldCheckInfo {
  /**
   * The type of quality check field
   */
  type: FieldCheckType;

  /**
   * The type id of quality check field
   */
  typeId?: string;

  /**
   * The field identifier in the context of the field check.
   */
  key: FieldCheckKey;

  /**
   * True if the quality of this field has been checked by a staff member
   */
  checked: boolean;
}


/**
 * This interface is used to provide info for history table.
 */
export interface HistoryInfo {
  /**
   * Is a name of the document, which used to save the table's history in collectr API.
   */
  documentName: string;

  /**
   * The name of the indexed field in collectr database, to perform a search against.
   * 'id' value will be used by default
   */
  indexedFieldName?: string

  /**
   * Coma-separated list of the table's properties that are relevant for the history search
   */
  historyProperties?: string;
}
