export const mockedPrivileges = [
  "staff",
  "read:activity",
  "read:category",
  "read:supplier",
  "edit:supplier",
  "edit:supplier.termsAndConditions",
  "edit:supplier.privacyPolicy",
  "menu_show_cms",
  "admin_login",
  "history",
  "create:activity",
  "update:activity",
  "update:activity.locations",
  "update:activity.pictures",
  "update:activity.pictures.valign",
  "update:activity.pictures.source.gyg",
  "update:activity.originals",
  "update:activity.ecoCertified",
  "update:activity.internalKeywords",
  "update:activity.activityClass",
  "update:activity.authorisedBadge",
  "update:activity.commissionRate",
  "update:activity.enforce3dSecure",
  "update:activity.itineraries",
  "update:activity.priority",
  "update:activity.productType",
  "update:activity.supplierOnlineVoucher",
  "update:activity.checkoutQuestions",
  "update:activity.cancellationPolicy",
  "update:activity.strategicRelationships",
  "update:activity.status",
  "update:activity.availabilityConstraint",
  "update:activity.locations.boosted",
  "update:activity.locations.revenueRelevant",
  "delete:activity",
  "reject:activity",
  "activate:activity.status.qualityFail",
  "update:activity.sourceText.active",
  "activate-deactivate:activity",
  "activate-deactivate:activityOption",
  "write:category",
  "read:tag",
  "write:tag",
  "read:deals",
  "write:deals",
  "create:activity-option",
  "update:activity-option",
  "admin_customer_edit",
  "admin_booking_edit",
  "portal_login",
  "bookings_manage",
  "bookings_export",
  "inventory_manage",
  "products_manage",
  "reviews_manage",
  "deals_manage",
  "financials_manage",
  "account_manage",
  "user_management",
  "accept_terms_and_conditions",
];
