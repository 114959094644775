import { AppState } from "@gygadmin/client3";
import { TagState } from "./types";
import { ActionContext, ActionTree } from "vuex";
import { AggregatedTag } from "@root/commons/types";
import { TagsService } from "@client/services";

export const actions: ActionTree<TagState, AppState> = {
  async loadPage(context: ActionContext<TagState, AppState>, tagId: number): Promise<void> {
    context.commit("SET_PAGE_LOADING", true);
    try {
      const tag = await TagsService.get(tagId).then((tag) => tag);

      context.commit("SET_PAGE_TAG", tag);
      context.commit("SET_PAGE_LOADING", false);
    } catch (error) {
      context.commit("SET_PAGE_LOADING", false);
    }
  },
  setTagData(context: ActionContext<TagState, AppState>, tag: AggregatedTag): void {
    context.commit("SET_PAGE_TAG", tag);
  },
};

export default actions;
