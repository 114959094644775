import { RequestUtils } from "@client/commons/utils";
import { RulesEngineDerivedAttributesService } from "@client/services/rules-engine";
import { AppState } from "@gygadmin/client3";
import { UpdateDerivedAttributeConfigurationInput } from "@client/autogenerated/graphql/operations";
import { ActionContext, ActionTree } from "vuex";
import { RulesEngineDerivedAttributesState } from "./types";

export const actions: ActionTree<RulesEngineDerivedAttributesState, AppState> = {
  loadPage(
    context: ActionContext<RulesEngineDerivedAttributesState, AppState>,
    attributeId: number,
  ): void {
    context.commit("SET_PAGE_LOADING", true);
    context.commit("SET_PAGE_ALERT", undefined);
    context.commit("SET_PAGE_LOAD_ERROR", undefined);
    RulesEngineDerivedAttributesService.getPage(attributeId)
      .then((page) => {
        context.commit("SET_PAGE_DATA", page);
        context.commit("SET_PAGE_LOADING", false);
      })
      .catch((error) => {
        context.commit("SET_PAGE_DATA", undefined);
        context.commit("SET_PAGE_LOADING", false);
        context.commit("SET_PAGE_LOAD_ERROR", {
          show: true,
          message: "Failed to load page",
          details: RequestUtils.parseErrorList(error),
        });
      });
  },
  edit(
    context: ActionContext<RulesEngineDerivedAttributesState, AppState>,
    input: UpdateDerivedAttributeConfigurationInput,
  ): void {
    context.commit("SET_PAGE_IS_SAVING", true);
    context.commit("SET_PAGE_ALERT", undefined);
    RulesEngineDerivedAttributesService.edit(input)
      .then((page) => {
        if (!page?.success) {
          context.commit("SET_PAGE_ALERT", {
            level: "error",
            message: "Failed to update issue type",
            details: page?.errors?.map((error) => error.message),
          });
          return;
        }
        context.commit("SET_PAGE_DATA", page?.derivedAttributeConfiguration);
        context.commit("SET_PAGE_ALERT", {
          level: "success",
          message: "Successfully updated derived attribute",
        });
      })
      .catch((error) => {
        const errorMessage = error.message || "Failed to update derived attribute";

        context.commit("SET_PAGE_ALERT", {
          level: "error",
          message: errorMessage,
          details: RequestUtils.parseErrorList(error),
        });
      })
      .finally(() => {
        context.commit("SET_PAGE_IS_SAVING", false);
      });
  },
};

export default actions;
