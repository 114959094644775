import { TagState } from "./types";
import { MutationTree } from "vuex";
import { AggregatedTag } from "@root/commons/types";

export const mutations: MutationTree<TagState> = {
  SET_PAGE_TAG(state: TagState, tag: AggregatedTag): void {
    state.page = {
      ...state.page,
      id: tag.id,
      loading: false,
      tag,
    };
  },
  SET_PAGE_LOADING(state: TagState, loading: boolean): void {
    state.page = {
      ...state.page,
      loading,
    };
  },
};

export default mutations;
