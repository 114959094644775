import { AppState } from "./types";

export const initialState: AppState = {
  loading: false,
  notification: {
    open: false,
    text: "",
    level: "info",
  },
  search: {
    text: "",
  },
};
