import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import {
  ContentReview,
  ContentReviewCheck,
  ContentReviewComment,
  ContentReviewQualityIssue,
  NewActivityRevert,
  ReviewQueueItemAssigneeInfo,
  TourCommentTypeEnum,
} from "@root/commons/types";
import {
  CreateActivityCommentMutation,
  CreateActivityCommentMutationVariables,
  GetContentReviewQuery,
  GetContentReviewQueryVariables,
  SaveContentReviewMutation,
  SaveContentReviewMutationVariables,
  ContentReviewCommentCreateMutation,
  ContentReviewCommentCreateMutationVariables,
  ContentReviewCommentEditMutation,
  ContentReviewCommentEditMutationVariables,
  ContentReviewCommentDeleteMutation,
  ContentReviewCommentDeleteMutationVariables,
  ContentReviewPrioritizeMutation,
  ContentReviewPrioritizeMutationVariables,
} from "@client/autogenerated/graphql/operations";
import { GygUserList } from "@gygadmin/auth/server/types";
import { assertPresent, graphClient, SearchParams, SortDirection } from "@gygadmin/client3";
import {
  ContentReviewCommentCreate,
  ContentReviewCommentDelete,
  ContentReviewCommentEdit,
  ContentReviewPrioritize,
  GetContentReview,
  SaveContentReview,
} from "@client/graphql/prioritized-review-queue/content-review";
import { CreateActivityComment } from "@client/graphql/activities/activity-comments";
import { ContentReviewMapper } from "@client/graphql/prioritized-review-queue/content-review-mapper";
import { ActivityCommentMapper } from "@client/graphql/activities/activity-comment-mapper";
import { ContentReviewStatus } from "@client/helpers/attribute-review-queue/types";
import moment from "moment";
import { ContentReviewCommentMapper } from "@client/graphql/prioritized-review-queue/content-review-comment-mapper";
import {
  FindBlockingContentReviewsQuery,
  FindBlockingContentReviewsQueryVariables
} from "@client/autogenerated/graphql/operations";
import {FindBlockingContentReviews} from "@client/graphql/prioritized-review-queue/content-review";

class ContentReviewService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({ baseURL: "/api" });
  }

  async get(id: number): Promise<ContentReview> {
    assertPresent(id);
    return graphClient
      .gql()
      .query<GetContentReviewQuery, GetContentReviewQueryVariables>({
        query: GetContentReview,
        variables: {
          id: String(id),
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => ContentReviewMapper.toManagementContentReview(res.data.contentReview));
  }

  async update(contentReview: ContentReview): Promise<ContentReview> {
    return graphClient
      .gql()
      .mutate<SaveContentReviewMutation, SaveContentReviewMutationVariables>({
        mutation: SaveContentReview,
        variables: {
          input: ContentReviewMapper.toContentReviewUpdateInput(contentReview),
        },
      })
      .then((res) =>
        ContentReviewMapper.toManagementContentReview(res.data?.contentReviewUpdate.contentReview),
      );
  }

  async contentReviewPrioritize(contentReviewId?: number, xtmDelayedTourPushId?: number, deprioritize = false) {
    return graphClient
      .gql()
      .mutate<ContentReviewPrioritizeMutation, ContentReviewPrioritizeMutationVariables>({
        mutation: ContentReviewPrioritize,
        variables: {
          input: {
            contentReviewId,
            xtmDelayedTourPushId,
            deprioritize
          },
        },
      })
      .then((res) => res.data);
  }

  async findBlockingContentReviews(xtmDelayedTourPushId?: number) {
    assertPresent(xtmDelayedTourPushId);
    return graphClient
        .gql()
        .query<FindBlockingContentReviewsQuery, FindBlockingContentReviewsQueryVariables>({
          query: FindBlockingContentReviews,
          variables: {
            xtmDelayedTourPushId,
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => res.data.findBlockingContentReviews);
  }

  async saveRejectionReason(tourId: number, comment: string, type: TourCommentTypeEnum) {
    return graphClient
      .gql()
      .mutate<CreateActivityCommentMutation, CreateActivityCommentMutationVariables>({
        mutation: CreateActivityComment,
        variables: {
          input: ActivityCommentMapper.toActivityCommentCreateInput(tourId, comment, type),
        },
      });
  }

  async fetchInProgressQueueItems(searchPath: string, staffId: number) {
    const searchParams: SearchParams = {
      filters: {
        review_status: ContentReviewStatus.IN_PROGRESS,
        assigned_staff_id: [`${staffId}`],
        in_progress_timestamp: moment().subtract(3, "days").toISOString(),
      },
      page: 1,
      limit: -1,
      text: "",
      order: [
        {
          column: "in_progress_timestamp",
          direction: SortDirection.Asc,
        },
      ],
    };
    return await this.restClient
      .post(`/attribute-review-queue/prioritized/${searchPath}`, searchParams)
      .then((response) => response.data.items)
      .catch(() => []);
  }

  async assignAllOpenQueueItemsToCurrentUser(contentReviewId: number) {
    return await this.restClient
      .post(`/attribute-review-queue/content-review/${contentReviewId}/assign-open-items`)
      .then((response) => response.data as ContentReview);
  }

  async saveContentReviewChecks(
    contentReviewId: number,
    contentReviewChecks: ContentReviewCheck[],
  ) {
    return this.restClient
      .put(
        `/attribute-review-queue/content-review/${contentReviewId}/save-checks`,
        contentReviewChecks,
      )
      .then((response) => response.data as ContentReview);
  }

  async sendGroupedSupplierEmail(
    contentReviewId: number,
    selectedQualityIssues: ContentReviewQualityIssue[],
    override = false,
  ) {
    return this.restClient
      .put(
        `/attribute-review-queue/content-review/${contentReviewId}/send-grouped-supplier-email?override=${override}`,
        selectedQualityIssues,
      )
      .then((response) => response.data as ContentReview);
  }

  async revert(contentReviewId: number) {
    return this.restClient
      .put(`/attribute-review-queue/content-review/${contentReviewId}/revert`)
      .then((response) => response.data as ContentReview);
  }

  async revertNewActivity(contentReviewId: number, newActivityRevert: NewActivityRevert) {
    return this.restClient
      .put(
        `/attribute-review-queue/content-review/${contentReviewId}/revert-new-activity`,
        newActivityRevert,
      )
      .then((response) => response.data as ContentReview);
  }

  async getAuthorizedUsers(): Promise<GygUserList> {
    return this.restClient
      .get("/attribute-review-queue/content-review/authorized-users")
      .then((response) => response.data as GygUserList);
  }

  async getAssignees(ids: number[]): Promise<ReviewQueueItemAssigneeInfo[]> {
    return this.restClient
      .get("/attribute-review-queue/content-review/assignees?ids=" + ids.join(","))
      .then((response) => response.data as ReviewQueueItemAssigneeInfo[]);
  }

  async getOpenQueueItemsForTour(contentReviewId: number) {
    return this.restClient
      .get(`/attribute-review-queue/content-review/${contentReviewId}/open-items`)
      .then((response) => response.data as ContentReview[]);
  }

  async getNonReportedItemsForTour(contentReviewId: number) {
    return this.restClient
      .get(`/attribute-review-queue/content-review/${contentReviewId}/non-reported-items`)
      .then((response) => response.data as ContentReview[]);
  }

  async createComment(comment: ContentReviewComment) {
    return graphClient
      .gql()
      .mutate<ContentReviewCommentCreateMutation, ContentReviewCommentCreateMutationVariables>({
        mutation: ContentReviewCommentCreate,
        variables: {
          input: ContentReviewCommentMapper.toCreateCommentInput(comment),
        },
      })
      .then((res) =>
        ContentReviewMapper.toManagementContentReview(
          res.data?.contentReviewCommentCreate.contentReview,
        ),
      );
  }

  async editComment(comment: ContentReviewComment) {
    return graphClient
      .gql()
      .mutate<ContentReviewCommentEditMutation, ContentReviewCommentEditMutationVariables>({
        mutation: ContentReviewCommentEdit,
        variables: {
          input: ContentReviewCommentMapper.toEditCommentInput(comment),
        },
      })
      .then((res) =>
        ContentReviewMapper.toManagementContentReview(
          res.data?.contentReviewCommentEdit.contentReview,
        ),
      );
  }

  async deleteComment(comment: ContentReviewComment) {
    return graphClient
      .gql()
      .mutate<ContentReviewCommentDeleteMutation, ContentReviewCommentDeleteMutationVariables>({
        mutation: ContentReviewCommentDelete,
        variables: {
          input: ContentReviewCommentMapper.toEditCommentInput(comment),
        },
      })
      .then((res) =>
        ContentReviewMapper.toManagementContentReview(
          res.data?.contentReviewCommentDelete.contentReview,
        ),
      );
  }
}

export default new ContentReviewService();
