import { gql } from "graphql-tag";
export const EmailSearch = gql`
  query EmailSearch($input: SearchInput!) {
    emailSearch(input: $input) {
      items {
        uuid
        subject
        categories
        creationTimestamp
        recipients {
          recipient {
            email
            name
          }
        }
      }
      pagination {
        totalItems
        offset
        limit
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const EmailDetail = gql`
  query EmailDetail($uuid: ID!) {
    email(uuid: $uuid) {
      email {
        uuid
        status
        subject
        categories
        creationTimestamp
        recipients {
          recipient {
            email
            name
          }
          status
          urlClickCount
          mailOpenCount
          recipientEntityType
          recipientEntityId
          deliveredAtTimestamp
        }
        from {
            email
            name
        }
        replyTo {
            email
            name
        }
        errorMessage
      }
      emailBody {
        htmlBody
        textBody
      }
      attachments {
        fileName
        preSignedUrl
      }
      contexts {
        contextType
        contextId
      }
    }
  }
`;

export const BlockedEmailSearch = gql`
  query BlockedEmailSearch($input: SearchInput!) {
    searchBlockedRelayEmail(input: $input) {
      items {
        id
        subject
        categories
        creationTimestamp
        recipients {
            email
            name
        }
      }
      pagination {
        totalItems
        offset
        limit
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const BlockedEmail = gql`
  query BlockedEmail($id: ID!) {
    blockedRelayEmail(id: $id) {
      email {
        id
        blockedReason
        subject
        categories
        creationTimestamp
        recipients {
            email
            name
        }
        from {
          email
          name
        }
        errorMessage
      }
      body {
        htmlBody
        textBody
      }
    }
  }
`;

export const ResendEmail = gql`
  mutation resendEmail($uuid: String!) {
    resendEmail(uuid: $uuid) {
      message
      newUuid
      success
    }
  }
`;
