import { AxiosError, AxiosInstance } from "axios";
import RestClient from "@root/commons/utils/rest-client";
import { SaveInfo } from "@gygadmin/client3/src/components/content-mode";
import gygadmin from "@gygadmin/client3";

class RequestUtils {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create();
  }

  /**
   * Message to be shown on form validation required.
   */
  saveInfoValidationRequired(): SaveInfo {
    return {
      success: false,
      level: "error",
      message: gygadmin.i18n.t("gygAdmin field validation required").toString(),
      closeable: true,
    };
  }

  /**
   * Message to be shown on form validation required.
   */
  saveInfoErrorMessage(message: string): SaveInfo {
    return {
      success: false,
      level: "error",
      message: message,
      closeable: true,
    };
  }

  /**
   * Displays a warning message.
   */
  saveInfoWarningMessage(message: string): SaveInfo {
    return {
      success: false,
      show: true,
      level: "warning",
      message: message,
      timeout: 4000,
      closeable: true,
    };
  }

  /**
   * Message to be shown on form save.
   */
  saveInfoSuccessMessage(message?: string): SaveInfo {
    return {
      success: true,
      show: true,
      level: "success",
      message: message || gygadmin.i18n.t("gygAdmin section field saved success").toString(),
      timeout: 4000,
      closeable: true,
    };
  }

  /**
   * Parse error message.
   */
  parseErrorMessage(error: AxiosError): string {
    if (error.response !== undefined && error.response.data !== undefined) {
      return error.response.data.message || error.message;
    }
    return error.message;
  }

  /**
   * Parse error message.
   */
  parseErrorList(error: AxiosError): string[] {
    if (error.response !== undefined && error.response.data !== undefined) {
      return error.response.data.errors || [];
    }
    return [];
  }

  /**
   * Message to be shown on the request to the server failed.
   *
   * @param error
   */
  saveInfoRequestFailed(error: any): SaveInfo {
    let errorMessage = gygadmin.i18n.t("gygAdmin section field saved error").toString();
    let errors: string[] = [];
    if (error.message != null) {
      // we first get general error message
      errorMessage = error.message;
    }
    if (
      error.response != null &&
      error.response.data != null &&
      error.response.data.message != null
    ) {
      // if the server responded with a more specific message, we use that
      errorMessage = error.response.data.message;
      errors = error.response.data.errors || [];
    }
    return {
      show: true,
      success: false,
      level: "error",
      message: errorMessage,
      closeable: true,
      errors,
    };
  }

  /**
   * Download file from a given url.
   *
   * @param url
   * @param forceDownloadName
   */
  downloadImage(url: string, forceDownloadName?: string): void {
    const fileLink = document.createElement("a");
    fileLink.href = url;
    fileLink.target = "_blank";
    if (forceDownloadName !== undefined) {
      fileLink.setAttribute("download", forceDownloadName || "");
    }
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }
}

export default new RequestUtils();
