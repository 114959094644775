import { gql } from "graphql-tag";

export const ActivityDatatable = gql`
  query ActivityDatatable($input: SearchInput!) {
    activitySearch(input: $input) {
      items {
        id
        bestseller
        sourceText {
          title
        }
        online
        status
        category
        originals
        supplierActivityCode
        isTestData
      }
      pagination {
        totalItems
        offset
        limit
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
