import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import { Continent } from "@root/commons/types";

class ContinentsService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Get the continent based on the id.
   *
   * @param continentId
   */
  async get(continentId: number): Promise<Continent> {
    return this.restClient
      .get(`/continents/${continentId}`)
      .then((response) => response.data as Continent)
      .catch((_) => ({}));
  }

  /**
   * Get all continents.
   */
  async fetchAll(): Promise<Continent[]> {
    return this.restClient.get(`/continents`).then((response) => response.data as Continent[]);
  }
}

export default new ContinentsService();
