import { SandboxState } from "./types";
import { MutationTree } from "vuex";
import { AggregateSandboxField } from "@root/commons/types";

export const mutations: MutationTree<SandboxState> = {
  SET_FIELDS_LOADING(state: SandboxState, loading: boolean): void {
    state.fields = {
      ...state.fields,
      loading: loading,
    };
  },
  SET_FIELDS_ITEMS(state: SandboxState, map: Record<number, AggregateSandboxField[]>): void {
    state.fields = {
      ...state.fields,
      items: map,
    };
  },
};

export default mutations;
