<template>
  <VSnackbar
    v-model="snackbar"
    :color="color"
    timeout="-1"
  >
    {{ text }}
    <template #action="{ attrs }">
      <VBtn
        variant="text"
        v-bind="attrs"
        @click="close()"
      >
        Close
      </VBtn>
    </template>
  </VSnackbar>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Notification as NotificationState } from "../../store/types";

export default defineComponent({
  name: "Notification",
  components: {},
  computed: {
    snackbar(): boolean {
      return this.notification.open;
    },
    text(): string {
      return this.notification.text;
    },
    color(): string {
      return this.notification.level;
    },
    notification(): NotificationState {
      return this.$store.getters["getNotification"];
    },
  },
  methods: {
    close() {
      this.$store.dispatch("closeNotification");
    },
  },
});
</script>
