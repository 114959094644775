import { gql } from "graphql-tag";

export const CopyReviewsByIds = gql`
  mutation copyReviewById($input: ReviewCopyMoveByIdInput!) {
    copyReviewById(input: $input) {
      errors {
        message
      }
      reviewId
      success
    }
  }
`;

export const CopyReviewsByOptionId = gql`
  mutation copyReviewByOptionId($input: ReviewCopyMoveByOptionIdInput!) {
    copyReviewByOptionId(input: $input) {
      errors {
        message
      }
      success
      targetOptionId
    }
  }
`;

export const MoveReviewsById = gql`
  mutation moveReviewById($input: ReviewCopyMoveByIdInput!) {
    moveReviewById(input: $input) {
      errors {
        message
      }
      reviewId
      success
    }
  }
`;

export const MoveReviewsByOptionId = gql`
  mutation moveReviewByOptionId($input: ReviewCopyMoveByOptionIdInput!) {
    moveReviewByOptionId(input: $input) {
      errors {
        message
      }
      success
      targetOptionId
    }
  }
`;
