import { gql } from "graphql-tag";

export const ReviewById = gql`
  query review($id: ID!) {
    review(id: $id) {
      id
      title
      message
      language
      travelerType
      isAnonymous
      rating
      ageGroup
      status
      bookingId
      author {
        firstName
        lastName
        countryName
      }
      ratings {
        ratingType
        ratingValue
      }
    }
  }
`;

export const ReviewByBookingId = gql`
  query reviewsByBookingId($id: ID!) {
    reviewsByBookingId(id: $id) {
      communication {
        nextReminderDate
        numberOfReminders
      }
      reviews {
        id
        title
        message
        language
        travelerType
        isAnonymous
        rating
        ageGroup
        status
        bookingId
        author {
          firstName
          lastName
          countryName
        }
        ratings {
          ratingType
          ratingValue
        }
      }
    }
  }
`;

export const UpdateReview = gql`
  mutation updateReview($input: ReviewUpdateInput!) {
    updateReview(input: $input) {
      success
      errors {
        message
      }
      review {
        id
        title
        message
        language
        travelerType
        isAnonymous
        bookingId
        rating
        ageGroup
        status
        author {
          firstName
          lastName
          countryName
        }
        ratings {
          ratingType
          ratingValue
        }
      }
    }
  }
`;
