import { VuetifyOptions, ThemeDefinition } from "vuetify";
import * as components from 'vuetify/components';
import { VDataTable, VDataTableServer } from 'vuetify/labs/VDataTable';
import { VDatePicker } from 'vuetify/labs/VDatePicker';
import { VStepper, VStepperItem, VStepperHeader, VStepperWindow, VStepperWindowItem, VStepperActions } from 'vuetify/labs/VStepper';
import * as directives from 'vuetify/directives'
export const gygTheme: ThemeDefinition = {
  colors: {
    primary: "#1976d2",
  },
};

export const vuetifyConfig: VuetifyOptions = {
  components: {
    ...components,
    VDataTableServer,
    VDatePicker,
    VStepper,
    VStepperHeader,
    VStepperItem,
    VStepperWindow,
    VStepperWindowItem,
    VStepperActions,
    VDataTable
  },
  directives,
  defaults: {
    VSlider: {
      step: 1
    },
    VTabs: {
      bgColor: "white",
    },
    VToolbar: {
      density: "comfortable",
    },
    VTextField: {
      density: "comfortable",
    },
    VBtn: {
      variant: "flat", // Buttons take elevation by default.
    },
    VSwitch: {
      density: "comfortable",
      color: "success",
    },
    VAutocomplete: {
      itemTitle: "text",
      density: "comfortable",
    },
    VSelect: {
      itemTitle: "text",
      density: "comfortable",
    },
    VDataTable: {
      VAutocomplete: {
        itemTitle: "text",
      },
      VSelect: {
        itemTitle: "title",
      },
    },
    VDataTableServer: {
      VAutocomplete: {
        itemTitle: "text",
      },
      VSelect: {
        itemTitle: "title",
      },
    },
  },
  theme: {
    defaultTheme: "gygTheme",
    themes: {
      gygTheme,
    },
  },
};
