export const Role = {
  basicUser: 'basic_user',
  brandMarketing: 'brand_marketing',
  businessDevelopment: 'business_development',
  businessDevelopmentSupport: 'business_development_support',
  catalogBulkEdit: 'catalog_bulk_edit',
  catalogFreelancer: 'catalog_freelancer',
  catalogInventoryOptimization: 'catalog_inventory_optimization',
  catalogSpecialist: 'catalog_specialist',
  catalogTagging: 'catalog_tagging',
  categoryManager: 'category_manager',
  contentCoordination: 'content_coordination',
  contentImageryManagement: 'content_imagery_management',
  contentLeadership: 'content_leadership',
  contentSelfUpload: 'content_self_upload',
  contentVisualEditing: 'content_visual_editing',
  contentVisualManagement: 'content_visual_management',
  customerService: 'customer_service',
  customerServiceAgent: 'customer_service_agent',
  customerService3PB: 'customer_service_3pb',
  customerServiceB2B: 'customer_service_b2b',
  customerServiceFinanceSpecialist: 'customer_service_finance_specialist',
  customerServiceInternalMainTeam: 'customer_service_internal_main_team',
  customerServiceManager: 'customer_service_manager',
  customerServicePartner: 'customer_service_partner',
  customerServiceRescueSquad: 'customer_service_rescue_squad',
  customerServiceSocialMedia: 'customer_service_social_media',
  customerServiceSpecialist: 'customer_service_specialist',
  customerServiceSupplierCancellation: 'customer_service_supplier_cancellation',
  customerServiceTeamLead: 'customer_service_team_lead',
  design: 'design',
  engineering: 'engineering',
  engineeringAnalytics: 'engineering_analytics',
  engineeringCatalog: 'engineering_catalog',
  engineeringFinance: 'engineering_finance',
  engineeringMarketing: 'engineering_marketing',
  engineeringPartner: 'engineering_partner',
  engineeringSupply: 'engineering_supply',
  engineeringSupport: 'engineering_support',
  finance: 'finance',
  financeAccountant: 'finance_accountant',
  financeAccountantPlus: 'finance_accountant_plus',
  financeAccountingPlatform: 'finance_accounting_platform',
  financeAccountingServices: 'finance_accounting_services',
  financeCurrency: 'finance_currency',
  financeFraudSpecialist: 'finance_fraud_specialist',
  financeFraudSpecialistSuperuser: 'finance_fraud_specialist_superuser',
  guest: 'guest',
  hr: 'hr',
  it: 'it',
  legal: 'legal',
  localizationCatalogTranslation: 'localization_catalog_translation',
  localizationFreelancer: 'localization_freelancer',
  localizationLanguageSpecialist: 'localization_language_specialist',
  merchandising: 'merchandising',
  performanceMarketing: 'performance_marketing',
  productManagement: 'product_management',
  productManagementMarketplace: 'product_management_marketplace',
  productManagementPartner: 'product_management_partner',
  productManagementSupply: 'product_management_supply',
  responsibleTourism: 'responsible_tourism',
  sales: 'sales',
  salesAreaManager: 'sales_area_manager',
  salesCoordinator: 'sales_coordinator',
  salesOperation: 'sales_operation',
  salesOperationSpecialist: 'sales_operation_specialist',
  salesRegionalDirector: 'sales_regional_director',
  salesRegionalManager: 'sales_regional_manager',
  salesSeniorAccountManager: 'sales_senior_account_manager',
  salesTicketUploader: 'sales_ticket_uploader',
  superAdmin: 'superadmin',
  experiencesAndDestinationsMarketing: 'experiences_and_destinations_marketing',
};
