import {gql} from "@gygadmin/client3";

export const GetGeneratedContentInputOfActivity = gql`
    query GetGeneratedContentInputOfActivity($activityId: ID!) {
        activity(id: $activityId) {
            generatedContentUserInput {
                inputText
                inputUrl
                inputUrlWebsiteContent
            }
        }
    }
`;