import { AppState } from "@gygadmin/client3";
import {
  PrioritizedReviewQueueDetailsPage,
  PrioritizedReviewQueuePage,
  PrioritizedReviewQueueState,
} from "@client/store/modules/attribute-review-queue/prioritized/types";
import { GetterTree } from "vuex";
import { ContentReview } from "@root/commons/types";
import { GygUserList } from "@gygadmin/auth/server/types";

export const getters: GetterTree<PrioritizedReviewQueueState, AppState> = {
  getState(state: PrioritizedReviewQueueState): PrioritizedReviewQueueState {
    return state;
  },
  getPrioritizedReviewQueuePage(state: PrioritizedReviewQueueState): PrioritizedReviewQueuePage {
    return state.prioritizedReviewQueuePage;
  },
  getPrioritizedReviewQueueDetailsPage(
    state: PrioritizedReviewQueueState,
  ): PrioritizedReviewQueueDetailsPage {
    return state.prioritizedReviewQueuePage.detail;
  },
  getPrioritizedReviewQueueDetailsContentReviewItem(
    state: PrioritizedReviewQueueState,
  ): ContentReview | undefined {
    return state.prioritizedReviewQueuePage.detail.contentReview;
  },
  getPrioritizedReviewQueueDetailsAuthorizedUsers(
    state: PrioritizedReviewQueueState,
  ): GygUserList | undefined {
    return state.prioritizedReviewQueuePage.detail.authorizedUsers;
  },
  getPrioritizedReviewQueueListAuthorizedUsers(
    state: PrioritizedReviewQueueState,
  ): GygUserList | undefined {
    return state.prioritizedReviewQueueListPage.authorizedUsers;
  },
};

export default getters;
