import { AggregatedTag, TagStatusEnum, TagText, TagType } from "@root/commons/types";
import { DefaultLanguage } from "@root/commons/static/languages";
import {
  Tag as GraphQLTag,
  TagStatus,
  TagTranslation,
  TagType as GraphQLTagType,
  UpdateTagInput,
} from "@client/autogenerated/graphql/operations";

class TagsMapper {
  public static toAggregatorTag(tag: GraphQLTag): AggregatedTag {
    let gettext_name = "";
    if (Array.isArray(tag.translations) && tag?.translations.length > 0) {
      const english: TagTranslation | undefined = tag.translations.find(
        (translation) => translation.languageId === DefaultLanguage.id,
      );

      gettext_name = english?.name ?? "";
    }
    const translations = tag.translations ? tag.translations.map(TagsMapper.toTagText) : [];

    return {
      id: Number(tag.id),
      name: tag.name,
      translations: translations,
      description: tag.description ?? undefined,
      internal_name: tag.internalName,
      gettext_name,
      parent: tag.parent ? TagsMapper.toAggregatorTag(tag.parent) : undefined,
      parent_id: tag.parent ? Number(tag.parent.id) : undefined,
      type: tag.type ? TagsMapper.toTagTypeEnum(tag.type) : undefined,
      children: Array.isArray(tag.children) ? tag.children.map(TagsMapper.toAggregatorTag) : [],
      status: tag.status ? TagsMapper.toTagStatusEnum(tag.status) : undefined,
      hide_from_tag_selector: tag.hideFromTagSelector ?? undefined,
    };
  }

  public static toTagText(tagTranslation: TagTranslation): TagText {
    return {
      name: tagTranslation.name,
      language_id: tagTranslation.languageId,
    };
  }

  public static toGraphQLUpdateInput(tag: AggregatedTag): UpdateTagInput {
    return {
      description: tag.description,
      hideFromTagSelector: tag.hide_from_tag_selector,
      id: `${tag.id}`,
      name: tag.name ?? "",
      parentId: tag.parent_id,
      rejectionReason: tag.rejection_reason,
      status: tag.status ? TagsMapper.toGraphQLTagStatus(tag.status) : undefined,
      childrenIds: Array.isArray(tag.children)
        ? tag.children.filter((tag) => Number.isInteger(tag.id)).map((tag) => tag.id as number)
        : undefined,
    };
  }

  public static toTagStatusEnum(type: TagStatus): TagStatusEnum | undefined {
    const toTagStatusEnumMap = new Map([
      [TagStatus.Active, TagStatusEnum.Active],
      [TagStatus.Pending, TagStatusEnum.Pending],
      [TagStatus.Rejected, TagStatusEnum.Rejected],
      [TagStatus.InProgress, TagStatusEnum.InProgress],
      [TagStatus.Deleted, TagStatusEnum.Deleted],
    ]);

    return toTagStatusEnumMap.get(type);
  }

  public static toGraphQLTagStatus(type: TagStatusEnum): TagStatus | undefined {
    const toTagStatusMap = new Map([
      [TagStatusEnum.Active, TagStatus.Active],
      [TagStatusEnum.Pending, TagStatus.Pending],
      [TagStatusEnum.Rejected, TagStatus.Rejected],
      [TagStatusEnum.InProgress, TagStatus.InProgress],
      [TagStatusEnum.Deleted, TagStatus.Deleted],
    ]);

    return toTagStatusMap.get(type);
  }

  public static toTagTypeEnum(type: GraphQLTagType): TagType | undefined {
    const toTagTypeEnumMap = new Map([
      [GraphQLTagType.Food, TagType.Food],
      [GraphQLTagType.FoodType, TagType.FoodType],
      [GraphQLTagType.Drinks, TagType.Drinks],
      [GraphQLTagType.DrinksAlcoholic, TagType.DrinksAlcoholic],
      [GraphQLTagType.DrinksNonAlcoholic, TagType.DrinksNonAlcoholic],
      [GraphQLTagType.ItemsToBring, TagType.ItemsToBring],
      [GraphQLTagType.Keywords, TagType.Keywords],
      [GraphQLTagType.InternalKeywords, TagType.InternalKeywords],
      [GraphQLTagType.Restrictions, TagType.Restrictions],
      [GraphQLTagType.NotSuitableFor, TagType.NotSuitableFor],
      [GraphQLTagType.ItineraryActivity, TagType.ItineraryActivity],
      [GraphQLTagType.GearIncluded, TagType.GearIncluded],
      [GraphQLTagType.MediaIncluded, TagType.MediaIncluded],
      [GraphQLTagType.RentalItems, TagType.RentalItems],
      [GraphQLTagType.HealthAdvisory, TagType.HealthAdvisory],
      [GraphQLTagType.HealthRequirement, TagType.HealthRequirement],
      [GraphQLTagType.Theme, TagType.Theme],
      [GraphQLTagType.Seasonal, TagType.Seasonal],
      [GraphQLTagType.Audience, TagType.Audience],
      [GraphQLTagType.ConfiguratorFoodAndDrink, TagType.ConfiguratorFoodAndDrink],
      [GraphQLTagType.UnclassifiedAttribute, TagType.UnclassifiedAttribute],
      [GraphQLTagType.ThemeGroup, TagType.ThemeGroup],
      [GraphQLTagType.LocationAreaType, TagType.LocationAreaType],
      [GraphQLTagType.ItineraryGenericLocation, TagType.ItineraryGenericLocation],
    ]);

    return toTagTypeEnumMap.get(type);
  }
}

export { TagsMapper };
