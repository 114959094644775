import { PrioritizedReviewQueueState } from "@client/store/modules/attribute-review-queue/prioritized/types";
import { MutationTree } from "vuex";
import { ActivityText, ContentReview } from "@root/commons/types";
import { GygUserList } from "@gygadmin/auth/server/types";

export const mutations: MutationTree<PrioritizedReviewQueueState> = {
  SET_PAGE_PRIORITIZED_REVIEW_DETAIL_LOADING(
    state: PrioritizedReviewQueueState,
    loading: boolean,
  ): void {
    state.prioritizedReviewQueuePage.detail = {
      ...state.prioritizedReviewQueuePage.detail,
      loading: loading,
    };
  },
  SET_PAGE_PRIORITIZED_REVIEW_DETAIL_SAVING(
    state: PrioritizedReviewQueueState,
    saving: boolean,
  ): void {
    state.prioritizedReviewQueuePage.detail = {
      ...state.prioritizedReviewQueuePage.detail,
      saving: saving,
    };
  },
  SET_PAGE_PRIORITIZED_REVIEW_DETAIL_ENGLISH_TEXT(
    state: PrioritizedReviewQueueState,
    englishText: ActivityText,
  ): void {
    state.prioritizedReviewQueuePage.detail = {
      ...state.prioritizedReviewQueuePage.detail,
      englishText: englishText,
    };
  },
  SET_PAGE_PRIORITIZED_DETAIL_USERS_LOADING(
    state: PrioritizedReviewQueueState,
    usersLoading: boolean,
  ): void {
    state.prioritizedReviewQueuePage.detail = {
      ...state.prioritizedReviewQueuePage.detail,
      usersLoading: usersLoading,
    };
  },
  SET_PAGE_PRIORITIZED_REVIEW_DETAIL_CONTENT_REVIEW(
    state: PrioritizedReviewQueueState,
    contentReview: ContentReview,
  ): void {
    state.prioritizedReviewQueuePage.detail = {
      ...state.prioritizedReviewQueuePage.detail,
      contentReview: contentReview,
    };
  },
  SET_PAGE_PRIORITIZED_REVIEW_DETAIL_ASSIGNED_USER(
    state: PrioritizedReviewQueueState,
    assignedUserId: number,
  ): void {
    state.prioritizedReviewQueuePage.detail.contentReview = {
      ...state.prioritizedReviewQueuePage.detail.contentReview,
      assigned_staff_id: assignedUserId,
    };
  },
  SET_PAGE_PRIORITIZED_REVIEW_DETAIL_AUTHORIZED_USERS(
    state: PrioritizedReviewQueueState,
    authorizedUsers: GygUserList,
  ): void {
    state.prioritizedReviewQueuePage.detail = {
      ...state.prioritizedReviewQueuePage.detail,
      authorizedUsers: authorizedUsers,
    };
  },
  SET_PAGE_PRIORITIZED_REVIEW_LIST_AUTHORIZED_USERS(
    state: PrioritizedReviewQueueState,
    authorizedUsers: GygUserList,
  ): void {
    state.prioritizedReviewQueueListPage = {
      ...state.prioritizedReviewQueueListPage,
      authorizedUsers: authorizedUsers,
    };
  },
};

export default mutations;
