<template>
  <div class="quick-search">
    <VMenu :close-on-content-click="false">
      <template #activator="{ props }">
        <VTextField
          v-bind="props"
          variant="filled"
          hide-details
          autocomplete="off"
          prepend-inner-icon="mdi-magnify"
          :label="$t('gygAdmin catalog quick search')"
          class="quick-search__input-term"
          :disabled="loading"
          :model-value="term"
          :loading="loading"
          @change="search"
        />
      </template>

      <VCard min-width="800">
        <VRow>
          <VCol cols="3">
            <VTabs
              v-model="selectedTab"
              bg-color="white"
              color="primary-accent-4"
              direction="vertical"
            >
              <VTab
                v-for="tab in tabs"
                :key="tab.configuration.key"
                :value="tab.configuration.key"
                :disabled="!haveItems(tab)"
              >
                {{ tab.configuration.key }}
                {{ countLabel(tab) }}
                <VProgressCircular
                  v-if="tab.loading"
                  indeterminate
                  class="ml-2"
                  size="20"
                />
              </VTab>
            </VTabs>
          </VCol>
          <VCol cols="9">
            <VWindow v-model="selectedTab">
              <VWindowItem
                v-for="tab in tabs"
                :key="tab.configuration.key"
                :value="tab.configuration.key"
              >
                <div v-if="!haveItems(tab)">
                  {{ noItemsFoundMessage(tab.configuration.key) }}
                </div>
                <div v-else>
                  <VContainer
                    :ref="tab.configuration.key"
                    class="quick-search__results__container"
                    @scroll="(e) => scroll(tab, e)"
                  >
                    <VCard
                      v-for="item in tab.items"
                      :key="item.id"
                      :href="url(tab, item)"
                      class="mt-2"
                      border="grey"
                    >
                      <VListItem
                        :title="item.title"
                        :subtitle="item.subtitle"
                      >
                        <template #prepend>
                          <VTooltip
                            v-if="!!item.status"
                            location="top"
                            :text="item.status"
                          >
                            <template #activator="{ props }">
                              <VAvatar
                                v-bind="props"
                                :color="statusColor(item.status)"
                                size="15"
                              />
                            </template>
                          </VTooltip>
                        </template>
                        <template #append>
                          <VChip
                            v-if="item.isTestData"
                            variant="outlined"
                            class="quick-search__testdata"
                            color="red"
                            text-color="red"
                          >
                            Test Data
                          </VChip>
                          <span class="text-grey">{{ item.id }}</span>
                        </template>
                      </VListItem>
                    </VCard>
                  </VContainer>
                </div>
              </VWindowItem>
            </VWindow>
          </VCol>
        </VRow>
      </VCard>
    </VMenu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { searchService } from "./services/search";
import { QuickSearchTab, QuickSearchTabItem } from "./types/search";
import { appletsUrl } from "../../configs/app";
import { QUICK_SEARCH_LIMIT, quickSearchConfigurations } from "./config/quick-search";
import { statusToColor } from "./status-chip/util";

export default defineComponent({
  name: "QuickSearch",
  data() {
    const tabs: QuickSearchTab[] = [];

    return {
      term: "",
      open: false,
      tabs,
      selectedTab: undefined as QuickSearchTab | undefined,
    };
  },
  computed: {
    loading(): boolean {
      return !!this.tabs.find((tab) => tab.loading);
    },
  },
  mounted(): void {
    this.parseConfigurations();
    const quickSearchContainer = this.$el;
    document.addEventListener("click", (event) => {
      const target = event.target as HTMLElement;
      this.open = quickSearchContainer.contains(target);
    });
  },
  methods: {
    parseConfigurations(): void {
      this.tabs = quickSearchConfigurations.map((config) => ({
        configuration: config,
        items: [],
        pagination: { offset: 0, hasNextPage: true },
        loading: false,
      }));
    },
    haveItems(tab: QuickSearchTab): boolean {
      return !!tab.pagination.totalItems ?? !!tab.items.length;
    },
    noItemsFoundMessage(key: string): string {
      return "No " + key + " found for given search term.";
    },
    countLabel(tab: QuickSearchTab): string {
      if (!this.term || tab.loading) {
        return "";
      }
      const count = tab.pagination.totalItems ?? tab.items.length;
      return "(" + count + ")";
    },
    url(tab: QuickSearchTab, item: QuickSearchTabItem): string {
      const appletUrl = appletsUrl[tab.configuration.app];
      if (!appletUrl) {
        return "";
      }
      const id = item.urlId !== undefined ? item.urlId : item.id;
      return (
        appletUrl +
        tab.configuration.url
          .replace("{id}", id.toString())
          .replace("{parentId}", item.parentId?.toString() ?? "")
      );
    },
    async search(term: any) {
      if (term.length < 1 || this.loading) {
        return;
      }
      const oldTerm = this.term;
      this.term = term.target.value;
      await Promise.all(
        this.tabs.map((tab) => {
          if (term !== oldTerm) {
            tab.items = [];
          }
          return this.fetch(tab);
        }),
      );
      if (this.selectedTab == undefined) {
        this.selectedTab = this.tabs.find((tab) => this.haveItems(tab));
      }
    },
    async fetch(tab: QuickSearchTab, offset = 0): Promise<void> {
      if (this.term.length < 1 || tab.loading) {
        return;
      }
      tab.loading = true;
      tab = await searchService
        .quickSearch(tab.configuration.query, this.term, offset, tab.configuration.queryConfig)
        .then((response: any) => {
          response.items.forEach((item: any) => {
            item = tab.configuration.mapper(item);
            if (!tab.items.find((existing) => existing.id === item.id)) {
              tab.items.push(item);
            }
          });
          tab.pagination = response.pagination ?? { totalItems: response.items.length };
          return tab;
        })
        .catch(() => {
          return tab;
        });
      tab.loading = false;
      this.tabs = this.tabs.map((oldTab) => {
        return tab.configuration.key !== oldTab.configuration.key ? oldTab : tab;
      });
    },
    scroll(tab: QuickSearchTab, e: { target: HTMLElement }): void {
      if (!this._isElementScrollBottom(e.target) || !tab.pagination.hasNextPage || tab.loading) {
        return;
      }
      this.fetch(tab, tab.pagination.offset + QUICK_SEARCH_LIMIT).then();
    },
    _isElementScrollBottom(elem: HTMLElement): boolean {
      // we deduct five to account for some imprecision
      return elem.scrollTop >= elem.scrollHeight - elem.offsetHeight - 5;
    },
    statusColor(status: string): string {
      if (!status) {
        return "";
      }
      return statusToColor(status.toLowerCase());
    },
  },
});
</script>
<style lang="scss" scoped>
$bdu: 8px;
$quickSearch: 350px;

.quick-search {
  min-width: $quickSearch;

  &__results {
    position: absolute !important;
    width: 100%;
    min-width: $quickSearch * 3;
    right: -$quickSearch;
    min-height: 50px;
    z-index: 999;

    &__container {
      max-height: 495px;
      overflow-y: scroll;
    }

    &__category {
      position: absolute !important;
      top: -10px;
      left: 10px;
    }
  }

  &__item,
  &__item.v-list-item {
    min-height: $bdu * 4 !important;
    max-height: $bdu * 4 !important;
  }
  &__testdata.v-chip {
    padding: 0 6px;
    font-size: x-small !important;
    height: 16px;
  }

  .v-input__control {
    min-height: 40px !important;
  }

  .v-input--is-focused > .v-input__control > .v-input__slot {
    background: rgba(0, 0, 0, 0.08) !important;
  }

  .v-input--is-focused > .v-input__control > .v-input__slot input {
    color: rgba(0, 0, 0) !important;
  }

  .v-input--is-focused .v-text-field__slot .v-label {
    color: rgba(0, 0, 0, 0.67) !important;
  }
}
</style>
