import dayjs from "dayjs";

const dateFormat = "DD.MM.YYYY";
const timeFormat = "HH:mm:ss";
const dateTimeFormat = dateFormat + " " + timeFormat;

export function getUserTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function formatDateTimeString(dateTime: string | null | undefined, localize = false): string {
  if (!dateTime) return "";

  if (!localize) {
    dateTime = dateTime.split("+")[0];
  }
  const localDateTime = new Date(dateTime);
  return formatDateTime(localDateTime);
}

export function formatDateTime(localDateTime: Date): string {
  return dayjs(localDateTime).format(dateTimeFormat);
}

export function formatDateString(date: string | null | undefined): string {
  if (date === null || date === undefined) return "";

  const localDate = new Date(date);
  return formatDate(localDate);
}

export function formatDate(localDate: Date): string {
  return dayjs(localDate).format(dateFormat);
}

export function formatTime(localDate: Date): string {
  return dayjs(localDate).format(timeFormat);
}

export function formatDateToIsoString(date: string | number | null | undefined): string | null {
  if (date === null || date === undefined) return null;

  const isoDate = new Date(date);
  return isoDate.toISOString().substring(0, 10);
}

export function getDateFromIsoString(date: string): string {
  return date.substring(0, 10);
}

export function getTimeFromIsoString(date: string): string {
  return date.substring(11, 16);
}
