import { GetterTree } from "vuex";
import { AppState } from "../../types";
import { User, UserIsAllowedInput } from "./types";

export const getters: GetterTree<User, AppState> = {
  getState(state: User): User {
    return state;
  },
  isStaff(state: User): boolean {
    return state.staffId !== undefined && state.staffId > 0;
  },
  getRoles(state: User): string[] {
    return state.roles || [];
  },
  getPrivileges(state: User): string[] {
    return state.privileges || []; //(fieldKey: string): boolean
  },
  isAllowed:
    (state: User) =>
    ({ roles, privileges }: UserIsAllowedInput): boolean => {
      const hasRole = roles?.length ? roles.some((role) => state.roles.includes(role)) : true;
      const hasPrivilege = privileges?.length
        ? privileges.some((privilege) => state.privileges?.includes(privilege))
        : true;
      return hasRole && hasPrivilege;
    },
};

export default getters;
