import {
  ContentReviewQualityIssue,
  NewActivityRevert,
  ContentReviewCheck,
  ContentReviewComment,
} from "@root/commons/types";

export enum ContentReviewContext {
  CONTENT_CHANGE = "content_change",
  NEW_OPTION = "new_option",
  NEW_ACTIVITY = "new_activity",
}

export enum ContentReviewEntity {
  TOUR = "tour",
  TOUR_OPTION = "tour_option",
}

export enum ContentReviewStatus {
  TODO = "todo",
  IN_PROGRESS = "in_progress",
  APPROVED = "approved",
  AUTO_APPROVED = "auto_approved",
  MODIFIED = "modified",
  ACTION_REQUIRED = "action_required",
  SKIP = 'skip',
}

export enum ContentReviewSection {
  ONCE_YOU_ARRIVE = "once_you_arrive",
  DESCRIPTION = "description",
  DROP_OFF_DESCRIPTION = "drop_off_description",
  MEETING_POINT_DESCRIPTION = "meeting_point_description",
  PICKUP_DESCRIPTION = "pickup_description",
  TITLE = "title",
  DESCRIPTION_SUMMARY = "description_summary",
  HIGHLIGHTS = "highlights",
  KNOW_BEFORE_YOU_GO = "know_before_you_go",
  NOT_INCLUDED = "not_included",
  VOUCHER_INFORMATION = "voucher_information",
  WHATS_INCLUDED = "whats_included",
}

export enum ContentReviewPriority {
  HIGH = 3,
  MEDIUM = 2,
  LOW = 1,
}

export enum NewOptionFields {
  CONDUCTION_LANGUAGES = "conductionLanguages",
  DESCRIPTION = "description",
  DROP_OFF = "dropOff",
  DROP_OFF_DESCRIPTION = "dropOffDescription",
  DURATION = "duration",
  GROUP_SIZE = "groupSize",
  IS_PRIVATE = "isPrivate",
  MEETING_POINT = "meetingPoint",
  MEETING_POINT_DESCRIPTION = "meetingPointDescription",
  MEETING_POINT_ONCE_YOU_ARRIVE = "meetingPointOnceYouArrive",
  PICK_UP = "pickUp",
  PICK_UP_DESCRIPTION = "pickUpDescription",
  PRICE_AND_AVAILABILITY = "priceAndAvailability",
  SKIP_THE_LINE_TYPE = "skipTheLineType",
  TITLE = "title",
}

export enum QualityIssueStatus {
  MODIFIED = "modified",
  ACTION_REQUIRED = "action_required",
}

export enum QualityIssueEntity {
  TOUR = "tour",
  TOUR_OPTION = "tour_option",
}

export enum ContentReviewCheckCategory {
  EDITING = "editing",
  CONFIGURATION = "configuration",
}

export enum ContentReviewCheckStatus {
  TODO = "todo",
  IN_PROGRESS = "in_progress",
  ON_HOLD = "on_hold",
  SKIP = "skip",
  APPROVED = "approved",
  QUALITY_FAIL = "quality_fail",
  REJECTED = "rejected",
}

export interface ExtendedContentReviewCheck extends ContentReviewCheck {
  text: string;
  icon: string;
}

export interface NewActivityCurrentState {
  tourId: number;
  tourTitle?: string;
  status: string;
  options: NewActivityCurrentStateOption[];
}

export interface NewActivityCurrentStateOption {
  optionId: number;
  status: string;
  title?: string;
  excluded: boolean;
}

export interface EditQualityIssueDialog {
  open: boolean;
  isQualityIssueValid: boolean;
  qualityIssue?: ContentReviewQualityIssue;
  qualityIssueIndex?: number;
}

export interface CommentDialog extends LoadingDialog {
  comment?: ContentReviewComment;
  commentText?: string;
  action?: "Create" | "Edit" | "Delete";
}

export interface AlertInfo {
  show: boolean;
  level?: string;
  message?: string;
}

export interface PrioritizeContentReviewInfo {
  xtmDelayedTourPushId?: number;
  contentReviewId?: number;
  isPrioritized: boolean;
}

export interface BaseDialog {
  open: boolean;
}

export interface LoadingDialog extends BaseDialog {
  loading: boolean;
}

export interface DropdownOption<T> {
  label: string;
  value: T;
}

export interface CheckDetailsDialog extends LoadingDialog {
  itemClone: ExtendedContentReviewCheck;
  item: ExtendedContentReviewCheck;
}

export interface NewActivityRevertDialog extends LoadingDialog {
  newActivityRevert: NewActivityRevert;
  title?: string;
}

export const QualityIssueTypes = {
  activity: "activity",
  activityCategorization: "tour_category_and_tour_class",
  activityOption: "activity_option",
  activityOptionDescription: "activity_option_description",
  activitySettings: "activity_settings",
  activityTitle: "activity_title",
  activityToLocationTag: "activity_to_location_tag",
  addons: "addons",
  availability: "availability",
  cartAndCheckout: "cart_and_checkout",
  dropOff: "drop_off",
  durationValidity: "duration_validity",
  fullDescription: "full_description",
  groupSizeAndGuideType: "group_size_and_guide_type",
  healthMeasures: "health_measures",
  highlights: "highlights",
  imagery: "imagery",
  inclusionsAndExclusions: "inclusions_and_exclusions",
  itemsToBring: "items_to_bring",
  knowBeforeYouGo: "know_before_you_go",
  locationsData: "locations_data",
  meetingPoint: "meeting_point",
  notSuitableFor: "not_suitable_for",
  optionTitle: "option_title",
  pickup: "pickup",
  pricing: "pricing",
  responsibleTourism: "responsible_tourism",
  restrictions: "restrictions",
  shortDescription: "short_description",
  supplierInformation: "supplier_information",
  supplierRequestedQuestions: "supplier_requested_questions",
  tags: "tags",
  tourLanguage: "tour_language",
  visualItinerary: "visual_itinerary",
  voucherAndConfirmationEmail: "voucher_and_confirmation_email",
};

export const QualityIssueSubtypes = {
  activityContentNotInEnglish: "activity_content_not_in_english",
  activityTypeNotRepresentative: "activity_type_not_representative",
  activityDuplicate: "activity_duplicate",
  gygConditionsNotMet: "gyg_conditions_not_met",
  activityNotAllowedOnGyg: "activity_not_allowed_on_gyg",
  activityOptionAddNeeded: "activity_option_add_needed",
  activityOptionRemoveNeeded: "activity_option_remove_needed",
  activityOptionDuplicate: "activity_option_duplicate",
  activityOptionShouldBeSeparate: "activity_option_should_be_separate",
  activityOptionUnclear: "activity_option_unclear",
  activityOptionDescriptionNotInformative: "activity_option_description_not_informative",
  spellingOrGrammarError: "spelling_or_grammar_error",
  translationError: "translation_error",
  activityOptionTitleInvalidStructure: "activity_option_title_invalid_structure",
  activityTitleInvalidStructure: "activity_title_invalid_structure",
  activityOptionsDifferencesUnclear: "activity_options_differences_unclear",
  activityNeedsLocationAdded: "activity_needs_location_added",
  activityNeedsLocationRemoved: "activity_needs_location_removed",
  activityLocationTaggingIncorrect: "activity_location_tagging_incorrect",
  poiEntryTypeIncorrect: "poi_entry_type_incorrect",
  poiEntryTypeMissing: "poi_entry_type_missing",
  openingTimesIncorrect: "opening_times_incorrect",
  startingTimesIncorrect: "starting_times_incorrect",
  timeSlotsMissingOrIncorrect: "time_slots_missing_or_incorrect",
  unableToAddToCart: "unable_to_add_to_cart",
  activityOptionTitleDurationValidityMismatch: "activity_option_title_duration_validity_mismatch",
  durationIncorrect: "duration_incorrect",
  durationSetupInsteadOfValidity: "duration_setup_instead_of_validity",
  durationUnclear: "duration_unclear",
  nonUserFriendly: "non_user_friendly",
  validityIncorrect: "validity_incorrect",
  validitySetupInsteadOfDuration: "validity_setup_instead_of_duration",
  validityUnclear: "validity_unclear",
  descriptionMissingFromIntro: "description_missing_from_intro",
  descriptionsNotMatching: "descriptions_not_matching",
  descriptionsMissingSomeUniqueSellingPoints: "descriptions_missing_some_unique_selling_points",
  notCompliantWithGygTone: "not_compliant_with_gyg_tone",
  inappropriateContent: "inappropriate_content",
  incorrectOrOutdated: "incorrect_or_outdated",
  spellingOrGrammarMistake: "spelling_or_grammar_mistake",
  audioGuideMissingOrIncorrect: "audio_guide_missing_or_incorrect",
  groupSizeContradictory: "group_size_contradictory",
  groupSizeIncorrect: "group_size_incorrect",
  guideTypeIncorrect: "guide_type_incorrect",
  liveGuideInfoMissing: "live_guide_info_missing",
  healthMeasuresInfoMissing: "health_measures_info_missing",
  uniqueSellingPointsMissing: "unique_selling_points_missing",
  exclusionsNotGrouped: "exclusions_not_grouped",
  descriptionsInclusionsExclusionsMismatch: "descriptions_inclusions_exclusions_mismatch",
  incorrect: "incorrect",
  phrasingConfusingOrUnclear: "phrasing_confusing_or_unclear",
  noBulletPoints: "no_bullet_points",
  addItemsToBring: "add_items_to_bring",
  removeItemsToBring: "remove_items_to_bring",
  startingPlaceNotSetupAsPickup: "starting_place_not_setup_as_pickup",
  howToRecognizeGuideNotMentioned: "how_to_recognize_guide_not_mentioned",
  extraEntryTypesInfoNotMentioned: "extra_entry_types_info_not_mentioned",
  meetingPointAddressIncorrectOrOutdated: "meeting_point_address_incorrect_or_outdated",
  meetingPointDescriptionIncorrectSpellingOrMissingFormatting:
    "meeting_point_description_incorrect_spelling_or_missing_formatting",
  meetingPointDescriptionIncorrectOrOutdated: "meeting_point_description_incorrect_or_outdated",
  meetingPointDescriptionTranslationError: "meeting_point_description_translation_error",
  pinAndAddressNotMatching: "pin_and_address_not_matching",
  whatToLookForNotMentioned: "what_to_look_for_not_mentioned",
  whenToArriveNotMentioned: "when_to_arrive_not_mentioned",
  addNotSuitableFor: "add_not_suitable_for",
  removeNotSuitableFor: "remove_not_suitable_for",
  startingPlaceNotSetAsMeetingPoint: "starting_place_not_set_as_meeting_point",
  areasIncorrectlyDisplayedOnMap: "areas_incorrectly_displayed_on_map",
  areasSetupAsLocations: "areas_setup_as_locations",
  descriptionHasSpellingMistakesOrMissingFormatting:
    "description_has_spelling_mistakes_or_missing_formatting",
  descriptionIncorrectOrOutdated: "description_incorrect_or_outdated",
  descriptionTranslationError: "description_translation_error",
  locationsIncorrectOrOutdated: "locations_incorrect_or_outdated",
  changeOrAddPriceForParticipants: "change_or_add_price_for_participants",
  changeOrAddRestrictions: "change_or_add_restrictions",
  priceNotMatchingBestPriceGuarantee: "price_not_matching_best_price_guarantee",
  pricingIncorrectOrOutdated: "pricing_incorrect_or_outdated",
  pricingSetupInconsistentOrConfusing: "pricing_setup_inconsistent_or_confusing",
  removeOrAddAddon: "remove_or_add_addon",
  wrongPriceOfAddon: "wrong_price_of_addon",
  addRestriction: "add_restriction",
  removeRestriction: "remove_restriction",
  contactInfoIncorrect: "contact_info_incorrect",
  requestedQuestionIncorrectOrOutdated: "requested_question_incorrect_or_outdated",
  requestedQuestionNeedsAdding: "requested_question_needs_adding",
  requestedQuestionNeedsRemoving: "requested_question_needs_removing",
  familyFriendlyTagNeedsUpdating: "family_friendly_tag_needs_updating",
  keywordTagNeedsUpdating: "keyword_tag_needs_updating",
  outdoorTagNeedsUpdating: "outdoor_tag_needs_updating",
  tourCategoryIncorrect: "tour_category_incorrect",
  tourClassIncorrect: "tour_class_incorrect",
  tourLanguageIncorrect: "tour_language_incorrect",
  groupedUnderWrongVA: "grouped_under_wrong_va",
  confirmationEmailContradictsTourPage: "confirmation_email_contradicts_tour_page",
  howToExchangeVoucherNotMentioned: "how_to_exchange_voucher_not_mentioned",
  mobileOrPrintedVoucherIncorrect: "mobile_or_printed_voucher_incorrect",
  voucherInfoIncorrectOrOutdated: "voucher_info_incorrect_or_outdated",
  itineraryMapPinPlacementWrong: "itinerary_map_pin_placement_wrong",
  itineraryUsesWrongLocation: "itinerary_uses_wrong_location",
  itineraryContainsWrongInformation: "itinerary_contains_wrong_information",
  itineraryContainsContradictingInformation: "itinerary_contains_contradicting_information",
  itineraryMissingCertainInformation: "itinerary_missing_certain_information",
  translationErrorVisualItinerary: "translation_error_visual_itinerary",
  blurryImage: "blurry_image",
  imageEditingAgainstBrandGuidelines: "image_editing_against_brand_guidelines",
  inappropriateImage: "inappropriate_image",
  incorrectOrdering: "incorrect_ordering",
  missingImageOfPoiOrLandmark: "missing_image_of_poi_or_landmark",
  missingImageOfUniqueSellingPoints: "missing_image_of_unique_selling_points",
  missingImageOfTransportation: "missing_image_of_transportation",
  missingImageOfParticipants: "missing_image_of_participants",
  containsPeopleInClichePoses: "contains_people_in_cliche_poses",
  photoshoppedImage: "photoshopped_image",
  repetitiveOrDuplicateImages: "repetitive_or_duplicate_images",
  supplierBrandingPresent: "supplier_branding_present",
  duplicateLocation: "duplicate_location",
  pictureMissingOrNeedsUpdating: "picture_missing_or_needs_updating",
  incorrectLocationName: "incorrect_location_name",
  incorrectLocationType: "incorrect_location_type",
  incorrectAreaCategorization: "incorrect_area_categorization",
  incorrectPoiCategorization: "incorrect_poi_categorization",
  incorrectLocationUseCase: "incorrect_location_use_case",
  incorrectParent: "incorrect_parent",
  incorrectMapPin: "incorrect_map_pin",
  addAlias: "add_alias",
  translationMissingOrIncorrect: "translation_missing_or_incorrect",
  mainTransportationTypeIncorrect: "main_transportation_type_missing",
  foodAndDrinksInfoMissing: "food_and_drinks_info_missing",
  timezoneIncorrect: "timezone_incorrect",
  wheelchairAccessibilityIncorrect: "wheelchair_accessibility_incorrect",
  privateOptionIncorrect: "private_option_incorrect",
  doesNotFollowDefinedStructure: "does_not_follow_defined_structure",
  transportationTypeIncorrect: "transportation_type_incorrect",
  availabilityNotMatchingSupplierInformation: "availability_not_matching_supplier_information",
  unableToCompletePayment: "unable_to_complete_payment",
  unableToAddParticipantsToBooking: "unable_to_add_participants_to_booking",
  discountIncorrect: "discount_incorrect",
  fromPriceMisleading: "from_price_misleading",
  groupPriceMissing: "group_price_missing",
  repeatedOrRedundantContent: "repeated_or_redundant_content",
  contentNotInEnglish: "content_not_in_english",
  informationBelongsToOtherContentSection: "information_belongs_to_other_content_section",
  supplierCancellationPolicyIncorrect: "supplier_cancellation_policy_incorrect",
  activityNeedsEcoBadge: "activity_needs_eco_badge",
  animalActivityNotAllowedOnGYG: "animal_activity_not_allowed_on_gyg",
  sensitiveContentSlumFavela: "sensitive_content_slum_favela",
  sensitiveContentDarkTourism: "sensitive_content_dark_tourism",
  sexualContent: "sexual_content",
};

/**
 * FLs have been instructed not to send out emails regarding these types of issues.
 * Therefore, they can safely be excluded from the list of suggested issues.
 */
export const QualityIssueSubtypesToExcludeFromSupplierEmail = [
  QualityIssueSubtypes.spellingOrGrammarMistake,
  QualityIssueSubtypes.spellingOrGrammarError,
  QualityIssueSubtypes.descriptionHasSpellingMistakesOrMissingFormatting,
  QualityIssueSubtypes.meetingPointDescriptionIncorrectSpellingOrMissingFormatting,
  QualityIssueSubtypes.notCompliantWithGygTone,
  QualityIssueSubtypes.noBulletPoints,
];
