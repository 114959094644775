import { ContentReviewCheck } from "@server/openapi/catalog/management";
import { ContentReviewCheck as GraphQLContentReviewCheck } from "@client/autogenerated/graphql/operations";

export class ContentReviewCheckMapper {
  static toManagementCheck(check: GraphQLContentReviewCheck): ContentReviewCheck {
    return {
      id: Number(check.id),
      content_review_id: check.contentReviewId ?? undefined,
      assigned_staff_id: check.assignedStaffId ?? undefined,
      category: check.category ?? undefined,
      type: check.type ?? undefined,
      status: check.status ?? undefined,
    };
  }
}
