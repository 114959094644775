/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Internal Management API
 * API for exclusive use with the Catalog Frontend. DO NOT USE ELSEWHERE! BACKWARDS COMPATIBILITY NOT GUARANTEED!
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: marketplace.catalog@getyourguide.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BoundingBox } from './bounding-box';
import { Coordinates } from './coordinates';
import { GooglePlace } from './google-place';
import { LocationCode } from './location-code';
import { LocationDetails } from './location-details';
import { LocationNameAlternative } from './location-name-alternative';
import { LocationPicture } from './location-picture';
import { LocationRelationship } from './location-relationship';
import { LocationText } from './location-text';
import { OpeningHours } from './opening-hours';
import { Tag } from './tag';
import { User } from './user';

/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    type?: LocationTypeEnum;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Location
     */
    area_types?: Array<Tag>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Location
     */
    poi_types?: Array<LocationPoiTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Location
     */
    access_modes?: Array<LocationAccessModesEnum>;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    country_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    country_name?: string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    parent_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    name?: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof Location
     */
    coordinates?: Coordinates;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    timezone_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    source?: string;
    /**
     * 
     * @type {BoundingBox}
     * @memberof Location
     */
    bounding_box?: BoundingBox;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    priority?: number;
    /**
     * 
     * @type {GooglePlace}
     * @memberof Location
     */
    google_place?: GooglePlace;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    unoptimized?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    business_relevance?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    use_case?: LocationUseCaseEnum;
    /**
     * 
     * @type {User}
     * @memberof Location
     */
    update_user?: User;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    update_timestamp?: string;
    /**
     * 
     * @type {Array<LocationCode>}
     * @memberof Location
     */
    codes?: Array<LocationCode>;
    /**
     * 
     * @type {Array<LocationRelationship>}
     * @memberof Location
     */
    relationships?: Array<LocationRelationship>;
    /**
     * 
     * @type {Array<LocationPicture>}
     * @memberof Location
     */
    pictures?: Array<LocationPicture>;
    /**
     * 
     * @type {Array<LocationText>}
     * @memberof Location
     */
    texts?: Array<LocationText>;
    /**
     * 
     * @type {Array<LocationNameAlternative>}
     * @memberof Location
     */
    name_alternatives?: Array<LocationNameAlternative>;
    /**
     * 
     * @type {LocationDetails}
     * @memberof Location
     */
    details?: LocationDetails;
    /**
     * 
     * @type {OpeningHours}
     * @memberof Location
     */
    opening_hours?: OpeningHours;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    status?: LocationStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    geoshape_id?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    custom_geoshape_id?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LocationTypeEnum {
    Area = 'area',
    City = 'city',
    Poi = 'poi',
    Subcontinent = 'subcontinent',
    Country = 'country',
    Neighborhood = 'neighborhood',
    Continent = 'continent'
}
/**
    * @export
    * @enum {string}
    */
export enum LocationPoiTypesEnum {
    Museum = 'museum',
    Tower = 'tower',
    CastleOrPalace = 'castle_or_palace',
    Gallery = 'gallery',
    ParkOrGarden = 'park_or_garden',
    ZooOrAquarium = 'zoo_or_aquarium',
    AmusementAndActivities = 'amusement_and_activities',
    HistoricAndArcheological = 'historic_and_archeological',
    Religious = 'religious',
    Theater = 'theater',
    Memorial = 'memorial',
    BrandExperience = 'brand_experience',
    RestaurantBar = 'restaurant_bar',
    PlazaSquare = 'plaza_square',
    Library = 'library',
    Stadium = 'stadium',
    Bridge = 'bridge',
    ShoppingMall = 'shopping_mall',
    River = 'river',
    Lake = 'lake',
    Bay = 'bay',
    PortHarbour = 'port_harbour',
    Island = 'island',
    Beach = 'beach',
    Cave = 'cave',
    Waterfalls = 'waterfalls',
    Mountain = 'mountain',
    NationalPark = 'national_park',
    Airport = 'airport',
    AlcoholProduction = 'alcohol_production',
    Aquarium = 'aquarium',
    Boardwalk = 'boardwalk',
    CableCar = 'cable_car',
    CanyonValley = 'canyon_valley',
    Cemetery = 'cemetery',
    FarmPlantation = 'farm_plantation',
    FoodProduction = 'food_production',
    FerrisWheel = 'ferris_wheel',
    HotSpring = 'hot_spring',
    Landmark = 'landmark',
    Market = 'market',
    Monument = 'monument',
    Resort = 'resort',
    Route = 'route',
    Ship = 'ship',
    SpaHammam = 'spa_hammam',
    Station = 'station',
    ViewingPoint = 'viewing_point',
    Volcano = 'volcano',
    WaterPark = 'water_park',
    SportPark = 'sport_park',
    NaturalLandmark = 'natural_landmark',
    InformationCenter = 'information_center',
    Zoo = 'zoo'
}
/**
    * @export
    * @enum {string}
    */
export enum LocationAccessModesEnum {
    Foot = 'foot',
    Stairs = 'stairs',
    Elevator = 'elevator'
}
/**
    * @export
    * @enum {string}
    */
export enum LocationUseCaseEnum {
    Standard = 'standard',
    AccessLevel = 'access_level',
    MainAccessLevel = 'main_access_level',
    EventOrExhibition = 'event_or_exhibition',
    FakeLocation = 'fake_location',
    Other = 'other'
}
/**
    * @export
    * @enum {string}
    */
export enum LocationStatusEnum {
    Active = 'active',
    Deleted = 'deleted'
}



