/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Internal Management API
 * API for exclusive use with the Catalog Frontend. DO NOT USE ELSEWHERE! BACKWARDS COMPATIBILITY NOT GUARANTEED!
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: marketplace.catalog@getyourguide.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { TagType } from './tag-type';
import { User } from './user';

/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    id?: number;
    /**
     * 
     * @type {User}
     * @memberof Tag
     */
    update_user?: User;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    update_timestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    internal_name?: string;
    /**
     * 
     * @type {TagType}
     * @memberof Tag
     */
    type?: TagType;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    parent_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    status?: TagStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    tours?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    hide_from_tag_selector?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    rejection_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    description?: string;
    /**
     * When querying tags in the context of a tour, contains the id of the connection between them
     * @type {number}
     * @memberof Tag
     */
    tour_to_tag_id?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TagStatusEnum {
    Pending = 'pending',
    Active = 'active',
    Rejected = 'rejected',
    InProgress = 'in_progress',
    Deleted = 'deleted'
}



