import { AxiosInstance } from "axios";
import RestClient from "@root/commons/utils/rest-client";
import {
  Activity,
  ActivityGygadminStatus,
  ActivityListItem,
  ActivityLocationSuggestions,
  ActivityStatusTransition,
  Tour,
  TourCopyRequest,
} from "@root/commons/types";

class ActivitiesService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  async search(searchParams: any): Promise<any> {
    return this.restClient
      .get("/activities/search", {
        params: searchParams,
      })
      .then((response) => response.data)
      .catch((_) => null);
  }

  async get(id: number): Promise<Activity> {
    if (!id) {
      return Promise.reject();
    }
        return this.restClient
      .get(`/activities/${id}`)
      .then((response) => response.data)
      .catch((error) => error.message);
        }

  async update(id: number, activity: Activity, formData?: FormData): Promise<Activity> {
    const headers = formData !== undefined ? { "Content-Type": "multipart/form-data" } : {};
    const data = formData !== undefined ? formData : activity;
    const config = { headers };
    return this.restClient
      .put(`/activities/${id}`, data, config)
      .then((response) => response.data.model);
  }

  async fetchAllBySupplier(supplierId: number): Promise<ActivityListItem> {
    return this.restClient
      .get(`/suppliers/${supplierId}/activities`)
      .then((response) => response.data)
      .catch((_) => []);
  }

  async transitionStatus(
    activityId: number,
    transition: ActivityStatusTransition,
    reason?: string,
  ): Promise<Activity> {
    return this.restClient
      .put(`/activities/${activityId}/transition-status/${transition}`, { reason })
      .then((response) => response.data.model as Activity);
  }

  convertGygadminStatusToColor(gygadminStatus: ActivityGygadminStatus | undefined): string {
    switch (gygadminStatus) {
      case ActivityGygadminStatus.Active:
        return "green";
      case ActivityGygadminStatus.QualityFailed:
        return "orange";
      case ActivityGygadminStatus.SupplierNotActive:
      case ActivityGygadminStatus.New:
        return "yellow";
      case ActivityGygadminStatus.DeactivatedBySupplier:
      case ActivityGygadminStatus.DeactivatedByStaff:
      case ActivityGygadminStatus.NoAvailableOptions:
      case ActivityGygadminStatus.Rejected:
        return "red";
      case ActivityGygadminStatus.DeletedBySupplier:
      case ActivityGygadminStatus.DeletedByStaff:
        return "black";
      case ActivityGygadminStatus.Temp:
      case ActivityGygadminStatus.Unknown:
        return "grey";
    }
    return "grey";
  }

  /**
   * Clone activity.
   *
   * @param tourCopyRequest
   */
  async clone(tourCopyRequest: TourCopyRequest): Promise<Tour> {
    return this.restClient.post(`/activities/clone`, { tourCopyRequest }).then((resp) => resp.data);
  }

  /**
   * Move activity.
   *
   * @param activityId
   * @param supplierId
   */
  async move(activityId: number, supplierId: number): Promise<any> {
    return this.restClient
      .post(`/activities/${activityId}/move`, { supplierId })
      .then((resp) => resp.data);
  }

  /**
   * Update an activity location suggestions.
   *
   * @param activityId
   * @param suggestions
   */
  async updateLocationSuggestions(
    activityId: number,
    suggestions: ActivityLocationSuggestions,
  ): Promise<ActivityLocationSuggestions> {
    return this.restClient
      .put(`/activities/${activityId}/locations/suggested-connections`, suggestions)
      .then((response) => response.data as ActivityLocationSuggestions);
  }
}

export default new ActivitiesService();
