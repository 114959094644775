import { gql } from "graphql-tag";

export const CommentsByEntityFields = gql`
  query CommentsByEntityFields($entity: String!, $entityId: Int!) {
    commentsByEntity(entity: $entity, entityId: $entityId) {
      id
      entity
      entityId
      type
      comment
      status
      date
      creationTime
      lastModificationTime
      creationUser {
        __typename
        ... on Staff {
          name
          id
        }
        ... on Supplier {
          id
          companyName
        }
        ... on Customer {
          id
          firstName
          lastName
        }
      }
      lastModificationUser {
        __typename
        ... on Staff {
          name
          id
        }
        ... on Supplier {
          id
          companyName
        }
        ... on Customer {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const DeleteCommentById = gql`
  mutation DeleteComment($deleteCommentInput: DeleteCommentInput!) {
    deleteComment(input: $deleteCommentInput) {
      success
    }
  }
`;

export const UpdateComment = gql`
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      comment {
        id
        entity
        entityId
        type
        comment
        status
        date
        creationUser {
          __typename
          ... on Staff {
            name
            id
          }
          ... on Supplier {
            id
            companyName
          }
          ... on Customer {
            id
            firstName
            lastName
          }
        }
        creationTime
        lastModificationUser {
          __typename
          ... on Staff {
            name
            id
          }
          ... on Supplier {
            id
            companyName
          }
          ... on Customer {
            id
            firstName
            lastName
          }
        }
        lastModificationTime
      }
    }
  }
`;

export const CreateComment = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      comment {
        id
        entity
        entityId
        type
        comment
        status
        date
        creationTime
        lastModificationTime
        creationUser {
          __typename
          ... on Staff {
            name
            id
          }
          ... on Supplier {
            id
            companyName
          }
          ... on Customer {
            id
            firstName
            lastName
          }
        }
        lastModificationUser {
          __typename
          ... on Staff {
            name
            id
          }
          ... on Supplier {
            id
            companyName
          }
          ... on Customer {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;
