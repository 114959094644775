<template>
  <BaseLayout page-identifier="error-404">
    <VCard rounded="0">
      <VCardText class="text-center pt-12 pb-12">
        <VIcon size="x-large">
          mdi-search-web
        </VIcon>
        <div class="block text-h4">
          Lost your way?
        </div>
        <div class="text-subtitle-1">
          It seems the page you're looking for isn't here.
        </div>
      </VCardText>
    </VCard>
  </BaseLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseLayout from "../../base.vue";

interface Error404PageData {
  title: string;
  message: string;
}

export default defineComponent({
  name: "Error404Page",
  components: {
    BaseLayout,
  },
  data(): Error404PageData {
    return {
      title: "Page not found",
      message: "Looks like the page you've visited does not exist.",
    };
  },
});
</script>
