import { AppState } from "@gygadmin/client3";
import { SandboxState } from "./types";
import { ActionContext, ActionTree } from "vuex";
import { SandboxService } from "@client/services/sandbox";
import { SandboxEntityInformation } from "@root/commons/types/sandbox";

export const actions: ActionTree<SandboxState, AppState> = {
  loadFields(
    context: ActionContext<SandboxState, AppState>,
    entity: SandboxEntityInformation,
  ): void {
    context.commit("SET_FIELDS_LOADING", true);
    SandboxService.fetchFields(entity)
      .then((items) => {
        const map = context.state.fields.items;
        map[entity.key!] = items;
        context.commit("SET_FIELDS_ITEMS", map);
        context.commit("SET_FIELDS_LOADING", false);
      })
      .catch(() => {
        context.commit("SET_FIELDS_LOADING", false);
      });
  },
};

export default actions;
