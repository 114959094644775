<template>
  <VAppBar
    class="pb-1"
    style="z-index: 100"
    dense
    elevation="0"
    color="white"
  >
    <VBtn
      v-if="!showNavigation"
      icon
      size="large"
      class="mt-1 ml-1"
      @click="$emit('update:showNavigation', true)"
    >
      <VIcon>mdi-menu</VIcon>
    </VBtn>
    <HomeLogo
      v-if="!mini && !showNavigation"
      :mini="mini"
      :applet-name="appletName"
    />
    <VSpacer />
    <template #append>
      <slot name="search" />
    </template>
  </VAppBar>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HomeLogo from "./HomeLogo.vue";

export default defineComponent({
  name: "AppBar",
  components: { HomeLogo },
  props: {
    domain: {
      default: "",
      type: String,
    },
    showNavigation: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
    appletName: {
      type: String,
      required: true,
    },
  },
  emits: {
    "update:showNavigation": Boolean,
  },
});
</script>
