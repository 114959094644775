import axios, { AxiosInstance } from 'axios';
import {
  getAuth,
  browserSessionPersistence,
  signInWithCustomToken,
  Auth,
} from 'firebase/auth';
import { Role } from '../../roles';
import { AuthOptions, FirebaseUser } from '../types';

class AuthService {
  public options: AuthOptions = {
    configs: {
      auth: { login: '', signOut: '', status: '' },
      cookie: { session: 'session', token: 'auth-token' },
      options: {
        apiKey: '',
        appId: '',
        authDomain: '',
        databaseURL: '',
        messagingSenderId: '',
        projectId: '',
        storageBucket: '',
      },
    },
    loadingScreen: (_): void => {},
    refreshInterval: 1000 * 1 * 60 * 10, // refresh user every 10min
  };
  private restClient: AxiosInstance;
  private refreshUserTimer: any = null;

  constructor() {
    this.restClient = axios.create({
      baseURL: '',
    });
  }

  public setOptions(customOptions: AuthOptions): void {
    this.options = {
      ...this.options,
      ...customOptions,
    };
  }

  public startRefreshUserCheck(): void {
    if (this.refreshUserTimer !== null) {
      return;
    }
    this.refreshUserTimer = setInterval(
      () => this.refreshUser(),
      this.options.refreshInterval
    );
  }

  public async signOut(): Promise<void> {
    this.options.loadingScreen(true);
    if (this.hasMockedUser()) {
      return;
    }
    await getAuth().signOut();
    window.location.href = this.logoutUrl();
  }

  public redirectToLogin(): void {
    if (this.hasMockedUser()) {
      return;
    }
    window.location.href = this.loginUrl();
  }

  public async decodeFirebaseUser(encodedUser: any): Promise<any> {
    if (encodedUser === null) {
      return null;
    }
    return encodedUser.getIdTokenResult().then((decodedUser: any) => {
      document.cookie = `${this.options.configs.cookie.session}=${decodedUser.token}; secure; path=/`;
      return decodedUser;
    });
  }

  public async updateUserFromFirebase(
    decodedUser: FirebaseUser
  ): Promise<void> {
    if (this.options.onUpdateUser) {
      await this.options.onUpdateUser(decodedUser);
    }
  }

  public hasMockedUser(): boolean {
    return !!this.options.configs?.mockUser;
  }

  public async refreshUser(): Promise<void> {
    if (this.hasMockedUser()) {
      await this.updateUserFromFirebase({
        claims: {
          email: 'john.doe@getyourguide.com',
          name: 'John Doe',
          picture: '',
          roles: [Role.superAdmin],
          staff_id: 1,
          uid: '1000000',
        },
      });
      return;
    }
    const response = await this.restClient.post(
      `${this.options.configs.auth.status}`,
      {},
      {
        withCredentials: true,
      }
    );
    const user = response.data;
    if (user.token) {
      const auth = getAuth();
      const signIn = auth
        .setPersistence(browserSessionPersistence)
        .then(() => signInWithCustomToken(auth, user.token))
        .catch((error) => error);
      const firebaseDecode = signIn.then(() =>
        this.decodeFirebaseUser(auth.currentUser)
      );
      return firebaseDecode.then((decodedUser) =>
        this.updateUserFromFirebase(decodedUser)
      );
    }
  }

  private logoutUrl(): string {
    return `${
      this.options.configs.auth.signOut
    }?redirect=${this.encodedRedirectLink()}`;
  }

  public getAuth(): Auth {
    return getAuth();
  }

  private loginUrl(): string {
    return `${
      this.options.configs.auth.login
    }?login_redirect=${this.encodedRedirectLink()}`;
  }

  private encodedRedirectLink(): string {
    return encodeURIComponent(window.location.href);
  }
}

export default new AuthService();
