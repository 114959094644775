<template>
  <div class="gyg-logo">
    <RouterLink
      to="/"
      class="logo-link"
    >
      <div
        v-if="mini"
        class="d-flex flex-row justify-center"
      >
        G
      </div>
      <div
        v-else
        class="ml-2"
        style="font-size: 14px"
      >
        GYGADMIN
        <span class="layout-base-navigation__applet_title">{{ appletName }}</span>
      </div>
    </RouterLink>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RouterLink } from 'vue-router';

export default defineComponent({
  name: "HomeLogo",
  components: {
    RouterLink,
  },
  props: {
    appletName: {
      type: String,
      required: true,
    },
    mini: {
      type: Boolean,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@getyourguide/design-system/styles/colors/palette";
.gyg-logo {
  font-family: "GYG Expressive", Arial, sans-serif;
  color: $guiding-red;
  font-size: 28px !important;
  &.mobile-logo {
    padding-top: 8px;
  }
  .logo-link {
    text-decoration: none;
    color: inherit;
  }
}
</style>
