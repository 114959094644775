import { Module } from "vuex";
import { AppState } from "@gygadmin/client3";
import { initialState } from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import { FieldCheckState } from "./types";

const fieldCheckModule: Module<FieldCheckState, AppState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default fieldCheckModule;
