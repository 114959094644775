import {gql} from "@gygadmin/client3";

export const CreateTourPicture = gql`
    mutation CreateTourPicture($input: CreatePictureInput!) {
        createTourPicture(input: $input) {
            success
            isDuplicate
            picture {
                id
                url
                order
                fileName
                originalFileName
                verticalAlignment
                source
            }
            errors {
                message
            }
        }
    }
`;

export const UpdateTourPicture = gql`
    mutation UpdateTourPicture($input: UpdatePictureInput!) {
        updateTourPicture(input: $input) {
            success
            isDuplicate
            picture {
                id
                url
                order
                fileName
                originalFileName
                verticalAlignment
                source
            }
            errors {
                message
            }
        }
    }
`

export const ReorderTourPicture = gql`
    mutation ReorderTourPictures($activityId: ID!, $input: [ReorderPictureInput]) {
        reorderTourPictures(activityId: $activityId, input: $input) {
            success
        }
    }
`

export const GetAllPicturesOfActivity = gql`
    query GetAllPicturesOfActivity($activityId: ID!) {
        activity(id: $activityId) {
            pictures {
                checked
                copyright
                fileName
                id
                order
                source
                status
                url
                verticalAlignment
                activity {
                    id
                }
            }
        }
    }
`;

/* @team:catalog */
export const DeleteTourPicture = gql`
    mutation DeleteTourPicture($pictureId: ID!, $input: DeletePictureInput) {
        deleteTourPicture(pictureId: $pictureId, input: $input) {
            success
            errors {
                message
            }
        }
    }
`;