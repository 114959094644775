import DOMPurify from "dompurify";

/**
 * Vue plugin for v-html-safe directive (safe alternative to insecure v-html)
 *
 * @usage   <div v-html-safe="message"></div>
 *
 * Extended with 2 new tags <del> and <ins>, they are needed to show the difference in review queue
 */
export default {
  install(Vue, options) {
    Vue.directive("html-safe", {
      created: function (el, binding) {
        el.innerHTML = DOMPurify.sanitize(binding.value, {
          ...options,
          ALLOWED_ATTR: ["target", "href", "rel"],
          ALLOWED_TAGS: [
            "a",
            "b",
            "br",
            "i",
            "img",
            "p",
            "span",
            "del",
            "ins",
            "em",
            "strong",
            "li",
            "ul",
            "ol",
            "h1",
            "h2",
            "h3",
          ],
        });
      },

      updated: function (el, binding) {
        if (binding.value !== binding.oldValue)
          el.innerHTML = DOMPurify.sanitize(binding.value, {
            ...options,
            ALLOWED_ATTR: ["target", "href", "rel"],
            ALLOWED_TAGS: [
              "a",
              "b",
              "br",
              "i",
              "img",
              "p",
              "span",
              "del",
              "ins",
              "em",
              "strong",
              "li",
              "ul",
              "ol",
              "h1",
              "h2",
              "h3",
            ],
          });
      },
    });
  },
};
