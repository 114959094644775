import { AppState } from "@gygadmin/client3";
import { GetterTree } from "vuex";
import {
  OngoingRequestsTab,
  RecentActionsTab,
  RecentlyCompletedTab,
  TourTranslationVolumeTab,
  TranslationsPage,
  TranslationsState,
} from "./types";

export const getters: GetterTree<TranslationsState, AppState> = {
  getState(state: TranslationsState): TranslationsState {
    return state;
  },
  getTranslationsPage(state: TranslationsState): TranslationsPage {
    return state.page;
  },
  getRecentActionsTab(state: TranslationsState): RecentActionsTab {
    return state.page.tabs.recentActions;
  },
  getOngoingRequestsTab(state: TranslationsState): OngoingRequestsTab {
    return state.page.tabs.ongoingRequests;
  },
  getRecentlyCompletedTab(state: TranslationsState): RecentlyCompletedTab {
    return state.page.tabs.recentlyCompleted;
  },
  getTourTranslationVolumeTab(state: TranslationsState): TourTranslationVolumeTab {
    return state.page.tabs.tourTranslationVolume;
  },
};

export default getters;
