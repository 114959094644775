import { gql } from "@gygadmin/client3";

const contentReviewFragment = gql`
  fragment ContentReviewFields on ContentReview {
    id
    entity
    entityId
    context
    assignedStaffId
    reviewSection
    reviewStatus
    currentState
    previousState
    supplierEmailSent
    reverted
    masterLanguageId
    contentReviewQualityIssues {
      id
      contentReviewId
      entity
      entityId
      status
      qualityIssueType
      qualityIssueSubtype
      qualityIssueDescription
    }
    contentReviewChecks {
      id
      contentReviewId
      type
      category
      status
      assignedStaffId
    }
    contentReviewComments {
      id
      contentReviewId
      comment
      creationUser {
        id
        type
      }
      creationStaff {
        name
      }
      creationTimestamp
      updateUser {
        id
        type
      }
      updateStaff {
        name
      }
      updateTimestamp
    }
    updateUser {
      id
      type
    }
    creationUser {
      id
      type
    }
    creationStaff {
      name
    }
    creationTimestamp
    updateTimestamp
    activationTimestamp
    inProgressTimestamp
    finalizedTimestamp
    priority
  }
`;

export const GetContentReview = gql`
  ${contentReviewFragment}

  query GetContentReview($id: ID!) {
    contentReview(id: $id) {
      ...ContentReviewFields
    }
  }
`;

export const SaveContentReview = gql`
  ${contentReviewFragment}

  mutation SaveContentReview($input: ContentReviewUpdateInput!) {
    contentReviewUpdate(input: $input) {
      success
      contentReview {
        ...ContentReviewFields
      }
      errors {
        message
      }
    }
  }
`;

export const ContentReviewPrioritize = gql`
  mutation ContentReviewPrioritize($input: ContentReviewPrioritizeInput!) {
    contentReviewPrioritize(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;

export const ContentReviewCommentCreate = gql`
  ${contentReviewFragment}

  mutation ContentReviewCommentCreate($input: ContentReviewCommentCreateInput!) {
    contentReviewCommentCreate(input: $input) {
      success
      contentReview {
        ...ContentReviewFields
      }
      errors {
        message
      }
    }
  }
`;

export const ContentReviewCommentEdit = gql`
  ${contentReviewFragment}

  mutation ContentReviewCommentEdit($input: ContentReviewCommentEditInput!) {
    contentReviewCommentEdit(input: $input) {
      success
      contentReview {
        ...ContentReviewFields
      }
      errors {
        message
      }
    }
  }
`;

export const ContentReviewCommentDelete = gql`
  ${contentReviewFragment}

  mutation ContentReviewCommentDelete($input: ContentReviewCommentEditInput!) {
    contentReviewCommentDelete(input: $input) {
      success
      contentReview {
        ...ContentReviewFields
      }
      errors {
        message
      }
    }
  }
`;

export const FindBlockingContentReviews = gql`
  ${contentReviewFragment}

  query FindBlockingContentReviews($xtmDelayedTourPushId: Int!) {
    findBlockingContentReviews(xtmDelayedTourPushId: $xtmDelayedTourPushId) {
      ...ContentReviewFields
    }
  }
`;
