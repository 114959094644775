import RulesEngineRouterPage from "../../views/rules-engine/index.vue";
import { Privilege } from "@client/commons/privileges";

export default [
  {
    path: "/rules-engine",
    component: RulesEngineRouterPage,
    children: [
      {
        path: "derived-attributes",
        meta: {
          privileges: [Privilege.DerivedAttributeConfigurationRead],
        },
        component: (): Promise<any> =>
          import("../../views/rules-engine/derived-attributes/list/list.vue"),
      },
      {
        path: "derived-attributes/:id",
        meta: {
          privileges: [Privilege.DerivedAttributeConfigurationRead],
        },
        component: (): Promise<any> =>
          import("../../views/rules-engine/derived-attributes/detail/detail.vue"),
      },
      {
        path: "quality-issues",
        meta: {
          privileges: [Privilege.QualityIssueConfigurationRead],
        },
        component: (): Promise<any> =>
          import("../../views/rules-engine/quality-issues/list/list.vue"),
      },
      {
        path: "quality-issues/:id",
        meta: {
          privileges: [Privilege.QualityIssueConfigurationRead],
        },
        component: (): Promise<any> =>
          import("../../views/rules-engine/quality-issues/detail/detail.vue"),
      },
      {
        path: "restrictions",
        meta: {
          privileges: [Privilege.RestrictionConfigurationRead],
        },
        component: (): Promise<any> =>
          import("../../views/rules-engine/restrictions/list/restrictions-list.vue"),
      },
      {
        path: "restrictions/:id",
        meta: {
          privileges: [Privilege.RestrictionConfigurationRead],
        },
        component: (): Promise<any> =>
          import("../../views/rules-engine/restrictions/detail/restriction-details-page.vue"),
      },
    ],
  },
];
