import { AxiosInstance } from "axios";
import RestClient from "@root/commons/utils/rest-client";
import { ActivityComment } from "@root/commons/types";

export class ActivityCommentsService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Fetch all comments based on the provided activity id.
   *
   * @param activityId
   */
  async fetchAllByActivity(activityId: number): Promise<Array<ActivityComment>> {
    return this.restClient
      .get(`/activities/${activityId}/comments`)
      .then((response) => response.data)
      .catch((_) => []);
  }

  /**
   * Removes a comment by the given id.
   *
   * @param commentId
   */
  async delete(commentId: number): Promise<boolean> {
    return this.restClient
      .delete(`/activities/comments/${commentId}`)
      .then((response) => response.data.success)
      .catch((_) => false);
  }

  /**
   * Create or update a comment.
   *
   * @param comment
   */
  async save(comment: ActivityComment): Promise<boolean> {
    if (comment.id != null) {
      return this.restClient
        .put(`/activities/comments/${comment.id}`, comment)
        .then((response) => response.data.success)
        .catch((_) => false);
    }
    return this.restClient
      .post(`/activities/comments`, comment)
      .then((response) => response.data.success)
      .catch((_) => false);
  }
}

export default new ActivityCommentsService();
