import { AxiosInstance } from "axios";
import RestClient from "@root/commons/utils/rest-client";

class SearchService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  async quickSearch(term: string): Promise<void> {
    return this.restClient.get("/search", {
      params: {
        s: term,
      },
    });
  }

  async quickSearchActivities(term: string, page = 1): Promise<void> {
    return this.restClient.get("/search/activities", {
      params: {
        s: term,
        page,
      },
    });
  }

  async quickSearchLocations(term: string, page = 1): Promise<void> {
    return this.restClient.get("/search/locations", {
      params: {
        s: term,
        page,
      },
    });
  }
}

export default new SearchService();
