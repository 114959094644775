import { gql } from "graphql-tag";

export const ReviewSearch = gql`
  query reviewSearch($input: SearchInput!) {
    reviewSearch(input: $input) {
      items {
        id
        title
        message
        enjoyment
        language
        bookingId
        rating
        created
        assignedStaff {
          id
          name
        }
        activityId
        status
        isTestData
      }
      pagination {
        totalItems
        offset
        limit
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const StaffByRoles = gql`
  query staffByRoles($roles: [String!]!) {
    staffByRoles(roles: $roles) {
      id
      name
    }
  }
`;

export const AssignStaffIdToReview = gql`
  mutation updateReviewStaffId($input: ReviewUpdateStaffIdInput!) {
    updateReviewStaffId(input: $input) {
      errors {
        message
      }
      reviewIds
      success
    }
  }
`;
