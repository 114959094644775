import graphClient from "@getyourguide/graphql-client-js";
import { gql } from "graphql-tag";
import "firebase/analytics";
import "vuetify/dist/vuetify.min.css";
import app from "./app";
import logger from "./commons/logger";

export * from "./commons/languages";
// To allow individual component use, export components
// each can be registered via Vue.component()
export * from "./components/index";
// To allow referencing services
export * from "./services";
// To allow referencing inner component types
export * from "./components/types";
export * from "./store/modules/user/types";
export * from "./store/types";
export * from "./theme";
export * from "./types/commons";
export * from "./types/config";
export * from "./utils/datetime";
export * from "./utils/helpers";
export {
  ActivityCategory,
  ActivityStatus,
  ActivitySupplierStatus,
} from "./__generated__/graphql/operations";
export { graphClient, gql, logger };
export type {
  ApolloQueryResult,
  InternalRefetchQueryDescriptor,
} from "@getyourguide/graphql-client-js";

export * from "./testing/index";
export const useI18n = app.useI18n;
export default app;

export * from "@vue/test-utils"
