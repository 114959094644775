import { gql } from "@gygadmin/client3";

export const ConnectivitySourceConfigurations = gql`
  query ConnectivitySourceConfigurations {
    connectivitySourceConfigurations {
      sourceConfigKey
      sourceConfigLabel
      clients {
        id
        name
      }
    }
  }
`;
