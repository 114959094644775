import {
  AvailabilityAndPricingFragment,
  AvailabilityAndPricingOptionByOptionIdQuery,
  ConnectivitySettings,
  ConnectivitySourceConfig,
  GeneratedContentUserInput,
} from "@client/autogenerated/graphql/operations";
import {
  Activity,
  ActivityChangeRequest,
  ActivityComment,
  ActivityListItem,
  ActivityOption,
  ActivityOptionText,
  ActivityPicture,
  ActivityText,
  AggregateSandboxField,
} from "@root/commons/types";
import { MutationTree } from "vuex";
import {
  ActivityCommentsTabAlert,
  ActivityCommentsTabChangeDialog,
  ActivityCommentsTabRemoveDialog,
  ActivityPageAlert,
  ActivityState,
} from "./types";

export const mutations: MutationTree<ActivityState> = {
  SET_PAGE_ACTIVITY(state: ActivityState, activity: Activity): void {
    state.page = {
      ...state.page,
      id: activity.tour_id,
      loading: false,
      activity,
    };
  },
  SHOW_ACTIVITY_PAGE_ALERT(state: ActivityState, show: boolean): void {
    state.page.alert.show = show;
  },
  SET_ACTIVITY_PAGE_ALERT(state: ActivityState, alert: ActivityPageAlert): void {
    state.page.alert = {
      ...state.page.alert,
      ...alert,
      show: alert.message.length > 0,
    };
  },
  SET_PAGE_LOADING(state: ActivityState, loading: boolean): void {
    state.page = {
      ...state.page,
      loading,
    };
  },
  SET_TAB_SUPPLIER_ACTIVITIES_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.supplierActivities = {
      ...state.page.tabs.supplierActivities,
      loading: loading,
    };
  },
  SET_TAB_SUPPLIER_ACTIVITIES_ITEMS(state: ActivityState, items: Array<ActivityListItem>): void {
    state.page.tabs.supplierActivities = {
      ...state.page.tabs.supplierActivities,
      activities: items,
    };
  },
  SET_TAB_ACTIVITY_COMMENTS_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activityComments = {
      ...state.page.tabs.activityComments,
      loading: loading,
    };
  },
  SET_TAB_ACTIVITY_AI_INPUT_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activityAiInput = {
      ...state.page.tabs.activityAiInput,
      loading: loading,
    };
  },
  SET_ACTIVITY_COMMENTS_COUNT(state: ActivityState, commentsCount: number): void {
    state.page.activityCommentsCount = commentsCount;
  },
  SET_TAB_ACTIVITY_COMMENTS_ALERT(state: ActivityState, alert: ActivityCommentsTabAlert): void {
    state.page.tabs.activityComments = {
      ...state.page.tabs.activityComments,
      alert: {
        ...alert,
      },
    };
  },
  SET_TAB_ACTIVITY_COMMENTS_ITEMS(state: ActivityState, items: Array<ActivityComment>): void {
    state.page.tabs.activityComments = {
      ...state.page.tabs.activityComments,
      comments: items,
    };
  },
  SET_TAB_ACTIVITY_COMMENTS_REMOVE_DIALOG(
    state: ActivityState,
    dialog: ActivityCommentsTabRemoveDialog,
  ): void {
    state.page.tabs.activityComments.removeDialog = {
      ...state.page.tabs.activityComments.removeDialog,
      ...dialog,
    };
  },
  SET_TAB_ACTIVITY_COMMENTS_CHANGE_DIALOG(
    state: ActivityState,
    dialog: ActivityCommentsTabChangeDialog,
  ): void {
    state.page.tabs.activityComments.changeDialog = {
      ...state.page.tabs.activityComments.changeDialog,
      ...dialog,
    };
  },
  SET_TAB_ACTIVITY_PICTURES_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activityPictures = {
      ...state.page.tabs.activityPictures,
      loading: loading,
    };
  },
  SET_TAB_ACTIVITY_PICTURES_ITEMS(state: ActivityState, items: ActivityPicture[]): void {
    state.page.tabs.activityPictures = {
      ...state.page.tabs.activityPictures,
      pictures: items,
    };
  },
  SET_TAB_ACTIVITY_OPTIONS_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activityOptions = {
      ...state.page.tabs.activityOptions,
      loading: loading,
    };
  },
  SET_TAB_ACTIVITY_OPTIONS_ITEMS(state: ActivityState, items: ActivityOption[]): void {
    state.page.tabs.activityOptions = {
      ...state.page.tabs.activityOptions,
      options: items,
    };
  },
  SET_TAB_ACTIVITY_OPTIONS_DETAIL_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activityOptions.detail = {
      ...state.page.tabs.activityOptions.detail,
      loading: loading,
    };
  },
  SET_TAB_ACTIVITY_OPTIONS_DETAIL_ITEM(state: ActivityState, item: ActivityOption): void {
    state.page.tabs.activityOptions.detail = {
      ...state.page.tabs.activityOptions.detail,
      option: item,
    };
    state.page.tabs.activityOptions = {
      ...state.page.tabs.activityOptions,
      options: state.page.tabs.activityOptions.options.map((option) =>
        option.tour_option_id === item.tour_option_id ? item : option,
      ),
    };
  },
  SET_CONNECTIVITY_SETTINGS_ITEM(state: ActivityState, item: ConnectivitySettings): void {
    state.page.tabs.activityOptions.detail = {
      ...state.page.tabs.activityOptions.detail,
      connectivitySettings: {
        ...state.page.tabs.activityOptions.detail.connectivitySettings,
        ...item,
      },
    };
  },
  SET_CONNECTIVITY_SOURCE_CONFIGURATIONS_ITEMS(
    state: ActivityState,
    items: Array<ConnectivitySourceConfig> | null | undefined,
  ): void {
    state.page.tabs.activityOptions.detail = {
      ...state.page.tabs.activityOptions.detail,
      connectivitySourceConfigurations: items,
    };
  },

  SET_TAB_ACTIVITY_TEXT_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activityText = {
      ...state.page.tabs.activityText,
      loading: loading,
    };
  },
  SET_TAB_ACTIVITY_TEXT_ITEMS(state: ActivityState, items: ActivityText[]): void {
    state.page.tabs.activityText = {
      ...state.page.tabs.activityText,
      text: items,
    };
  },
  SET_TAB_ACTIVITY_TEXT_AUTO_ITEMS(state: ActivityState, items: ActivityText[]): void {
    state.page.tabs.activityText = {
      ...state.page.tabs.activityText,
      autotranslated: items,
    };
  },
  SET_TAB_ACTIVITY_TEXT_ITEM(state: ActivityState, item: ActivityText): void {
    const items = state.page.tabs.activityText.text;
    state.page.tabs.activityText = {
      ...state.page.tabs.activityText,
      text: items.map((curr) => {
        return curr.id === item.id ? item : curr;
      }),
    };
  },
  SET_TAB_ACTIVITY_CHANGE_REQUESTS_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activityChangeRequests = {
      ...state.page.tabs.activityChangeRequests,
      loading: loading,
    };
  },
  SET_TAB_ACTIVITY_CHANGE_REQUESTS_ITEMS(
    state: ActivityState,
    items: ActivityChangeRequest[],
  ): void {
    state.page.tabs.activityChangeRequests = {
      ...state.page.tabs.activityChangeRequests,
      changeRequests: items,
    };
  },
  SET_TAB_ACTIVITY_CHANGE_REQUESTS_ITEM(state: ActivityState, item: ActivityChangeRequest): void {
    const items = state.page.tabs.activityChangeRequests.changeRequests;
    state.page.tabs.activityChangeRequests = {
      ...state.page.tabs.activityChangeRequests,
      changeRequests: items.map((curr) => {
        return curr.id === item.id ? item : curr;
      }),
    };
  },
  SET_TAB_ACTIVITY_OPTION_TEXT_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activityOptionText = {
      ...state.page.tabs.activityOptionText,
      loading: loading,
    };
  },
  SET_TAB_ACTIVITY_OPTION_TEXT_ITEMS(state: ActivityState, items: ActivityOptionText[]): void {
    state.page.tabs.activityOptionText = {
      ...state.page.tabs.activityOptionText,
      text: items,
    };
  },
  SET_TAB_ACTIVITY_OPTION_TEXT_AUTO_ITEMS(state: ActivityState, items: ActivityOptionText[]): void {
    state.page.tabs.activityOptionText = {
      ...state.page.tabs.activityOptionText,
      autotranslated: items,
    };
  },
  SET_TAB_ACTIVITY_OPTION_TEXT_ITEM(state: ActivityState, item: ActivityOptionText): void {
    const items = state.page.tabs.activityOptionText.text;
    state.page.tabs.activityOptionText = {
      ...state.page.tabs.activityOptionText,
      text: items.map((curr) => {
        return curr.id === item.id ? item : curr;
      }),
    };
  },
  SET_TAB_ACTIVITY_OPTION_SANDBOX_FIELDS_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activityOptionSandboxFields = {
      ...state.page.tabs.activityOptionSandboxFields,
      loading: loading,
    };
  },
  SET_TAB_ACTIVITY_OPTION_SANDBOX_FIELDS_ITEMS(
    state: ActivityState,
    items: AggregateSandboxField[],
  ): void {
    state.page.tabs.activityOptionSandboxFields = {
      ...state.page.tabs.activityOptionSandboxFields,
      items: items,
    };
  },
  SET_TAB_ACTIVITY_SANDBOX_FIELDS_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activitySandboxFields = {
      ...state.page.tabs.activitySandboxFields,
      loading: loading,
    };
  },
  SET_TAB_ACTIVITY_SANDBOX_FIELDS_ITEMS(
    state: ActivityState,
    items: AggregateSandboxField[],
  ): void {
    state.page.tabs.activitySandboxFields = {
      ...state.page.tabs.activitySandboxFields,
      items: items,
    };
  },

  SET_ACTIVITY_OPTION_PRICING_SECTION(
    state: ActivityState,
    payload: AvailabilityAndPricingOptionByOptionIdQuery["activityOption"],
  ): void {
    state.page.tabs.activityOptions.pricing = {
      ...state.page.tabs.activityOptions.pricing,
      ...payload,
    };
  },

  SET_ACTIVITY_OPTION_PRICING_LOADING(state: ActivityState, loading: boolean): void {
    state.page.tabs.activityOptions.pricing = {
      ...state.page.tabs.activityOptions.pricing,
      loading,
    };
  },

  SET_TAB_ACTIVITY_AI_INPUT(state: ActivityState, userInput: GeneratedContentUserInput): void {
    state.page.tabs.activityAiInput = {
      ...state.page.tabs.activityAiInput,
      userInput: userInput,
    };
  },

  UPDATE_ACTIVITY_OPTION_PRICING(
    state: ActivityState,
    updatedPricing: AvailabilityAndPricingFragment,
  ): void {
    const index = state.page.tabs.activityOptions.pricing.availabilityAndPricing?.findIndex(
      (availabilityBlock) => availabilityBlock.pricing.id === updatedPricing.pricing.id,
    ) ?? -1;

    if (index !== -1 && state.page.tabs.activityOptions.pricing.availabilityAndPricing) {
      state.page.tabs.activityOptions.pricing.availabilityAndPricing[index] = updatedPricing;
    }

  },
};

export default mutations;
