import { AppState } from "@gygadmin/client3";
import { TranslationsState } from "./types";
import { ActionContext, ActionTree } from "vuex";
import { TranslationsService } from "@client/services";

export const actions: ActionTree<TranslationsState, AppState> = {
  loadRecentEventsTab(context: ActionContext<TranslationsState, AppState>): void {
    context.commit("SET_TAB_RECENT_EVENTS_LOADING", true);
    TranslationsService.getRecentEvents()
      .then((xtmEvents) => {
        context.commit("SET_TAB_RECENT_EVENTS_ITEMS", xtmEvents);
        context.commit("SET_TAB_RECENT_EVENTS_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_RECENT_EVENTS_LOADING", false);
      });
  },
  loadOngoingTab(context: ActionContext<TranslationsState, AppState>): void {
    context.commit("SET_TAB_ONGOING_LOADING", true);
    TranslationsService.getOverview()
      .then((overview) => {
        context.commit("SET_TAB_ONGOING_ITEMS", overview.pending || []);
        context.commit("SET_TAB_ONGOING_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_ONGOING_LOADING", false);
      });
  },
  loadRecentlyCompletedTab(context: ActionContext<TranslationsState, AppState>): void {
    context.commit("SET_TAB_RECENTLY_COMPLETED_LOADING", true);
    TranslationsService.getOverview()
      .then((overview) => {
        context.commit("SET_TAB_RECENTLY_COMPLETED_ITEMS", overview.recently_done || []);
        context.commit("SET_TAB_RECENTLY_COMPLETED_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_RECENTLY_COMPLETED_LOADING", false);
      });
  },
  loadTourTranslationVolumeTab(context: ActionContext<TranslationsState, AppState>): void {
    context.commit("SET_TAB_TOUR_TRANSLATION_VOLUME_LOADING", true);
    TranslationsService.getTourTranslationVolumes()
      .then((tourTranslationVolumes) => {
        context.commit("SET_TAB_TOUR_TRANSLATION_VOLUME_ITEMS", tourTranslationVolumes);
        context.commit("SET_TAB_TOUR_TRANSLATION_VOLUME_LOADING", false);
      })
      .catch((_) => {
        context.commit("SET_TAB_TOUR_TRANSLATION_VOLUME_LOADING", false);
      });
  },
};

export default actions;
