import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import { Supplier } from "@root/commons/types";
import { graphClient } from "@gygadmin/client3";
import {
  EmailTypeEnum,
  MutationSupplierEmailActionArgs,
} from "@client/autogenerated/graphql/operations";
import { SupplierEmailAction } from "@client/graphql/suppliers/suppliers";

class SuppliersService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Search suppliers.
   *
   * @param term
   */
  async search(term: string): Promise<Supplier[]> {
    return this.restClient
      .get(`/suppliers?q=${term}`)
      .then((response) => response.data)
      .catch(() => []);
  }

  async sendActivityDeactivationEmailToSupplier(supplierId: number | undefined): Promise<boolean> {
    return graphClient
      .gql()
      .mutate<MutationSupplierEmailActionArgs>({
        mutation: SupplierEmailAction,
        variables: {
          input: {
            emailType: EmailTypeEnum.ActivityDeactivation,
            supplierId: supplierId,
          },
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }
        return true;
      });
  }
}

export default new SuppliersService();
