import { gql } from "graphql-tag";

export const BookingSearch = gql`
  query BookingsTab($input: SearchInput!) {
    bookingsSearch(input: $input) {
      items {
        id
        bookingReference
        activityOption {
          id
          activity {
            id
            sourceText {
              title
            }
          }
        }
        tourStart
        translatedConductionLanguageName
        customer {
          firstName
          lastName
        }
        customerTotalPrice {
          currency
          floatAmount
        }
        supplierTotalPrice {
          currency
          floatAmount
        }
        totalNumberOfParticipants
        status
        guideStatus
        creationDate
        isTestData
      }
      pagination {
        hasNextPage
        hasPreviousPage
        limit
        offset
        totalItems
      }
    }
  }
`;
