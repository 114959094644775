import { AppState } from "@gygadmin/client3";
import { ToolsState } from "./types";
import { ActionContext, ActionTree } from "vuex";
import { BulkEditService } from "@client/services";
import { BulkOperationAggregate } from "@root/commons/types";

export const actions: ActionTree<ToolsState, AppState> = {
  loadBulkOperation(context: ActionContext<ToolsState, AppState>, bulkOperationId: number): void {
    context.commit("SET_PAGE_BULK_EDIT_DETAIL_LOADING", true);
    BulkEditService.get(bulkOperationId)
      .then((bulkOperation) => {
        context.commit("SET_PAGE_BULK_EDIT_DETAIL_OPERATION", bulkOperation);
        context.commit("SET_PAGE_BULK_EDIT_DETAIL_LOADING", false);
      })
      .catch(() => {
        context.commit("SET_PAGE_BULK_EDIT_DETAIL_LOADING", false);
      });
  },
  setBulkOperation(
    context: ActionContext<ToolsState, AppState>,
    bulkOperation: BulkOperationAggregate,
  ): void {
    context.commit("SET_PAGE_BULK_EDIT_DETAIL_OPERATION", bulkOperation);
  },
  setBulkOperationLoading(context: ActionContext<ToolsState, AppState>, loading: boolean): void {
    context.commit("SET_PAGE_BULK_EDIT_DETAIL_LOADING", loading);
  },
};

export default actions;
