import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import {
  XtmEvent,
  XtmTranslateHighDemand,
  XtmTranslateTours,
  XtmUploadTasksOverview,
  Translation,
  XtmTourTranslationVolume,
} from "@root/commons/types";
import { assertPresent, graphClient } from "@gygadmin/client3";
import {
  MutationPushForTranslationArgs,
  PushForTranslationInput,
  PushForTranslationPayload,
  ReprocessDelayedXtmTourPushMutation,
  ReprocessDelayedXtmTourPushMutationVariables
} from "@client/autogenerated/graphql/operations";
import { PushForTranslation, ReprocessDelayedXTMTourPush } from "@client/graphql/translation/translations";

class TranslationsService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Get overview.
   *
   */
  async getOverview(): Promise<XtmUploadTasksOverview> {
    return this.restClient.get(`/translations/overview`).then((resp) => resp.data);
  }

  /**
   * Get recent events.
   *
   */
  async getRecentEvents(): Promise<XtmEvent[]> {
    return this.restClient.get(`/translations/recent-events`).then((resp) => resp.data);
  }

  /**
   * Get all tour translation volumes.
   *
   */
  async getTourTranslationVolumes(): Promise<XtmTourTranslationVolume[]> {
    return this.restClient.get(`/translations/tours/volumes`).then((resp) => resp.data);
  }

  /**
   * Create a tour translation volume.
   *
   * @param volume
   */
  async createOrEditTourTranslationVolume(volume: XtmTourTranslationVolume): Promise<void> {
    await this.restClient.post(`/translations/tours/volumes`, volume);
  }

  /**
   * Push high demand translations.
   *
   * @param languageId
   * @param data
   */
  async pushHighDemand(languageId: number, data: XtmTranslateHighDemand): Promise<void> {
    return this.restClient.post(`/translations/${languageId}/high-demand`, data);
  }

  /**
   * Push activities to translation.
   *
   * @param languageIds
   * @param data
   */
  async pushTours(data: XtmTranslateTours): Promise<void> {
    return this.restClient.post(`/translations/tours`, data);
  }

  /**
   * Push activities to translation.
   *
   * @param input
   */
  async pushToursGraph(input: PushForTranslationInput): Promise<boolean> {
    return await graphClient
      .gql()
      .mutate<PushForTranslationPayload, MutationPushForTranslationArgs>({
        mutation: PushForTranslation,
        variables: {
          input,
        },
      })
      .then((res) => {
        return res.data?.success ?? false;
      });
  }

  async reprocessXTMDelayedTourPush(xtmDelayedTourPushId?: number) {
    assertPresent(xtmDelayedTourPushId);
    return await graphClient
        .gql()
        .mutate<ReprocessDelayedXtmTourPushMutation, ReprocessDelayedXtmTourPushMutationVariables>({
          mutation: ReprocessDelayedXTMTourPush,
          variables: {
            xtmDelayedTourPushId,
          },
        })
        .then((res) => res.data?.reprocessXTMDelayedTourPush);
  }

  /**
   * Push activity to translation.
   *
   * @param activityId
   * @param allLanguages
   */
  async pushActivity(activityId: number, allLanguages: boolean): Promise<void> {
    return this.restClient.post(
      `/translations/push/activities/${activityId}?allLanguages=${allLanguages}`,
    );
  }

  /**
   * Edit a gettext translation.
   *
   * @param translation
   */
  async edit(translation: Translation): Promise<Translation> {
    return this.restClient.put(`/translations/edit`, translation);
  }
}

export default new TranslationsService();
