import { appletsUrl, gygadminUrl } from "../../configs/app";

export const customerPage = (customerId: string) =>
  `${appletsUrl.customer}/customers/${customerId}`;

export const bookingPage = (bookingId: string) => `${appletsUrl.customer}/bookings/${bookingId}`;

export const shoppingCartPage = (shoppingCartId: string) =>
  `${appletsUrl.customer}/shopping-carts/${shoppingCartId}`;

export const activityPage = (activityId: string) =>
  `${appletsUrl.catalog}/activities/${activityId}`;

export const activityOptionPage = (activityId: string, activityOptionId: string) =>
  `${appletsUrl.catalog}/activities/${activityId}/options/${activityOptionId}`;

export const supplierPage = (supplierId: string) =>
  `${appletsUrl.supplier}/suppliers/${supplierId}`;

export const couponPage = (couponId: string) => `${appletsUrl.customer}/coupons/${couponId}`;

export const staffPage = (staffId: string) => `${gygadminUrl}/portal/#page=staff&id=${staffId}`;
