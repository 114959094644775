/**
 * Client specific configs should be added here.
 */

declare global {
  interface Window {
    _appConfigs: any;
  }
}

const appConfigs = window["_appConfigs"] || {};
const gygadminUrl = appConfigs.gygadminUrl || "https://www.gygadmin.com";
const appletsUrl = appConfigs.appletsUrl || {};

const supplierPortalUrl = appConfigs.supplierPortalUrl || "https://supplier.getyourguide.com";

const graphUrl = appConfigs.graphUrl || "https://api.gygadmin.com";
const firebaseConfig: any = appConfigs.firebaseConfig || {};
const publicDomains = appConfigs.publicDomains || {};
const googleMaps = appConfigs.googleMaps || { apiKey: "" };
const googleDocs = appConfigs.googleDocs || { clientEmail: "" };
const cdnImageUrl = appConfigs.cdnImageUrl || "";
const appletName = appConfigs.appletName || "";
const sentry = appConfigs.sentry || {};
const environment = appConfigs.environment || "development";
const appVersion = appConfigs.appVersion ?? "local";
const serviceName = appConfigs.serviceName ?? "gygadmin";
const freshchat = appConfigs.freshchat ?? "";
const useMockData = false;
const kibanaLogsBaseUrl =
  "https://logging.gygservice.com/_dashboards/app/discover#/?_g=(refreshInterval:(pause:!t,value:0),time:(from:now-30d,mode:quick,to:now))&_a=(columns:!(message.message),filters:!(),";
const collectrUrl = appConfigs.collectrUrl ?? "https://history.gygadmin.gygtest.com";
const datadogClientToken = appConfigs.datadogClientToken ?? "";

export {
  environment,
  gygadminUrl,
  firebaseConfig,
  publicDomains,
  googleMaps,
  googleDocs,
  cdnImageUrl,
  appletName,
  appletsUrl,
  sentry,
  graphUrl,
  appVersion,
  serviceName,
  useMockData,
  kibanaLogsBaseUrl,
  freshchat,
  supplierPortalUrl,
  collectrUrl,
  datadogClientToken,
};
