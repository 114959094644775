/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Internal Management API
 * API for exclusive use with the Catalog Frontend. DO NOT USE ELSEWHERE! BACKWARDS COMPATIBILITY NOT GUARANTEED!
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: marketplace.catalog@getyourguide.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { User } from './user';

/**
 * 
 * @export
 * @interface TourComment
 */
export interface TourComment {
    /**
     * 
     * @type {number}
     * @memberof TourComment
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TourComment
     */
    tour_id?: number;
    /**
     * 
     * @type {string}
     * @memberof TourComment
     */
    type?: TourCommentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TourComment
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof TourComment
     */
    status?: TourCommentStatusEnum;
    /**
     * 
     * @type {User}
     * @memberof TourComment
     */
    creation_user?: User;
    /**
     * 
     * @type {User}
     * @memberof TourComment
     */
    update_user?: User;
    /**
     * 
     * @type {string}
     * @memberof TourComment
     */
    creation_timestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof TourComment
     */
    update_timestamp?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TourCommentTypeEnum {
    General = 'general',
    StatusChange = 'status_change',
    SupplierStatusChangeReason = 'supplier_status_change_reason',
    ContentReset = 'content_reset',
    RejectReason = 'reject_reason',
    CancellationPolicyRequest = 'cancellation_policy_request'
}
/**
    * @export
    * @enum {string}
    */
export enum TourCommentStatusEnum {
    Active = 'active',
    Deleted = 'deleted'
}



