import { gql } from "@gygadmin/client3";

/* @team:sg */
export const AddExternalRatings = gql`
  mutation AddActivityExternalRatings($input: AddActivityExternalRatingRequest!) {
    addActivityExternalRating(input: $input) {
      ... on ActivityAcceptExternalReviewSuccess {
        rating
      }
      ... on ActivityAcceptExternalReviewError {
        code
      }
    }
  }
`;
