import OptionsRouterPage from "../../views/options/index.vue";

export default [
  {
    path: "/options",
    component: OptionsRouterPage,
    children: [
      {
        path: ":id",
        meta: { authorize: [] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "activities" */
            "../../views/options/default/options.vue"
          ),
      },
    ],
  },
];
