import { gql } from "@gygadmin/client3";

export const RecomputeCacheEntries = gql`
  mutation RecomputeCacheEntries($input: RecomputeCacheEntriesInput!) {
    recomputeCacheEntriesMutation(input: $input) {
      success
      errors {
        message
      }
    }
  }
`;
