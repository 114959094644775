import { LocationState } from "./types";

export const initialState: LocationState = {
  page: {
    loading: false,
    areaTypeOptions: [],
    tabs: {
      locationMap: {
        loading: false,
      },
      locationPictures: {
        loading: false,
        pictures: [],
      },
    },
  },
};
