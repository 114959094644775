import { QuickSearchConfiguration } from "../types/search";
import {
  QuickSearchActivities,
  QuickSearchCustomers,
  QuickSearchLocations,
  QuickSearchBookings,
  QuickSearchShoppingCarts,
  QuickSearchCoupons,
  QuickSearchSuppliers,
  QuickSearchSuppliersAccounts,
  QuickSearchActivityOptions,
} from "../../../graphql/quick-search";
import {
  Activity,
  Location,
  Supplier,
  SupplierAccount,
  SearchItem,
  ActivityOption,
} from "../../../__generated__/graphql/operations";

export const QUICK_SEARCH_LIMIT = 10;
export const QUICK_SEARCH_FIELD = "quickSearchTerm";

const activitiesConfig: QuickSearchConfiguration = {
  key: "activities",
  query: { query: QuickSearchActivities, key: "activitySearch" },
  url: "/activities/{id}",
  app: "catalog",
  mapper: (activity: Activity) => {
    return {
      id: activity.id,
      title: activity.sourceText?.title ?? "",
      subtitle: (activity.primaryLocation?.name ?? "-") + " | "
          + (activity.supplier?.companyName ?? "-") + " | "
          + activity.category,
      status: activity.status,
      isTestData: activity.isTestData,
    };
  },
};

const activityOptionsConfig: QuickSearchConfiguration = {
  key: "activity options",
  query: { query: QuickSearchActivityOptions, key: "activityOptionSearch" },
  url: "/activities/{parentId}/options/{id}",
  app: "catalog",
  mapper: (activityOption: ActivityOption) => {
    return {
      id: activityOption.id,
      title: activityOption.sourceText?.title ?? "",
      subtitle: activityOption.activity?.sourceText?.title ?? "",
      status: activityOption.status,
      parentId: activityOption.activity?.id,
      isTestData: activityOption.activity?.isTestData,
    };
  },
};

const locationsConfig: QuickSearchConfiguration = {
  key: "locations",
  query: { query: QuickSearchLocations, key: "locationSearch" },
  url: "/locations/{id}",
  app: "catalog",
  mapper: (location: Location) => {
    return { id: location.id, title: location.name ?? "", subtitle: location.type };
  },
};

const customersSearch: QuickSearchConfiguration = {
  key: "customers",
  query: { query: QuickSearchCustomers, key: "quickCustomersSearch" },
  queryConfig: { searchField: "searchQuery", limit: 600 },
  url: "/customers/{id}",
  app: "customer",
  mapper: ({ id, title, description, status }: SearchItem) => {
    return { id, title, subtitle: description, status };
  },
};

const bookingsSearch: QuickSearchConfiguration = {
  key: "bookings",
  query: { query: QuickSearchBookings, key: "quickBookingsSearch" },
  queryConfig: { searchField: "searchQuery", limit: 600 },
  url: "/bookings/{id}",
  app: "customer",
  mapper: ({ id, title, description, status, isTestData }: SearchItem) => {
    return { id, title, subtitle: description, status, isTestData };
  },
};

const shoppingCartsSearch: QuickSearchConfiguration = {
  key: "shopping carts",
  query: { query: QuickSearchShoppingCarts, key: "quickShoppingCartsSearch" },
  queryConfig: { searchField: "searchQuery", limit: 600 },
  url: "/shopping-carts/{id}",
  app: "customer",
  mapper: ({ id, title, description, status }: SearchItem) => {
    return { id, title, subtitle: description, status };
  },
};

const couponsSearch: QuickSearchConfiguration = {
  key: "coupons",
  query: { query: QuickSearchCoupons, key: "quickCouponsSearch" },
  queryConfig: { searchField: "searchQuery", limit: 600 },
  url: "/coupons/{id}",
  app: "customer",
  mapper: ({ id, title, description, status, isTestData }: SearchItem) => {
    return { id, title, subtitle: description, status, isTestData };
  },
};

const suppliersSearch: QuickSearchConfiguration = {
  key: "suppliers",
  query: { query: QuickSearchSuppliers, key: "supplierSearch" },
  url: "/suppliers/{id}",
  app: "supplier",
  mapper: ({ id, companyName, legalCompanyName, gygStatus, isTestData }: Supplier) => {
    return { id, title: (companyName?.length ?? 0) > 0 ? companyName : legalCompanyName, status: gygStatus, isTestData };
  },
};

const suppliersAccountsSearch: QuickSearchConfiguration = {
  key: "suppliers accounts",
  query: { query: QuickSearchSuppliersAccounts, key: "supplierAccountSearch" },
  url: "/suppliers/{id}/accounts",
  app: "supplier",
  mapper: ({ id, supplierId, email, firstName, lastName, allowLogin }: SupplierAccount) => {
    return {
      id: "accountId: " + id + ", supplierId: " + supplierId,
      urlId: supplierId,
      title: email,
      subtitle: firstName + " " + lastName,
      status: "can login: " + allowLogin,
    };
  },
};

export const quickSearchConfigurations: QuickSearchConfiguration[] = [
  bookingsSearch,
  customersSearch,
  shoppingCartsSearch,
  activitiesConfig,
  activityOptionsConfig,
  suppliersSearch,
  suppliersAccountsSearch,
  couponsSearch,
  locationsConfig,
];
