import LocationsRouterPage from "../../views/locations/index.vue";
import { Privilege, PrivilegeService } from "@root/commons/privileges";

const privilegeService = new PrivilegeService();

export default [
  {
    path: "/locations",
    component: LocationsRouterPage,
    children: [
      {
        path: "",
        meta: { authorize: [] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "locations" */
            "../../views/locations/default/locations.vue"
          ),
      },
      {
        path: "create",
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminGygLocation)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "locations" */
            "../../views/locations/create/create.vue"
          ),
      },
      {
        path: "guidelines",
        meta: { authorize: [] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "locations" */
            "../../views/locations/guidelines/guidelines.vue"
          ),
      },
      {
        path: [":id/:pathMatch(.*)*"],
        meta: { authorize: [] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "locations" */
            "../../views/locations/detail/detail.vue"
          ),
      },
    ],
  },
];
