export interface LanguageInfo {
  id: number;
  isoCode: string;
  flag: string;
}

export interface LanguageList {
  [key: string]: LanguageInfo;
  abkhazian: LanguageInfo;
  afar: LanguageInfo;
  afrikaans: LanguageInfo;
  albanian: LanguageInfo;
  amharic: LanguageInfo;
  arabic: LanguageInfo;
  armenian: LanguageInfo;
  assamese: LanguageInfo;
  aymara: LanguageInfo;
  azerbaijani: LanguageInfo;
  bashkir: LanguageInfo;
  basque: LanguageInfo;
  bengaliBangla: LanguageInfo;
  bhutani: LanguageInfo;
  bihari: LanguageInfo;
  bislama: LanguageInfo;
  breton: LanguageInfo;
  bulgarian: LanguageInfo;
  burmese: LanguageInfo;
  byelorussianVelarusian: LanguageInfo;
  khmer: LanguageInfo;
  catalan: LanguageInfo;
  chinese: LanguageInfo;
  corsican: LanguageInfo;
  croatian: LanguageInfo;
  czech: LanguageInfo;
  danish: LanguageInfo;
  dutch: LanguageInfo;
  english: LanguageInfo;
  esperanto: LanguageInfo;
  estonian: LanguageInfo;
  faeroese: LanguageInfo;
  fiji: LanguageInfo;
  finnish: LanguageInfo;
  french: LanguageInfo;
  frisian: LanguageInfo;
  galician: LanguageInfo;
  georgian: LanguageInfo;
  german: LanguageInfo;
  greek: LanguageInfo;
  kalaallisut: LanguageInfo;
  guarani: LanguageInfo;
  gujarati: LanguageInfo;
  hausa: LanguageInfo;
  hebrew: LanguageInfo;
  hindi: LanguageInfo;
  hungarian: LanguageInfo;
  icelandic: LanguageInfo;
  indonesian: LanguageInfo;
  interlingua: LanguageInfo;
  interlingue: LanguageInfo;
  inupiak: LanguageInfo;
  inuktitut: LanguageInfo;
  irish: LanguageInfo;
  italian: LanguageInfo;
  japanese: LanguageInfo;
  javanese: LanguageInfo;
  kannada: LanguageInfo;
  kashmiri: LanguageInfo;
  kazakh: LanguageInfo;
  kinyarwandaRuanda: LanguageInfo;
  kirghiz: LanguageInfo;
  kirundiRundi: LanguageInfo;
  korean: LanguageInfo;
  kurdish: LanguageInfo;
  laothian: LanguageInfo;
  latin: LanguageInfo;
  latvianLettish: LanguageInfo;
  lingala: LanguageInfo;
  lithuanian: LanguageInfo;
  macedonian: LanguageInfo;
  malagasy: LanguageInfo;
  malay: LanguageInfo;
  malayalam: LanguageInfo;
  maltese: LanguageInfo;
  maori: LanguageInfo;
  marathi: LanguageInfo;
  moldavian: LanguageInfo;
  mongolian: LanguageInfo;
  nauru: LanguageInfo;
  nepali: LanguageInfo;
  norwegian: LanguageInfo;
  occitan: LanguageInfo;
  oriya: LanguageInfo;
  pashtoPushto: LanguageInfo;
  persian: LanguageInfo;
  polish: LanguageInfo;
  portuguese: LanguageInfo;
  punjabi: LanguageInfo;
  quechua: LanguageInfo;
  rhaetoRomance: LanguageInfo;
  romanian: LanguageInfo;
  russian: LanguageInfo;
  samoan: LanguageInfo;
  sangro: LanguageInfo;
  sanskrit: LanguageInfo;
  gaelicScottish: LanguageInfo;
  serbian: LanguageInfo;
  serboCroatian: LanguageInfo;
  sesotho: LanguageInfo;
  setswana: LanguageInfo;
  shona: LanguageInfo;
  sindhi: LanguageInfo;
  sinhalese: LanguageInfo;
  siswati: LanguageInfo;
  slovak: LanguageInfo;
  slovenian: LanguageInfo;
  somali: LanguageInfo;
  spanish: LanguageInfo;
  sundanese: LanguageInfo;
  swahiliKiswahili: LanguageInfo;
  swedish: LanguageInfo;
  tagalog: LanguageInfo;
  tajik: LanguageInfo;
  tamil: LanguageInfo;
  tatar: LanguageInfo;
  telugu: LanguageInfo;
  thai: LanguageInfo;
  tibetan: LanguageInfo;
  tigrinya: LanguageInfo;
  tonga: LanguageInfo;
  tsonga: LanguageInfo;
  turkish: LanguageInfo;
  turkmen: LanguageInfo;
  twi: LanguageInfo;
  uighur: LanguageInfo;
  ukrainian: LanguageInfo;
  urdu: LanguageInfo;
  uzbek: LanguageInfo;
  vietnamese: LanguageInfo;
  volapuk: LanguageInfo;
  welsh: LanguageInfo;
  wolof: LanguageInfo;
  xhosa: LanguageInfo;
  yiddish: LanguageInfo;
  yoruba: LanguageInfo;
  zhuang: LanguageInfo;
  zulu: LanguageInfo;
  bosnian: LanguageInfo;
  spanishMexican: LanguageInfo;
  chineseTraditional: LanguageInfo;
  englishUnitedKingdom: LanguageInfo;
}

export const Language: LanguageList = {
  abkhazian: { id: 1, isoCode: "AB", flag: "zz" },
  afar: { id: 2, isoCode: "AA", flag: "zz" },
  afrikaans: { id: 3, isoCode: "AF", flag: "zz" },
  albanian: { id: 4, isoCode: "SQ", flag: "al" },
  amharic: { id: 5, isoCode: "AM", flag: "et" },
  arabic: { id: 6, isoCode: "AR", flag: "sa" },
  armenian: { id: 7, isoCode: "HY", flag: "am" },
  assamese: { id: 8, isoCode: "AS", flag: "zz" },
  aymara: { id: 9, isoCode: "AY", flag: "zz" },
  azerbaijani: { id: 10, isoCode: "AZ", flag: "zz" },
  bashkir: { id: 11, isoCode: "BA", flag: "zz" },
  basque: { id: 12, isoCode: "EU", flag: "es-pv" },
  bengaliBangla: { id: 13, isoCode: "BN", flag: "zz" },
  bhutani: { id: 14, isoCode: "DZ", flag: "zz" },
  bihari: { id: 15, isoCode: "BH", flag: "zz" },
  bislama: { id: 16, isoCode: "BI", flag: "zz" },
  breton: { id: 17, isoCode: "BR", flag: "zz" },
  bulgarian: { id: 18, isoCode: "BG", flag: "bg" },
  burmese: { id: 19, isoCode: "MY", flag: "zz" },
  byelorussianVelarusian: { id: 20, isoCode: "BE", flag: "by" },
  khmer: { id: 21, isoCode: "KM", flag: "zz" },
  catalan: { id: 22, isoCode: "CA", flag: "cat" },
  chinese: { id: 23, isoCode: "ZH", flag: "cn" },
  corsican: { id: 24, isoCode: "CO", flag: "zz" },
  croatian: { id: 25, isoCode: "HR", flag: "hr" },
  czech: { id: 26, isoCode: "CS", flag: "cz" },
  danish: { id: 27, isoCode: "DA", flag: "dk" },
  dutch: { id: 28, isoCode: "NL", flag: "nl" },
  english: { id: 29, isoCode: "EN", flag: "gb" },
  esperanto: { id: 30, isoCode: "EO", flag: "eo" },
  estonian: { id: 31, isoCode: "ET", flag: "ee" },
  faeroese: { id: 32, isoCode: "FO", flag: "fo" },
  fiji: { id: 33, isoCode: "FJ", flag: "zz" },
  finnish: { id: 34, isoCode: "FI", flag: "fi" },
  french: { id: 35, isoCode: "FR", flag: "fr" },
  frisian: { id: 36, isoCode: "FY", flag: "zz" },
  galician: { id: 37, isoCode: "GL", flag: "es-ga" },
  georgian: { id: 38, isoCode: "KA", flag: "ge" },
  german: { id: 39, isoCode: "DE", flag: "de" },
  greek: { id: 40, isoCode: "EL", flag: "gr" },
  kalaallisut: { id: 41, isoCode: "KL", flag: "zz" },
  guarani: { id: 42, isoCode: "GN", flag: "zz" },
  gujarati: { id: 43, isoCode: "GU", flag: "zz" },
  hausa: { id: 44, isoCode: "HA", flag: "zz" },
  hebrew: { id: 45, isoCode: "HE", flag: "il" },
  hindi: { id: 46, isoCode: "HI", flag: "in" },
  hungarian: { id: 47, isoCode: "HU", flag: "hu" },
  icelandic: { id: 48, isoCode: "IS", flag: "is" },
  indonesian: { id: 49, isoCode: "ID", flag: "id" },
  interlingua: { id: 50, isoCode: "IA", flag: "zz" },
  interlingue: { id: 51, isoCode: "IE", flag: "zz" },
  inupiak: { id: 52, isoCode: "IK", flag: "zz" },
  inuktitut: { id: 53, isoCode: "IU", flag: "zz" },
  irish: { id: 54, isoCode: "GA", flag: "ie" },
  italian: { id: 55, isoCode: "IT", flag: "it" },
  japanese: { id: 56, isoCode: "JA", flag: "jp" },
  javanese: { id: 57, isoCode: "JV", flag: "zz" },
  kannada: { id: 58, isoCode: "KN", flag: "zz" },
  kashmiri: { id: 59, isoCode: "KS", flag: "zz" },
  kazakh: { id: 60, isoCode: "KK", flag: "zz" },
  kinyarwandaRuanda: { id: 61, isoCode: "RW", flag: "zz" },
  kirghiz: { id: 62, isoCode: "KY", flag: "kg" },
  kirundiRundi: { id: 63, isoCode: "RN", flag: "zz" },
  korean: { id: 64, isoCode: "KO", flag: "kr" },
  kurdish: { id: 65, isoCode: "KU", flag: "zz" },
  laothian: { id: 66, isoCode: "LO", flag: "zz" },
  latin: { id: 67, isoCode: "LA", flag: "zz" },
  latvianLettish: { id: 68, isoCode: "LV", flag: "lv" },
  lingala: { id: 69, isoCode: "LN", flag: "zz" },
  lithuanian: { id: 70, isoCode: "LT", flag: "lt" },
  macedonian: { id: 71, isoCode: "MK", flag: "mk" },
  malagasy: { id: 72, isoCode: "MG", flag: "zz" },
  malay: { id: 73, isoCode: "MS", flag: "my" },
  malayalam: { id: 74, isoCode: "ML", flag: "zz" },
  maltese: { id: 75, isoCode: "MT", flag: "mt" },
  maori: { id: 76, isoCode: "MI", flag: "zz" },
  marathi: { id: 77, isoCode: "MR", flag: "zz" },
  moldavian: { id: 78, isoCode: "MO", flag: "md" },
  mongolian: { id: 79, isoCode: "MN", flag: "mn" },
  nauru: { id: 80, isoCode: "NA", flag: "zz" },
  nepali: { id: 81, isoCode: "NE", flag: "np" },
  norwegian: { id: 82, isoCode: "NO", flag: "no" },
  occitan: { id: 83, isoCode: "OC", flag: "zz" },
  oriya: { id: 84, isoCode: "OR", flag: "zz" },
  pashtoPushto: { id: 85, isoCode: "PS", flag: "zz" },
  persian: { id: 86, isoCode: "FA", flag: "ir" },
  polish: { id: 87, isoCode: "PL", flag: "pl" },
  portuguese: { id: 88, isoCode: "PT", flag: "br" },
  punjabi: { id: 89, isoCode: "PA", flag: "zz" },
  quechua: { id: 90, isoCode: "QU", flag: "zz" },
  rhaetoRomance: { id: 91, isoCode: "RM", flag: "zz" },
  romanian: { id: 92, isoCode: "RO", flag: "ro" },
  russian: { id: 93, isoCode: "RU", flag: "ru" },
  samoan: { id: 94, isoCode: "SM", flag: "ws" },
  sangro: { id: 95, isoCode: "SG", flag: "zz" },
  sanskrit: { id: 96, isoCode: "SA", flag: "zz" },
  gaelicScottish: { id: 97, isoCode: "GD", flag: "sco" },
  serbian: { id: 98, isoCode: "SR", flag: "rs" },
  serboCroatian: { id: 99, isoCode: "SH", flag: "zz" },
  sesotho: { id: 100, isoCode: "ST", flag: "zz" },
  setswana: { id: 101, isoCode: "TN", flag: "zz" },
  shona: { id: 102, isoCode: "SN", flag: "zz" },
  sindhi: { id: 103, isoCode: "SD", flag: "zz" },
  sinhalese: { id: 104, isoCode: "SI", flag: "zz" },
  siswati: { id: 105, isoCode: "SS", flag: "zz" },
  slovak: { id: 106, isoCode: "SK", flag: "sk" },
  slovenian: { id: 107, isoCode: "SL", flag: "si" },
  somali: { id: 108, isoCode: "SO", flag: "so" },
  spanish: { id: 109, isoCode: "ES", flag: "es" },
  sundanese: { id: 110, isoCode: "SU", flag: "zz" },
  swahiliKiswahili: { id: 111, isoCode: "SW", flag: "zz" },
  swedish: { id: 112, isoCode: "SV", flag: "se" },
  tagalog: { id: 113, isoCode: "TL", flag: "zz" },
  tajik: { id: 114, isoCode: "TG", flag: "zz" },
  tamil: { id: 115, isoCode: "TA", flag: "zz" },
  tatar: { id: 116, isoCode: "TT", flag: "zz" },
  telugu: { id: 117, isoCode: "TE", flag: "zz" },
  thai: { id: 118, isoCode: "TH", flag: "th" },
  tibetan: { id: 119, isoCode: "BO", flag: "zz" },
  tigrinya: { id: 120, isoCode: "TI", flag: "zz" },
  tonga: { id: 121, isoCode: "TO", flag: "zz" },
  tsonga: { id: 122, isoCode: "TS", flag: "zz" },
  turkish: { id: 123, isoCode: "TR", flag: "tr" },
  turkmen: { id: 124, isoCode: "TK", flag: "tm" },
  twi: { id: 125, isoCode: "TW", flag: "gh" },
  uighur: { id: 126, isoCode: "UG", flag: "zz" },
  ukrainian: { id: 127, isoCode: "UK", flag: "ua" },
  urdu: { id: 128, isoCode: "UR", flag: "zz" },
  uzbek: { id: 129, isoCode: "UZ", flag: "uz" },
  vietnamese: { id: 130, isoCode: "VI", flag: "vn" },
  volapuk: { id: 131, isoCode: "VO", flag: "zz" },
  welsh: { id: 132, isoCode: "CY", flag: "wa" },
  wolof: { id: 133, isoCode: "WO", flag: "zz" },
  xhosa: { id: 134, isoCode: "XH", flag: "zz" },
  yiddish: { id: 135, isoCode: "YI", flag: "zz" },
  yoruba: { id: 136, isoCode: "YO", flag: "zz" },
  zhuang: { id: 137, isoCode: "ZA", flag: "zz" },
  zulu: { id: 138, isoCode: "ZU", flag: "zz" },
  bosnian: { id: 139, isoCode: "BS", flag: "ba" },
  spanishMexican: { id: 140, isoCode: "ES-MX", flag: "mx" },
  chineseTraditional: { id: 141, isoCode: "ZH-HANT", flag: "tw" },
  englishUnitedKingdom: { id: 142, isoCode: "EN-GB", flag: "gb" },
};

export const DefaultLanguage = Language.english;

export const SupportedLanguages = [
  Language.english,
  Language.german,
  Language.spanish,
  Language.french,
  Language.italian,
  Language.dutch,
  Language.portuguese,
  Language.danish,
  Language.swedish,
  Language.norwegian,
  Language.finnish,
  Language.polish,
  Language.japanese,
  Language.russian,
  Language.czech,
  Language.greek,
  Language.romanian,
  Language.hungarian,
  Language.turkish,
  Language.korean,
  Language.chinese,
  Language.chineseTraditional,
  Language.bulgarian,
  Language.catalan,
  Language.croatian,
  Language.estonian,
  Language.latvianLettish,
  Language.lithuanian,
  Language.serbian,
  Language.slovak,
  Language.slovenian,
  Language.ukrainian,
  Language.arabic,
  Language.hebrew,
  Language.indonesian,
  Language.malay,
  Language.thai,
];

export interface LanguageIdMapping {
  [key: number]: string;
}

const languageIdMapping: LanguageIdMapping = {};
Object.entries(Language).forEach((pair) => {
  const key = pair[0];
  const languageId = pair[1].id;
  languageIdMapping[languageId] = key;
});

const supportedLanguageIdMapping: LanguageIdMapping = {};
Object.values(SupportedLanguages).forEach((language) => {
  supportedLanguageIdMapping[language.id] = languageIdMapping[language.id];
});

export { languageIdMapping, supportedLanguageIdMapping };
