import { Loggable } from "./types";

const HTTP_TIMEOUT = 3000;
const MESSAGE_FLUSHING_LIMIT = 3;
const MESSAGE_FLUSHING_TIMEOUT = 3000;

const queue: Loggable[] = [];
let href = "";

function flush() {
  if (!queue.length) {
    return;
  }
  const logs = queue.splice(0, queue.length);
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    clearTimeout(timeout);
    controller.abort();
  }, HTTP_TIMEOUT);
  fetch("/client/logging", {
    body: JSON.stringify(logs),
    method: "POST",
    keepalive: true,
    headers: {
      "Content-Type": "application/json",
      "x-log-origin-url": href,
    },
    signal: controller.signal,
  });
}

const log = function (level: string, args: Loggable[]) {
  queue.push({
    level,
    args,
  });
  if (queue.length >= MESSAGE_FLUSHING_LIMIT) {
    flush();
  }
};

setInterval(() => flush(), MESSAGE_FLUSHING_TIMEOUT);

export const asyncLogging = (ev: any) => {
  log(ev.level, ev.args);
  href = ev.url;
};
