import { gql } from "@gygadmin/client3";

export const LocationDatatable = gql`
  query LocationDatatable($input: SearchInput!) {
    locationSearch(input: $input) {
      items {
        id
        name
        type
      }
      pagination {
        totalItems
        offset
        limit
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const LocationSuppliersSearch = gql`
  query LocationSuppliersSearch($input: SearchInput!) {
    locationSuppliersSearch(input: $input) {
      items {
        supplierId
        supplierName
        supplierStatus
        locationId
      }
      pagination {
        totalItems
        offset
        limit
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const LocationSearchQuery = gql`
  query LocationSearch($input: SearchInput) {
    locationSearch(input: $input) {
      items {
        id
        name
        type
      }
      pagination {
        hasPreviousPage
        hasNextPage
        totalItems
      }
    }
  }
`;

export const GeoshapesFromGooglePlaceIdQuery = gql`
  query LocationGeoshapeCandidates($id: ID!) {
    googlePlace(id: $id) {
      locationGeoshapeCandidates {
        geoshapeId
        name
        placeType
        geometry
        location {
          id
          name
        }
      }
    }
  }
`;

export const MergeLocationsMutation = gql`
  mutation MergeLocations($originalLocationId: ID!, $duplicateLocationId: ID!) {
    mergeLocations(
      original_location_id: $originalLocationId
      duplicate_location_id: $duplicateLocationId
    ) {
      location {
        id
        name
      }
      success
      errors {
        message
      }
    }
  }
`;

export const DeleteLocationById = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      success
      errors {
        message
      }
    }
  }
`;
