import { TranslationsState } from "./types";
import { MutationTree } from "vuex";
import { XtmEvent, XtmUploadTask, XtmTourTranslationVolume } from "@root/commons/types";

export const mutations: MutationTree<TranslationsState> = {
  SET_TAB_RECENT_EVENTS_LOADING(state: TranslationsState, loading: boolean): void {
    state.page.tabs.recentActions = {
      ...state.page.tabs.recentActions,
      loading: loading,
    };
  },
  SET_TAB_RECENT_EVENTS_ITEMS(state: TranslationsState, items: XtmEvent[]): void {
    state.page.tabs.recentActions = {
      ...state.page.tabs.recentActions,
      items: items,
    };
  },
  SET_TAB_ONGOING_LOADING(state: TranslationsState, loading: boolean): void {
    state.page.tabs.ongoingRequests = {
      ...state.page.tabs.ongoingRequests,
      loading: loading,
    };
  },
  SET_TAB_ONGOING_ITEMS(state: TranslationsState, items: XtmUploadTask[]): void {
    state.page.tabs.ongoingRequests = {
      ...state.page.tabs.ongoingRequests,
      items: items,
    };
  },
  SET_TAB_RECENTLY_COMPLETED_LOADING(state: TranslationsState, loading: boolean): void {
    state.page.tabs.recentlyCompleted = {
      ...state.page.tabs.recentlyCompleted,
      loading: loading,
    };
  },
  SET_TAB_RECENTLY_COMPLETED_ITEMS(state: TranslationsState, items: XtmUploadTask[]): void {
    state.page.tabs.recentlyCompleted = {
      ...state.page.tabs.recentlyCompleted,
      items: items,
    };
  },
  SET_TAB_TOUR_TRANSLATION_VOLUME_LOADING(state: TranslationsState, loading: boolean): void {
    state.page.tabs.tourTranslationVolume = {
      ...state.page.tabs.tourTranslationVolume,
      loading: loading,
    };
  },
  SET_TAB_TOUR_TRANSLATION_VOLUME_ITEMS(
    state: TranslationsState,
    items: XtmTourTranslationVolume[],
  ): void {
    state.page.tabs.tourTranslationVolume = {
      ...state.page.tabs.tourTranslationVolume,
      items: items,
    };
  },
};

export default mutations;
