import i18next from "i18next";
import I18NextVue from "i18next-vue";
import axios from "axios";

export type GetTextMessages = {
  [locale: string]: Record<string, string>;
};

const restClient = axios.create({
  baseURL: "/client",
});

const defaultNS = "translations";
export const englishLocale = "en";
i18next.init({
  lng: englishLocale,
  defaultNS,
  interpolation: {
    escapeValue: false,
  },
  postProcess: "formatter",
});
i18next.use({
  type: "postProcessor",
  name: "formatter",
  process(value: string, key: string, options: unknown) {
    if (Array.isArray(options)) {
      return value.replace(/%(\d+)/g, (_, m) => options[--m]);
    }
    return value;
  },
});

export const i18n = i18next;
export const i18NextVue = I18NextVue;
export const refresh = async (locale: string): Promise<void> => {
  const res = await restClient.get<GetTextMessages>("/gettext");
  const updatedTranslations = res.data[locale];
  applyMessages(locale, {
    [locale]: updatedTranslations,
  });
};

export const applyMessages = (locale: string, messages: GetTextMessages) => {
  i18next.addResourceBundle(locale, defaultNS, messages[locale], true, true);
};
