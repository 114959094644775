import {
  QualityIssuePageFieldsFragment,
  UpdateQualityIssueConfigurationInput,
} from "@client/autogenerated/graphql/operations";
import { IPageLoadError } from "@client/commons/types/helpers";
import { RulesEngineMapper } from "@client/mappers/rules-engine";
import { MutationTree } from "vuex";
import { RulesEnginePageAlert, RulesEngineQualityIssuesState } from "./types";

export const mutations: MutationTree<RulesEngineQualityIssuesState> = {
  SET_PAGE_DATA(
    state: RulesEngineQualityIssuesState,
    fields: QualityIssuePageFieldsFragment,
  ): void {
    const attribute: QualityIssuePageFieldsFragment = JSON.parse(JSON.stringify(fields));
    const model: UpdateQualityIssueConfigurationInput = {
      id: attribute.id,
      enabled: attribute.enabled,
      entityType: attribute.entityType,
      internalName: attribute.internalName,
      name: attribute.name,
      order: attribute.order,
      rules: RulesEngineMapper.unflatRuleItemsTree(attribute.rules ?? []),
    };

    state.page = {
      ...state.page,
      id: Number(attribute.id),
      loading: false,
      previousState: JSON.stringify(model ?? {}),
      data: attribute,
      edit: model,
    };
  },
  SET_PAGE_LOAD_ERROR(state: RulesEngineQualityIssuesState, loadError?: IPageLoadError): void {
    state.page["loadError"] = loadError;
  },
  SET_PAGE_LOADING(state: RulesEngineQualityIssuesState, loading: boolean): void {
    state.page = {
      ...state.page,
      loading,
    };
  },
  SET_PAGE_IS_SAVING(state: RulesEngineQualityIssuesState, saving: boolean): void {
    state.page = {
      ...state.page,
      isSaving: saving,
    };
  },
  SET_PAGE_ALERT(state: RulesEngineQualityIssuesState, alert?: RulesEnginePageAlert): void {
    state.page = {
      ...state.page,
      alert,
    };
  },
};

export default mutations;
