import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import {
  LocationInfo,
  LocationQueryParam,
  Location,
  AggregatedLocation,
  TourToLocationSuggestion,
  LocationPictureSuggestion,
  AggregatedLocationPicture,
  LocationNameSearchItem,
  LocationTypeEnum,
} from "@root/commons/types";

import {
  DeleteLocationMutation,
  Location as LocationGraphQL,
  LocationGeoshape,
  LocationSearch,
  MergeLocationMutationPayload,
  MutationDeleteLocationArgs,
  MutationMergeLocationsArgs,
  Query,
  QueryGooglePlaceArgs,
  QueryLocationSearchArgs,
  SearchInput,
} from "@client/autogenerated/graphql/operations";
import {
  DeleteLocationById,
  GeoshapesFromGooglePlaceIdQuery,
  LocationSearchQuery,
  MergeLocationsMutation,
} from "@client/graphql/locations/locations";
import { graphClient } from "@gygadmin/client3";

class LocationsService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Get the location based on the id.
   *
   * @param locationId
   */
  async get(locationId: number): Promise<AggregatedLocation> {
    return this.restClient
      .get(`/locations/${locationId}/detail`)
      .then((response) => response.data as AggregatedLocation)
      .catch(() => ({}));
  }

  /**
   * Get picture suggestions for the given location.
   *
   * @param locationId
   */
  async getPictureSuggestions(locationId: number) {
    return this.restClient
      .get(`/locations/${locationId}/picture-suggestions`)
      .then((response) => response.data as LocationPictureSuggestion[])
      .catch(() => []);
  }

  async getGeojson(googlePlaceId: string): Promise<LocationGeoshape[]> {
    return graphClient
      .gql()
      .query<Query, QueryGooglePlaceArgs>({
        query: GeoshapesFromGooglePlaceIdQuery,
        variables: {
          id: googlePlaceId,
        },
        errorPolicy: "ignore",
      })
      .then((res) => res.data.googlePlace?.locationGeoshapeCandidates || []);
  }

  /**
   * Delete location by id
   */
  async deleteLocation(
    locationId: number,
  ): Promise<DeleteLocationMutation["deleteLocation"] | null | undefined> {
    return await graphClient
      .gql()
      .mutate<DeleteLocationMutation, MutationDeleteLocationArgs>({
        mutation: DeleteLocationById,
        variables: {
          id: locationId.toString(),
        },
      })
      .then((res) => {
        return res.data?.deleteLocation;
      });
  }

  async mergeLocations(
    originalLocationId: number,
    duplicateLocationId: number,
  ): Promise<MergeLocationMutationPayload | null | undefined> {
    return await graphClient
      .gql()
      .mutate<MergeLocationMutationPayload, MutationMergeLocationsArgs>({
        mutation: MergeLocationsMutation,
        variables: {
          originalLocationId: `${originalLocationId}`,
          duplicateLocationId: `${duplicateLocationId}`,
        },
      })
      .then((res) => res.data);
  }

  /**
   * Save the picture suggestion.
   *
   * @param pictureSuggestion
   */
  async savePictureSuggestion(pictureSuggestion: LocationPictureSuggestion) {
    return this.restClient
      .post(
        `/locations/${pictureSuggestion.location_id}/save-picture-suggestion`,
        pictureSuggestion,
      )
      .then((response) => response.data as AggregatedLocationPicture);
  }

  /**
   * Get all location sub-pois of a given location.
   *
   * @param locationId
   */
  async fetchSubPois(locationId: number): Promise<LocationInfo[]> {
    return this.restClient
      .get(`/locations/${locationId}/sub-pois`)
      .then((response) => response.data as LocationInfo[])
      .catch(() => []);
  }

  /**
   * Get the info of a location based on the id and all its siblings.
   *
   * @param locationId
   */
  async fetchTree(locationId: number): Promise<LocationInfo[]> {
    return this.restClient
      .get(`/locations/${locationId}/tree`)
      .then((response) => response.data as LocationInfo[])
      .catch(() => []);
  }

  /**
   * Get all locations given the name contains the given term.
   *
   * @param nameFuzzy
   */
  async fetchByNameContains(nameFuzzy: string): Promise<Location[]> {
    return this.restClient
      .get(`/locations?${LocationQueryParam.NameContains}=${nameFuzzy}`)
      .then((response) => response.data as Location[])
      .catch(() => []);
  }

  async search(searchInput: SearchInput): Promise<LocationSearch> {
    const results = await graphClient
      .gql()
      .query<{ locationSearch: LocationSearch }, QueryLocationSearchArgs>({
        query: LocationSearchQuery,
        variables: {
          input: searchInput,
        },
      });
    return results.data.locationSearch;
  }

  async fetchCountriesNames() {
    const allCountriesSearchInput: SearchInput = {
      filters: [
        {
          field: "type",
          values: [LocationTypeEnum.Country],
        },
      ],
      limit: -1,
      offset: 0,
      sorts: [],
    };

    return await this.search(allCountriesSearchInput)
      .then((response) => {
        return (response?.items ?? []).map((location) => location.name);
      })
      .catch(() => {
        return [];
      });
  }

  /**
   * Get all locations given the google place id equals the given term.
   *
   * @param googlePlaceId
   */
  async fetchByGooglePlaceIdEquals(googlePlaceId: string): Promise<LocationGraphQL[] | null> {
    const searchInput: SearchInput = {
      limit: 20,
      offset: 0,
      filters: [{ field: "googlePlaceId", values: [googlePlaceId] }],
    };
    return (await this.search(searchInput)).items;
  }

  /**
   * Get all locations given the names contains the given term.
   * Search is done by name, alternative name and location text.
   *
   * @param nameFuzzy
   */
  async fetchByAllNamesContains(nameFuzzy: string): Promise<LocationNameSearchItem[]> {
    return this.restClient
      .get(`/locations/names/${nameFuzzy}`)
      .then((response) => response.data as LocationNameSearchItem[])
      .catch(() => []);
  }

  async update(locationId: number, location: AggregatedLocation): Promise<AggregatedLocation> {
    return this.restClient
      .put(`/locations/${locationId}`, location)
      .then((response) => response.data as AggregatedLocation);
  }

  async deleteLocationNameAlternative(locationNameAlternativeId?: number): Promise<boolean> {
    return this.restClient
      .delete(`/locations/name-alternatives/${locationNameAlternativeId}`)
      .then((response) => response.data.success)
      .catch(() => false);
  }

  async create(location: Location): Promise<Location> {
    return this.restClient.post<Location>(`/locations`, location).then((response) => response.data);
  }

  async updateTourToLocationSuggestion(
    locationId: number,
    tourId: number,
    suggestion: TourToLocationSuggestion,
  ): Promise<TourToLocationSuggestion> {
    return this.restClient
      .put(`/locations/${locationId}/tours/${tourId}/suggested-connection`, suggestion)
      .then((response) => response.data as TourToLocationSuggestion);
  }
}

export default new LocationsService();
