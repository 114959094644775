import { Ref } from "vue";

export enum TableEvents {
  RefreshTable = "refresh-table",
  PausePolling = "pausePolling",
  ResumePolling = "resumePolling",
}

export enum FilterType {
  Text = "text",
  DateRange = "date",
  Number = "number",
  Dropdown = "dropdown",
  DropdownMultiple = "dropdown_multiple",
  Checkbox = "checkbox",
  AutoComplete = "autocomplete",
  AutoCompleteMultiple = "autocomplete_multiple",
  Boolean = "boolean",
  NumberRange = "number_range",
  ThreeWayToggle = "three_way_toggle",
}

export enum ColumnType {
  TEXT = "text",
  NUMBER = "number",
  DATE = "date",
  DATETIME = "datetime",
}

export enum TextAlign {
  Left = "start",
  Center = "center",
  Right = "end",
}

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
  None = "none",
}

export interface ColumnOptions {
  defaultValue?: string;
  width?: string | number;
  filter?: {
    loading?: boolean | Ref<boolean>;
    enable: boolean;
    hidden?: boolean;
    isDefault?: boolean;
    name?: string;
    label?: string;
    type?: FilterType;
    value?: any;
    search?: any;
    placeholder?: any;
    options?: any[] | Ref<any[]>;
    config?: any;
    apply?(column: ColumnDefinition, items: DatatableItem[]): DatatableItem[];
  };
  sort?: {
    enable: boolean;
    name?: string;
    direction?: SortDirection;
    priority?: number;
  };
}
export interface ColumnDefinition {
  name: string;
  label: string;
  visible?: boolean;
  type?: ColumnType;
  align?: TextAlign;
  altText?: string;
  class?: string;
  options?: ColumnOptions;
}

export interface GraphSearch {
  query: any;
  key: string;
}

export interface DatatableOptions {
  server?: {
    enable?: boolean;
    // Provide either location or searchImpl
    location?: string | GraphSearch;
    searchImpl?: SearchService;
    poll?: PollingParams;
  };
  footer?: {
    itemsPerPageOptions?: number[];
  };
  hideDefaultHeader?: boolean;
  hideDefaultFooter?: boolean;
  pagination?: boolean;
  dense?: boolean;
  showSelect?: boolean;
  clickableRows?: boolean;
  sort?: {
    enable: boolean;
    multiSort?: boolean;
    defaultSortBy?: string;
    defaultSortDesc?: boolean;
  };
  filter?: {
    open: boolean;
    enable: boolean;
    skipRestoreFromParams?: boolean;
    list?: any[];
  };
  localization?: {
    filterTitle?: string;
    filterSearchBtn?: string;
    filterSearchResetBtn?: string;
  };
}

export type DatatableItem = Record<string, any>;

export interface DatatableState {
  page: number;
  loading: boolean;
  title: string;
  items: DatatableItem[];
  columns: ColumnDefinition[];
  options?: DatatableOptions;
}

export interface TableSearchFilters {
  [key: string]: string | string[] | boolean;
}

export interface TableSearchOrder {
  column: string;
  direction: SortDirection;
}

export interface PollingParams {
  enable: boolean;
  pollIntervalMilliseconds: number;
  // when set to true, the polling will only occur when the component
  // is displayed in the currently active browser tab
  pollWhenComponentIsVisible?: boolean;
}

export interface SearchParams {
  [key: string]: string | number | TableSearchFilters | TableSearchOrder[];
  page: number;
  limit: number;
  text: string;
  order: TableSearchOrder[];
  filters: TableSearchFilters;
}

export interface VDataTableProps {
  page?: number;
  itemsPerPage?: number;
  sortBy?: { key: string; order: "asc" | "desc" | boolean }[];
}

export interface SearchResult<T> {
  page: number;
  serverItemsLength: number;
  items: Array<T>;
  showManyLabel: boolean;
}

export interface SearchService<T> {
  search: (searchParams: SearchParams) => Promise<SearchResult<T>>;
}
