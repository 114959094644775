import {
  AvailabilityAndPricingInput,
  AvailabilityAndPricingOptionByOptionIdQuery,
  AvailabilityAndPricingOptionByOptionIdQueryVariables,
  UpdateAvailabilityAndPricingMutation,
  UpdateAvailabilityAndPricingMutationVariables,
} from "@client/autogenerated/graphql/operations";
import {
  AvailabilityAndPricingOptionByOptionId,
  UpdateAvailabilityAndPricing,
} from "@client/graphql/pricing-availability/pricing-availability";
import { graphClient } from "@gygadmin/client3";


class PricingConfigurationService {
  async getActivityOptionByActivityOptionId(
    activityOptionId: string,
  ): Promise<AvailabilityAndPricingOptionByOptionIdQuery["activityOption"]> {
    return graphClient
      .gql()
      .query<
        AvailabilityAndPricingOptionByOptionIdQuery,
        AvailabilityAndPricingOptionByOptionIdQueryVariables
      >({
        query: AvailabilityAndPricingOptionByOptionId,
        variables: {
          activityOptionId,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => res.data.activityOption);
  }

  async updateAvailabilityAndPricing(
    pricing: AvailabilityAndPricingInput,
    pricingId: string,
    availabilityBlockId: string,
  ): Promise<UpdateAvailabilityAndPricingMutation["updateAvailabilityAndPricing"]> {
    return graphClient
      .gql()
      .mutate<UpdateAvailabilityAndPricingMutation, UpdateAvailabilityAndPricingMutationVariables>({
        mutation: UpdateAvailabilityAndPricing,
        variables: {
          availabilityBlockId,
          pricingId,
          input: pricing,
        },
      })
      .then((res) => res.data?.updateAvailabilityAndPricing);
  }
}

export default new PricingConfigurationService();
