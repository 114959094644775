import {
  CreateQualityIssueConfigurationInput,
  CreateQualityIssueConfigurationMutation,
  CreateQualityIssueConfigurationMutationVariables,
  DeleteQualityIssueConfigurationMutation,
  DeleteQualityIssueConfigurationMutationVariables,
  EditQualityIssueConfigurationMutation,
  EditQualityIssueConfigurationMutationVariables,
  QualityIssueConfigurationPageQuery,
  QualityIssueConfigurationPageQueryVariables,
  QualityIssueConfigurationPreviewInput,
  QualityIssueConfigurationPreviewQuery,
  QualityIssueConfigurationPreviewQueryVariables,
  UpdateQualityIssueConfigurationInput,
} from "@client/autogenerated/graphql/operations";
import {
  CreateQualityIssueMutation,
  DeleteQualityIssueMutation,
  QualityIssueEditMutation,
  QualityIssuePageQuery,
  QualityIssuePreviewQuery,
} from "@client/graphql/rules-engine/quality-issues";
import { assertPresent, graphClient } from "@gygadmin/client3";

export class RulesEngineQualityIssuesService {
  async getPage(
    id: number,
  ): Promise<QualityIssueConfigurationPageQuery["qualityIssueConfiguration"]> {
    return graphClient
      .gql()
      .query<QualityIssueConfigurationPageQuery, QualityIssueConfigurationPageQueryVariables>({
        query: QualityIssuePageQuery,
        variables: {
          id: String(id),
        },
      })
      .then((res) => res.data?.qualityIssueConfiguration);
  }

  async edit(
    input: UpdateQualityIssueConfigurationInput,
  ): Promise<EditQualityIssueConfigurationMutation["updateQualityIssueConfiguration"]> {
    return graphClient
      .gql()
      .mutate<
        EditQualityIssueConfigurationMutation,
        EditQualityIssueConfigurationMutationVariables
      >({
        mutation: QualityIssueEditMutation,
        variables: {
          input,
        },
      })
      .then((res) => res.data?.updateQualityIssueConfiguration);
  }

  async delete(id: number): Promise<boolean> {
    return graphClient
      .gql()
      .mutate<
        DeleteQualityIssueConfigurationMutation,
        DeleteQualityIssueConfigurationMutationVariables
      >({
        mutation: DeleteQualityIssueMutation,
        variables: {
          id: String(id),
        },
      })
      .then((res) => res.data?.deleteQualityIssueConfiguration?.success ?? false);
  }

  async create(input: CreateQualityIssueConfigurationInput): Promise<number> {
    const response = await graphClient
      .gql()
      .mutate<
        CreateQualityIssueConfigurationMutation,
        CreateQualityIssueConfigurationMutationVariables
      >({
        mutation: CreateQualityIssueMutation,
        variables: {
          input,
        },
      })
      .then((res) => res.data?.createQualityIssueConfiguration);

    if (!response?.success) {
      throw new Error("Failed to create issue type");
    }

    assertPresent(response.qualityIssueConfiguration);

    return Number(response.qualityIssueConfiguration.id);
  }

  async preview(
    input: QualityIssueConfigurationPreviewInput,
  ): Promise<QualityIssueConfigurationPreviewQuery["qualityIssueConfigurationPreview"]> {
    return graphClient
      .gql()
      .query<QualityIssueConfigurationPreviewQuery, QualityIssueConfigurationPreviewQueryVariables>(
        {
          query: QualityIssuePreviewQuery,
          fetchPolicy: "network-only",
          variables: {
            input,
          },
        },
      )
      .then((res) => res.data.qualityIssueConfigurationPreview);
  }
}

export default new RulesEngineQualityIssuesService();
