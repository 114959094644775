import { TourCommentTypeEnum } from "@root/commons/types";
import {
  ActivityCommentCreateInput,
  ActivityCommentType,
} from "@client/autogenerated/graphql/operations";

export class ActivityCommentMapper {
  static toActivityCommentCreateInput(
    tourId: number,
    comment: string,
    type: TourCommentTypeEnum,
  ): ActivityCommentCreateInput {
    return {
      tourId: tourId,
      comment: comment,
      type: this.toGQLActivityCommentType(type),
    };
  }

  static toGQLActivityCommentType(type?: TourCommentTypeEnum) {
    switch (type) {
      case TourCommentTypeEnum.General:
        return ActivityCommentType.General;
      case TourCommentTypeEnum.ContentReset:
        return ActivityCommentType.ContentReset;
      case TourCommentTypeEnum.CancellationPolicyRequest:
        return ActivityCommentType.CancellationPolicyRequest;
      case TourCommentTypeEnum.RejectReason:
        return ActivityCommentType.RejectReason;
      case TourCommentTypeEnum.StatusChange:
        return ActivityCommentType.StatusChange;
      case TourCommentTypeEnum.SupplierStatusChangeReason:
        return ActivityCommentType.SupplierStatusChangeReason;
      default:
        return ActivityCommentType.General;
    }
  }
}
