import TranslationsRouterPage from "../../views/translations/index.vue";
import { Privilege, PrivilegeService } from "@root/commons/privileges";

const privilegeService = new PrivilegeService();

export default [
  {
    path: "/translations",
    component: TranslationsRouterPage,
    children: [
      {
        path: "",
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "translations" */
            "../../views/translations/default/translations.vue"
            ),
      },
      {
        path:  ["/:pathMatch(recent-actions)*"],
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "translations" */
            "../../views/translations/default/translations.vue"
            ),
      },
      {
        path:  ["/:pathMatch(ongoing-requests)*"],
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "translations" */
            "../../views/translations/default/translations.vue"
            ),
      },
      {
        path:  ["/:pathMatch(pending)*"],
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "translations" */
            "../../views/translations/default/translations.vue"
            ),
      },
      {
        path:  ["/:pathMatch(recently-completed)*"],
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "translations" */
            "../../views/translations/default/translations.vue"
            ),
      },
      {
        path:  ["/:pathMatch(tour-translation-volume)*"],
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "translations" */
            "../../views/translations/default/translations.vue"
            ),
      },
      {
        path:  ["/:pathMatch(archived-projects)*"],
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "translations" */
            "../../views/translations/default/translations.vue"
            ),
      },
      {
        path:  "glossaries",
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "translations" */
            "../../views/translations/default/translations.vue"
            ),
      },
      {
        path: "glossaries/:id/items",
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "translations" */
            "@client/pages/views/translations/default/components/glossaries/items/glossary-item.vue"
            ),
      },
    ],
  },
];
