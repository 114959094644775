import { AxiosInstance } from "axios";
import RestClient from "@root/commons/utils/rest-client";
import {
  RecordOpEnum,
  ResponseRecords,
  Record as HistoryAPIRecord,
} from "@root/server/openapi/historization";
import { formatDateTime } from "@gygadmin/client3";

export interface HistoryRecord {
  op?: RecordOpEnum;
  update_timestamp?: string;
  update_user?: string | null;
  update_user_type?: string | null;
  previous_value?: unknown;
  new_value?: unknown;
}

export interface ClusterResponse {
  databases: {
    cluster_name: string;
    database_names: string[];
  }[];
}

export interface TablesResponse {
  tables: {
    tableName: string;
    namespace: string;
  }[];
}

export interface TableMetadata {
  nb_records?: {
    key: number;
  };
  storageType: string;
  primary_keys: {
    fieldName: string;
    fieldType: string;
    primary: boolean;
    isPrimary?: boolean;
  }[];
  name: string;
  namespace: string;
  date_to: string;
  fields: string[];
  fileloc?: string[];
  date_from: string;
}

class HistoryService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({ baseURL: "/api/history" });
  }

  async getClusters(): Promise<ClusterResponse> {
    const { data } = await this.restClient.get<ClusterResponse>(`/clusters`);
    return data;
  }

  async getTables(clusterName: string, databaseName: string): Promise<TablesResponse> {
    const { data } = await this.restClient.get<TablesResponse>(
      `/clusters/${clusterName}/databases/${databaseName}/tables`,
    );
    return data;
  }

  async getTableMetadata(
    clusterName: string,
    databaseName: string,
    tableName: string,
  ): Promise<TableMetadata> {
    const { data } = await this.restClient.get<TableMetadata>(
      `/clusters/${clusterName}/databases/${databaseName}/tables/${tableName}`,
    );

    return data;
  }

  async getRecords(
    clusterName: string,
    databaseName: string,
    table: string,
    id: string | undefined,
    fromDate: string,
    toDate: string,
  ): Promise<HistoryRecord[]> {
    const { data } = await this.restClient.get<ResponseRecords>(
      `/clusters/${clusterName}/databases/${databaseName}/tables/${table}/records`,
      {
        params: {
          id,
          fromDate,
          toDate,
        },
      },
    );

    const results: HistoryRecord[] = [];

    (data.records as (HistoryAPIRecord & { update_user_name: string })[])
      .sort((a, b) => b.ts?.localeCompare(a.ts || "") || 0)
      .forEach((record) => {
        const before: Record<string, any> | undefined = record.before || undefined;
        const after: Record<string, any> | undefined = record.after || undefined;
        const result: HistoryRecord = {
          op: record.op,
          update_timestamp: record.ts ? formatDateTime(new Date(record.ts)) : undefined,
          update_user: record.update_user_name ?? after?.update_user_id,
          update_user_type: after?.update_user_type,
        };

        if (record.op === RecordOpEnum.U) {
          const safeBefore = before || {};
          const safeAfter = after || {};

          Object.keys(before || after || {})
            .filter(
              (field) =>
                safeBefore[field] !== safeAfter[field] &&
                !["update_timestamp", "update_user_id", "update_user_type"].includes(field),
            )
            .forEach((field) => {
              results.push(
                Object.assign({}, result, {
                  field_name: field,
                  previous_value: safeBefore[field],
                  new_value: safeAfter[field],
                }),
              );
            });
        } else {
          result.previous_value = before && JSON.stringify(before);
          result.new_value = after && JSON.stringify(after);
          results.push(result);
        }
      });

    return results;
  }
}

export default new HistoryService();
