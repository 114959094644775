import { AppState } from "@gygadmin/client3";
import { Module } from "vuex";
import { PrioritizedReviewQueueState } from "@client/store/modules/attribute-review-queue/prioritized/types";
import { initialState } from "@client/store/modules/attribute-review-queue/prioritized/state";
import getters from "@client/store/modules/attribute-review-queue/prioritized/getters";
import actions from "@client/store/modules/attribute-review-queue/prioritized/actions";
import mutations from "@client/store/modules/attribute-review-queue/prioritized/mutations";

const prioritizedReviewQueueModule: Module<PrioritizedReviewQueueState, AppState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default prioritizedReviewQueueModule;
