import { AppState } from "@gygadmin/client3";
import { GetterTree } from "vuex";
import { BulkEditDetail, BulkEditPage, ToolsState } from "./types";
import { BulkOperationAggregate } from "@root/commons/types";

export const getters: GetterTree<ToolsState, AppState> = {
  getState(state: ToolsState): ToolsState {
    return state;
  },
  getBulkEditPage(state: ToolsState): BulkEditPage {
    return state.bulkEditPage;
  },
  getBulkEditDetail(state: ToolsState): BulkEditDetail {
    return state.bulkEditPage.detail;
  },
  getBulkEditOperation(state: ToolsState): BulkOperationAggregate | null {
    return state.bulkEditPage.detail.operation ?? null;
  },
  getBulkEditLoading(state: ToolsState): boolean {
    return state.bulkEditPage.detail.loading;
  },
};

export default getters;
