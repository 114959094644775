import { TransportationTypeModeEnum } from "@root/server/openapi/catalog/management/models";

export enum TransportationLevel {
  TwoWheels = "level_two_wheels",
  CarBus = "level_car_bus",
  Water = "level_water",
  Rail = "level_rail",
  Air = "level_air",
  Public = "level_public",
  Other = "level_other",
}

export const TransportationHierarchy = {
  [TransportationLevel.TwoWheels]: [
    TransportationTypeModeEnum.Bicycle,
    TransportationTypeModeEnum.MountainBike,
    TransportationTypeModeEnum.ElectricBicycle,
    TransportationTypeModeEnum.Motorcycle,
    TransportationTypeModeEnum.Scooter,
    TransportationTypeModeEnum.Segway,
  ],
  [TransportationLevel.CarBus]: [
    TransportationTypeModeEnum.Car,
    TransportationTypeModeEnum.Limousine,
    TransportationTypeModeEnum.JeepSuv,
    TransportationTypeModeEnum.Van,
    TransportationTypeModeEnum.BusCoach,
    TransportationTypeModeEnum.ElectricCar,
    TransportationTypeModeEnum.VintageCar,
    TransportationTypeModeEnum.BlackCab,
  ],
  [TransportationLevel.Water]: [
    TransportationTypeModeEnum.Sailboat,
    TransportationTypeModeEnum.Ferry,
    TransportationTypeModeEnum.Gondola,
    TransportationTypeModeEnum.DuckBoat,
    TransportationTypeModeEnum.SightseeingCruise,
    TransportationTypeModeEnum.WaterTaxi,
    TransportationTypeModeEnum.JetSki,
    TransportationTypeModeEnum.RiverBoat,
    TransportationTypeModeEnum.Kayak,
    TransportationTypeModeEnum.Raft,
    TransportationTypeModeEnum.Catamaran,
    TransportationTypeModeEnum.SpeedBoat,
    TransportationTypeModeEnum.Yacht,
    TransportationTypeModeEnum.Canoe,
    TransportationTypeModeEnum.StandUpPaddleBoard,
    TransportationTypeModeEnum.Surfboard,
    TransportationTypeModeEnum.Submarine,
    TransportationTypeModeEnum.OtherWaterTransport,
    TransportationTypeModeEnum.GlassBottomBoat,
    TransportationTypeModeEnum.LakeCruise,
    TransportationTypeModeEnum.BananaBoat,
    TransportationTypeModeEnum.BeerBoat,
    TransportationTypeModeEnum.AirBoat,
    TransportationTypeModeEnum.Dhow,
  ],
  [TransportationLevel.Rail]: [
    TransportationTypeModeEnum.Train,
    TransportationTypeModeEnum.Tram,
    TransportationTypeModeEnum.Subway,
  ],
  [TransportationLevel.Air]: [
    TransportationTypeModeEnum.Helicopter,
    TransportationTypeModeEnum.Airplane,
    TransportationTypeModeEnum.Balloon,
    TransportationTypeModeEnum.Glider,
  ],
  [TransportationLevel.Public]: [TransportationTypeModeEnum.Public],
  [TransportationLevel.Other]: [
    TransportationTypeModeEnum.CableCar,
    TransportationTypeModeEnum.QuadAtv,
    TransportationTypeModeEnum.PedicabRickshaw,
    TransportationTypeModeEnum.HorseCarriage,
    TransportationTypeModeEnum.Camel,
    TransportationTypeModeEnum.Snowmobile,
    TransportationTypeModeEnum.Sled,
    TransportationTypeModeEnum.OtherAnimal,
    TransportationTypeModeEnum.BeerBike,
    TransportationTypeModeEnum.Trike,
    TransportationTypeModeEnum.TukTuk,
    TransportationTypeModeEnum.MotorizedTukTuk,
    TransportationTypeModeEnum.GolfCart,
    TransportationTypeModeEnum.OnFoot,
    TransportationTypeModeEnum.Horse,
    TransportationTypeModeEnum.Trolley,
  ],
};

export const TransportationAllowedMeetingPointModes: Set<TransportationTypeModeEnum> = new Set<
  TransportationTypeModeEnum
>([
  TransportationTypeModeEnum.Bicycle,
  TransportationTypeModeEnum.MountainBike,
  TransportationTypeModeEnum.ElectricBicycle,
  TransportationTypeModeEnum.Motorcycle,
  TransportationTypeModeEnum.Scooter,
  TransportationTypeModeEnum.Segway,
  // level car/bus
  TransportationTypeModeEnum.Car,
  TransportationTypeModeEnum.Limousine,
  TransportationTypeModeEnum.JeepSuv,
  TransportationTypeModeEnum.Van,
  TransportationTypeModeEnum.BusCoach,
  TransportationTypeModeEnum.ElectricCar,
  TransportationTypeModeEnum.VintageCar,
  TransportationTypeModeEnum.BlackCab,
  // level water
  TransportationTypeModeEnum.Sailboat,
  TransportationTypeModeEnum.Ferry,
  TransportationTypeModeEnum.Gondola,
  TransportationTypeModeEnum.SightseeingCruise,
  TransportationTypeModeEnum.WaterTaxi,
  TransportationTypeModeEnum.RiverBoat,
  TransportationTypeModeEnum.Kayak,
  TransportationTypeModeEnum.Raft,
  TransportationTypeModeEnum.Catamaran,
  TransportationTypeModeEnum.SpeedBoat,
  TransportationTypeModeEnum.Yacht,
  TransportationTypeModeEnum.Canoe,
  TransportationTypeModeEnum.OtherWaterTransport,
  TransportationTypeModeEnum.LakeCruise,
  TransportationTypeModeEnum.AirBoat,
  // level rail transport
  TransportationTypeModeEnum.Train,
  TransportationTypeModeEnum.Tram,
  TransportationTypeModeEnum.Subway,
  // level air
  TransportationTypeModeEnum.Helicopter,
  TransportationTypeModeEnum.Airplane,
  // level public transport
  TransportationTypeModeEnum.Public,
  // level other
  TransportationTypeModeEnum.CableCar,
  TransportationTypeModeEnum.QuadAtv,
  TransportationTypeModeEnum.PedicabRickshaw,
  TransportationTypeModeEnum.HorseCarriage,
  TransportationTypeModeEnum.Camel,
  TransportationTypeModeEnum.Snowmobile,
  TransportationTypeModeEnum.Sled,
  TransportationTypeModeEnum.OtherAnimal,
  TransportationTypeModeEnum.Trike,
  TransportationTypeModeEnum.TukTuk,
  TransportationTypeModeEnum.MotorizedTukTuk,
  TransportationTypeModeEnum.GolfCart,
  TransportationTypeModeEnum.OnFoot,
]);

export const TransportationAllowedPickUpModes: Set<TransportationTypeModeEnum> = new Set<
  TransportationTypeModeEnum
>([
  // level two wheel
  TransportationTypeModeEnum.Bicycle,
  TransportationTypeModeEnum.MountainBike,
  TransportationTypeModeEnum.ElectricBicycle,
  TransportationTypeModeEnum.Motorcycle,
  TransportationTypeModeEnum.Scooter,
  TransportationTypeModeEnum.Segway,
  // level car/bus
  TransportationTypeModeEnum.Car,
  TransportationTypeModeEnum.Limousine,
  TransportationTypeModeEnum.JeepSuv,
  TransportationTypeModeEnum.Van,
  TransportationTypeModeEnum.BusCoach,
  TransportationTypeModeEnum.ElectricCar,
  TransportationTypeModeEnum.VintageCar,
  TransportationTypeModeEnum.BlackCab,
  // level water
  TransportationTypeModeEnum.Gondola,
  TransportationTypeModeEnum.WaterTaxi,
  TransportationTypeModeEnum.OtherWaterTransport,
  // level other
  TransportationTypeModeEnum.QuadAtv,
  TransportationTypeModeEnum.PedicabRickshaw,
  TransportationTypeModeEnum.Trike,
  TransportationTypeModeEnum.TukTuk,
  TransportationTypeModeEnum.MotorizedTukTuk,
  TransportationTypeModeEnum.GolfCart,
  TransportationTypeModeEnum.OnFoot,
]);

const TransportationAllowedDropOffModes: Set<TransportationTypeModeEnum> = new Set<
  TransportationTypeModeEnum
>();
TransportationAllowedMeetingPointModes.forEach((mode) =>
  TransportationAllowedDropOffModes.add(mode),
);
TransportationAllowedPickUpModes.forEach((mode) => TransportationAllowedDropOffModes.add(mode));

export { TransportationAllowedDropOffModes };
