import { ToolsState } from "./types";
import { MutationTree } from "vuex";
import { BulkOperationAggregate } from "@root/commons/types";

export const mutations: MutationTree<ToolsState> = {
  SET_PAGE_BULK_EDIT_DETAIL_LOADING(state: ToolsState, loading: boolean): void {
    state.bulkEditPage.detail = {
      ...state.bulkEditPage.detail,
      loading: loading,
    };
  },
  SET_PAGE_BULK_EDIT_DETAIL_OPERATION(state: ToolsState, operation: BulkOperationAggregate): void {
    state.bulkEditPage.detail = {
      ...state.bulkEditPage.detail,
      operation: operation,
    };
  },
};

export default mutations;
