import { gql } from "graphql-tag";

export const QuickSearchActivities = gql`
  query QuickSearchActivities($input: SearchInput!) {
    activitySearch(input: $input) {
      items {
        id
        sourceText {
          title
        }
        status
        primaryLocation {
          name
        }
        supplier {
          companyName
        }
        category
        isTestData
      }
      pagination {
        totalItems
        offset
        hasNextPage
      }
    }
  }
`;

export const QuickSearchActivityOptions = gql`
  query QuickSearchActivityOptions($input: SearchInput!) {
    activityOptionSearch(input: $input) {
      items {
        id
        sourceText {
          title
        }
        activity {
          id
          sourceText {
            title
          }
          isTestData
        }
        status
      }
      pagination {
        totalItems
        offset
        hasNextPage
      }
    }
  }
`;

export const QuickSearchLocations = gql`
  query QuickSearchLocations($input: SearchInput!) {
    locationSearch(input: $input) {
      items {
        id
        name
        type
      }
      pagination {
        totalItems
        offset
        hasNextPage
      }
    }
  }
`;

export const QuickSearchCustomers = gql`
  query QuickSearchCustomers($input: SearchInput!) {
    quickCustomersSearch(input: $input) {
      items {
        id
        title
        description
        status
      }
    }
  }
`;

export const QuickSearchBookings = gql`
  query QuickSearchBookings($input: SearchInput!) {
    quickBookingsSearch(input: $input) {
      items {
        id
        title
        description
        status
      }
    }
  }
`;

export const QuickSearchShoppingCarts = gql`
  query QuickSearchShoppingCarts($input: SearchInput!) {
    quickShoppingCartsSearch(input: $input) {
      items {
        id
        title
        description
        status
      }
    }
  }
`;

export const QuickSearchCoupons = gql`
  query QuickSearchCoupons($input: SearchInput!) {
    quickCouponsSearch(input: $input) {
      items {
        id
        title
        description
        status
      }
    }
  }
`;

export const QuickSearchSuppliers = gql`
  query QuickSearchSuppliers($input: SearchInput!) {
    supplierSearch(input: $input) {
      items {
        supplierGygStatus
        id
        companyName
        legalCompanyName
        isTestData
      }
      pagination {
        totalItems
        offset
        hasNextPage
      }
    }
  }
`;

export const QuickSearchSuppliersAccounts = gql`
  query QuickSearchSuppliersAccounts($input: SearchInput!) {
    supplierAccountSearch(input: $input) {
      items {
        id
        firstName
        lastName
        email
        allowLogin
        isPrimary
        supplierId
      }
      pagination {
        totalItems
        offset
        hasNextPage
      }
    }
  }
`;
