import ActivitiesRouterPage from "../../views/activities/index.vue";

export default [
    {
        path: "/activities",
        component: ActivitiesRouterPage,
        children: [
            {
                path: [""],
                meta: {authorize: []},
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "activities" */
                        "../../views/activities/default/activities.vue"
                        ),
            },
            {
                path: [":id/options/:optionId/:pathMatch(.*)*"],
                meta: {authorize: []},
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "activities" */
                        "../../views/activities/detail/detail.vue"
                        ),
            },
            {
                path: ":id/ai-input",
                meta: {authorize: []},
                component: (): Promise<any> =>
                  import(
                        /* webpackChunkName: "activities" */
                        "../../views/activities/detail/detail.vue"
                        ),
            },
            {
                path: ":id/special-offers",
                meta: {authorize: []},
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "activities" */
                        "../../views/activities/detail/detail.vue"
                        ),
            },
            {
                name: "activity-review",
                path: ":id/reviews/:reviewId/:pathMatch(.*)*",
                meta: {authorize: []},
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "activities" */
                        "../../views/activities/detail/detail.vue"
                        ),
            },
            {
                path: ":id/change-requests/:changeRequestId",
                meta: {authorize: []},
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "activities" */
                        "../../views/activities/detail/detail.vue"
                        ),
            },
            {
                path: [":id/:pathMatch(.*)*"],
                meta: {authorize: []},
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "activities" */
                        "../../views/activities/detail/detail.vue"
                        ),
            },
        ],
    },
];
