import { Logger, LogLevels, Meta } from "./types";
import { asyncLogging } from "./logger.async";

const decorateErrorsInProps = (data: Meta) => {
  Object.keys(data).forEach((key: string) => {
    const val = (data as Record<string, unknown>)[key];
    if (val instanceof Error) {
      (data as Record<string, unknown>)[key] = {};
      ["name", "message", "fileName", "lineNumber", "stack"].forEach(function (prop) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: TS gets confused because the default Error type can't be indexed by string
        data[key][prop] = val[prop];
      });
    }
  });
};

const loggerBuild = (level: LogLevels) => {
  return (...args: Meta[]) => {
    console[level](...args);
    // Ensure errors are properly decorated so thay can be JSON.stringify and indexed in kibanna
    args.forEach((arg: Meta) => decorateErrorsInProps(arg));

    try {
      asyncLogging({
        level,
        args,
        url: window.location.href,
      });
    } catch (err) {
      console.error(
        "Failed to send data to logger-worker. Please make sure you send only strings or serializable data. \n",
        err,
      );
    }
    return logger;
  };
};

const logger: Logger = {
  debug: loggerBuild("debug"),
  error: loggerBuild("error"),
  info: loggerBuild("info"),
  warn: loggerBuild("warn"),
};

export { logger };
