import { CountryState } from "./types";
import { MutationTree } from "vuex";
import { AggregatedCountry, CountryPicture } from "@root/commons/types";

export const mutations: MutationTree<CountryState> = {
  SET_PAGE_COUNTRY(state: CountryState, country: AggregatedCountry): void {
    state.page = {
      ...state.page,
      id: country.id,
      loading: false,
      country,
    };
  },
  SET_PAGE_LOADING(state: CountryState, loading: boolean): void {
    state.page = {
      ...state.page,
      loading,
    };
  },
  SET_TAB_COUNTRY_PICTURES_LOADING(state: CountryState, loading: boolean): void {
    state.page.tabs.countryPictures = {
      ...state.page.tabs.countryPictures,
      loading: loading,
    };
  },
  SET_TAB_COUNTRY_PICTURES_ITEMS(state: CountryState, items: CountryPicture[]): void {
    state.page.tabs.countryPictures = {
      ...state.page.tabs.countryPictures,
      pictures: items,
    };
  },
};

export default mutations;
