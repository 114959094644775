/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Internal Management API
 * API for exclusive use with the Catalog Frontend. DO NOT USE ELSEWHERE! BACKWARDS COMPATIBILITY NOT GUARANTEED!
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: marketplace.catalog@getyourguide.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TransportationType
 */
export interface TransportationType {
    /**
     * 
     * @type {string}
     * @memberof TransportationType
     */
    mode: TransportationTypeModeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TransportationTypeModeEnum {
    Bicycle = 'bicycle',
    MountainBike = 'mountain_bike',
    ElectricBicycle = 'electric_bicycle',
    Motorcycle = 'motorcycle',
    Scooter = 'scooter',
    Segway = 'segway',
    Car = 'car',
    Limousine = 'limousine',
    JeepSuv = 'jeep_suv',
    Van = 'van',
    QuadAtv = 'quad_atv',
    BusCoach = 'bus_coach',
    ElectricCar = 'electric_car',
    VintageCar = 'vintage_car',
    BlackCab = 'black_cab',
    Sailboat = 'sailboat',
    Ferry = 'ferry',
    Gondola = 'gondola',
    DuckBoat = 'duck_boat',
    JetSki = 'jet_ski',
    SightseeingCruise = 'sightseeing_cruise',
    WaterTaxi = 'water_taxi',
    RiverBoat = 'river_boat',
    Kayak = 'kayak',
    Raft = 'raft',
    Catamaran = 'catamaran',
    SpeedBoat = 'speed_boat',
    Yacht = 'yacht',
    Canoe = 'canoe',
    StandUpPaddleBoard = 'stand_up_paddle_board',
    Surfboard = 'surfboard',
    Submarine = 'submarine',
    OtherWaterTransport = 'other_water_transport',
    Train = 'train',
    Tram = 'tram',
    Helicopter = 'helicopter',
    Airplane = 'airplane',
    Balloon = 'balloon',
    Glider = 'glider',
    CableCar = 'cable_car',
    PedicabRickshaw = 'pedicab_rickshaw',
    HorseCarriage = 'horse_carriage',
    Camel = 'camel',
    Snowmobile = 'snowmobile',
    Sled = 'sled',
    OtherAnimal = 'other_animal',
    Public = 'public',
    GlassBottomBoat = 'glass_bottom_boat',
    LakeCruise = 'lake_cruise',
    BananaBoat = 'banana_boat',
    BeerBoat = 'beer_boat',
    AirBoat = 'air_boat',
    Dhow = 'dhow',
    Subway = 'subway',
    BeerBike = 'beer_bike',
    Trike = 'trike',
    TukTuk = 'tuk_tuk',
    GolfCart = 'golf_cart',
    MotorizedTukTuk = 'motorized_tuk_tuk',
    OnFoot = 'on_foot',
    Horse = 'horse',
    Trolley = 'trolley'
}



