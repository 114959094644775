import { LocationState } from "./types";
import { MutationTree } from "vuex";
import {AggregatedLocation, LocationPicture, Tag} from "@root/commons/types";

export const mutations: MutationTree<LocationState> = {
  SET_PAGE_LOCATION(state: LocationState, location: AggregatedLocation): void {
    state.page = {
      ...state.page,
      id: location.id,
      loading: false,
      location,
    };
  },
  SET_PAGE_LOADING(state: LocationState, loading: boolean): void {
    state.page = {
      ...state.page,
      loading,
    };
  },
  SET_PAGE_AREA_TYPES(state: LocationState, areaTypes: Tag[]): void {
    state.page = {
      ...state.page,
      areaTypeOptions: areaTypes,
    };
  },
  SET_TAB_LOCATION_PICTURES_LOADING(state: LocationState, loading: boolean): void {
    state.page.tabs.locationPictures = {
      ...state.page.tabs.locationPictures,
      loading: loading,
    };
  },
  SET_TAB_LOCATION_PICTURES_ITEMS(state: LocationState, items: LocationPicture[]): void {
    state.page.tabs.locationPictures = {
      ...state.page.tabs.locationPictures,
      pictures: items,
    };
  },
};

export default mutations;
