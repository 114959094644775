import { AxiosInstance } from "axios";
import { GoogleSpreadsheetRow } from "google-spreadsheet";
import RestClient from "@root/commons/utils/rest-client";
import { BulkOperation } from "@root/commons/types";

class BulkEditService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api/tools/bulk-edit",
    });
  }

  /**
   * Get all information for a bulk operation
   *
   * @param id
   */
  async get(id: number): Promise<BulkOperation> {
    return this.restClient.get<BulkOperation>(`/${id}`).then((response) => response.data);
  }

  /**
   * Create a new bulk operation
   *
   * @param operation
   */
  async create(operation: BulkOperation): Promise<BulkOperation> {
    return this.restClient
      .post(`/`, operation)
      .then((response) => response.data as BulkOperation)
      .catch(() => ({}));
  }

  /**
   * Create a revert operation
   *
   * @param id
   */
  async createRevert(id: number): Promise<BulkOperation> {
    return this.restClient
      .post(`/${id}/revert`)
      .then((response) => response.data as BulkOperation)
      .catch(() => ({}));
  }

  /**
   * Edit the input of an existing bulk operation
   *
   * @param operation
   * @param id
   */
  async editInput(id: number, operation: BulkOperation): Promise<BulkOperation> {
    return this.restClient
      .put<BulkOperation>(`/${id}/input`, operation)
      .then((response) => response.data);
  }

  /**
   * Run an operation
   *
   * @param persist
   * @param id
   */
  async run(id: number, persist: boolean): Promise<BulkOperation> {
    return this.restClient
      .post<BulkOperation>(`/${id}/run?persist=${persist}`)
      .then((response) => response.data);
  }

  async loadSpreadsheet(spreadsheetId: string): Promise<GoogleSpreadsheetRow[]> {
    const { data } = await this.restClient.get<GoogleSpreadsheetRow[]>("/load-spreadsheet", {
      params: { id: spreadsheetId },
    });
    return data;
  }
}

export default new BulkEditService();
