import {
  Location,
  LocationPicture,
  SalesArea,
  SalesOfficeAssignment,
  Supplier,
} from "@root/server/openapi/catalog/management";
import { LanguageInfo } from "@root/commons/static/languages";

export enum LocationQueryParam {
  NameContains = "name-contains",
}

export enum DeprecatedLocationPoiTypes {
  BrandExperience = "brand_experience",
  ZooOrAquarium = "zoo_or_aquarium",
}

export interface AggregatedLocation extends Location {
  parent?: AggregatedLocation;
  country_name?: string;
  gettext_name?: string;
  short_name?: string;
  description?: string;
  tips?: string;
  pictures?: AggregatedLocationPicture[];
  salesOfficeAssignment?: AggregatedSalesOfficeAssignment;
  suppliers?: Supplier[];
  landingPageLink?: LocationLandingPageLink;
}

export interface AggregatedLocationPicture extends LocationPicture {
  img_path?: string;
}

export interface AggregatedSalesOfficeAssignment extends SalesOfficeAssignment {
  salesOffice?: AggregatedLocation;
  salesArea?: SalesArea;
}

export enum LocationRelationshipTypeEnum {
  HasSecondaryParent = "has_secondary_parent",
}

export interface LocationLandingPageLink {
  language: LanguageInfo;
  href: string;
}

export interface LocationSearchResponse {
  items: Location[];
}
