export enum ChipStatusType {
  Active = "active",
  Approved = "approved",
  Temp = "temp",
  Check = "check",
  Revise = "revise",
  Deactivated = "deactivated",
  Deleted = "deleted",
  Removed = "removed",
  Rejected = "rejected",
  Pending = "pending",
  New = "new",
  QualityCheckFailed = "quality_check_failed",
  Online = "online",
  Offline = "offline",
  Expired = "expired",
  Processed = "processed",
}
