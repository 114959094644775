import { AppState } from "@gygadmin/client3";
import { GetterTree } from "vuex";
import { FieldCheck } from "@root/commons/types/fields";
import { FieldCheckState } from "./types";
import { getFieldUniqueKey } from "./helpers";

export const getters: GetterTree<FieldCheckState, AppState> = {
  getState(state: FieldCheckState): FieldCheckState {
    return state;
  },
  getItem:
    (state: FieldCheckState) =>
    (fieldCheckItem: FieldCheck): FieldCheck => {
      const itemKey = getFieldUniqueKey(
        fieldCheckItem.type,
        fieldCheckItem.type_id,
        fieldCheckItem.key,
      );
      return state.items[itemKey];
    },
};

export default getters;
