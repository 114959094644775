import { AppState } from "@gygadmin/client3";
import { TagState, TagPage } from "./types";
import { GetterTree } from "vuex";
import { AggregatedTag } from "@root/commons/types";

export const getters: GetterTree<TagState, AppState> = {
  getState(state: TagState): TagState {
    return state;
  },
  getTag(state: TagState): AggregatedTag | null {
    if (state.page.tag !== undefined) {
      return state.page.tag;
    }
    return null;
  },
  getTagPage(state: TagState): TagPage {
    return state.page;
  },
};

export default getters;
