import { AppState } from "@gygadmin/client3";
import { FieldCheck } from "@root/commons/types/fields";
import { FieldCheckState } from "./types";
import { ActionContext, ActionTree } from "vuex";
import { FieldsCheckService } from "@client/services";

export const actions: ActionTree<FieldCheckState, AppState> = {
  saveItem(context: ActionContext<FieldCheckState, AppState>, fieldCheckItem: FieldCheck): void {
    context.commit("CACHE_ITEM", fieldCheckItem);
    FieldsCheckService.save({
      type: fieldCheckItem.type,
      type_id: fieldCheckItem.type_id,
      key: fieldCheckItem.key,
      checked: fieldCheckItem.checked,
    }).then();
  },
  cacheItem(context: ActionContext<FieldCheckState, AppState>, fieldCheckItem: FieldCheck): void {
    context.commit("CACHE_ITEM", fieldCheckItem);
  },
  evictItem(context: ActionContext<FieldCheckState, AppState>, fieldCheckItem: FieldCheck): void {
    context.commit("EVICT_ITEM", fieldCheckItem);
  },
};

export default actions;
