import TagsRouterPage from "../../views/tags/index.vue";
import { Privilege, PrivilegeService } from "@root/commons/privileges";

const privilegeService = new PrivilegeService();

export default [
  {
    path: "/tags",
    component: TagsRouterPage,
    children: [
      {
        path: "",
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "tags" */
            "../../views/tags/default/tags.vue"
          ),
      },
      {
        path: "create",
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "tags" */
            "../../views/tags/create/create.vue"
          ),
      },
      {
        path :[":id/:pathMatch(.*)*"],
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.AdminTourEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "tags" */
            "../../views/tags/detail/detail.vue"
          ),
      },
    ],
  },
];
