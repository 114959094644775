import { User, ContentReview } from "@server/openapi/catalog/management";
import { ContentReviewQualityIssueMapper } from "@client/graphql/prioritized-review-queue/content-review-quality-issue-mapper";
import { ContentReviewCheckMapper } from "@client/graphql/prioritized-review-queue/content-review-check-mapper";
import { ContentReviewWithStaffName } from "@root/commons/types";
import {
  ContentReview as GraphQLContentReview,
  ContentReviewUpdateInput,
} from "@client/autogenerated/graphql/operations";
import { ContentReviewCommentMapper } from "@client/graphql/prioritized-review-queue/content-review-comment-mapper";

export class ContentReviewMapper {
  static toManagementContentReview(
    contentReview: GraphQLContentReview,
  ): ContentReviewWithStaffName {
    return {
      id: Number(contentReview?.id),
      entity: contentReview?.entity?.toLowerCase() ?? undefined,
      entity_id: contentReview?.entityId ?? undefined,
      context: contentReview?.context?.toLowerCase() ?? undefined,
      review_section: contentReview?.reviewSection ?? undefined,
      review_status: contentReview?.reviewStatus?.toLowerCase() ?? undefined,
      current_state: contentReview?.currentState ?? undefined,
      previous_state: contentReview?.previousState ?? undefined,
      assigned_staff_id: contentReview?.assignedStaffId ?? undefined,
      creation_user: contentReview?.creationUser
        ? ({
            id: Number(contentReview.creationUser.id),
            type: contentReview.creationUser.type,
          } as User)
        : undefined,
      update_user: contentReview?.updateUser
        ? ({
            id: Number(contentReview.updateUser.id),
            type: contentReview.updateUser.type,
          } as User)
        : undefined,
      username: contentReview?.creationStaff ? contentReview.creationStaff.name : undefined,
      creation_timestamp: contentReview?.creationTimestamp ?? undefined,
      update_timestamp: contentReview?.updateTimestamp ?? undefined,
      finalized_timestamp: contentReview?.finalizedTimestamp ?? undefined,
      activation_timestamp: contentReview?.activationTimestamp ?? undefined,
      in_progress_timestamp: contentReview?.inProgressTimestamp ?? undefined,
      content_review_quality_issues: contentReview?.contentReviewQualityIssues.map(
        ContentReviewQualityIssueMapper.toManagementQualityIssue,
      ),
      content_review_checks: contentReview?.contentReviewChecks.map(
        ContentReviewCheckMapper.toManagementCheck,
      ),
      content_review_comments: contentReview?.contentReviewComments.map(
        ContentReviewCommentMapper.toManagementComment,
      ),
      supplier_email_sent: contentReview?.supplierEmailSent ?? undefined,
      reverted: contentReview?.reverted ?? undefined,
      priority: contentReview?.priority ?? undefined,
      masterLanguageId: contentReview?.masterLanguageId,
    };
  }

  static toContentReviewUpdateInput(contentReview: ContentReview): ContentReviewUpdateInput {
    return {
      id: `${contentReview.id}`,
      reviewStatus: contentReview.review_status,
      assignedStaffId: contentReview.assigned_staff_id,
      contentReviewQualityIssues: (contentReview.content_review_quality_issues ?? []).map(
        ContentReviewQualityIssueMapper.toContentReviewQualityIssueInput,
      ),
    };
  }
}
