import { FieldCheck } from "@root/commons/types/fields";
import {
  AuthorizedBadge,
  Category,
  ChangeRequest,
  CustomCancellationPolicy,
  FreeTextCancellationPolicy,
  Itinerary,
  ItineraryItem,
  ItineraryItemToTag,
  Location,
  LocationSuggestion,
  LocationTypeEnum,
  SupplierGygStatusEnum,
  Tag,
  Tour,
  TourComment,
  TourCommentTypeEnum,
  TourOption,
  TourOptionText,
  TourPicture,
  TourSearchItem,
  TourText,
  TourToLocation,
  TourToLocationLocationEntranceTypeEnum,
  TourToLocationSuggestion,
  TransportationType,
  User,
} from "@root/server/openapi/catalog/management/models";
import { LanguageInfo } from "@root/commons/static/languages";

/**
 * The number of bookings that indicates whether an activity should be promoted.
 */
export const ActivityPromoteBookingCount = 5;

export enum ActivityGygadminStatus {
  Active = "active",
  DeletedByStaff = "deleted_by_gyg",
  DeletedBySupplier = "deleted_by_supplier",
  DeactivatedByStaff = "deactivated_by_gyg",
  DeactivatedBySupplier = "deactivated_by_supplier",
  Temp = "temp",
  New = "new",
  QualityFailed = "quality_failed",
  SupplierNotActive = "supplier_not_active",
  NoAvailableOptions = "no_available_options",
  Rejected = "rejected",
  Unknown = "unknown",
}

export enum TourStrategicRelationshipsEnum {
  PreferredPartnerContract = "preferred_partner_contract",
  SupplierCrossSellReseller = "supplier_cross_sell_reseller",
  JointMarketing = "joint_marketing",
  ExclusiveGygProducts = "exclusive_gyg_products",
  BeatTheCrowd = "beat_the_crowd",
  Donations = "donations",
}

export enum ActivityStatusTransition {
  Activate = "activate",
  Delete = "delete",
  Undelete = "undelete",
  Deactivate = "deactivate",
  Reject = "reject",
}

export enum ActivityOptionStatusTransition {
  Activate = "activate",
  Delete = "delete",
  Revise = "revise",
  Deactivate = "deactivate",
}

export type ActivityCustomCancellationPolicy = CustomCancellationPolicy;
export type ActivityFreeTextCancellationPolicy = FreeTextCancellationPolicy;

export interface ActivityFreeTextCancellationPolicies {
  [key: string]: ActivityFreeTextCancellationPolicy;
}

export interface ActivityUser extends User {
  name?: string;
}

export interface ActivityCreationUser extends User {
  name?: string;
}

export interface ActivityUpdateUser extends User {
  name?: string;
}

export enum ActivityCommentType {
  General = TourCommentTypeEnum.General,
  StatusChange = TourCommentTypeEnum.StatusChange,
  RejectReason = TourCommentTypeEnum.RejectReason,
  CancellationPolicyRequest = TourCommentTypeEnum.CancellationPolicyRequest,
  ContentReset = TourCommentTypeEnum.ContentReset,
}

export interface ActivitySupplier {
  id?: number;
  name?: string;
  status?: SupplierGygStatusEnum;
  public_link?: string;
  currency?: string;
  commission_rate?: number;
  is_gyg_supplier?: boolean;
}

export interface ActivityCustomerLink {
  language: LanguageInfo;
  href: string;
}

export interface ActivityCategory extends Category {
  level_1_name?: string;
  level_2_name?: string;
}

export interface ActivityAuthorizedBadge extends AuthorizedBadge {
  location_name?: string;
  picture_path?: string;
}

export enum ActivityAdditionalFormFields {
  AuthorisedBadgePicture = "authorized_badge_raw_picture",
}

export enum ActivityPictureAdditionalFormFields {
  Pictures = "pictures",
  Uploads = "picture_uploads",
  Copyright = "copyright",
}

export enum AuthorisedBadgeType {
  Custom = "custom",
  Default = "default",
  NotSet = "not_set",
}

export interface LocationInfo extends Location {
  parent?: LocationInfo;
  country_name?: string;
  location_entrance_type?: TourToLocationLocationEntranceTypeEnum;
}

export interface TourToLocationInfo extends TourToLocation {
  country_name?: string;
  location_info?: LocationInfo;
  location_entrance_type?: TourToLocationLocationEntranceTypeEnum;
  parent?: LocationInfo;
  type?: LocationTypeEnum;
}

export type ActivityText = TourText;

export type ActivityOptionText = TourOptionText;

export interface ActivityItineraryItemToTag extends ItineraryItemToTag {
  tag?: Tag;
}

export interface ActivityItineraryItem extends ItineraryItem {
  location?: Location;
  itinerary_item_to_tags?: ActivityItineraryItemToTag[];
}

export interface ActivityItinerary extends Itinerary {
  itinerary_items?: ActivityItineraryItem[];
}

export interface ActivityChangeRequest extends ChangeRequest {
  requester_user?: ActivityUser;
  update_user?: ActivityUpdateUser;
}

export interface ActivityLocationSuggestions {
  supplier: LocationSuggestion;
  internal: TourToLocationSuggestion[];
}

export interface Activity extends Tour {
  [key: string]: unknown;

  locations?: Array<TourToLocationInfo>;
  supplier?: ActivitySupplier;
  title?: string;
  description?: string;
  highlights?: string[];
  short_description?: string;
  inclusions?: string;
  exclusions?: string;
  main_picture_path?: string;
  gygadmin_status?: ActivityGygadminStatus;
  gygadmin_status_color?: string;
  available_languages?: Array<number>;
  public_links?: Array<ActivityCustomerLink>;
  has_availability?: boolean;
  field_checks?: Array<FieldCheck>;
  authorized_badge?: ActivityAuthorizedBadge;
  itineraries?: ActivityItinerary[];
  locationSuggestion?: ActivityLocationSuggestions;
  meeting_point_transportation_types?: TransportationType[];
  pick_up_transportation_types?: TransportationType[];
  drop_off_transportation_types?: TransportationType[];
  isTestData?: boolean;
}

export interface ActivityListItem extends TourSearchItem {
  gyg_status_color?: string;
}

export interface ActivityComment extends TourComment {
  creation_user?: ActivityCreationUser;
  update_user?: ActivityUpdateUser;
}

export interface ActivityPicture extends TourPicture {
  img_path?: string;
}

export interface ActivityOption extends TourOption {
  title?: string;
  supplier_requested_questions?: string;
}

export interface SpecialOffer {
  title: string;
  description?: string;
  startDatetime: string;
  price: number;
  participants: number;
}

export enum TextTranslation {
  MANUAL = "manual",
  AUTOTRANSLATED = "autotranslated",
}

export interface TranslationFieldTimestamps {
  manualTimestamp?: string;
  autoTimestamp?: string;
}
