import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import {
  AggregatedSalesOfficeAssignment,
  LocationSuggestion,
  SalesOfficeAssignment,
} from "@root/commons/types";

class SalesOfficeAssignmentService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Create a sales office assignment.
   *
   * @param salesOfficeAssignment
   */
  async create(salesOfficeAssignment: SalesOfficeAssignment): Promise<SalesOfficeAssignment> {
    return this.restClient
      .post(`/sales-office-assignments`, salesOfficeAssignment)
      .then((response) => response.data as LocationSuggestion)
      .catch((_) => ({}));
  }

  /**
   * Update a sales office assignment.
   *
   * @param salesOfficeAssignmentId
   * @param salesOfficeAssignment
   */
  async update(
    salesOfficeAssignmentId: number,
    salesOfficeAssignment: SalesOfficeAssignment,
  ): Promise<AggregatedSalesOfficeAssignment> {
    return this.restClient
      .put(`/sales-office-assignments/${salesOfficeAssignmentId}`, salesOfficeAssignment)
      .then((response) => response.data as AggregatedSalesOfficeAssignment)
      .catch((_) => ({}));
  }

  /**
   * Delete a sales office assignment.
   *
   * @param salesOfficeAssignmentId
   */
  async delete(salesOfficeAssignmentId: number): Promise<boolean> {
    return this.restClient
      .delete(`/sales-office-assignments/${salesOfficeAssignmentId}`)
      .then(() => true);
  }
}

export default new SalesOfficeAssignmentService();
