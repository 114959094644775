import { Tag } from "@root/server/openapi/catalog/management/models";
import { TagText } from "@root/server/openapi/catalog/management/models/tag-text";

export enum KeywordCategories {
  Sightseeing = "sightseeing",
  Nature = "nature",
  SportsAndAdventure = "sports_adventure",
  CulinaryAndNightlife = "culinary_nightlife",
  CruisesAndWater = "cruises_water",
  Themes = "themes",
  CultureAndHistory = "culture_history",
  Audience = "audience",
  TransportationTransfer = "transportation_transfer",
  Other = "other",
}

export interface Keyword extends Tag {
  suggested: boolean;
}

export interface KeywordTopListResponse {
  [key: string]: Keyword[];
}

export enum TagQueryParam {
  types = "types",
  isParent = "is-parent",
  nameContains = "name-contains",
}

export interface TagParams {
  types?: string[];
  isParent?: boolean;
  name?: string;
}

export interface AggregatedTag extends Tag {
  gettext_name?: string;
  parent?: Tag;
  children?: Tag[];
  translations?: TagText[];
}
