import { AppState } from "@gygadmin/client3";
import { SandboxState } from "./types";
import { GetterTree } from "vuex";
import { AggregateSandboxField } from "@root/commons/types/sandbox";

export const getters: GetterTree<SandboxState, AppState> = {
  getLoading(state: SandboxState): boolean {
    return state.fields.loading;
  },
  getItemsMap(state: SandboxState): Record<number, AggregateSandboxField[]> {
    return state.fields.items;
  },
  getItems:
    (state: SandboxState) =>
    (key: string): AggregateSandboxField[] => {
      return state.fields.items[key];
    },
};

export default getters;
