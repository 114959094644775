import { gql } from "@gygadmin/client3";

export const CreateDerivedAttributeMutation = gql`
  mutation CreateDerivedAttributeConfiguration($input: CreateDerivedAttributeConfigurationInput!) {
    createDerivedAttributeConfiguration(input: $input) {
      success
      derivedAttributeConfiguration {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const DeleteDerivedAttributeMutation = gql`
  mutation DeleteDerivedAttributeConfiguration($id: ID!) {
    deleteDerivedAttributeConfiguration(id: $id) {
      success
    }
  }
`;

const derivedAttributesPageFragment = gql`
  fragment DerivedAttributePageFields on DerivedAttributeConfiguration {
    id
    type
    entityType
    internalName
    name
    order
    enabled
    multiple
    creationTime
    lastModificationUser {
      id
      type
    }
    lastModificationTime
    options {
      id
      value
      enabled
      order
      rules {
        uid
        parent {
          uid
        }
        clause
        operation
        ruleId
        params {
          key
          value {
            dropdown {
              values
            }
            freeText {
              values
            }
            integer {
              value
            }
            interval {
              lowerBound
              upperBound
            }
          }
        }
      }
    }
    availableRules {
      id
      name
      internalName
      description
      inputs {
        internalName
        label
        order
        validation {
          __typename
          ... on RulesEngineRuleInputValidationInterval {
            lowerBound
            upperBound
          }
          ... on RulesEngineRuleInputValidationDropdown {
            items {
              label
              value
            }
            multiSelect
          }
          ... on RulesEngineRuleInputValidationFreeText {
            minCharLength
            maxCharLength
          }
          ... on RulesEngineRuleInputValidationInteger {
            multiInput
            min
            max
          }
        }
      }
    }
    rules {
      uid
      parent {
        uid
      }
      clause
      operation
      ruleId
      params {
        key
        value {
          dropdown {
            values
          }
          freeText {
            values
          }
          integer {
            value
          }
          interval {
            lowerBound
            upperBound
          }
        }
      }
    }
  }
`;

export const DerivedAttributePageQuery = gql`
  ${derivedAttributesPageFragment}

  query DerivedAttributeConfigurationPage($id: ID!) {
    derivedAttributeConfiguration(id: $id) {
      ...DerivedAttributePageFields
    }
  }
`;

export const DerivedAttributeEditMutation = gql`
  ${derivedAttributesPageFragment}

  mutation EditDerivedAttributeConfiguration($input: UpdateDerivedAttributeConfigurationInput!) {
    updateDerivedAttributeConfiguration(input: $input) {
      success
      derivedAttributeConfiguration {
        ...DerivedAttributePageFields
      }
      errors {
        message
      }
    }
  }
`;

export const DerivedAttributePreviewQuery = gql`
  query DerivedAttributeConfigurationPreview($input: DerivedAttributeConfigurationPreviewInput!) {
    derivedAttributeConfigurationPreview(input: $input) {
      items {
        check
        entityId
        option {
          check
          value
        }
      }
    }
  }
`;

export const DerivedAttributeSearchQuery = gql`
  query DerivedAttributeConfigurationSearchPage($input: SearchInput) {
    derivedAttributeConfigurationSearch(input: $input) {
      pagination {
        totalItems
        offset
        limit
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        entityType
        internalName
        name
        type
        enabled
      }
    }
  }
`;
