import { TranslationsState } from "./types";

export const initialState: TranslationsState = {
  page: {
    loading: false,
    tabs: {
      recentActions: { loading: false, items: [] },
      ongoingRequests: { loading: false, items: [] },
      recentlyCompleted: { loading: false, items: [] },
      tourTranslationVolume: { loading: false, items: [] },
    },
  },
};
