// hygen_placeholder__pages
import attributeReviewQueuePages from "./attribute-review-queue";
import countriesPages from "./countries";
import activitiesPages from "./activities";
import translationsPages from "./translations";
import locationsPages from "./locations";
import tagsPages from "./tags";
import optionsPages from "./options";
import rulesEnginePages from "./rules-engine";
import toolsPages from "./tools";

export default [
  {
    path: "/",
    redirect: "/activities",
  },
  ...activitiesPages,
  ...translationsPages,
  ...locationsPages,
  ...tagsPages,
  ...countriesPages,
  ...optionsPages,
  ...rulesEnginePages,
  ...toolsPages,
  ...attributeReviewQueuePages,
  // hygen_placeholder__routes
];
