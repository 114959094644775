import { ContentReviewQualityIssue } from "@server/openapi/catalog/management";
import {
  ContentReviewQualityIssue as GraphQLContentReviewQualityIssue,
  ContentReviewQualityIssueInput,
} from "@client/autogenerated/graphql/operations";

export class ContentReviewQualityIssueMapper {
  static toManagementQualityIssue(
    qualityIssue: GraphQLContentReviewQualityIssue,
  ): ContentReviewQualityIssue {
    return {
      id: Number(qualityIssue.id),
      content_review_id: qualityIssue.contentReviewId ?? undefined,
      entity: qualityIssue.entity ?? undefined,
      entity_id: qualityIssue.entityId ?? undefined,
      status: qualityIssue.status ?? undefined,
      quality_issue_type: qualityIssue.qualityIssueType ?? undefined,
      quality_issue_subtype: qualityIssue.qualityIssueSubtype ?? undefined,
      quality_issue_description: qualityIssue.qualityIssueDescription ?? undefined,
    };
  }

  static toContentReviewQualityIssueInput(
    qualityIssue: ContentReviewQualityIssue,
  ): ContentReviewQualityIssueInput {
    return {
      contentReviewId: qualityIssue.content_review_id!,
      entity: qualityIssue.entity!,
      entityId: `${qualityIssue.entity_id}`,
      qualityIssueType: qualityIssue.quality_issue_type!,
      qualityIssueSubtype: qualityIssue.quality_issue_subtype!,
      qualityIssueDescription: qualityIssue.quality_issue_description ?? null,
      status: qualityIssue.status!,
    };
  }
}
