import { gql } from "@gygadmin/client3";

const activityCommentFragment = gql`
  fragment ActivityCommentFields on ActivityComment {
    id
    tourId
    type
    comment
    status
    creationUser {
      id
      type
    }
    creationTimestamp
    updateUser {
      id
      type
    }
    updateTimestamp
  }
`;

export const CreateActivityComment = gql`
  ${activityCommentFragment}

  mutation CreateActivityComment($input: ActivityCommentCreateInput!) {
    activityCommentCreate(input: $input) {
      success
      comment {
        ...ActivityCommentFields
      }
      errors {
        message
      }
    }
  }
`;
