import {GeneratedContentUserInput, Query} from "@client/autogenerated/graphql/operations";
import {graphClient} from "@gygadmin/client3";
import {GetGeneratedContentInputOfActivity} from "@client/graphql/activities/activity-generated-content";

export class ActivityGeneratedContentService {

  /**
   * Fetch user input for AI generated activity
   *
   * @param activityId
   */
  async fetchUserInputByActivity(activityId: number): Promise<GeneratedContentUserInput> {
    return (await graphClient
      .gql()
      .query<Query>({
        query: GetGeneratedContentInputOfActivity,
        variables: {
          activityId: `${activityId}`,
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }
        return res.data.activity?.generatedContentUserInput;
      })
      .catch((_) => undefined)) as GeneratedContentUserInput;
  }
}

export default new ActivityGeneratedContentService();
