<template>
  <BaseLayout page-identifier="error-403">
    <VCard rounded="0">
      <VCardText class="text-center pt-12 pb-12">
        <VIcon size="x-large">
          mdi-lock
        </VIcon>
        <div class="block text-h4">
          Unauthorized
        </div>
        <div class="text-subtitle-1">
          Looks like you don't have access to this page.
        </div>
      </VCardText>
    </VCard>
  </BaseLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseLayout from "../../base.vue";

export default defineComponent({
  name: "Error403Page",
  components: {
    BaseLayout,
  },
});
</script>
