import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import { ActivityText, TourText } from "@root/commons/types";

export class ActivitiesTextService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Fetch all text based on the provided activity id.
   *
   * @param activityId
   */
  async fetchAllByActivity(activityId: number): Promise<ActivityText[]> {
    return this.restClient
      .get(`/activities/${activityId}/text`)
      .then((response) => response.data as ActivityText[])
      .catch((_) => []);
  }

  async fetchByTourIdAndLanguageId(tourId: number, languageId: number): Promise<TourText> {
    return this.restClient
      .get(`/activities/${tourId}/text/${languageId}`)
      .then((response) => response.data as ActivityText);
  }

  /**
   * Fetch all autotranslated text based on the provided activity id.
   *
   * @param activityId
   */
  async fetchAllAutotranslatedByActivity(activityId: number): Promise<ActivityText[]> {
    return this.restClient
      .get(`/activities/${activityId}/text/autotranslated`)
      .then((response) => response.data as ActivityText[])
      .catch((_) => []);
  }

  /**
   * Update manual translated activity text without publishing to XTM.
   *
   * @param activityId
   * @param languageId
   * @param text
   * @param translate
   */
  async update(
    activityId: number,
    languageId: number,
    text: ActivityText,
    translate = true,
  ): Promise<ActivityText> {
    return this.restClient
      .put(`/activities/${activityId}/text/${languageId}?translate=${translate}`, text)
      .then((response) => response.data as ActivityText);
  }

  /**
   * Trigger the auto-translation process in all languages for an activity
   *
   * @param activityId
   */
  async retranslate(activityId: number): Promise<void> {
    return this.restClient.post(`/activities/${activityId}/text/retranslate`);
  }
}

export default new ActivitiesTextService();
