<template>
  <slot v-if="shouldRender" />
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { User } from "../../store/modules/user/types";
export default defineComponent({
  name: "AllowRoles",
  components: {},
  props: {
    roles: {
      default: () => [],
      required: false,
      type: Array as PropType<string[]>,
    },
    privileges: {
      default: () => [],
      required: false,
      type: Array as PropType<string[]>,
    },
  },
  computed: {
    shouldRender(): boolean {
      if ((!this.roles.length && !this.privileges.length) || this.user.root) {
        return true;
      }
      const hasRole = this.roles.some((role) => this.user.roles.includes(role));
      const hasPrivilege = this.privileges.some((privilege) =>
        this.user.privileges?.includes(privilege),
      );
      return hasRole || hasPrivilege;
    },
    user(): User {
      return this.$store.getters["user/getState"];
    },
  },
});
</script>
