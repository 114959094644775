import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import { ActivityOptionText } from "@root/commons/types";

export class ActivityOptionTextService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Fetch all text based on the provided option id.
   *
   * @param optionId
   */
  async fetchAllByOption(optionId: number): Promise<ActivityOptionText[]> {
    return this.restClient
      .get(`/activities/options/${optionId}/text`)
      .then((response) => response.data as ActivityOptionText[])
      .catch((_) => []);
  }

  /**
   * Fetch all autotranslated text based on the provided option id.
   *
   * @param optionId
   */
  async fetchAllAutotranslatedByOption(optionId: number): Promise<ActivityOptionText[]> {
    return this.restClient
      .get(`/activities/options/${optionId}/text/autotranslated`)
      .then((response) => response.data as ActivityOptionText[])
      .catch((_) => []);
  }

  /**
   * Update manual translated activity option text.
   *
   * @param optionId
   * @param languageId
   * @param text
   * @param notifyCustomers
   * @param translate
   */
  async update(
    optionId: number,
    languageId: number,
    text: ActivityOptionText,
    notifyCustomers = false,
    translate = true,
  ): Promise<ActivityOptionText> {
    return this.restClient
      .put(
        `/activities/options/${optionId}/text/${languageId}?notify-customers=${notifyCustomers}&translate=${translate}`,
        text,
      )
      .then((response) => response.data as ActivityOptionText);
  }

  /**
   * Trigger the auto-translation process in all languages for an option
   *
   * @param optionId
   */
  async retranslate(optionId: number): Promise<void> {
    return this.restClient
        .post(`/activities/options/${optionId}/text/retranslate`);
  }
}

export default new ActivityOptionTextService();
