import { PrivilegeService } from "@root/commons/privileges/service";
import { Privilege } from "@root/commons/privileges/privileges";
export { Privilege } from "./privileges";
export { RoleToPrivilegeMapping } from "./mappings";
export { PrivilegeService } from "./service";

const privilegeService = new PrivilegeService();
export const AttributeReviewQueueRoles = [
  ...privilegeService.getRolesOfPrivilege(Privilege.PrioritizedReviewQueue),
];
