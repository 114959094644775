import { gql } from "@gygadmin/client3";

export const CommentsByEntity = gql`
  query CommentsByEntity($entity: String!, $entityId: Int!) {
    commentsByEntity(entity: $entity, entityId: $entityId) {
      comment
      creationTime
    }
  }
`;
