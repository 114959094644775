import { gql } from "@gygadmin/client3";

export const Tags = gql`
  query Tags($ids: [ID!]!) {
    tags(ids: $ids) {
      id
      name
      internalName
      type
      status
      hideFromTagSelector
      rejectionReason
      description
      updateTimestamp
    }
  }
`;

export const UpdateTags = gql`
  mutation UpdateTags($input: [UpdateTagInput!]!) {
    updateTags(input: $input) {
      tags {
        id
        name
        internalName
        parent {
          id
          name
          internalName
          status
          type
          hideFromTagSelector
          updateTimestamp
        }
        type
        status
        children {
          id
          name
          hideFromTagSelector
          status
          type
          updateTimestamp
        }
        hideFromTagSelector
        rejectionReason
        description
        updateTimestamp
        translations {
          name
          languageId
          updateTimestamp
        }
      }
    }
  }
`;

export const CreateTag = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      tag {
        id
        name
        internalName
        parent {
          id
          name
          internalName
          status
          type
          hideFromTagSelector
          updateTimestamp
        }
        type
        status
        children {
          id
          name
          hideFromTagSelector
          status
          type
          updateTimestamp
        }
        hideFromTagSelector
        rejectionReason
        description
        updateTimestamp
        translations {
          name
          languageId
          updateTimestamp
        }
      }
    }
  }
`;

export const Tag = gql`
  query Tag($id: ID!) {
    tag(id: $id) {
      id
      name
      internalName
      parent {
        id
        name
        internalName
        status
        type
        hideFromTagSelector
        updateTimestamp
      }
      type
      status
      children {
        id
        name
        hideFromTagSelector
        status
        type
        updateTimestamp
      }
      hideFromTagSelector
      rejectionReason
      description
      updateTimestamp
      translations {
        name
        languageId
        updateTimestamp
      }
    }
  }
`;

export const TagSearchNameIncludes = gql`
  query TagsSearchNameIncludes($input: SearchInput!) {
    tagSearch(input: $input) {
      items {
        id
        name
        internalName
        hideFromTagSelector
        status
        type
        updateTimestamp
      }
      pagination {
        hasNextPage
        hasPreviousPage
        totalItems
        offset
        limit
      }
    }
  }
`;

export const TagDatatable = gql`
  query TagDatatable($input: SearchInput!) {
    tagSearch(input: $input) {
      items {
        id
        name
        internalName
        hideFromTagSelector
        status
        tours
        parent {
          id
          name
        }
        type
        updateTimestamp
      }
      pagination {
        hasNextPage
        hasPreviousPage
        totalItems
        offset
        limit
      }
    }
  }
`;
