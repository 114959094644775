import { datadogRum } from "@datadog/browser-rum";
import graphClient, { InMemoryCache, IRequestHeaders } from "@getyourguide/graphql-client-js";
import { createApolloProvider } from "@vue/apollo-option";
import { AuthService, FirebaseUser, initializeAuthentication } from "@gygadmin/auth/client";
import { Dedupe as DedupeIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/vue";
import { App, createApp } from "vue";
import "vuetify/styles";
import { createVuetify } from "vuetify/lib/framework.mjs";
import { Store } from "vuex";
import { createPinia, Pinia } from "pinia";
import { i18n, i18NextVue, refresh, englishLocale, applyMessages } from "./commons/translations";
import { NavigationItem } from "./components/types";
import {
  appVersion,
  environment,
  firebaseConfig,
  graphUrl,
  sentry,
  serviceName,
  datadogClientToken,
  appletsUrl,
} from "./configs/app";
import { mockedPrivileges } from "./mocks/privileges";
import AppContainer from "./components/AppContainer.vue";
import { initialState as initialUserState } from "./store/modules/user/state";
import { User, UserIsAllowedInput } from "./store/modules/user/types";
import { AppState } from "./store/types";
import { IAppConfig } from "./types/config";
import { vuetifyConfig } from "./commons/vuetifyConfig";
import { initializeStore } from "./store";
import { initializeRouter } from "./router/router";
import { Router } from "vue-router";
import sanitize from "../../plugins/sanitize";
import { AppLink } from "./components/theme/AppsMenu.types";

class GygadminApp {
  app: App;
  appConfig!: IAppConfig;
  store!: Store<AppState>;
  pinia!: Pinia;
  router!: Router;
  i18n: typeof i18n;
  i18nPlugin: typeof i18NextVue;
  vuetify!: ReturnType<typeof createVuetify>;

  constructor() {
    this.app = createApp(AppContainer);
    this.i18n = i18n;
    this.i18nPlugin = i18NextVue;
  }

  async initialize(appConfig: IAppConfig): Promise<GygadminApp> {
    this.appConfig = appConfig;
    this.initSentry();
    this.initDatadog();
    this.initUIComponents();
    this.initStore();
    this.initPinia();
    this.initTranslations();
    this.initGQLClient();
    await this.warmup();
    this.initRouter();
    this.initPlugins()
    return this;
  }

  private initDatadog(): void {
    if (environment !== "production") {
      return;
    }
    datadogRum.init({
      applicationId: "4dfcace0-bbc6-4958-a302-62a63f7dffc4",
      clientToken: datadogClientToken,
      site: "datadoghq.com",
      env: environment,
      service: serviceName ?? "gygadmin",
      version: appVersion,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackSessionAcrossSubdomains: true,
      trackViewsManually: true,
      allowedTracingUrls: [graphUrl],
    });
  }

  getNavigation(): NavigationItem[] {
    return this.appConfig?.appNavigation?.getItems() ?? [];
  }

  private initGQLClient(): void {
    graphClient.init({
      httpLink: {
        graphURL: graphUrl,
      },
      clientOptions: {
        cache: new InMemoryCache(),
        name: serviceName,
        version: appVersion,
      },
      requestContextHeaders: async (): Promise<IRequestHeaders> => {
        const headers: IRequestHeaders = {};
        const currentUser = AuthService.getAuth().currentUser;
        if (currentUser) {
          const idToken = await currentUser
            .getIdToken()
            .then((idToken) => idToken)
            .catch(() => false);

          if (idToken) {
            headers.authorization = `Bearer ${idToken}`;
          }
        }
        return Promise.resolve(headers);
      },
    });
    this.app.use(
      createApolloProvider({
        defaultClient: graphClient.gql(),
      }),
    );
  }

  private initRouter(): void {
    this.router = initializeRouter(this.store, this.appConfig.routes);
    this.app.use(this.router);
  }

  initPlugins(): void {
    this.app.use(sanitize);
  }

  useRouter() {
    return this.router;
  }

  useRoute() {
    return this.router?.currentRoute.value;
  }

  useI18n() {
    return {
      t: i18n.t.bind(i18n),
    };
  }

  useStore() {
    return this.store;
  }

  private initSentry(): void {
    Sentry.init({
      app: this.app,
      dsn: sentry?.dsn,
      release: sentry?.release,
      environment: sentry?.environment,
      attachProps: true,
      integrations: [new DedupeIntegration()],
    });
  }

  private initUIComponents(): void {
    const vuetify = createVuetify(vuetifyConfig);
    this.app.use(vuetify);
    this.vuetify = vuetify;
  }

  private initStore(): void {
    const store = initializeStore(this.appConfig.storeModules);
    this.app.use(store);
    this.store = store;
  }

  private initPinia(): void {
    this.pinia = createPinia();
    this.app.use(this.pinia);
  }

  private initTranslations(): void {
    applyMessages(englishLocale, this.appConfig?.localizations ?? {});
    this.app.use(i18NextVue, { i18next: i18n });
  }

  showSplashScreen(show: boolean): void {
    const splash = document.querySelector(".gygadmin-splash-screen") as any;
    splash.style.display = show ? "block" : "none";
  }

  isInUIDevelopmentMode(): boolean {
    return this.appConfig?.developmentModeUI ?? false;
  }

  auth() {
    return {
      isAllowed: (input: UserIsAllowedInput): boolean => {
        if (firebaseConfig.mockUser || environment === "development") {
          return true;
        }

        return this.store.getters["user/isAllowed"](input);
      },
      user: (): User => {
        return this.store.getters["user/getState"];
      },
    };
  }

  private async warmup(): Promise<void> {
    if (this.isInUIDevelopmentMode()) {
      return;
    }
    await initializeAuthentication({
      configs: firebaseConfig,
      loadingScreen: (show: boolean) => {
        this.showSplashScreen(show);
      },
      onUpdateUser: async (user: FirebaseUser): Promise<void> => {
        let userData = initialUserState;
        if (user !== null) {
          userData = {
            uid: user.claims.uid,
            staffId: user.claims.staff_id,
            name: user.claims.name,
            email: user.claims.email,
            picture: user.claims.picture,
            roles: [...user.claims.roles],
          };
        }
        this.store.dispatch("user/update", userData);
        if (firebaseConfig.mockUser) {
          this.store.dispatch("user/updateLevel", true);
          this.store.commit("user/SET_USER_PRIVILEGES", mockedPrivileges);
          return;
        }
        if (user) {
          datadogRum.setUser({
            id: user.claims.staff_id?.toString(),
          });
          await this.store.dispatch("user/fetchPrivileges");
        }
      },
    });

    await refresh(englishLocale);
    if (this.appConfig?.afterWarmup) {
      await this.appConfig.afterWarmup();
    }
  }

  async mount(): Promise<void> {
    this.showSplashScreen(false);
    this.app.mount("#app");
  }

  getPlugins() {
    return [this.store, this.pinia, this.vuetify, this.router];
  }

  switchLinks(component: { $t: (arg0: string) => any; }): AppLink[] {
    return [
      { url: `${appletsUrl.tools}/portal`, name: component.$t("gygAdmin apps applet home"), description: "Home"},
      { url: appletsUrl.catalog, name: component.$t("gygAdmin apps applet catalog"), description: "Activities catalog" },
      { url: appletsUrl.communications, name: "Communications", description: "Emails" },
      { url: appletsUrl.contentQuality, name: component.$t("gygAdmin apps applet contentQuality"), description: "Content quality cockpit" },
      { url: appletsUrl.customer, name: "Customer", description: "Bookings, coupons, reviews management" },
      { url: appletsUrl.experimentation, name: component.$t("gygAdmin apps applet experimentation"), description: "Experimentation" },
      { url: appletsUrl.finance, name: component.$t("gygAdmin_apps_applet_finance"), description: "Finance tools" },
      { url: appletsUrl.gettext, name: "Gettext", description: "Translation tools" },
      { url: appletsUrl.inventory, name: component.$t("gygAdmin apps applet inventory"), description: "Inventory management" },
      { url: appletsUrl.supplier, name: "Supplier", description: "Supplier management" },
      { url: appletsUrl.tools, name: "Tools", description: "Miscellaneous tools" },
    ];
  }
}

const gygadminApp = new GygadminApp();
export const useApp = () => gygadminApp.app;
export default gygadminApp;
