import { AppState } from "@gygadmin/client3";
import { GetterTree } from "vuex";
import { RulesEngineDerivedAttributesState, RulesEngineDerivedAttributesPage } from "./types";

export const getters: GetterTree<RulesEngineDerivedAttributesState, AppState> = {
  getState(state: RulesEngineDerivedAttributesState): RulesEngineDerivedAttributesState {
    return state;
  },
  getPage(state: RulesEngineDerivedAttributesState): RulesEngineDerivedAttributesPage {
    return state.page;
  },
  getPreviousState(state: RulesEngineDerivedAttributesState): string {
    return state.page.previousState;
  },
};

export default getters;
