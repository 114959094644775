import { AppState } from "@gygadmin/client3";
import { GetterTree } from "vuex";
import { RulesEngineQualityIssuesState, RulesEngineQualityIssuesPage } from "./types";

export const getters: GetterTree<RulesEngineQualityIssuesState, AppState> = {
  getState(state: RulesEngineQualityIssuesState): RulesEngineQualityIssuesState {
    return state;
  },
  getPage(state: RulesEngineQualityIssuesState): RulesEngineQualityIssuesPage {
    return state.page;
  },
  getPreviousState(state: RulesEngineQualityIssuesState): string {
    return state.page.previousState;
  },
};

export default getters;
