import { VNode } from "vue";

export function findComponentOfType(slots: Array<VNode> | undefined, type: string): VNode | null {
  for (const slot of slots ?? []) {
    if ((slot.type as any)?.name === type) {
      return slot;
    }
  }
  return null;
}

export function isEmpty(str: string | null | undefined): boolean {
  return str == null || str.trim() === "";
}

type AssertPresent = (value: unknown, message?: string) => asserts value;

export const assertPresent: AssertPresent = function <T>(
  value: T,
  message = "Value not specified",
): asserts value {
  if (value == null) throw new Error(message);
};

export const fileInputToBase64 = (file: File): Promise<string | null | ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const Form = {
  rules: {
    required: (v: any) => {
      if (v == null || v === "") {
        return "Required field";
      }

      return true;
    },
    datetime: (v: any) => {
      if (v == null || v === "") {
        return true;
      }

      const parsedDate = Date.parse(v);
      const isParsedDateValid = !isNaN(parsedDate);

      if (!isParsedDateValid) {
        return "Enter yyyy-mm-dd hh:mm:ss format";
      }

      return true;
    },
    price: (v: any) => {
      if (v == null || v === "") {
        return true;
      }

      if (Number.isNaN(Number(v)) || Number(v) <= 0) {
        return "Enter positive number";
      }
      return true;
    },
    priceFromZero: (v: any) => {
      if (v == null || v === "") {
        return true;
      }

      if (Number.isNaN(Number(v)) || Number(v) < 0) {
        return "Enter positive number";
      }
      return true;
    },
    date: (v: string) => {
      if (v == null || v === "") {
        return true;
      }
      const parsedDate = Date.parse(v);
      const isParsedDateValid = !isNaN(parsedDate);

      if (!isParsedDateValid) {
        return "Enter yyyy-mm-dd format";
      }

      return true;
    },
    time: (v: string) => {
      if (v == null || v === "") {
        return true;
      }
      const re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      return re.test(v) || "Enter hh:mm format";
    },

    idList: (v: string) => {
      if (v == null || v === "") {
        return true;
      }
      const re = /^[0-9]+(,[0-9]+)*$/;
      return re.test(v) || "Enter numbers and commas with no spaces";
    },
    id: (v: string) => {
      if (v == null || v === "") {
        return true;
      }
      const re = /^\d+$/;
      return re.test(v) || "Enter ID number";
    },
  },
};
