import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import { AggregatedTag, Tag, TagParams, TagQueryParam, TagType } from "@root/commons/types";
import { graphClient } from "@gygadmin/client3";
import { TagsMapper } from "@client/graphql/tags/tagsMapper";
import {
  CreateTag,
  Tag as GraphqlTag,
  TagSearchNameIncludes,
  UpdateTags,
} from "@client/graphql/tags/tags";
import {
  TagQuery,
  Tag as GraphQLTag,
  UpdateTagsMutationVariables,
  UpdateTagsMutation,
  CreateTagMutation,
  CreateTagMutationVariables,
  TagsSearchNameIncludesQuery,
  TagsSearchNameIncludesQueryVariables,
  SortDirection,
} from "@client/autogenerated/graphql/operations";

class TagsService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  async get(id: number): Promise<AggregatedTag | null> {
    const tag = (await graphClient
      .gql()
      .query<TagQuery>({
        query: GraphqlTag,
        variables: {
          id: `${id}`,
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }

        return res.data.tag;
      })) as GraphQLTag;

    if (tag === null || tag === undefined) {
      return null;
    }

    return TagsMapper.toAggregatorTag(tag);
  }

  async getByTypeAndNameContains(
    type: TagType,
    name: string,
    activeOnly?: boolean,
  ): Promise<Tag[]> {
    return this.restClient
      .get(`/tags/by-type/${type}/by-name/${name}${activeOnly ? `?activeOnly=${activeOnly}` : ""}`)
      .then((response) => response.data as Tag[])
      .catch(() => []);
  }

  async getAllByParams(params: TagParams): Promise<Tag[]> {
    return this.restClient
      .get(`/tags`, {
        params: {
          [TagQueryParam.nameContains]: params.name,
          [TagQueryParam.isParent]: params.isParent,
          [TagQueryParam.types]: params.types !== undefined ? params.types.join(",") : undefined,
        },
      })
      .then((response) => response.data as Tag[])
      .catch(() => []);
  }

  async getByNameContainsAndTypeIn(name: string, types: TagType[]): Promise<Tag[]> {
    return (await graphClient
      .gql()
      .query<TagsSearchNameIncludesQuery, TagsSearchNameIncludesQueryVariables>({
        query: TagSearchNameIncludes,
        variables: {
          input: {
            offset: 0,
            limit: -1,
            filters: [
              {
                field: "name_includes",
                values: [name],
              },
              {
                field: "type",
                values: types,
              },
            ],
            sorts: [
              {
                field: "status",
                direction: SortDirection.Asc,
              },
              {
                field: "name",
                direction: SortDirection.Desc,
              },
            ],
          },
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }

        return res?.data?.tagSearch?.items?.map(TagsMapper.toAggregatorTag);
      })) as Tag[];
  }

  async update(tagId: number, tag: AggregatedTag): Promise<AggregatedTag> {
    const updatedTagInput = [TagsMapper.toGraphQLUpdateInput({ ...tag, id: tagId })];
    const response = (await graphClient
      .gql()
      .mutate<UpdateTagsMutation, UpdateTagsMutationVariables>({
        mutation: UpdateTags,
        variables: {
          input: updatedTagInput,
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }

        return res?.data?.updateTags?.tags;
      })) as [GraphQLTag];

    return TagsMapper.toAggregatorTag(response[0]);
  }

  async create(tag: AggregatedTag): Promise<AggregatedTag> {
    const response = (await graphClient
      .gql()
      .mutate<CreateTagMutation, CreateTagMutationVariables>({
        mutation: CreateTag,
        variables: {
          input: {
            type: tag.type!,
            name: tag.name!,
          },
        },
      })
      .then((res) => {
        if (res.errors) {
          throw res.errors;
        }

        return res?.data?.createTag?.tag;
      })) as GraphQLTag;

    return TagsMapper.toAggregatorTag(response);
  }
}

export default new TagsService();
