export enum Privilege {
  AdminTourEdit = "admin_tour_edit",
  AdminTourEditPricing = "admin_tour_edit_pricing",
  AdminTourEditLocations = "admin_tour_edit_locations",
  AdminCategoryInformation = "admin_category_information",
  AdminContentManage = "admin_content_manage",
  AdminBookingEngineSetLonger = "admin_booking_engine_set_longer",
  AdminContentChangeRequestsEdit = "content_change_requests_edit",
  AdminImpersonateSupplier = "admin_impersonate_supplier",
  AdminTourManagePictures = "admin_tour_manage_pictures",
  AdminTourManageGygPictures = "admin_tour_manage_gyg_pictures",
  AdminTourManagePicturesVerticalAlign = "admin_tour_manage_pictures_valign",
  AdminDeletePictureFromCDN = "admin_delete_picture_from_cdn",
  AdminTourActivateDeactivate = "admin_tour_activate_deactivate",
  AdminTourOptionActivateDeactivate = "admin_tour_option_activate_deactivate",
  AdminTourDelete = "admin_tour_delete",
  AdminTourToggleGygOriginals = "admin_tour_toggle_gyg_originals",
  AdminTourToggleEcoCertified = "admin_tour_toggle_eco_certified",
  AdminTourEditInternalKeywords = "admin_tour_edit_internal_keywords",
  AdminTourChangeLocationRelevancyScore = "admin_tour_change_location_relevancy_score",
  AdminTourChangeLocation = "admin_tour_change_location",
  AdminTourChangeLocationBeforeOnline = "admin_tour_change_location_before_online",
  AdminTourChangeStatus = "admin_tour_change_status",
  AdminTourActivateQualityFailStatus = "admin_tour_activate_quality_fail_status",
  AdminTourEditAuthorisedBadge = "admin_tour_edit_authorised_badge",
  AdminTourEditCommissionRate = "admin_tour_edit_commission_rate",
  AdminTourEditEnforce3dSecure = "admin_tour_edit_enforce_3d_secure",
  AdminTourEditItinerary = "admin_tour_edit_itinerary",
  AdminTourEditPriority = "admin_tour_edit_priority",
  AdminTourEditProductType = "admin_tour_edit_product_type",
  AdminTourEditSupplierOnlineVoucher = "admin_tour_edit_supplier_online_voucher",
  AdminTourEditSupplierFixedQuestions = "admin_tour_edit_supplier_fixed_questions",
  AdminTourEditTourClass = "admin_tour_edit_tour_class",
  AdminTourEditActiveTourText = "admin_tour_edit_active_tour_text",
  AdminTourEditStrategicRelationships = "admin_tour_edit_strategic_relationships",
  AdminTourReject = "admin_tour_reject",
  AdminTourShowFullCancellationPolicy = "admin_tour_show_full_cancellation_policy",
  AdminTourEditAvailabilityConstraint = "admin_tour_edit_availability_constraint",
  AdminTourEditLocationBoost = "admin_tour_edit_location_boost",
  AdminTourEditLocationRevenueRelevant = "admin_tour_edit_location_revenue_relevant",
  AdminGygLocation = "admin_gyg_location",
  AdminCreateExclusiveTourOption = "admin_create_exclusive_tour_option",
  AdminManageToursTickets = "admin_manage_tours_and_tickets",
  AdminTourEditCancellationPolicy = "admin_tour_edit_cancellation_policy",
  AdminRetranslateText = "admin_retranslate_text",
  BulkEdit = "bulk_edit",
  History = "history",
  PrioritizedReviewQueue = "prioritized_review_queue",
  UpdateReview = "review_update",
  UpdateReviewBatch = "review_update_batch",
  // privileges available in the graph
  DerivedAttributeConfigurationRead = "read:derived-attribute-configuration",
  DerivedAttributeConfigurationWrite = "write:derived-attribute-configuration",
  QualityIssueConfigurationRead = "read:quality-issue-configuration",
  QualityIssueConfigurationWrite = "write:quality-issue-configuration",
  RestrictionConfigurationRead = "read:restriction-configuration",
  RestrictionConfigurationWrite = "write:restriction-configuration",
}
