<template>
  <div>
    <AllowRoles
      v-for="(item, i) in items"
      :key="`${item.title}-${i}`"
      :roles="item.authorize"
      :privileges="item.privileges"
    >
      <VList
        :key="`${item.title}-${i}`"
        class="pa-0"
      >
        <VListItem
          v-if="!isGroup(item)"
          :href="item.href"
          :to="item.to"
          :exact="isExact(`${item.to}`)"
          color="primary"
          :title="item.title"
          @click="item.onClick ? item.onClick() : () => {}"
        >
          <template
            v-if="level === 0"
            #prepend
          >
            <VIcon>{{ item.icon }}</VIcon>
          </template>
        </VListItem>
        <VListGroup
          v-else
          :value="item.active"
          :prepend-icon="item.icon"
          color="primary"
        >
          <template #activator="{ props }">
            <VListItem
              v-bind="props"
              :title="item.title"
            />
          </template>
          <NavigationItems
            :items="item.items"
            :level="nextLevel"
          />
        </VListGroup>
      </VList>
    </AllowRoles>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { NavigationItem } from "./types";
import AllowRoles from "../allow-roles/index.vue";

export default defineComponent({
  name: "NavigationItems",
  components: { AllowRoles },
  props: {
    level: {
      type: Number,
      default: 0,
    },
    userRoles: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    items: {
      type: Array as PropType<NavigationItem[]>,
      default: () => [],
    },
  },
  data() {
    return {
      separator: "/",
    };
  },
  computed: {
    nextLevel(): number {
      return this.level + 1;
    },
  },
  methods: {
    isGroup(item: NavigationItem): boolean {
      return item.items !== undefined && item.items.length > 0;
    },
    isExact(to: string): boolean {
      if (to === undefined || to === "") {
        return false;
      }
      const parts = to.split(this.separator).filter((part) => part !== "");
      return parts.length === 1 && this.level !== 0;
    },
  },
});
</script>
