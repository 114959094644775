import { GetCurrentStaffPrivilegesQuery } from "../../../__generated__/graphql/operations";
import graphClient from "@getyourguide/graphql-client-js";
import { gql } from "graphql-tag";
import { ActionContext, ActionTree } from "vuex";
import { AppState } from "../../types";
import { User } from "./types";
import { logger } from "../../../main";

const GqlStaffPrivilegesQuery = gql`
  query GetCurrentStaffPrivileges {
    currentStaff {
      privileges
    }
  }
`;

export const actions: ActionTree<User, AppState> = {
  updateLevel(context: ActionContext<User, AppState>, root: boolean): void {
    context.commit("SET_USER_LEVEL", root);
  },
  async update(context: ActionContext<User, AppState>, user: User): Promise<void> {
    context.commit("SET_USER", user);
  },
  async fetchPrivileges(context: ActionContext<User, AppState>): Promise<void> {
    await graphClient
      .gql()
      .query<GetCurrentStaffPrivilegesQuery>({
        query: GqlStaffPrivilegesQuery,
      })
      .then((res) => {
        const privileges = res.data.currentStaff?.privileges ?? [];
        context.commit("SET_USER_PRIVILEGES", privileges);
      })
      .catch((err) => logger.error(err));
  },
};

export default actions;
