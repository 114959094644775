import { AppState } from "@gygadmin/client3";
import {
  ActivityChangeRequestsTab,
  ActivityCommentsTab,
  ActivityOptionsTab,
  ActivityOptionTextTab,
  ActivityPage,
  ActivityPageAlert,
  ActivityPicturesTab,
  ActivitySandboxFieldsTab,
  ActivityState,
  ActivitySupplierActivitiesTab,
  ActivityTextTab,
  ActivityOptionSandboxFieldsTab,
  ActivityAiInputTab,
} from "./types";
import { GetterTree } from "vuex";
import {
  Activity,
  ActivityChangeRequest,
  ActivityOption,
  ActivityOptionText,
  ActivityText,
} from "@root/commons/types";
import {
  ConnectivitySettings,
  ConnectivitySourceConfig,
} from "@client/autogenerated/graphql/operations";
import { supplierPage } from "@gygadmin/client3/src/utils/gygadmin-redirects";
import { Language } from "@root/commons/static/languages";
import { supplierPortalUrl } from "@gygadmin/client3/src/configs/app";

export const getters: GetterTree<ActivityState, AppState> = {
  getState(state: ActivityState): ActivityState {
    return state;
  },
  getActivityPageAlert(state: ActivityState): ActivityPageAlert {
    return state.page.alert;
  },
  getActivity(state: ActivityState): Activity | null {
    if (state.page.activity !== undefined) {
      return state.page.activity;
    }
    return null;
  },
  getActivityPage(state: ActivityState): ActivityPage {
    return state.page;
  },
  getActivitySupplierUrl(state: ActivityState): string {
    if (state.page.activity === undefined || !state.page.activity.supplier_id) {
      return "";
    }
    return supplierPage(state.page.activity.supplier_id.toString());
  },
  getActivityCommentsCount(state: ActivityState): number | undefined {
    return state.page?.activityCommentsCount;
  },
  getActivityTextTab(state: ActivityState): ActivityTextTab {
    return state.page.tabs.activityText;
  },
  getActivityLanguageId(state: ActivityState): number | undefined {
    return state.page.activity?.source_text?.language_id;
  },
  getActivityOptionTextTab(state: ActivityState): ActivityOptionTextTab {
    return state.page.tabs.activityOptionText;
  },
  getActivityOptionsTab(state: ActivityState): ActivityOptionsTab {
    return state.page.tabs.activityOptions;
  },
  getActivityOptionSandboxFieldsTab(state: ActivityState): ActivityOptionSandboxFieldsTab {
    return state.page.tabs.activityOptionSandboxFields;
  },
  getSupplierActivitiesTab(state: ActivityState): ActivitySupplierActivitiesTab {
    return state.page.tabs.supplierActivities;
  },
  getActivityCommentsTab(state: ActivityState): ActivityCommentsTab {
    return state.page.tabs.activityComments;
  },
  getActivityPicturesTab(state: ActivityState): ActivityPicturesTab {
    return state.page.tabs.activityPictures;
  },
  getActivitySandboxFieldsTab(state: ActivityState): ActivitySandboxFieldsTab {
    return state.page.tabs.activitySandboxFields;
  },
  getActivityChangeRequestsTab(state: ActivityState): ActivityChangeRequestsTab {
    return state.page.tabs.activityChangeRequests;
  },
  getActivityAiInputTab(state: ActivityState): ActivityAiInputTab {
    return state.page.tabs.activityAiInput;
  },
  getOptions(state: ActivityState): ActivityOption[] {
    return state.page.tabs.activityOptions.options;
  },
  getOption(state: ActivityState): ActivityOption | null {
    const option = state.page.tabs.activityOptions.detail.option;
    return option === undefined ? null : option;
  },
  getConnectivitySettings(state: ActivityState): ConnectivitySettings | null {
    const connectivitySettings = state.page.tabs.activityOptions.detail.connectivitySettings;
    return connectivitySettings === undefined ? null : connectivitySettings;
  },
  getConnectivitySourceConfigurations(
    state: ActivityState,
  ): Array<ConnectivitySourceConfig> | null {
    const connectivitySourceConfigurations =
      state.page.tabs.activityOptions.detail.connectivitySourceConfigurations;
    return connectivitySourceConfigurations === undefined ? null : connectivitySourceConfigurations;
  },
  getFieldIsChecked:
    (state: ActivityState) =>
    (fieldKey: string): boolean => {
      if (state.page.activity === undefined) {
        return false;
      }
      for (const field of state.page.activity.field_checks || []) {
        if (
          field.type === "tour" &&
          field.type_id === `${state.page.activity.tour_id}` &&
          field.key === fieldKey
        ) {
          return field.checked;
        }
      }
      return false;
    },
  getActivityText:
    (state: ActivityState) =>
    (languageId: number): ActivityText => {
      for (const activityText of state.page.tabs.activityText.text || []) {
        if (activityText.language_id === languageId) {
          return activityText;
        }
      }
      return {};
    },
  getDefaultLanguageTourTextId: (state: ActivityState): string | null => {
    for (const activityText of state.page.tabs.activityText.text || []) {
      if (activityText.language_id === Language.english.id) {
        return activityText.id === undefined ? null : activityText.id.toString();
      }
    }

    return null;
  },
  getDefaultLanguageTourOptionTextId: (state: ActivityState): string | null => {
    for (const activityOptionText of state.page.tabs.activityOptionText.text || []) {
      if (activityOptionText.language_id === Language.english.id) {
        return activityOptionText.id === undefined ? null : activityOptionText.id.toString();
      }
    }

    return null;
  },
  getActivityTextAuto:
    (state: ActivityState) =>
    (languageId: number): ActivityText => {
      for (const activityText of state.page.tabs.activityText.autotranslated || []) {
        if (activityText.language_id === languageId) {
          return activityText;
        }
      }
      return {};
    },
  getActivityOptionText:
    (state: ActivityState) =>
    (languageId: number): ActivityOptionText => {
      for (const activityOptionText of state.page.tabs.activityOptionText.text || []) {
        if (activityOptionText.language_id === languageId) {
          return activityOptionText;
        }
      }
      return {};
    },
  getActivityOptionTextAuto:
    (state: ActivityState) =>
    (languageId: number): ActivityOptionText => {
      for (const activityOptionText of state.page.tabs.activityOptionText.autotranslated || []) {
        if (activityOptionText.language_id === languageId) {
          return activityOptionText;
        }
      }
      return {};
    },
  getActivityChangeRequest:
    (state: ActivityState) =>
    (id: number): ActivityChangeRequest => {
      for (const activityChangeRequest of state.page.tabs.activityChangeRequests.changeRequests) {
        if (activityChangeRequest.id === id) {
          return activityChangeRequest;
        }
      }
      return {};
    },
  getActivityOption:
    (state: ActivityState) =>
    (id: number): ActivityOption => {
      for (const activityOption of state.page.tabs.activityOptions.options) {
        if (activityOption.tour_option_id === id) {
          return activityOption;
        }
      }
      return {};
    },
  getSupplierId: (state: ActivityState): number | undefined => {
    return state.page.activity?.supplier_id;
  },
  getActivityOptionPricingPage: (state: ActivityState): ActivityOptionsTab["pricing"] =>
    state.page.tabs.activityOptions.pricing,

  getUsesGroupPricing: (state: ActivityState): boolean =>
    state.page.tabs.activityOptions.pricing.inventoryConfiguration?.usesGroupPricing || false,

  getSupplierPortalAvailabilityPricingUrl: (state: ActivityState): string => {
    const tourId = state.page.activity?.tour_id;
    const optionId = state.page.tabs.activityOptions.detail.option?.tour_option_id;

    return `${supplierPortalUrl}/products/create/#/availability-and-pricing?tourId=${tourId}&optionId=${optionId}`;
  }
};

export default getters;
