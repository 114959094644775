import { Module } from "vuex";
import { AppState } from "@gygadmin/client3";
import { initialState } from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import { ToolsState } from "./types";

const toolsModule: Module<ToolsState, AppState> = {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

export default toolsModule;
