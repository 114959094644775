/* tslint:disable */
/* eslint-disable */
/**
 * Database historization API
 * Retrieve historized records from monitored databases
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: di@getyourguide.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ClusterAndDatabases
 */
export interface ClusterAndDatabases {
    /**
     * 
     * @type {string}
     * @memberof ClusterAndDatabases
     */
    cluster_name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClusterAndDatabases
     */
    database_names?: Array<string>;
}
/**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    fieldName?: string;
    /**
     * 
     * @type {FieldType}
     * @memberof Field
     */
    fieldType?: FieldType;
    /**
     * 
     * @type {boolean}
     * @memberof Field
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Field
     */
    primary?: boolean;
    /**
     * 
     * @type {object}
     * @memberof Field
     */
    fieldValue?: object;
}
/**
 * 
 * @export
 * @interface FieldMeta
 */
export interface FieldMeta {
    /**
     * 
     * @type {string}
     * @memberof FieldMeta
     */
    fieldName?: string;
    /**
     * 
     * @type {FieldType}
     * @memberof FieldMeta
     */
    fieldType?: FieldType;
    /**
     * 
     * @type {boolean}
     * @memberof FieldMeta
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldMeta
     */
    primary?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldType {
    Datetime = 'DATETIME',
    Integer = 'INTEGER',
    String = 'STRING',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface Record
 */
export interface Record {
    /**
     * 
     * @type {StorageType & string}
     * @memberof Record
     */
    storage_type?: StorageType & string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    op?: RecordOpEnum;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    ts?: string;
    /**
     * Value of the record before the change
     * @type {object}
     * @memberof Record
     */
    before?: object;
    /**
     * Value of the record after the change
     * @type {object}
     * @memberof Record
     */
    after?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum RecordOpEnum {
    C = 'c',
    D = 'd',
    U = 'u'
}

/**
 * 
 * @export
 * @interface ResponseDatabases
 */
export interface ResponseDatabases {
    /**
     * 
     * @type {Array<ClusterAndDatabases>}
     * @memberof ResponseDatabases
     */
    databases?: Array<ClusterAndDatabases>;
}
/**
 * 
 * @export
 * @interface ResponseRecords
 */
export interface ResponseRecords {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ResponseRecords
     */
    nb_records?: { [key: string]: number; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseRecords
     */
    fileloc?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ResponseRecords
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseRecords
     */
    next?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseRecords
     */
    from_date?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseRecords
     */
    to_date?: string;
    /**
     * 
     * @type {Array<Field>}
     * @memberof ResponseRecords
     */
    primary_keys?: Array<Field>;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseRecords
     */
    soft_delete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseRecords
     */
    soft_delete_field?: string;
    /**
     * 
     * @type {Array<Record>}
     * @memberof ResponseRecords
     */
    records?: Array<Record>;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseRecords
     */
    has_next?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseRecords
     */
    is_error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseRecords
     */
    error_message?: string;
}
/**
 * 
 * @export
 * @interface ResponseTableDetails
 */
export interface ResponseTableDetails {
    /**
     * 
     * @type {boolean}
     * @memberof ResponseTableDetails
     */
    is_error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseTableDetails
     */
    error_message?: string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ResponseTableDetails
     */
    nb_records?: { [key: string]: number; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseTableDetails
     */
    fileloc?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResponseTableDetails
     */
    name?: string;
    /**
     * The schema name for Postgres or the fix value \"default\" for MySQL
     * @type {string}
     * @memberof ResponseTableDetails
     */
    namespace?: string;
    /**
     * 
     * @type {Array<FieldMeta>}
     * @memberof ResponseTableDetails
     */
    primary_keys?: Array<FieldMeta>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseTableDetails
     */
    fields?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResponseTableDetails
     */
    date_from?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTableDetails
     */
    date_to?: string;
    /**
     * Indicates if the data is in cache or in S3
     * @type {string}
     * @memberof ResponseTableDetails
     */
    storage_type?: string;
}
/**
 * 
 * @export
 * @interface ResponseTables
 */
export interface ResponseTables {
    /**
     * 
     * @type {string}
     * @memberof ResponseTables
     */
    cluster?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTables
     */
    database?: string;
    /**
     * 
     * @type {Array<Table>}
     * @memberof ResponseTables
     */
    tables?: Array<Table>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StorageType {
    Cold = 'COLD',
    Hot = 'HOT'
}

/**
 * 
 * @export
 * @interface Table
 */
export interface Table {
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    tableName?: string;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    namespace?: string;
}

/**
 * DatabaseResourceApi - axios parameter creator
 * @export
 */
export const DatabaseResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get changed records for a specific table in a database (cluster)
         * @summary Get changed records
         * @param {string} clusterName 
         * @param {string} databaseName 
         * @param {string} tableName 
         * @param {string} [fromDate] 
         * @param {number} [id] 
         * @param {number} [limit] 
         * @param {string} [namespace] 
         * @param {number} [next] 
         * @param {boolean} [softDelete] 
         * @param {string} [softDeleteField] 
         * @param {string} [toDate] 
         * @param {boolean} [verbose] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordsByOptionalId: async (clusterName: string, databaseName: string, tableName: string, fromDate?: string, id?: number, limit?: number, namespace?: string, next?: number, softDelete?: boolean, softDeleteField?: string, toDate?: string, verbose?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterName' is not null or undefined
            if (clusterName === null || clusterName === undefined) {
                throw new RequiredError('clusterName','Required parameter clusterName was null or undefined when calling getRecordsByOptionalId.');
            }
            // verify required parameter 'databaseName' is not null or undefined
            if (databaseName === null || databaseName === undefined) {
                throw new RequiredError('databaseName','Required parameter databaseName was null or undefined when calling getRecordsByOptionalId.');
            }
            // verify required parameter 'tableName' is not null or undefined
            if (tableName === null || tableName === undefined) {
                throw new RequiredError('tableName','Required parameter tableName was null or undefined when calling getRecordsByOptionalId.');
            }
            const localVarPath = `/clusters/{cluster_name}/databases/{database_name}/tables/{table_name}/records`
                .replace(`{${"cluster_name"}}`, encodeURIComponent(String(clusterName)))
                .replace(`{${"database_name"}}`, encodeURIComponent(String(databaseName)))
                .replace(`{${"table_name"}}`, encodeURIComponent(String(tableName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (namespace !== undefined) {
                localVarQueryParameter['namespace'] = namespace;
            }

            if (next !== undefined) {
                localVarQueryParameter['next'] = next;
            }

            if (softDelete !== undefined) {
                localVarQueryParameter['soft_delete'] = softDelete;
            }

            if (softDeleteField !== undefined) {
                localVarQueryParameter['soft_delete_field'] = softDeleteField;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (verbose !== undefined) {
                localVarQueryParameter['verbose'] = verbose;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get table details such as primary keys, fields, available range...
         * @summary Get table metadata
         * @param {string} clusterName 
         * @param {string} databaseName 
         * @param {string} tableName 
         * @param {string} [namespace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableMetadata: async (clusterName: string, databaseName: string, tableName: string, namespace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterName' is not null or undefined
            if (clusterName === null || clusterName === undefined) {
                throw new RequiredError('clusterName','Required parameter clusterName was null or undefined when calling getTableMetadata.');
            }
            // verify required parameter 'databaseName' is not null or undefined
            if (databaseName === null || databaseName === undefined) {
                throw new RequiredError('databaseName','Required parameter databaseName was null or undefined when calling getTableMetadata.');
            }
            // verify required parameter 'tableName' is not null or undefined
            if (tableName === null || tableName === undefined) {
                throw new RequiredError('tableName','Required parameter tableName was null or undefined when calling getTableMetadata.');
            }
            const localVarPath = `/clusters/{cluster_name}/databases/{database_name}/tables/{table_name}`
                .replace(`{${"cluster_name"}}`, encodeURIComponent(String(clusterName)))
                .replace(`{${"database_name"}}`, encodeURIComponent(String(databaseName)))
                .replace(`{${"table_name"}}`, encodeURIComponent(String(tableName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (namespace !== undefined) {
                localVarQueryParameter['namespace'] = namespace;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all historized databases in any cluster
         * @summary Get all databases for all clusters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all historized tables of a particular database
         * @summary Get all tables from database
         * @param {string} clusterName 
         * @param {string} databaseName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllTablesPerDatabasesPerCluster: async (clusterName: string, databaseName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterName' is not null or undefined
            if (clusterName === null || clusterName === undefined) {
                throw new RequiredError('clusterName','Required parameter clusterName was null or undefined when calling listAllTablesPerDatabasesPerCluster.');
            }
            // verify required parameter 'databaseName' is not null or undefined
            if (databaseName === null || databaseName === undefined) {
                throw new RequiredError('databaseName','Required parameter databaseName was null or undefined when calling listAllTablesPerDatabasesPerCluster.');
            }
            const localVarPath = `/clusters/{cluster_name}/databases/{database_name}/tables`
                .replace(`{${"cluster_name"}}`, encodeURIComponent(String(clusterName)))
                .replace(`{${"database_name"}}`, encodeURIComponent(String(databaseName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatabaseResourceApi - functional programming interface
 * @export
 */
export const DatabaseResourceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get changed records for a specific table in a database (cluster)
         * @summary Get changed records
         * @param {string} clusterName 
         * @param {string} databaseName 
         * @param {string} tableName 
         * @param {string} [fromDate] 
         * @param {number} [id] 
         * @param {number} [limit] 
         * @param {string} [namespace] 
         * @param {number} [next] 
         * @param {boolean} [softDelete] 
         * @param {string} [softDeleteField] 
         * @param {string} [toDate] 
         * @param {boolean} [verbose] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordsByOptionalId(clusterName: string, databaseName: string, tableName: string, fromDate?: string, id?: number, limit?: number, namespace?: string, next?: number, softDelete?: boolean, softDeleteField?: string, toDate?: string, verbose?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseRecords>> {
            const localVarAxiosArgs = await DatabaseResourceApiAxiosParamCreator(configuration).getRecordsByOptionalId(clusterName, databaseName, tableName, fromDate, id, limit, namespace, next, softDelete, softDeleteField, toDate, verbose, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get table details such as primary keys, fields, available range...
         * @summary Get table metadata
         * @param {string} clusterName 
         * @param {string} databaseName 
         * @param {string} tableName 
         * @param {string} [namespace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTableMetadata(clusterName: string, databaseName: string, tableName: string, namespace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTableDetails>> {
            const localVarAxiosArgs = await DatabaseResourceApiAxiosParamCreator(configuration).getTableMetadata(clusterName, databaseName, tableName, namespace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all historized databases in any cluster
         * @summary Get all databases for all clusters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDatabases>> {
            const localVarAxiosArgs = await DatabaseResourceApiAxiosParamCreator(configuration).listAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all historized tables of a particular database
         * @summary Get all tables from database
         * @param {string} clusterName 
         * @param {string} databaseName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllTablesPerDatabasesPerCluster(clusterName: string, databaseName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTables>> {
            const localVarAxiosArgs = await DatabaseResourceApiAxiosParamCreator(configuration).listAllTablesPerDatabasesPerCluster(clusterName, databaseName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DatabaseResourceApi - factory interface
 * @export
 */
export const DatabaseResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get changed records for a specific table in a database (cluster)
         * @summary Get changed records
         * @param {string} clusterName 
         * @param {string} databaseName 
         * @param {string} tableName 
         * @param {string} [fromDate] 
         * @param {number} [id] 
         * @param {number} [limit] 
         * @param {string} [namespace] 
         * @param {number} [next] 
         * @param {boolean} [softDelete] 
         * @param {string} [softDeleteField] 
         * @param {string} [toDate] 
         * @param {boolean} [verbose] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordsByOptionalId(clusterName: string, databaseName: string, tableName: string, fromDate?: string, id?: number, limit?: number, namespace?: string, next?: number, softDelete?: boolean, softDeleteField?: string, toDate?: string, verbose?: boolean, options?: any): AxiosPromise<ResponseRecords> {
            return DatabaseResourceApiFp(configuration).getRecordsByOptionalId(clusterName, databaseName, tableName, fromDate, id, limit, namespace, next, softDelete, softDeleteField, toDate, verbose, options).then((request) => request(axios, basePath));
        },
        /**
         * Get table details such as primary keys, fields, available range...
         * @summary Get table metadata
         * @param {string} clusterName 
         * @param {string} databaseName 
         * @param {string} tableName 
         * @param {string} [namespace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableMetadata(clusterName: string, databaseName: string, tableName: string, namespace?: string, options?: any): AxiosPromise<ResponseTableDetails> {
            return DatabaseResourceApiFp(configuration).getTableMetadata(clusterName, databaseName, tableName, namespace, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all historized databases in any cluster
         * @summary Get all databases for all clusters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAll(options?: any): AxiosPromise<ResponseDatabases> {
            return DatabaseResourceApiFp(configuration).listAll(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all historized tables of a particular database
         * @summary Get all tables from database
         * @param {string} clusterName 
         * @param {string} databaseName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllTablesPerDatabasesPerCluster(clusterName: string, databaseName: string, options?: any): AxiosPromise<ResponseTables> {
            return DatabaseResourceApiFp(configuration).listAllTablesPerDatabasesPerCluster(clusterName, databaseName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatabaseResourceApi - object-oriented interface
 * @export
 * @class DatabaseResourceApi
 * @extends {BaseAPI}
 */
export class DatabaseResourceApi extends BaseAPI {
    /**
     * Get changed records for a specific table in a database (cluster)
     * @summary Get changed records
     * @param {string} clusterName 
     * @param {string} databaseName 
     * @param {string} tableName 
     * @param {string} [fromDate] 
     * @param {number} [id] 
     * @param {number} [limit] 
     * @param {string} [namespace] 
     * @param {number} [next] 
     * @param {boolean} [softDelete] 
     * @param {string} [softDeleteField] 
     * @param {string} [toDate] 
     * @param {boolean} [verbose] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatabaseResourceApi
     */
    public getRecordsByOptionalId(clusterName: string, databaseName: string, tableName: string, fromDate?: string, id?: number, limit?: number, namespace?: string, next?: number, softDelete?: boolean, softDeleteField?: string, toDate?: string, verbose?: boolean, options?: any) {
        return DatabaseResourceApiFp(this.configuration).getRecordsByOptionalId(clusterName, databaseName, tableName, fromDate, id, limit, namespace, next, softDelete, softDeleteField, toDate, verbose, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get table details such as primary keys, fields, available range...
     * @summary Get table metadata
     * @param {string} clusterName 
     * @param {string} databaseName 
     * @param {string} tableName 
     * @param {string} [namespace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatabaseResourceApi
     */
    public getTableMetadata(clusterName: string, databaseName: string, tableName: string, namespace?: string, options?: any) {
        return DatabaseResourceApiFp(this.configuration).getTableMetadata(clusterName, databaseName, tableName, namespace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all historized databases in any cluster
     * @summary Get all databases for all clusters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatabaseResourceApi
     */
    public listAll(options?: any) {
        return DatabaseResourceApiFp(this.configuration).listAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all historized tables of a particular database
     * @summary Get all tables from database
     * @param {string} clusterName 
     * @param {string} databaseName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatabaseResourceApi
     */
    public listAllTablesPerDatabasesPerCluster(clusterName: string, databaseName: string, options?: any) {
        return DatabaseResourceApiFp(this.configuration).listAllTablesPerDatabasesPerCluster(clusterName, databaseName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HelloApi - axios parameter creator
 * @export
 */
export const HelloApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This is used only for OAuth purpose and always return the hello string
         * @summary Entry point for OAuth redirection
         * @param {string} [xGYGUSER] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (xGYGUSER?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xGYGUSER !== undefined && xGYGUSER !== null) {
                localVarHeaderParameter['X-GYG-USER'] = String(xGYGUSER);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelloApi - functional programming interface
 * @export
 */
export const HelloApiFp = function(configuration?: Configuration) {
    return {
        /**
         * This is used only for OAuth purpose and always return the hello string
         * @summary Entry point for OAuth redirection
         * @param {string} [xGYGUSER] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(xGYGUSER?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await HelloApiAxiosParamCreator(configuration).rootGet(xGYGUSER, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HelloApi - factory interface
 * @export
 */
export const HelloApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * This is used only for OAuth purpose and always return the hello string
         * @summary Entry point for OAuth redirection
         * @param {string} [xGYGUSER] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(xGYGUSER?: string, options?: any): AxiosPromise<string> {
            return HelloApiFp(configuration).rootGet(xGYGUSER, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HelloApi - object-oriented interface
 * @export
 * @class HelloApi
 * @extends {BaseAPI}
 */
export class HelloApi extends BaseAPI {
    /**
     * This is used only for OAuth purpose and always return the hello string
     * @summary Entry point for OAuth redirection
     * @param {string} [xGYGUSER] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelloApi
     */
    public rootGet(xGYGUSER?: string, options?: any) {
        return HelloApiFp(this.configuration).rootGet(xGYGUSER, options).then((request) => request(this.axios, this.basePath));
    }
}


