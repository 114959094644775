import ToolsRouterPage from "../../views/tools/index.vue";
import { Privilege, PrivilegeService } from "@root/commons/privileges";
import { Role } from "@gygadmin/auth/roles";

const privilegeService = new PrivilegeService();

export default [
  {
    path: "/tools",
    component: ToolsRouterPage,
    children: [
      {
        path: ["bulk-edit"],
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.BulkEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "bulk-edit" */
            "../../views/tools/bulk-edit/default/bulk-edit.vue"
          ),
      },
      {
        path: "bulk-edit/:id",
        meta: { authorize: [...privilegeService.getRolesOfPrivilege(Privilege.BulkEdit)] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "details" */
            "../../views/tools/bulk-edit/detail/detail.vue"
          ),
      },
      {
        path: "reviews-move-and-copy",
        meta: {
          authorize: [...privilegeService.getRolesOfPrivilege(Privilege.UpdateReviewBatch)],
        },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "move-copy-wrapper" */
            "../../views/tools/move-copy-reviews/move-copy-wrapper.vue"
          ),
      },
      {
        path: "cache-management",
        meta: {
          // only allowed for catalog engineers, other people will have access to
          // individual entity refresh via the respective pages
          authorize: [Role.engineeringCatalog],
        },
        component: (): Promise<any> =>
          import("../../views/tools/cache-management/cache-management.vue"),
      },
    ],
  },
];
