import { ToolsState } from "./types";

export const initialState: ToolsState = {
  bulkEditPage: {
    loading: false,
    detail: {
      loading: false,
    },
  },
};
