import {
  ConnectivitySettingsOptionByOptionId,
  UpdateConnectivitySettings,
} from "@client/graphql/activities/connectivity-settings";
import { ConnectivitySourceConfigurations } from "@client/graphql/activities/connectivity-source-configurations";
import { graphClient } from "@gygadmin/client3";
import {
  ActivityOptionQuery,
  ActivityOptionQueryVariables,
  ConnectivitySettingsInput,
  ConnectivitySourceConfigurationsQuery,
  ConnectivitySourceConfigurationsQueryVariables,
  UpdateConnectivitySettingsMutation,
  UpdateConnectivitySettingsMutationVariables,
} from "@client/autogenerated/graphql/operations";

class ConnectivityOptionsService {
  async getConnectivitySettingsByOptionId(optionId: number): Promise<ActivityOptionQuery> {
    const connectivitySettings = await graphClient
      .gql()
      .query<ActivityOptionQuery, ActivityOptionQueryVariables>({
        query: ConnectivitySettingsOptionByOptionId,
        fetchPolicy: "network-only",
        variables: {
          activityOptionId: optionId.toString(),
        },
      })
      .then((res) => res.data);

    return connectivitySettings;
  }

  async getConnectivitySourceConfigurations(): Promise<ConnectivitySourceConfigurationsQuery> {
    const ConnectivitySourceConfigs = await graphClient
      .gql()
      .query<ConnectivitySourceConfigurationsQuery, ConnectivitySourceConfigurationsQueryVariables>(
        {
          query: ConnectivitySourceConfigurations,
        },
      )
      .then((res) => res.data);

    return ConnectivitySourceConfigs;
  }

  async updateConnectivitySettings(
    tourOptionId: number,
    input: ConnectivitySettingsInput,
    actionSendEmail?: boolean,
  ): Promise<UpdateConnectivitySettingsMutation["updateConnectivitySettings"]> {
    const updateResult = await graphClient
      .gql()
      .mutate<UpdateConnectivitySettingsMutation, UpdateConnectivitySettingsMutationVariables>({
        mutation: UpdateConnectivitySettings,
        variables: {
          tourOptionId,
          input,
          actionSendEmail,
        },
      });
    return updateResult?.data?.updateConnectivitySettings;
  }
}

export default new ConnectivityOptionsService();
