import { Role } from "@gygadmin/auth/roles";
import { Privilege } from "./privileges";

export interface RoleToPrivilegeMappingType {
  [key: string]: Array<Privilege>;
}

const mapping: RoleToPrivilegeMappingType = {};

// business development
mapping[Role.businessDevelopment] = [
  Privilege.AdminTourEdit,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
];
mapping[Role.businessDevelopmentSupport] = [...mapping[Role.businessDevelopment]];

// customer service
mapping[Role.customerServiceAgent] = [
  Privilege.AdminCreateExclusiveTourOption,
  Privilege.UpdateReview,
];
mapping[Role.customerService] = [
  // FIXME: devoid customerService of privileges
  ...mapping[Role.customerServiceAgent],
];
mapping[Role.customerServiceB2B] = [
  ...mapping[Role.customerServiceAgent],
  Privilege.AdminTourEdit,
  Privilege.AdminTourEditActiveTourText,
  Privilege.AdminImpersonateSupplier,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminTourEditSupplierOnlineVoucher,
  Privilege.AdminBookingEngineSetLonger,
  Privilege.UpdateReviewBatch,
];
mapping[Role.customerServiceSupplierCancellation] = [
  ...mapping[Role.customerServiceAgent],
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminImpersonateSupplier,
];
mapping[Role.customerServiceTeamLead] = [
  ...mapping[Role.customerServiceAgent],
  Privilege.AdminTourEdit,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminImpersonateSupplier,
  Privilege.AdminBookingEngineSetLonger,
  Privilege.AdminGygLocation,
];
mapping[Role.customerServiceManager] = [...mapping[Role.customerServiceTeamLead]];
mapping[Role.customerServiceSpecialist] = [
  ...mapping[Role.customerServiceAgent],
  Privilege.AdminTourEdit,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminImpersonateSupplier,
];
mapping[Role.customerServicePartner] = [
  ...mapping[Role.customerServiceAgent],
  Privilege.AdminTourEdit,
  Privilege.AdminTourActivateDeactivate,
];
mapping[Role.customerService3PB] = [
  ...mapping[Role.customerServiceAgent],
  Privilege.AdminTourEdit,
  Privilege.AdminTourEditTourClass,
  Privilege.AdminTourChangeLocation,
  Privilege.AdminImpersonateSupplier,
  Privilege.AdminBookingEngineSetLonger,
];
mapping[Role.customerServiceSocialMedia] = [...mapping[Role.customerServiceAgent]];
mapping[Role.customerServiceRescueSquad] = [...mapping[Role.customerServiceAgent]];
mapping[Role.customerServiceInternalMainTeam] = [
  ...mapping[Role.customerServiceAgent],
  ...mapping[Role.customerServiceB2B],
  ...mapping[Role.customerServiceSupplierCancellation],
  ...mapping[Role.customerServiceSpecialist],
  ...mapping[Role.customerServicePartner],
  ...mapping[Role.customerService3PB],
  ...mapping[Role.customerServiceSocialMedia],
  ...mapping[Role.customerServiceRescueSquad],
  ...mapping[Role.customerServiceManager],
];

// product management
mapping[Role.productManagement] = [
  Privilege.AdminTourEdit,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminTourChangeLocation,
  Privilege.AdminTourChangeLocationBeforeOnline,
  Privilege.AdminImpersonateSupplier,
  Privilege.AdminCategoryInformation,
  Privilege.AdminGygLocation,
];
mapping[Role.productManagementMarketplace] = [...mapping[Role.productManagement]];
mapping[Role.productManagementPartner] = [...mapping[Role.productManagement]];
mapping[Role.productManagementSupply] = [
  ...mapping[Role.productManagement],
  Privilege.AdminImpersonateSupplier,
];

// marketing
mapping[Role.performanceMarketing] = [
  ...mapping[Role.productManagement],
  Privilege.AdminGygLocation,
];

// content
mapping[Role.design] = [Privilege.AdminImpersonateSupplier];
mapping[Role.localizationCatalogTranslation] = [];
mapping[Role.catalogInventoryOptimization] = [
  ...mapping[Role.localizationCatalogTranslation],
  Privilege.AdminTourChangeLocation,
  Privilege.AdminTourChangeLocationBeforeOnline,
  Privilege.PrioritizedReviewQueue,
];
mapping[Role.contentSelfUpload] = [
  ...mapping[Role.catalogInventoryOptimization],
  Privilege.AdminTourEdit,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.PrioritizedReviewQueue,
  Privilege.AdminDeletePictureFromCDN,
];
mapping[Role.localizationFreelancer] = [
  Privilege.AdminTourEdit,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminTourEditProductType,
  Privilege.AdminTourEditSupplierOnlineVoucher,
  Privilege.AdminContentManage,
  Privilege.AdminGygLocation,
  Privilege.AdminRetranslateText,
];
mapping[Role.localizationLanguageSpecialist] = [
  Privilege.AdminTourEdit,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminContentManage,
  Privilege.AdminContentChangeRequestsEdit,
  Privilege.AdminCategoryInformation,
  Privilege.AdminGygLocation,
  Privilege.AdminRetranslateText,
];
mapping[Role.catalogFreelancer] = [
  Privilege.AdminTourEdit,
  Privilege.AdminTourEditLocations,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminTourChangeStatus,
  Privilege.AdminTourActivateQualityFailStatus,
  Privilege.AdminTourEditSupplierFixedQuestions,
  Privilege.AdminTourChangeLocation,
  Privilege.AdminTourChangeLocationBeforeOnline,
  Privilege.AdminTourEditInternalKeywords,
  Privilege.AdminTourEditActiveTourText,
  Privilege.AdminTourManagePictures,
  Privilege.AdminTourEditItinerary,
  Privilege.AdminTourManagePicturesVerticalAlign,
  Privilege.AdminImpersonateSupplier,
  Privilege.AdminContentManage,
  Privilege.PrioritizedReviewQueue,
  Privilege.AdminCategoryInformation,
  Privilege.AdminGygLocation,
];
mapping[Role.catalogSpecialist] = [
  ...mapping[Role.catalogFreelancer],
  Privilege.AdminTourEditTourClass,
  Privilege.AdminTourEditProductType,
  Privilege.AdminTourDelete,
  Privilege.AdminTourEditSupplierOnlineVoucher,
  Privilege.RestrictionConfigurationRead,
  Privilege.RestrictionConfigurationWrite,
];
mapping[Role.contentVisualEditing] = [
  Privilege.AdminTourEdit,
  Privilege.AdminTourEditLocations,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminTourManagePictures,
  Privilege.AdminTourManageGygPictures,
  Privilege.AdminTourManagePicturesVerticalAlign,
  Privilege.AdminGygLocation,
  Privilege.PrioritizedReviewQueue,
  Privilege.AdminDeletePictureFromCDN,
];
mapping[Role.contentVisualManagement] = [...mapping[Role.contentVisualEditing]];
mapping[Role.contentCoordination] = [
  Privilege.AdminTourEdit,
  Privilege.AdminTourEditLocations,
  Privilege.AdminTourEditTourClass,
  Privilege.AdminTourChangeStatus,
  Privilege.AdminTourActivateQualityFailStatus,
  Privilege.AdminTourDelete,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminTourEditItinerary,
  Privilege.AdminTourEditPriority,
  Privilege.AdminTourChangeLocation,
  Privilege.AdminTourChangeLocationBeforeOnline,
  Privilege.AdminTourEditActiveTourText,
  Privilege.AdminImpersonateSupplier,
  Privilege.AdminContentManage,
  Privilege.PrioritizedReviewQueue,
  Privilege.AdminBookingEngineSetLonger,
  Privilege.AdminCategoryInformation,
  Privilege.AdminGygLocation,
  Privilege.AdminDeletePictureFromCDN,
];
mapping[Role.contentLeadership] = [
  Privilege.AdminTourEdit,
  Privilege.AdminTourEditLocations,
  Privilege.AdminTourEditTourClass,
  Privilege.AdminTourChangeStatus,
  Privilege.AdminTourActivateQualityFailStatus,
  Privilege.AdminTourDelete,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminTourEditItinerary,
  Privilege.AdminTourEditPriority,
  Privilege.AdminTourChangeLocation,
  Privilege.AdminTourChangeLocationBeforeOnline,
  Privilege.AdminTourEditActiveTourText,
  Privilege.AdminImpersonateSupplier,
  Privilege.AdminContentManage,
  Privilege.PrioritizedReviewQueue,
  Privilege.AdminBookingEngineSetLonger,
  Privilege.AdminCategoryInformation,
  Privilege.AdminGygLocation,
  Privilege.AdminDeletePictureFromCDN,
  Privilege.History,
];
mapping[Role.responsibleTourism] = [Privilege.AdminTourToggleEcoCertified];

// sales
mapping[Role.sales] = [
  Privilege.AdminTourEdit,
  Privilege.AdminTourEditActiveTourText,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminTourEditItinerary,
  Privilege.AdminTourEditCommissionRate,
  Privilege.AdminTourEditSupplierOnlineVoucher,
  Privilege.AdminTourEditAuthorisedBadge,
  Privilege.AdminTourReject,
  Privilege.AdminTourChangeLocationBeforeOnline,
  Privilege.AdminTourManagePicturesVerticalAlign,
  Privilege.AdminImpersonateSupplier,
  Privilege.AdminBookingEngineSetLonger,
  Privilege.AdminTourEditCancellationPolicy,
];
mapping[Role.salesSeniorAccountManager] = [...mapping[Role.sales]];
mapping[Role.salesAreaManager] = [
  ...mapping[Role.salesSeniorAccountManager],
  Privilege.AdminTourDelete,
  Privilege.AdminTourChangeStatus,
];
mapping[Role.salesRegionalManager] = [
  ...mapping[Role.salesAreaManager],
  Privilege.AdminTourEditCancellationPolicy,
];
mapping[Role.salesRegionalDirector] = [...mapping[Role.salesRegionalManager]];
mapping[Role.salesOperation] = [
  ...mapping[Role.sales],
  Privilege.AdminManageToursTickets,
  Privilege.AdminTourEditPriority,
  Privilege.AdminTourEditProductType,
  Privilege.AdminTourChangeStatus,
  Privilege.AdminTourActivateQualityFailStatus,
  Privilege.AdminTourDelete,
  Privilege.AdminTourChangeLocation,
  Privilege.AdminTourChangeLocationBeforeOnline,
  Privilege.AdminTourEditAuthorisedBadge,
  Privilege.UpdateReview,
];
mapping[Role.salesOperationSpecialist] = [
  ...mapping[Role.salesOperation],
  Privilege.AdminTourEditTourClass,
];
mapping[Role.salesCoordinator] = [
  ...mapping[Role.salesOperationSpecialist],
  Privilege.AdminTourEditSupplierFixedQuestions,
  Privilege.AdminTourEditLocationRevenueRelevant,
];
mapping[Role.categoryManager] = [
  ...mapping[Role.sales],
  Privilege.AdminTourEditProductType,
  Privilege.AdminTourToggleGygOriginals,
  Privilege.AdminTourEditStrategicRelationships,
];
mapping[Role.merchandising] = [
  Privilege.AdminTourEditLocations,
  Privilege.BulkEdit,
  Privilege.DerivedAttributeConfigurationRead,
  Privilege.DerivedAttributeConfigurationWrite,
  Privilege.QualityIssueConfigurationRead,
  Privilege.QualityIssueConfigurationWrite,
];

// finance & legal
mapping[Role.financeAccountant] = [
  Privilege.AdminManageToursTickets,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminTourEdit,
  Privilege.AdminTourEditCommissionRate,
  Privilege.AdminImpersonateSupplier,
];
mapping[Role.financeAccountantPlus] = [...mapping[Role.financeAccountant]];
mapping[Role.finance] = [...mapping[Role.financeAccountantPlus]];
mapping[Role.financeFraudSpecialist] = [...mapping[Role.finance]];
mapping[Role.financeFraudSpecialistSuperuser] = [
  ...mapping[Role.financeFraudSpecialist],
  Privilege.AdminTourEditEnforce3dSecure,
];
mapping[Role.legal] = [...mapping[Role.finance]];

// engineering
mapping[Role.engineering] = [
  Privilege.AdminTourChangeLocation,
  Privilege.AdminTourChangeLocationBeforeOnline,
  Privilege.AdminTourEditSupplierFixedQuestions,
  Privilege.AdminTourShowFullCancellationPolicy,
  Privilege.AdminCategoryInformation,
  Privilege.AdminGygLocation,
  Privilege.History,
];
mapping[Role.engineeringFinance] = [
  ...mapping[Role.engineering],
  ...mapping[Role.finance],
  ...mapping[Role.legal],
  ...mapping[Role.customerServiceSpecialist],
  Privilege.AdminManageToursTickets,
  Privilege.AdminTourEditSupplierOnlineVoucher,
];
mapping[Role.engineeringPartner] = [
  ...mapping[Role.engineering],
  ...mapping[Role.customerServiceB2B],
  ...mapping[Role.customerServicePartner],
];
mapping[Role.engineeringSupply] = [
  ...mapping[Role.engineering],
  Privilege.AdminTourEdit,
  Privilege.AdminTourEditLocations,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminTourChangeStatus,
  Privilege.AdminTourActivateQualityFailStatus,
  Privilege.AdminTourDelete,
  Privilege.AdminTourEditSupplierOnlineVoucher,
  Privilege.AdminTourEditTourClass,
  Privilege.AdminImpersonateSupplier,
  Privilege.AdminBookingEngineSetLonger,
];
mapping[Role.catalogTagging] = [
  Privilege.AdminTourEditProductType,
  Privilege.AdminTourEditTourClass,
  Privilege.AdminTourEditAvailabilityConstraint,
  Privilege.AdminTourEditLocationBoost,
  Privilege.AdminTourEditLocationRevenueRelevant,
  Privilege.AdminGygLocation,
];
mapping[Role.catalogBulkEdit] = [Privilege.BulkEdit];
mapping[Role.engineeringCatalog] = [
  ...mapping[Role.engineering],
  ...mapping[Role.engineeringSupply],
  ...mapping[Role.catalogSpecialist],
  ...mapping[Role.catalogTagging],
  ...mapping[Role.contentSelfUpload],
  ...mapping[Role.contentCoordination],
  ...mapping[Role.contentLeadership],
  ...mapping[Role.contentVisualManagement],
  ...mapping[Role.localizationFreelancer],
  ...mapping[Role.productManagement],
  ...mapping[Role.salesCoordinator],
  ...mapping[Role.categoryManager],
  ...mapping[Role.finance],
  ...mapping[Role.customerServiceTeamLead],
  ...mapping[Role.catalogBulkEdit],
  Privilege.AdminRetranslateText,
  Privilege.PrioritizedReviewQueue,
  Privilege.AdminDeletePictureFromCDN,
  Privilege.AdminTourEditCancellationPolicy,
];
mapping[Role.engineeringMarketing] = [...mapping[Role.engineering]];
mapping[Role.engineeringSupport] = [
  ...mapping[Role.engineering],
  Privilege.AdminTourEdit,
  Privilege.AdminTourEditLocations,
  Privilege.AdminTourActivateDeactivate,
  Privilege.AdminTourOptionActivateDeactivate,
  Privilege.AdminImpersonateSupplier,
];
//EDM
mapping[Role.experiencesAndDestinationsMarketing] = [Privilege.AdminTourToggleGygOriginals];

// the super admin role has all privileges
mapping[Role.superAdmin] = [...Object.values(Privilege)];

// this will prevent duplicate keys in a role
for (const key of Object.keys(mapping)) {
  mapping[key] = Array.from(new Set(mapping[key]));
}

export const RoleToPrivilegeMapping = mapping;
