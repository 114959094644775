import { AxiosInstance } from "axios";
import RestClient from "../../commons/rest-client";
import { collectrUrl } from "../../configs/app";
import { AuthService } from "@gygadmin/auth/client";
import { IRequestHeaders } from "@getyourguide/graphql-client-js";
import { HistoryInfo } from "@root/src/types/fields";

export interface CollectionRecord {
  id: string;
  name: string;
  update_timestamp?: number;
  update_user_id?: string;
  update_user_type?: string;
  previous_value?: string;
  new_value?: string;
}

export interface CollectionResponse {
  data: CollectionRecord[];
}

export interface HistoryRecord {
  metadata: {
    op: string;
    timestamp: number;
  };
  data: any;
}

export interface HistoryResponse {
  data: HistoryRecord[];
}

class HistoryService {
  restClient: AxiosInstance;
  headers: IRequestHeaders = {};

  constructor() {
    this.restClient = RestClient.create({ baseURL: collectrUrl });
  }

  async setAuthorizationHeaders(): Promise<void> {
    const currentUser = AuthService.getAuth().currentUser;
    if (currentUser) {
      const idToken = await currentUser
        .getIdToken()
        .then((idToken) => idToken)
        .catch(() => false);
      if (idToken) {
        this.headers.authorization = `Bearer ${idToken}`;
      }
    }
  }

  async getRecords(
    historyInfo?: HistoryInfo,
    fromDate?: string,
    toDate?: string,
    entityId?: string,
  ): Promise<HistoryRecord[]> {
    const documentId = await this.getCollectionId(historyInfo?.documentName);

    if (documentId === null) {
      return [];
    }

    const { data } = await this.restClient.post<HistoryResponse>(
      `/collections/${documentId}/documents/search`,
      {
        fields: [
          {
            name: historyInfo?.indexedFieldName ?? "id",
            value: entityId, // value of the indexed field
          },
          {
            name: "__source.ts_ms", // required if we want the history for a specific time period
            from: fromDate,
            to: toDate,
          },
        ],
      },
      {
        headers: this.headers,
      },
    );

    const items = (data.data || []) as HistoryRecord[];

    if (items.length === 0) {
      return [];
    }

    const historyProperties: string[] = historyInfo?.historyProperties?.split(",") || [];
    const results: HistoryRecord[] = [];

    items.sort(
      (a, b) =>
        b.metadata.timestamp.toString().localeCompare(a.metadata.timestamp.toString() || "") || 0,
    );

    items.forEach((record, index) => {
      if (record.metadata.op === "u" && historyProperties.length > 0) {
        //  Operation 'u' -> update
        historyProperties?.forEach((property) => {
          const before = items[index + 1];
          const safeBefore = before || {};
          const safeAfter = record || {};

          /* If the change has update -> compare the current value of incoming field
                    with the previous value and add it to the result set if there is a mismatch */
          if (before && safeBefore && safeAfter) {
            Object.keys(before.data || record.data || {})
              .filter(
                (field) =>
                  safeBefore.data[field] !== safeAfter.data[field] &&
                  !["update_timestamp", "update_user_id", "update_user_type"].includes(field) &&
                  field === property,
              )
              .forEach((field) => {
                results.push(
                  Object.assign({}, record, {
                    data: {
                      field_name: field,
                      previous_value: safeBefore.data[field],
                      new_value: safeAfter.data[field],
                      update_user_id: safeAfter.data.update_user_id,
                      update_user_type: safeAfter.data.update_user_type,
                      update_timestamp: safeAfter.data.update_timestamp / 1000,
                    },
                  }),
                );
              });
          }
        });
      } else {
        // operations like 'c' -> create and 'd' -> delete
        results.push(
          Object.assign({}, record, {
            data: {
              new_value: JSON.stringify(record.data),
              update_user_id: record.data.update_user_id,
              update_user_type: record.data.update_user_type,
              update_timestamp: record.data.update_timestamp / 1000,
            },
          }),
        );
      }
    });

    return results;
  }

  async getRecordsWithFields(documentId: string, fields: any): Promise<any> {
    if (documentId === null) {
      return [];
    }

    const { data } = await this.restClient.post<HistoryResponse>(
      `/collections/${documentId}/documents/search`,
      {
        fields,
      },
      {
        headers: this.headers,
      },
    );

    return data;
  }

  async getCollectionId(collectionName?: string): Promise<string | null> {
    await this.setAuthorizationHeaders();
    const { data } = await this.restClient.get<CollectionResponse>(`/collections`, {
      headers: this.headers,
    });
    const items = data.data as CollectionRecord[];
    const collection = items.filter((item) => item.name === collectionName).pop();
    return collection ? collection.id : null;
  }

  async getCollectionsByName(collectionName: string): Promise<CollectionRecord[]> {
    await this.setAuthorizationHeaders(); // TODO refactor
    const { data } = await this.restClient.get<CollectionResponse>(`/collections`, {
      headers: this.headers,
    });
    const items = data.data as CollectionRecord[];
    return items.filter((item) => item.name.includes(collectionName));
  }

  async getAllCollectionsByName(): Promise<CollectionRecord[]> {
    await this.setAuthorizationHeaders();
    const { data } = await this.restClient.get<CollectionResponse>(`/collections`, {
      headers: this.headers,
    });
    return data.data as CollectionRecord[];
  }
}

export default new HistoryService();
