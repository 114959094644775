<template>
  <div
    v-show="show"
    class="global-loader"
  >
    <div class="global-loader-image" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Loading",
  components: {},
  computed: {
    show(): boolean {
      return this.$store.state.loading;
    },
  },
});
</script>
<style lang="scss" scoped>
$bdu: 8px;

.global-loader {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.3);
}

.global-loader-image {
  width: $bdu * 10;
  height: $bdu * 10;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.global-loader-image:after {
  content: " ";
  display: block;
  width: $bdu * 7;
  height: $bdu * 7;
  margin: $bdu;
  border-radius: 50%;
  border-width: 6px;
  border-style: solid;
  border-color: white transparent white transparent;
  animation: global-loader-image 1.2s linear infinite;
}
@keyframes global-loader-image {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
