import gql from "graphql-tag";

export const PriceOverridesFragment = gql`
  fragment PriceOverridesFragment on PriceOverride {
    id
    dateRanges {
      start
      end
    }
    name
    pricingPrices {
      id
      pricingCategoryId
      pricingId
      pricingScaleId
      pricingScale {
        maxParticipants
        minParticipants
      }
      prices {
        id
        netRatePrice
        retailPrice
        returnPrice
        breakagePrice
      }
    }
  }
`;
