<template>
  <VApp id="app">
    <RouterView />
  </VApp>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import logger from "../commons/logger";

export default defineComponent({
  name: "AppContainer",
  errorCaptured: (err, component): void => {
    logger.error(`[render error] Error in component: ${component?.$options.name}`, { err });
  },
});
</script>

<style lang="scss">
*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
}

.gygadmin-splash-screen {
  position: fixed;
  background: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
}

.gygadmin-splash-screen-logo {
  position: absolute;
  background: url("../assets/images/gyg_symbol.jpg") no-repeat top center;
  background-size: 120px 155px;
  width: 120px;
  height: 155px;
  top: 50%;
  left: 50%;
  margin-left: -60px;
  margin-top: -77px;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
    moz-transform: rotateY(0deg);
    ms-transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
    moz-transform: rotateY(360deg);
    ms-transform: rotateY(360deg);
  }
}

.gygadmin-splash-screen-logo-spin {
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation-name: spin;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
}
</style>
