<template>
  <ThemeDefault
    :domain="domain"
    :expand-on-hover="expandOnHover"
    :switch-links="switchLinks"
    :page-identifier="pageIdentifier"
    :page-title="pageTitle"
    :page-subtitle="pageSubtitle"
    :page-loading="pageLoading"
    :hide-header="hideHeader"
    :hide-navigation="hideNavigation"
    :navigation-items="items"
    :user-id="user.staffId?.toString()"
    :user-name="user.name"
    :user-picture="user.picture"
  >
    <Notification />
    <template #search>
      <QuickSearch v-if="showSearch" />
    </template>
    <template #body>
      <Loading />
    </template>
    <slot />
  </ThemeDefault>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Loading from "../components/loading/loading.vue";
import QuickSearch from "./quick-search/quick-search.vue";
import { User } from "../store/modules/user/types";
import { appletName } from "../configs/app";
import app from "../main";
import Notification from "./notification/index.vue";
import { ThemeDefault } from "../components";
import { AppLink } from "../components/theme/AppsMenu.types";

export default defineComponent({
  name: "BaseLayout",
  components: {
    ThemeDefault,
    QuickSearch,
    Loading,
    Notification,
  },
  props: {
    pageIdentifier: {
      type: String,
      default: "",
    },
    pageTitle: {
      type: String,
      default: "",
    },
    pageSubtitle: {
      type: String,
      default: "",
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    hideSearch: {
      type: [Boolean, String],
      default: false,
    },
    hideHeader: {
      type: [Boolean, String],
      default: true,
    },
    hideNavigation: {
      type: [Boolean, String],
      default: false,
    },
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      domain: appletName,
      items: app.getNavigation(),
    };
  },
  computed: {
    switchLinks(): AppLink[] {
      return app.switchLinks(this);
    },
    user(): User {
      return this.$store.state.user;
    },
    showSearch(): boolean {
      return !(this.hideSearch === true || this.hideSearch === "");
    },
  },
});
</script>
