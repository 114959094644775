import { FieldCheck } from "@root/commons/types/fields";
import { FieldCheckState } from "./types";
import { MutationTree } from "vuex";
import { getFieldUniqueKey } from "./helpers";

export const mutations: MutationTree<FieldCheckState> = {
  CACHE_ITEM(state: FieldCheckState, change: FieldCheck): void {
    const itemKey = getFieldUniqueKey(change.type, change.type_id, change.key);
    state.items[itemKey] = {
      ...change,
    };
  },
  EVICT_ITEM(state: FieldCheckState, change: FieldCheck): void {
    const itemKey = getFieldUniqueKey(change.type, change.type_id, change.key);
    if (state.items[itemKey]) {
      delete state.items[itemKey];
    }
  },
};

export default mutations;
