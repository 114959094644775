import { ActivityState } from "./types";

export const initialState: ActivityState = {
  page: {
    loading: false,
    activityCommentsCount: 0,
    alert: {
      show: false,
      level: "info",
      message: "",
      timeout: 4000,
    },
    tabs: {
      activityText: {
        loading: false,
        text: [],
        autotranslated: [],
      },
      activityComments: {
        loading: false,
        comments: [],
        removeDialog: {
          open: false,
          loading: false,
        },
        changeDialog: {
          open: false,
          loading: false,
        },
      },
      activityPictures: {
        loading: false,
        pictures: [],
      },
      activityChangeRequests: {
        loading: false,
        changeRequests: [],
      },
      activitySandboxFields: {
        loading: false,
        items: [],
      },
      supplierActivities: {
        loading: false,
        activities: [],
      },
      activityOptions: {
        loading: false,
        options: [],
        detail: {
          loading: false,
          connectivitySettings: null,
        },
        pricing: {
          id: "",
          loading: false,
          pricingConfigurations: [],
          inactiveAvailabilityBlocks: [],
        },
      },
      activityOptionText: {
        loading: false,
        text: [],
        autotranslated: [],
      },
      activityOptionSandboxFields: {
        loading: false,
        items: [],
      },
    },
  },
};
