import { AxiosInstance } from "axios";
import RestClient from "@root/commons/utils/rest-client";
import { AggregateSandboxField } from "@root/commons/types";
import { SandboxEntityInformation } from "@root/commons/types/sandbox";

export class SandboxService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  async fetchActivityFields(activityId: number): Promise<Array<AggregateSandboxField>> {
    return this.restClient
      .get(`/activities/${activityId}/sandbox/fields`)
      .then((response) => response.data)
      .catch(() => []);
  }

  async fetchOptionFields(optionId: number): Promise<Array<AggregateSandboxField>> {
    return this.restClient
      .get(`/activities/options/${optionId}/sandbox/fields`)
      .then((response) => response.data)
      .catch(() => []);
  }

  async fetchFields(entity: SandboxEntityInformation): Promise<Array<AggregateSandboxField>> {
    return this.restClient
      .get(`/sandbox/fields/${entity.type.toString()}/${entity.id}`)
      .then((response) => response.data)
      .catch(() => []);
  }

  async deleteValue(valueId: number): Promise<boolean> {
    return this.restClient
      .delete(`/sandbox/fields/values/${valueId}`)
      .then((response) => response.data.success)
      .catch(() => false);
  }

  /**
   * Create or update a sandbox field.
   *
   * @param field
   */
  async save(field: AggregateSandboxField): Promise<AggregateSandboxField> {
    if (field.id != null) {
      return this.restClient
        .put(`/sandbox/fields/${field.id}`, field)
        .then((response) => response.data);
    }
    return this.restClient.post(`/sandbox/fields`, field).then((response) => response.data);
  }
}

export default new SandboxService();
