import axios, { AxiosInstance } from "axios";

interface Params {
  baseURL?: string;
  [key: string]: unknown;
}

class RestClient {
  create(params: Params = {}): AxiosInstance {
    const baseURL = params.baseURL || "/";
    return axios.create({
      ...params,
      baseURL,
    });
  }
}

export default new RestClient();
