import { Keyword, KeywordCategories, KeywordTopListResponse } from "@root/commons/types";
import { graphClient } from "@gygadmin/client3";
import { Query } from "@client/autogenerated/graphql/operations";
import { GetActivityKeywordsSuggestion } from "@client/graphql/activities/activities";
import { TagsService } from "@client/services";

class KeywordsService {
  async getTopKeywordsByActivity(activityId: number): Promise<KeywordTopListResponse> {
    const suggestedTagIds = await this.getActivityKeywordsSuggestion(activityId);
    const suggestedTagIdsMap: { [key: number]: boolean } = {};
    const topList: KeywordTopListResponse = {};
    const topKeywordIds: number[] = [];
    for (const suggestedTagId of suggestedTagIds) {
      suggestedTagIdsMap[suggestedTagId] = true;
    }
    for (const ids of Object.values(KeywordTopList)) {
      topKeywordIds.push(...ids);
    }

    const keywordsRequests = [];
    for (const tagId of Array.from(new Set([...topKeywordIds, ...suggestedTagIds]))) {
      keywordsRequests.push(TagsService.get(Number(tagId)));
    }

    const keywords = (await Promise.all(keywordsRequests)) as Keyword[];

    for (const keyword of keywords) {
      if (!keyword || keyword.id === undefined) continue;
      const category = KeywordTopListMap[keyword.id] || KeywordCategories.Other;
      if (suggestedTagIdsMap[keyword.id]) {
        keyword.suggested = true;
      }
      topList[category] = [...(topList[category] || []), keyword];
    }
    return topList;
  }

  async getActivityKeywordsSuggestion(activityId: number): Promise<Array<number>> {
    return graphClient
      .gql()
      .query<Query>({
        query: GetActivityKeywordsSuggestion,
        variables: {
          activityId: activityId.toString(),
        },
      })
      .then((res) => {
        return res.data?.activity?.keywordsSuggestion?.map((suggestion) => +suggestion.id) || [];
      })
      .catch(() => []);
  }
}

/**
 * Copied from
 * https://docs.google.com/spreadsheets/d/1mtsu-rV3dGqSQgWKWm-9S-VX2-w0IVPmjf_vzE-XIh4/edit#gid=2136923027
 */
const KeywordTopList = {
  [KeywordCategories.Sightseeing]: [6828, 1094, 527, 974, 1154],
  [KeywordCategories.Nature]: [623, 1280, 608, 1339, 1345, 1055, 926, 977],
  [KeywordCategories.SportsAndAdventure]: [
    1115, 515, 1046, 1214, 1265, 533, 1316, 1043, 767, 617, 794, 1193,
  ],
  [KeywordCategories.CulinaryAndNightlife]: [1292, 1295, 5379, 1019, 587, 1205, 1283],
  [KeywordCategories.CruisesAndWater]: [1476, 491, 1061, 1145],
  [KeywordCategories.Themes]: [752, 920, 473],
  [KeywordCategories.CultureAndHistory]: [23415, 683, 584, 959, 434, 554, 779, 446],
  [KeywordCategories.Audience]: [668, 503],
  [KeywordCategories.TransportationTransfer]: [410],
  [KeywordCategories.Other]: [],
};

const KeywordTopListMap: { [key: number]: KeywordCategories } = {};

for (const category in KeywordTopList) {
  const keywordIds = KeywordTopList[category as KeywordCategories];
  for (const keywordId of keywordIds) {
    KeywordTopListMap[keywordId] = category as KeywordCategories;
  }
}

export default new KeywordsService();
