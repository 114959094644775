import CountriesRouterPage from "../../views/countries/index.vue";

export default [
  {
    path: "/countries",
    component: CountriesRouterPage,
    children: [
      {
        path: "",
        meta: { authorize: [] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "countries" */
            "../../views/countries/default/countries.vue"
          ),
      },
      {
        path: [":id/:pathMatch(.*)*"],
        meta: { authorize: [] },
        component: (): Promise<any> =>
          import(
            /* webpackChunkName: "countries" */
            "../../views/countries/detail/detail.vue"
          ),
      },
    ],
  },
];
