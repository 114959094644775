import { AppState } from "@gygadmin/client3";
import { LocationState } from "./types";
import { ActionContext, ActionTree } from "vuex";
import { LocationsService, TagsService } from "@client/services";
import { AggregatedLocation, LocationTypeEnum, TagType } from "@root/commons/types";

export const actions: ActionTree<LocationState, AppState> = {
  loadPage(context: ActionContext<LocationState, AppState>, locationId: number): void {
    context.commit("SET_PAGE_LOADING", true);
    LocationsService.get(locationId)
      .then((location) => {
        context.commit("SET_PAGE_LOCATION", location);
        context.commit("SET_PAGE_LOADING", false);
        if (location.type === LocationTypeEnum.Area) {
          TagsService.getAllByParams({ types: [TagType.LocationAreaType] }).then((areaTypes) =>
            context.commit("SET_PAGE_AREA_TYPES", areaTypes),
          );
        }
      })
      .catch((_) => {
        context.commit("SET_PAGE_LOADING", false);
      });
  },
  setLocationData(
    context: ActionContext<LocationState, AppState>,
    location: AggregatedLocation,
  ): void {
    context.commit("SET_PAGE_LOCATION", location);
  },
};

export default actions;
