<template>
  <div class="main-wrapper">
    <SidebarNavigation
      v-bind="{ ...$props, ...$attrs }"
      v-model:show-navigation="navigationToggle"
      :expand-on-hover="expandOnHover"
      :items="!hideNavigation ? navigationItems : []"
      :applet-name="domain"
      :user-id="userId"
      :user-name="userName"
      :user-picture="userPicture"
    />

    <div>
      <AppBar
        v-model:show-navigation="navigationToggle"
        :applet-name="domain"
        :domain="domain"
      >
        <template #search>
          <slot name="search" />
        </template>
      </AppBar>
    </div>

    <VMain :class="pageIdentifier">
      <VContainer
        v-show="showHeader"
        fluid
        class="page-header"
      >
        <VRow
          no-gutters
          class=""
        >
          <VCol
            class="text-h4"
            cols="12"
          >
            <div class="px-2 page-header-title">
              {{ pageTitle }}
            </div>
          </VCol>
          <VCol
            class="text-subtitle-1 mt-1"
            cols="12"
          >
            <div class="px-2 py-1 page-header-subtitle">
              {{ pageSubtitle }}
            </div>
          </VCol>
        </VRow>
      </VContainer>
      <VContainer
        v-if="!pageLoading"
        fluid
      >
        <slot />
      </VContainer>
      <VContainer
        v-show="pageLoading"
        fluid
        class="no-padding"
      >
        <VProgressLinear
          indeterminate
          color="primary"
        />
      </VContainer>
    </VMain>

    <slot name="body" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import SidebarNavigation from "./SidebarNavigation.vue";
import { NavigationItem } from "./types";
import { AppLink } from "./AppsMenu.types";
import AppBar from "../theme/AppBar.vue";
import { AuthService } from "@gygadmin/auth/client";
import { freshchat } from "../../configs/app";
import { User as AppUser } from "../../store/modules/user/types";
import { Role } from "@gygadmin/auth/roles";

const FRESHCHAT_URL = "https://wchat.eu.freshchat.com";

export default defineComponent({
  name: "ThemeDefault",
  components: {
    AppBar,
    SidebarNavigation,
  },
  props: {
    pageIdentifier: {
      default: "",
      type: String,
    },
    pageTitle: {
      default: "",
      type: String,
    },
    pageSubtitle: {
      default: "",
      type: String,
    },
    pageLoading: {
      default: false,
      type: Boolean,
    },
    hideHeader: {
      default: true,
      type: [Boolean, String],
    },
    hideNavigation: {
      default: false,
      type: [Boolean, String],
    },
    navigationItems: {
      default: () => [],
      type: Array as PropType<NavigationItem[]>,
    },
    switchLinks: {
      default: () => [],
      type: Array as PropType<AppLink[]>,
    },
    domain: {
      default: "",
      type: String,
    },
    userId: {
      default: "",
      type: String,
    },
    userName: {
      default: "",
      type: String,
    },
    userPicture: {
      default: "",
      type: String,
    },
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      navigationToggle: true,
    };
  },
  computed: {
    showHeader(): boolean {
      return this.hideHeader === false;
    },
  },
  mounted() {
    const currentUser = this.$store.getters["user/getState"] as AppUser;

    const allowedRoles = [
      Role.superAdmin,
      Role.customerService,
      Role.customerServiceSpecialist,
      Role.customerServiceFinanceSpecialist,
    ];

    const showFreshchat = currentUser?.roles?.some((e) => allowedRoles.includes(e)) && !!freshchat;
    if (showFreshchat) {
      const currentUser = AuthService.getAuth().currentUser;
      const widget = document.createElement("script");
      widget.setAttribute("src", `${FRESHCHAT_URL}/js/widget.js`);
      document.head.appendChild(widget);
      widget.addEventListener("load", function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.fcWidget.init({
          token: freshchat,
          host: FRESHCHAT_URL,
          locale: "en",
          externalId: currentUser?.email,
          config: {
            disableEvents: false,
            hideFAQ: false,
            agent: {
              hideBio: true,
            },
            headerProperty: {
              direction: "ltr",
              backgroundColor: "#1A2B49",
              foregroundColor: "#FFFFFF",
            },
            cssNames: {
              widget: "custom_fc_frame",
            },
          },
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.fcWidget.user.setProperties({
          firstName: currentUser?.displayName,
          email: currentUser?.email,
        });
      });
    }
  },
  methods: {
    showNavigation(): void {
      this.navigationToggle = !this.navigationToggle;
    },
  },
});
</script>
<style lang="scss">
@import "~@getyourguide/design-system/styles/colors/colors";
@import "~@getyourguide/design-system/styles/typography/fonts/GYG-Expressive/gyg_expressive";
@import "~@getyourguide/design-system/styles/typography/fonts/GT-Eesti/gt_eesti";
@import "~@getyourguide/design-system/styles/typography/typography";

$font-family-gyg: "GT Eesti", Arial, sans-serif;
$base-font-size: 16px;

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  font-family: $font-family;
  font-size: getFontValue(14px, $base-font-size);
  line-height: getFontValue(20px, $base-font-size);
  margin: 0;
  font-weight: 400 !important;
}

pre {
  font-family: $font-family-gyg;
}

.v-application {
  font-family: $font-family-gyg !important;
}

h1 {
  @include font-style("title-1");
}

h2 {
  @include font-style("title-2");
}

h3 {
  @include font-style("title-3");
}

caption {
  @include font-style("caption");
}

label {
  @include font-style("label");
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background: #f5f5f5;
}

.midnight-blue {
  background-color: $midnight-blue;
}

.page-header {
  background: url("../../../assets/images/88-amsterdam.jpg") repeat-x center;
  min-height: 100px;
}

.page-header-title,
.page-header-subtitle {
  display: block;
  float: left;
  background: $white;
  background: rgba(255, 255, 255, 0.8);
}

.main-wrapper .theme--light.v-card .v-card__subtitle,
.main-wrapper .theme--light.v-card > .v-card__text {
  color: rgba(0, 0, 0, 0.87);
}

.no-padding {
  padding: 0 !important;

  &__top {
    padding-top: 0 !important;
  }

  &__bottom {
    padding-bottom: 0 !important;
  }

  &__left {
    padding-left: 0 !important;
  }

  &__right {
    padding-right: 0 !important;
  }
}

.full-width {
  width: 100%;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.no-border {
  border: 0 !important;

  &__top {
    border-top: 0 !important;
  }

  &__bottom {
    border-bottom: 0 !important;
  }

  &__left {
    border-left: 0 !important;
  }

  &__right {
    border-right: 0 !important;
  }
}

.img-gyg-originals-label {
  background: url("../../../assets/images/gyg-originals-label.svg") no-repeat;
  background-size: cover;
  width: 58px;
  height: 18px;
  display: inline-block;
}

.block {
  display: block;
}

.line-height-20 {
  line-height: 20px;
}

.v-application .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0;
}

.sticky-container {
  position: sticky;
  top: 8px;
}

.inline-flex-field {
  all: inherit;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
}

.v-card-title {
  display: flex !important;
}

.custom_fc_frame {
  left: 60px !important;
  bottom: 10px !important;
  width: 40px;
}
</style>
