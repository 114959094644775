import RestClient from "@root/commons/utils/rest-client";
import { AxiosInstance } from "axios";
import { ActivityOption, ActivityOptionStatusTransition, SpecialOffer } from "@root/commons/types";

export class ActivityOptionsService {
  restClient: AxiosInstance;

  constructor() {
    this.restClient = RestClient.create({
      baseURL: "/api",
    });
  }

  /**
   * Fetch all options based on the provided activity id.
   *
   * @param activityId
   */
  async fetchAllByActivity(activityId: number): Promise<ActivityOption[]> {
    return this.restClient
      .get(`/activities/${activityId}/options`)
      .then((response) => response.data)
      .catch((_) => []);
  }

  /**
   * Fetch all special offers based on the provided activity id.
   *
   * @param activityId
   */
  async fetchAllSpecialOffersByActivity(activityId: number): Promise<ActivityOption[]> {
    return this.restClient
      .get(`/activities/${activityId}/options/special-offers`)
      .then((response) => response.data)
      .catch(() => []);
  }

  /**
   * Fetch an option based on the provided id.
   *
   * @param id
   */
  async get(id: number): Promise<ActivityOption> {
    return this.restClient
      .get(`/activities/options/${id}`)
      .then((response) => response.data)
      .catch((error) => error.message);
  }

  /**
   * Update an option.
   *
   * @param optionId
   * @param option
   */
  async update(optionId: number, option: ActivityOption): Promise<ActivityOption> {
    return this.restClient
      .put(`/activities/options/${optionId}`, option)
      .then((response) => response.data as ActivityOption);
  }

  /**
   * Transitions the status of an option.
   *
   * @param optionId
   * @param transition
   * @param reason
   */
  async transitionStatus(
    optionId: number,
    transition: ActivityOptionStatusTransition,
    reason?: string,
  ): Promise<ActivityOption> {
    return this.restClient
      .put(`/activities/options/${optionId}/transition-status/${transition}`, { reason })
      .then((response) => response.data as ActivityOption);
  }

  /**
   * Send meeting point changed email.
   *
   * @param optionId
   * @param languageId
   */
  async sendMeetingPointChangedEmail(optionId: number, languageId?: number): Promise<boolean> {
    return this.restClient
      .post(`/activities/options/${optionId}/send-email/meeting-point-changed`, { languageId })
      .then((resp) => resp.data.success === true);
  }

  /**
   * Create a new special offer.
   *
   * @param optionId
   * @param offer
   */
  async createSpecialOffer(optionId: number, offer: SpecialOffer): Promise<ActivityOption> {
    return this.restClient
      .post(`/activities/options/${optionId}/make-special-offer`, offer)
      .then((resp) => resp.data);
  }
}

export default new ActivityOptionsService();
