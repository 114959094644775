<template>
  <VMenu transition="slide-y-transition">
    <template #activator="{ props }">
      <VBtn
        size="large"
        variant="flat"
        icon="mdi-apps"
        class="mt-1"
        v-bind="props"
      />
    </template>
    <VList density="compact">
      <VListSubheader class="text-uppercase">
        Switch To
      </VListSubheader>
      <VListItem
        v-for="(appLink, i) in switchLinks"
        :key="i"
        density="compact"
        :href="appLink.url"
      >
        <template #prepend>
          <VIcon size="small">
            mdi-open-in-new
          </VIcon>
        </template>
        <VListItemTitle>
          {{ appLink.name }}
        </VListItemTitle>
      </VListItem>
    </VList>
  </VMenu>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AppLink } from "./AppsMenu.types";

export default defineComponent({
  name: "AppsMenu",
  props: {
    switchLinks: {
      type: Array as PropType<AppLink[]>,
      default: () => [],
    },
  },
});
</script>
