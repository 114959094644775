/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Internal Management API
 * API for exclusive use with the Catalog Frontend. DO NOT USE ELSEWHERE! BACKWARDS COMPATIBILITY NOT GUARANTEED!
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: marketplace.catalog@getyourguide.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Contains all valid tag types
 * @export
 * @enum {string}
 */
export enum TagType {
    Food = 'food',
    FoodType = 'food_type',
    DietaryRestrictions = 'dietary_restrictions',
    Drinks = 'drinks',
    DrinksAlcoholic = 'drinks_alcoholic',
    DrinksNonAlcoholic = 'drinks_non_alcoholic',
    ItemsToBring = 'items_to_bring',
    Keywords = 'keywords',
    InternalKeywords = 'internal_keywords',
    Restrictions = 'restrictions',
    NotSuitableFor = 'not_suitable_for',
    ItineraryActivity = 'itinerary_activity',
    RentalItems = 'rental_items',
    Theme = 'theme',
    Seasonal = 'seasonal',
    Audience = 'audience',
    ConfiguratorFoodAndDrink = 'configurator_food_and_drink',
    UnclassifiedAttribute = 'unclassified_attribute',
    ThemeGroup = 'theme_group',
    LocationAreaType = 'location_area_type',
    ItineraryGenericLocation = 'itinerary_generic_location'
}



