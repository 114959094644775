import {
  ColumnDefinition,
  ColumnType,
  DatatableItem,
  DatatableOptions,
  FilterType,
  SortDirection,
  TextAlign,
} from "../models";

export const multipleValueFilters = [
  FilterType.AutoCompleteMultiple,
  FilterType.DropdownMultiple,
  FilterType.Checkbox,
];

export const objectValueFilters = [FilterType.NumberRange];

export const defaultColumnDefinition: ColumnDefinition = {
  name: "",
  label: "",
  visible: true,
  type: ColumnType.TEXT,
  align: TextAlign.Left,
  class: "",
  options: {
    defaultValue: "",
    filter: {
      enable: true,
      type: FilterType.Text,
      value: null,
      options: [],
      loading: false,
      apply(column: ColumnDefinition, items: DatatableItem[]): DatatableItem[] {
        const filterName = column.options?.filter?.name || column.name;
        const filter = column.options?.filter;
        return items.filter((item) => {
          if (filter !== undefined && filter.value != null && item[filterName] !== undefined) {
            const itemValue = item[filterName];
            if (multipleValueFilters.includes(filter.type as FilterType)) {
              const filterValues = Array.isArray(filter.value) ? filter.value : [];
              if (filterValues.length === 0) {
                return true;
              }
              return filterValues.includes(String(itemValue));
            }
            if (column.type === ColumnType.TEXT) {
              return String(itemValue).includes(String(filter.value));
            }
            return String(itemValue) === String(filter.value);
          }
          return true;
        });
      },
    },
    sort: {
      enable: true,
      direction: SortDirection.None,
      priority: 0,
    },
  },
};

export const defaultColumnDefinitionMultiValueFilter: ColumnDefinition = {
  ...defaultColumnDefinition,
  options: {
    ...defaultColumnDefinition.options,
    filter: {
      ...defaultColumnDefinition.options?.filter,
      enable: true,
      value: [],
    },
  },
};

export const defaultColumnDefinitionObjectFilter: ColumnDefinition = {
  ...defaultColumnDefinition,
  options: {
    ...defaultColumnDefinition.options,
    filter: {
      ...defaultColumnDefinition.options?.filter,
      enable: true,
      value: {},
    },
  },
};

export const defaultDatatableOptions: DatatableOptions = {
  hideDefaultHeader: false,
  hideDefaultFooter: false,
  dense: false,
  showSelect: false,
  clickableRows: false,
  server: {
    enable: false,
    location: "#",
  },
  footer: {
    itemsPerPageOptions: [10, 25, 50, 100],
  },
  sort: {
    enable: true,
    multiSort: true,
  },
  filter: {
    open: false,
    enable: true,
    list: [],
  },
  localization: {
    filterTitle: "Filters",
    filterSearchBtn: "Apply",
    filterSearchResetBtn: "Clear",
  },
  pagination: true,
};
