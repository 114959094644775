import { gql } from "@gygadmin/client3";

export const ConnectivitySettingsOptionByOptionId = gql`
  query ActivityOption($activityOptionId: ID!) {
    activityOption(id: $activityOptionId) {
      connectivitySettings {
        managedByConnector
        acquisitionChannel
        externalProductId
        availabilityUpdateEnabled
        priceUpdateEnabled
        sourceConfigKey
        clientId
        target
      }
    }
  }
`;

export const UpdateConnectivitySettings = gql`
  mutation UpdateConnectivitySettings(
    $tourOptionId: Int!
    $input: ConnectivitySettingsInput!
    $actionSendEmail: Boolean
  ) {
    updateConnectivitySettings(
      tourOptionId: $tourOptionId
      input: $input
      actionSendEmail: $actionSendEmail
    ) {
      success
      message
    }
  }
`;
