import { CountryState } from "./types";

export const initialState: CountryState = {
  page: {
    loading: false,
    tabs: {
      countryMap: {
        loading: false,
      },
      countryPictures: {
        loading: false,
        pictures: [],
      },
    },
  },
};
