import { gql } from "@gygadmin/client3";

export const ActivityDatatable = gql`
  query ActivityDatatable($input: SearchInput!) {
    activitySearch(input: $input) {
      items {
        id
        bestseller
        sourceText {
          title
        }
        online
        status
        category
        originals
        supplierActivityCode
        isTestData
      }
      pagination {
        totalItems
        offset
        limit
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GetActivityKeywordsSuggestion = gql`
  query GetActivityKeywordsSuggestion($activityId: ID!) {
    activity(id: $activityId) {
      keywordsSuggestion {
        id
      }
    }
  }
`;
